import Quill from "quill";
const Parchment = Quill.import("parchment");

export class IndentAttributor extends Parchment.StyleAttributor {
    add(node, value) {
        if (value === 0) {
            this.remove(node);
            return true;
        } else {
            return super.add(node, `${value}em`);
        }
    }
}

export default IndentAttributor;
