import React from "react";
import PropTypes from "prop-types";
import SalarySettings from "./SalarySettings";

function WorkTab({ loading, setParentLoading, styles = {} }) {
    return (
        <div className="work-tab flex column gap-1" style={styles.parent || {}}>
            <SalarySettings loading={loading} setParentLoading={setParentLoading} readOnly />
        </div>
    );
}

export default WorkTab;

WorkTab.propTypes = {
    loading: PropTypes.bool,
    setParentLoading: PropTypes.func,
    styles: PropTypes.shape({
        parent: PropTypes.object
    })
};
