import React from "react";
import PropTypes from "prop-types";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import Divider from "../../../common/components/extra/Divider";
import Card from "../../../common/components/extra/Card";
import Select from "../../../common/components/extra/select/Select";
import { setPreventDefault } from "../../../common/utilities/helper";
import Controls from "./Controls";
import { GENERAL_FIELDS, MOBILE_FIELDS } from "./const";
import { useManageGeneralState } from "./hooks";
import { createGeneralOptions } from "./helper";

function GeneralTab({ setParentLoading, onLoading, styles = {} }) {
    const [setting, isLoading, { hasChanges, hasError, user, isEditing, handleCancel, handleSubmit, handleToggle, toggleEdit, handleChange }] =
        useManageGeneralState((bool) => {
            setParentLoading?.(bool);
            onLoading?.(bool);
        });

    const totalEmployees = user.totalEmployees;
    const hasArabicName = user.arabic_name;

    const { hourFormats, timezones, currencies, maxTimeInOutSVOpt, maxTimeInOutEMPOpt, minutes } = createGeneralOptions({ totalEmployees });

    const findMinuteValue = (val, conf = { from: 0, to: 60 }) => minutes(conf).find((min) => min.value == val) || "";

    const inputPropsValue = (value) => {
        if (isEditing) {
            return { value };
        } else {
            return { renderValue: value, readOnly: true };
        }
    };

    const renderInput = ({ name, label, value, onChange, options, afterExtra, ...rest } = {}) => {
        if (isEditing) {
            return (
                <Select
                    options={options}
                    onChange={(val) => onChange({ target: { name, value: val.value } })}
                    value={value}
                    name={name}
                    label={label}
                    placeholder=""
                    style={{ flex: "30%" }}
                    isClearable={false}
                    menuPortalTarget={document.body}
                    isOutlined
                    disabledOutline
                    {...rest}
                />
            );
        }
        return (
            <Input
                type={INPUT_TYPE.TEXT}
                name={name}
                label={label}
                onChange={onChange}
                afterExtra={afterExtra}
                isLoading={isLoading}
                {...inputPropsValue(typeof value === "object" && "label" in value ? value.label : value)}
            />
        );
    };

    return (
        <Card className="box-shadow-mini" styles={{ parent: styles.parent || {} }}>
            <form className="profile-tab flex column" style={{ gap: "2rem" }} onSubmit={setPreventDefault(handleSubmit)}>
                <div className="flex column">
                    <Divider title="Manage General Settings" />
                    <p className="fade small-font">
                        The general tab holds vital settings like mobile configurations, time zone adjustments, and time-in/out setups for streamlined
                        operations.
                    </p>
                    <div className="flex column gap-1" style={{ gap: "2rem" }}>
                        <section className="flex column gap-05">
                            {renderInput({
                                name: GENERAL_FIELDS.TIMEZONE,
                                label: "Timezone",
                                onChange: handleChange,
                                value: timezones.find((tz) => tz.value === setting[GENERAL_FIELDS.TIMEZONE]) || "",
                                options: timezones,
                                autoFocus: true
                            })}
                            {renderInput({
                                name: GENERAL_FIELDS.CURRENCY,
                                label: "Currency",
                                onChange: handleChange,
                                value: currencies.find((currency) => currency.value === setting[GENERAL_FIELDS.CURRENCY]) || "",
                                options: currencies
                            })}
                            {renderInput({
                                name: GENERAL_FIELDS.TIME_FORMAT,
                                label: "Time Format",
                                onChange: handleChange,
                                value: hourFormats.find((hf) => hf.value === setting[GENERAL_FIELDS.TIME_FORMAT]) || "",
                                options: hourFormats
                            })}
                            {hasArabicName && (
                                <Input
                                    type={INPUT_TYPE.TOGGLE}
                                    name={GENERAL_FIELDS.USE_ARABIC_NAME}
                                    label="Use Arabic Name"
                                    value={setting?.[GENERAL_FIELDS.USE_ARABIC_NAME]}
                                    renderValue={setting?.[GENERAL_FIELDS.USE_ARABIC_NAME] ? "Yes" : "No"}
                                    onChange={handleToggle}
                                    readOnly={!isEditing}
                                    parentStyle={{ paddingRight: ".5rem" }}
                                    tooltip="Arabic Name will be used on the header instead of the english name."
                                />
                            )}
                        </section>
                        <section className="flex column gap-05">
                            <span className="bold" style={{ marginLeft: ".5rem" }}>
                                Mobile
                            </span>
                            <div className="flex column" style={{ marginLeft: "1rem" }}>
                                {renderInput({
                                    name: "mobile_app." + MOBILE_FIELDS.EARLY_TIME_IN,
                                    label: "Early Time In",
                                    onChange: handleChange,
                                    value: findMinuteValue(setting.mobile_app?.[MOBILE_FIELDS.EARLY_TIME_IN], { from: 0, to: 60 }),
                                    options: minutes({ from: 0, to: 60 })
                                })}
                                {renderInput({
                                    name: "mobile_app." + MOBILE_FIELDS.EARLY_TIME_OUT,
                                    label: "Early Time Out",
                                    onChange: handleChange,
                                    value: findMinuteValue(setting.mobile_app?.[MOBILE_FIELDS.EARLY_TIME_OUT], { from: 0, to: 60 }),
                                    options: minutes({ from: 0, to: 60 })
                                })}
                                {renderInput({
                                    name: "mobile_app." + MOBILE_FIELDS.DEFAULT_LATE_GRACE_PERIOD,
                                    label: "Late Grace Period",
                                    onChange: handleChange,
                                    value: findMinuteValue(setting.mobile_app?.[MOBILE_FIELDS.DEFAULT_LATE_GRACE_PERIOD], { from: 0, to: 30 }),
                                    options: minutes({ from: 0, to: 30 })
                                })}
                                {renderInput({
                                    name: "mobile_app." + MOBILE_FIELDS.BREAK_GRACE_PERIOD,
                                    label: "Break Grace Period",
                                    onChange: handleChange,
                                    value: findMinuteValue(setting.mobile_app?.[MOBILE_FIELDS.BREAK_GRACE_PERIOD], { from: 0, to: 30 }),
                                    options: minutes({ from: 0, to: 30 })
                                })}
                                {renderInput({
                                    name: "mobile_app." + MOBILE_FIELDS.BG_LOCATION_GRACE_PERIOD,
                                    label: "Location Grace Period",
                                    onChange: handleChange,
                                    value: findMinuteValue(setting.mobile_app?.[MOBILE_FIELDS.BG_LOCATION_GRACE_PERIOD], { from: 15, to: 60 }),
                                    options: minutes({ from: 15, to: 60 })
                                })}
                            </div>
                        </section>
                        <div className="flex column gap-05">
                            <span className="bold" style={{ marginLeft: ".5rem" }}>
                                Max Time In/Out by Supervisors
                            </span>
                            <div className="flex column" style={{ marginLeft: "1rem" }}>
                                {renderInput({
                                    name: "mobile_app." + MOBILE_FIELDS.MAX_SV_TIMEINOUT,
                                    label: "For Employees",
                                    onChange: handleChange,
                                    value: maxTimeInOutEMPOpt.find((opt) => opt.value == setting.mobile_app?.maxSvTimeInOutEmp) || "",
                                    options: maxTimeInOutEMPOpt,
                                    menuPlacement: "top"
                                })}
                                {renderInput({
                                    name: "mobile_app." + MOBILE_FIELDS.MAX_EMP_TIMEINOUT,
                                    label: "For Supervisors",
                                    onChange: handleChange,
                                    value: maxTimeInOutSVOpt.find((opt) => opt.value == setting.mobile_app?.maxEmpTimeInOutSv) || "",
                                    options: maxTimeInOutSVOpt,
                                    menuPlacement: "top"
                                })}
                                {isEditing && (
                                    <span className="small-font fade" style={{ marginLeft: ".5rem", marginTop: ".5rem" }}>
                                        Note: 0 = unlimited
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <Controls
                    onCancel={handleCancel}
                    onEdit={toggleEdit}
                    isLoading={isLoading}
                    isEditing={isEditing}
                    disableSave={hasError || isLoading || !hasChanges}
                />
            </form>
        </Card>
    );
}

export default GeneralTab;

GeneralTab.propTypes = {
    loading: PropTypes.bool,
    setParentLoading: PropTypes.func,
    onLoading: PropTypes.func,
    styles: PropTypes.shape({
        parent: PropTypes.object
    })
};
