import moment from "moment-timezone";
import { getTimeDuration, toTimeWithTimeZone } from "../../../utilities/helper";

export const getDateSelectionAvailability = (date, constraint, timezone, offsetMinutesMin = 0, offsetMinutesMax = 0) => {
    let show = false;

    if (date) {
        const selectedTime = getTimeDuration(date, timezone);

        // Calculate offsets in milliseconds
        const offsetMinMs = offsetMinutesMin * 60 * 1000; // Add this to minTime
        const offsetMaxMs = offsetMinutesMax * 60 * 1000; // Subtract this from maxTime

        const max = constraint?.max && moment(constraint.max.toDate()).tz(timezone);
        const min = constraint?.min && moment(constraint.min.toDate()).tz(timezone);

        const minTime = min ? getTimeDuration(min, timezone) + offsetMinMs : null;
        const maxTime = max ? getTimeDuration(max, timezone) - offsetMaxMs : null;

        if (minTime !== null && maxTime === null) {
            show = selectedTime >= minTime;
        } else if (minTime === null && maxTime !== null) {
            show = selectedTime <= maxTime;
        } else if (minTime !== null && maxTime !== null) {
            // Handle the scenario where the range spans midnight
            if (minTime > maxTime) {
                show = selectedTime > minTime || selectedTime < maxTime;
            } else {
                if (minTime == maxTime) {
                    show = true;
                } else {
                    show = selectedTime > minTime && selectedTime < maxTime;
                }
            }
        } else {
            // No constraints, allow any time
            show = true;
        }
    }
    return show;
};

export const checkIfEndIsForNextDay = ({ end, start }) => {
    const temp = {
        start: start || null,
        end: end || null
    };
    if (end && start && temp.end.isSameOrBefore(temp.start)) {
        temp.end = moment(end).add(1, "day");
    }
    return temp;
};

export const isStartAlreadyNextDay = (start, base, timezone) => {
    if (!start) {
        return false;
    }
    return toTimeWithTimeZone(base, timezone).get("D") != toTimeWithTimeZone(start, timezone).get("D");
};
