import React from "react";
import moment from "moment-timezone";
import {
    createMockData,
    createTextFromDate,
    hoursDiff,
    roundToNearestTenth,
    sanitizeWords,
    toTimeWithTimeZone
} from "../../../common/utilities/helper";
import { SHIFT_TYPE } from "../../../common/utilities/const";
import { TIME_TYPE, TABS, FIELDS, BREAK_TYPE } from "./const";
import WorkShift from "./WorkShift";

const TAB_COMPONENT_STYLES = {
    pagination: {},
    table: {}
};

export const createWorkShiftTabs = (tabs, props, activeTab) => {
    return Object.values(tabs)
        .filter((tab) => tab.isActive)
        .map((tab) => {
            switch (tab.id) {
                case TABS.REGULAR_WORKSHIFT.id: {
                    tab.component = activeTab == tab.id && <WorkShift {...props} styles={TAB_COMPONENT_STYLES} height={29} />;
                    break;
                }
                case TABS.HOLIDAY_WORKSHIFT.id: {
                    tab.component = activeTab == tab.id && <WorkShift {...props} styles={TAB_COMPONENT_STYLES} height={29} isHoliday />;
                    break;
                }
                default: {
                    break;
                }
            }
            return tab;
        });
};

export const isReqHoursSatisfied = (reqTime = null, form, internalForms) => {
    if (!reqTime) return null;

    const startTimeOne = form[FIELDS.START_TIME.name];
    const endTimeOne = form[FIELDS.END_TIME.name];
    const breakOne = form[FIELDS.BREAK_TIME.name];
    const breakOneEnd = form[FIELDS.BREAK_END_TIME.name];

    const startTimeTwo = form[FIELDS.START_TIME_2.name];
    const endTimeTwo = form[FIELDS.END_TIME_2.name];
    const breakTwo = form[FIELDS.BREAK_TIME_2.name];
    const breakTwoEnd = form[FIELDS.BREAK_END_TIME_2.name];
    const maxBreakHours = form[FIELDS.MAX_BREAK_DURATION.name];

    const isFlexibleBreak = internalForms.breakType == BREAK_TYPE.FLEXIBLE_BREAK;

    const shift1Diff = roundToNearestTenth(hoursDiff(startTimeOne, endTimeOne)) || 0;
    const shift2Diff = roundToNearestTenth(hoursDiff(startTimeTwo, endTimeTwo)) || 0;
    const breakDiff = roundToNearestTenth(hoursDiff(breakOne, breakOneEnd)) || 0;
    const break2Diff = roundToNearestTenth(hoursDiff(breakTwo, breakTwoEnd)) || 0;
    const totalBreakDiff = isFlexibleBreak ? Number(maxBreakHours) : breakDiff + break2Diff;
    const total = shift1Diff + shift2Diff;

    return roundToNearestTenth(total) - roundToNearestTenth(totalBreakDiff) >= reqTime;
};

export const isBreakSatisfied = (form) => {
    const maxBreakHours = form[FIELDS.MAX_BREAK_DURATION.name];

    if (!maxBreakHours) {
        return { isSatisfied: true };
    }

    const breakOne = form[FIELDS.BREAK_TIME.name];
    const breakOneEnd = form[FIELDS.BREAK_END_TIME.name];

    const breakTwo = form[FIELDS.BREAK_TIME_2.name];
    const breakTwoEnd = form[FIELDS.BREAK_END_TIME_2.name];

    if (!breakOne && !breakTwo) {
        return { isSatisfied: true };
    }

    const breakDiff = roundToNearestTenth(hoursDiff(breakOne, breakOneEnd)) || 0;
    const break2Diff = roundToNearestTenth(hoursDiff(breakTwo, breakTwoEnd)) || 0;
    const totalBreak = roundToNearestTenth(breakDiff + break2Diff);

    const isSatisfied = totalBreak == maxBreakHours;
    const isNotMet = totalBreak < maxBreakHours;
    const isExceeded = totalBreak > maxBreakHours;

    if (isSatisfied) {
        return { isSatisfied: true };
    }

    return { isSatisfied, isNotMet, isExceeded };
};

export const getTimeRangeValues = (form = {}, isSplit, timezone) => {
    const formatTime = (time) => moment(moment.tz(time, timezone).format());

    const shiftOne = {
        range: {
            start: (form.start_time && formatTime(form.start_time)) || null,
            end: (form.end_time && formatTime(form.end_time)) || null
        },
        constraint: {}
    };

    const isShiftOneNextDay = isNextDay({
        startTimeOne: shiftOne.range.start,
        endTimeOne: shiftOne.range.end
    });

    const shiftTwo = {
        range: {
            start: (form.start_time_2 && formatTime(form.start_time_2)) || null,
            end: (form.end_time_2 && formatTime(form.end_time_2)) || null
        },
        constraint: {
            start: {
                min: (isShiftOneNextDay && shiftOne.range.end) || null
            },
            end: {
                min: (isShiftOneNextDay && form.start_time_2 && formatTime(form.start_time_2)) || null
            }
        }
    };
    const breakTime = {
        range: {
            start: (form.break_time && formatTime(form.break_time)) || null,
            end: (form.break_end_time && formatTime(form.break_end_time)) || null
        },
        constraint: {
            start: {
                min: shiftOne.range.start || null,
                max: shiftOne.range.end || null
            },
            end: {
                min: (form.break_time && formatTime(form.break_time)) || null,
                max: shiftOne.range.end || null
            }
        }
    };
    const breakTimeTwo = {
        range: {
            start: (form.break_time_2 && formatTime(form.break_time_2)) || null,
            end: (form.break_end_time_2 && formatTime(form.break_end_time_2)) || null
        },
        constraint: {
            start: {
                min: shiftTwo.range.start || null,
                max: shiftTwo.range.end || null
            },
            end: {
                min: (form.break_time_2 && formatTime(form.break_time_2)) || null,
                max: shiftTwo.range.end || null
            }
        }
    };

    return {
        [TIME_TYPE.SHIFT_ONE]: shiftOne,
        [TIME_TYPE.SHIFT_TWO]: shiftTwo,
        [TIME_TYPE.BREAK]: breakTime,
        [TIME_TYPE.BREAK_TWO]: breakTimeTwo
    };
};

export const isNextDay = (shift1, shift2, isSplit) => {
    if (!shift1.startTimeOne || !shift1.endTimeOne) {
        return false;
    }

    const startTimeOne = moment(shift1.startTimeOne);
    const endTimeOne = moment(shift1.endTimeOne);
    const startTimeOneIso = startTimeOne.format("YYYY-MM-DD");
    const endTimeOneIso = endTimeOne.format("YYYY-MM-DD");

    if (isSplit) {
        if (!shift2.startTimeTwo || !shift2.endTimeTwo) {
            return false;
        }
        const startTimeTwo = moment(shift2.startTimeTwo);
        const endTimeTwo = moment(shift2.endTimeTwo);
        const startTimeTwoIso = startTimeTwo.format("YYYY-MM-DD");
        const endTimeTwoIso = endTimeTwo.format("YYYY-MM-DD");
        if (startTimeTwoIso != endTimeOneIso || endTimeTwoIso !== endTimeOneIso) {
            return true;
        }
        if (startTimeTwo.isBefore(endTimeOne) || startTimeTwo.isBefore(startTimeOne)) {
            return true;
        }
    } else {
        if (startTimeOneIso != endTimeOneIso) {
            return true;
        }
    }
    return false;
};

export const createWorkShiftInfo = (workShift, timezone) => {
    return {
        title: sanitizeWords(workShift.title),
        date: workShift.start_time && createTextFromDate(workShift.start_time, workShift.end_time_2, timezone).date,
        shiftOne: workShift.start_time && createTextFromDate(workShift.start_time, workShift.end_time, timezone).time,
        breakOne: workShift.break_time && createTextFromDate(workShift.break_time, workShift.break_end_time, timezone).time,
        shiftTwo: workShift.start_time_2 && createTextFromDate(workShift.start_time_2, workShift.end_time_2, timezone).time,
        breakTwo: workShift.break_time_2 && createTextFromDate(workShift.break_time_2, workShift.break_end_time_2, timezone).time,
        shiftType: sanitizeWords(workShift.shift_type),
        isSplit: workShift.shift_type == SHIFT_TYPE.SPLIT,
        maxOT: workShift.max_overtime,
        maxBreak: workShift.max_break_duration,
        requiredShiftTime: workShift.required_shift_time
    };
};

export const checkShiftDayDifferences = (form, timezone) => {
    let result = {
        totalDayDifference: 0, // instead of 0 we use 1 we will declare the start date as 1 day already
        remainingHrsUntilEndDay: 24
    };

    const start = form[FIELDS.START_TIME.name];
    const end = form[FIELDS.END_TIME.name];
    const startTwo = form[FIELDS.START_TIME_2.name];
    const endTwo = form[FIELDS.END_TIME_2.name];

    if (start && end) {
        const shiftOneStart = toTimeWithTimeZone(start, timezone);
        const shiftOneEnd = toTimeWithTimeZone(end, timezone);
        const shiftTwoStart = startTwo && toTimeWithTimeZone(startTwo, timezone);
        const shiftTwoEnd = endTwo && toTimeWithTimeZone(endTwo, timezone);

        // Get the minimum start date and maximum end date to calculate the overall span
        const minStartDate = !shiftTwoStart || (shiftTwoStart && shiftOneStart.isBefore(shiftTwoStart)) ? shiftOneStart : shiftTwoStart;
        const maxEndDate = !shiftTwoEnd || (shiftTwoEnd && shiftOneEnd.isAfter(shiftTwoEnd)) ? shiftOneEnd : shiftTwoEnd;

        // Calculate the total day difference from the minimum start to the maximum end
        // declare the start date as 1 day already so we +1 so instead of 0
        result.totalDayDifference = moment(maxEndDate.format()).startOf("day").diff(moment(minStartDate.format()).startOf("day"), "days", true);
        result.remainingHrsUntilEndDay = moment(maxEndDate.format()).endOf("day").diff(moment(maxEndDate.format()), "hours", true);
    }
    return result;
};

export const createHoursOption = ({ max, min = 0 } = {}, noLabel = "") => {
    max = max * 2 + 1;
    return createMockData(
        max,
        (value) => ({
            value: value ? value / 2 : 0,
            label: <span className="semi-bold">{!value ? noLabel || value : <>{(value ? value / 2 : 0).toFixed(1)}</>}</span>
        }),
        min
    );
};

export const createHourLabel = (options, value) => {
    const option = options.find((ctr) => ctr.value === value);
    return option
        ? {
              ...(option || {}),
              label: option.value ? (
                  <span className="semi-bold">
                      {option.value} <span className="fade semi-bold">Hour(s)</span>
                  </span>
              ) : (
                  option.label
              )
          }
        : "";
};
