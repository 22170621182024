import React from "react";
import PropTypes from "prop-types";
import LetteredAvatar from "./LetteredAvatar";
import Tag from "../Tag";
import { sanitizeWords } from "../../../utilities/helper";
import { createPublicApi } from "../../../../app/apiSlice";
import { selectUser } from "../../../../features/common/slice";
import { useAppSelector } from "../../../hooks/reduxHooks";

function EmployeeAvatar({ id, filename, fullname, isLoading, designation, department }) {
    const user = useAppSelector(selectUser);

    const src = !isLoading && filename ? createPublicApi({ companyId: user.id, id, filename }) : "";

    return (
        <div className="tk-employee-avatar">
            <div className="flex column center gap-05">
                <LetteredAvatar src={src} isLoading={isLoading} name={fullname} size={60} fetchConfig={{ filename, nofetch: true }} hasborder />
                {!isLoading && (
                    <>
                        <div className="name flex gap-05 center wrap">
                            <span className="bold">{sanitizeWords(fullname)}</span>
                            {designation && <Tag>{designation}</Tag>}
                        </div>
                        {department && (
                            <div className="designation small-font">
                                <span className="fade">{sanitizeWords(department)}</span>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

export default EmployeeAvatar;

EmployeeAvatar.propTypes = {
    id: PropTypes.any,
    filename: PropTypes.string,
    fullname: PropTypes.string,
    isLoading: PropTypes.bool,
    designation: PropTypes.string,
    department: PropTypes.string
};
