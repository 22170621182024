import React from "react";

function ContactUs() {
    return (
        <div className="tk-contact-us flex gap-05 center">
            Need help?
            <strong>
                <a href="mailto:support@time-keeper.app">Contact customer support</a>
            </strong>
        </div>
    );
}

export default ContactUs;
