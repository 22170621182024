import React from "react";
import PropTypes from "prop-types";
import ReactSelect from "react-select";
import CreatableSelect from "react-select/creatable";
import AsyncSelect from "react-select/async";
import InfoIcon from "@mui/icons-material/Info";
import SelectVirtual from "./SelectVirtual";
import MyTooltip from "../Tooltip";
import Input, { INPUT_TYPE } from "../form/Input";
import ConditionWrapper from "../form/ConditionWrapper";

function Select({
    style = {},
    isClearable,
    isOutlined,
    disabledOutline,
    label,
    customNoOption,
    isCustom,
    noborder,
    onChange,
    async,
    virtualize,
    tooltip,
    readOnly,
    subtext,
    warning,
    error,
    useSubTextStyle,
    errorProps,
    warningProps,
    ...rest
}) {
    const isMulti = rest.isMulti;
    const hasCondition = !!warning?.length || !!error?.length;

    const NoOptionsMessage = () => {
        return <div className="tk-select--no-option">{customNoOption || "No options"}</div>;
    };

    const handleChange = (data, ...rest) => {
        if (!data) {
            data = { value: "" };
        }
        typeof onChange === "function" && onChange(data, ...rest);
    };

    const selectProps = {
        placeholder: "",
        ...rest,
        components: customNoOption ? { NoOptionsMessage } : {},
        classNamePrefix: "tk-select-inner",
        isClearable: rest.required ? false : isClearable,
        onChange: handleChange,
        styles: {
            ...(rest?.styles || {}),
            menuPortal: (base) => ({ ...base, zIndex: 99999, maxHeight: 200 }),
            control: (base) => ({ ...base, ...(rest?.styles?.control || {}), ...(disabledOutline ? { border: 0, boxShadow: "none" } : {}) }),
            menuList: (provided) => ({
                ...provided,
                maxHeight: 230, // Set the maximum height for the list of options
                overflowY: "auto" // Ensure the list is scrollable if it exceeds max height
            }),
            multiValueRemove: (base, state) => (state.data.isFixed ? { ...base, display: "none" } : base)
        }
    };

    if (isCustom) {
        selectProps.noOptionsMessage = () => "Type/Select to Create a new Option.";
    }

    if (readOnly) {
        return (
            <Input
                type={INPUT_TYPE.TEXT}
                label={label}
                renderValue={<span style={{ whiteSpace: "nowrap" }}>{selectProps?.value?.label}</span>}
                parentStyle={{ minWidth: "unset" }}
                readOnly
            />
        );
    }

    return (
        <ConditionWrapper
            warnings={warning}
            errors={error}
            subtext={subtext}
            useSubTextStyle={useSubTextStyle}
            errorProps={errorProps}
            warningProps={warningProps}
        >
            <div
                className={`tk-select ${isMulti ? "tk-select--multi" : ""} ${isOutlined ? "tk-select--outlined" : ""} ${
                    label ? "tk-select--right" : ""
                } ${!hasCondition && noborder ? "tk-select--noborder" : ""}  ${label ? "tk-select--has-label" : ""}`.trim()}
                style={style || {}}
            >
                {label && (
                    <div className="tk-select__label flex gap-05">
                        <span>
                            {label}
                            {rest.required && !readOnly && !rest.isDisabled ? <span className="danger-color bold">*</span> : ""}
                        </span>
                        {tooltip && (
                            <MyTooltip message={tooltip} className="flex">
                                <InfoIcon className="hover-svg" style={{ width: "1rem" }} />
                            </MyTooltip>
                        )}
                    </div>
                )}
                {async ? (
                    <AsyncSelect cacheOptions defaultOptions {...selectProps} />
                ) : isCustom ? (
                    <CreatableSelect {...selectProps} />
                ) : virtualize ? (
                    <SelectVirtual {...selectProps} />
                ) : (
                    <ReactSelect {...selectProps} />
                )}

                <input type="hidden" name="" required={rest.required} />
            </div>
        </ConditionWrapper>
    );
}

Select.propTypes = {
    onChange: PropTypes.func,
    label: PropTypes.any,
    style: PropTypes.object,
    isClearable: PropTypes.bool,
    noborder: PropTypes.bool,
    isCustom: PropTypes.bool,
    async: PropTypes.bool,
    isOutlined: PropTypes.bool,
    disabledOutline: PropTypes.bool,
    customNoOption: PropTypes.any,
    virtualize: PropTypes.bool,
    tooltip: PropTypes.any,
    readOnly: PropTypes.bool,
    subtext: PropTypes.shape({
        style: PropTypes.object,
        className: PropTypes.string,
        message: PropTypes.any,
        hide: PropTypes.bool
    }),
    warning: PropTypes.array,
    error: PropTypes.array,
    errorProps: PropTypes.object,
    warningProps: PropTypes.object,
    useSubTextStyle: PropTypes.bool
};

export default Select;
