import { IsMatch, file2b64, validate } from "./helper";

/*
 * From: https://github.com/kensnyder/quill-image-drop-module/blob/master/index.js
 */
export class ImageDrop {
    localDrag = false;
    quill = null;
    onNewDataUrl = () => {};
    handleOnPaste = false;
    size = 1;

    constructor(quill, onNewDataUrl, handleOnPaste, size) {
        this.quill = quill;
        this.onNewDataUrl = onNewDataUrl;
        this.handleOnPaste = handleOnPaste;
        this.size = size;

        // listen for drop and paste events
        this.quill.root.addEventListener("dragstart", (e) => this.handleDragStart(e), false);
        this.quill.root.addEventListener("dragend", (e) => this.handleDragEnd(e), false);
        this.quill.root.addEventListener("drop", (e) => this.handleDrop(e), false);
        if (this.handleOnPaste) {
            this.quill.root.addEventListener("paste", (e) => this.handlePaste(e), false);
        }
        const uploader = this.quill.getModule("uploader");
        if (uploader) {
            uploader.options.handler = () => {};
        }
    }

    handleDragStart() {
        this.localDrag = true;
    }

    handleDragEnd() {
        this.localDrag = false;
    }

    async handleDrop(evt) {
        if (this.localDrag) return; // use default method
        evt.preventDefault();
        if (document.caretRangeFromPoint) {
            const selection = document.getSelection();
            const range = document.caretRangeFromPoint(evt.clientX, evt.clientY);
            if (selection && range) {
                selection.setBaseAndExtent(range.startContainer, range.startOffset, range.startContainer, range.startOffset);
            }
        }
        const files = evt.dataTransfer?.files;
        const imageFiles = Array.from(files || []).filter((f) => IsMatch(f.type));
        if (imageFiles.length > 0) {
            await this.pasteFilesIntoQuill(imageFiles);
            return;
        }
        if (evt.dataTransfer?.items) {
            await this.handleDataTransferList(evt.dataTransfer?.items, evt);
            return;
        }
        const draggedUrl = evt.dataTransfer?.getData("URL");
        if (draggedUrl) {
            const blob = await (await fetch(draggedUrl)).blob();
            await this.pasteFilesIntoQuill([blob]);
        }
    }

    async handlePaste(evt) {
        await this.handleDataTransferList(evt.clipboardData?.items, evt);
    }

    async handleDataTransferList(dataTransferItems, evt) {
        const items = Array.from(dataTransferItems || []);
        // Can only compress images of type "file"
        const images = items.filter((f) => f.kind === "file" && IsMatch(f.type));
        if (!images.length) {
            // No images in clipboard, proceed with inbuilt paste into quill
            return;
        }
        evt.preventDefault();
        const imageFiles = images.map((image) => image.getAsFile());
        await this.pasteFilesIntoQuill(imageFiles);
    }

    async pasteFilesIntoQuill(imageFiles) {
        const hasError = await validate(imageFiles, { defaultSize: this.size });
        if (hasError) {
            return;
        }
        await Promise.all(
            imageFiles.map(async (imageFile) => {
                if (!imageFile) {
                    return;
                }
                const base64ImageSrc = await file2b64(imageFile);
                this.onNewDataUrl(base64ImageSrc);
            })
        );
    }
}
