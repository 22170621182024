import React, { useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as ID_SVG } from "../../../assets/images/id-icon.svg";
import { selectIndustries } from "../../common/slice";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import useFetchCountries from "../../../common/hooks/useFetchCountries";
import Modal from "../../../common/components/extra/Modal";
import Select from "../../../common/components/extra/select/Select";
import Group from "../../../common/components/extra/Group";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import { TOAST_TYPE, createConfirmAlert, createToast } from "../../../common/utilities/helper";
import ViewModalSub from "./ViewModalSub";
import { FIELDS, UPLOAD_FIELDS, VIEW_MODAL_TYPE } from "./const";
import { useUpdateCompanies, useUploadCompanyFiles } from "./hooks";

const {
    NAME,
    EMAIL,
    INDUSTRY_TYPE,
    LICENCE_TRADE_NUMBER,
    LICENCE_TRADE_EXPIRY,
    LICENCE_COMMERCIAL_NUMBER,
    LICENCE_COMMERCIAL_EXPIRY,
    CONTRACT_NUMBER,
    CONTRACT_EXPIRY,
    ESTABLISHMENT_ID,
    ESTABLISHMENT_ID_EXPIRY,
    EMPLOYEE_COUNT,
    ADDRESS,
    COUNTRY,
    CONTACT_NAME,
    CONTACT_EMAIL,
    CONTACT_NUMBER,
    CONTACT_POSITION
} = FIELDS;

const {
    LICENSE_TRADE: LICENSE_TRADE_COPY,
    LICENSE_COMMERCIAL: LICENSE_COMMERCIAL_COPY,
    COMPANY_CONTRACT: COMPANY_CONTRACT_COPY,
    ESTABLISHMENT_ID: ESTABLISHMENT_ID_COPY
} = UPLOAD_FIELDS;

function UpdateModal({ open, onClose, onBack, onFinish, data = {} }) {
    const [viewObject, setViewObject] = useState({ type: null, data: null });

    const industries = useAppSelector(selectIndustries).map((ind) => ({ ...ind, value: ind.id, label: ind.name }));
    const fetchedCountries = useFetchCountries({ rtl: true, mobile: true });

    const [form, setForm, updateCompanyLoading, onFormSave, setMobileCode, hasFormChanges] = useUpdateCompanies({
        id: data?.id,
        initial: data || {}
    });
    const [uploads, setUploads, uploadFilesLoading, onStartUpload, hasUploadChanges] = useUploadCompanyFiles({
        id: data?.id,
        initial: data?.uploads || {}
    });

    const isLoading = uploadFilesLoading || updateCompanyLoading;
    const timezone = data?.CompanySetting?.timezone;
    const countries = fetchedCountries.nationalities;
    const country = countries.find((fc) => fc.value === form[COUNTRY.name]) || "";
    // if the value is string that means its the filename saved in the DB else it is a file object
    const industryType = industries.find((ind) => ind.value === form[INDUSTRY_TYPE.name]) || "";
    const hasNewUploads = Object.values(uploads).some((upload) => typeof upload == "object");

    const handleUploadsChange = (e) => {
        const name = e.target.name;
        const value = e.target?.files?.[0];
        setUploads({ ...uploads, [name]: value });
    };

    const handleSave = async () => {
        try {
            let res2 = uploads;
            const res1 = await onFormSave();
            if (res1.error) {
                throw new Error(res1.error);
            }
            if (hasNewUploads) {
                res2 = await onStartUpload(res1);
                if (res2.error) {
                    throw new Error(res2.error);
                }
            }
            onFinish({ ...res1, uploads: res2 });
        } catch (error) {
            createToast(error.message || "Failed to update company. Please try again later or contact support.", TOAST_TYPE.ERROR);
        }
    };

    const handleViewChange = (newObject = {}) => setViewObject({ ...viewObject, ...newObject });

    const handleChange = (e) => {
        const target = e.target;
        const config = {
            ...form,
            [target.name]: target.value
        };
        if (target?.mobileCode) {
            setMobileCode(target?.mobileCode);
        }
        setForm(config);
    };

    const createInputFile = (fileObject = {}, modalType, icon = <ID_SVG />) => {
        const isNotFileObject = typeof uploads[fileObject.key] === "string";
        return (
            <Input
                type={INPUT_TYPE.UPLOAD}
                name={fileObject.key}
                icon={icon}
                accept={fileObject.accepts}
                sizeLimit={fileObject.size}
                label={fileObject.label}
                onChange={handleUploadsChange}
                onView={() =>
                    handleViewChange({
                        type: modalType,
                        data: {
                            id: data?.id,
                            src: (!isNotFileObject && uploads[fileObject.key] && URL.createObjectURL(uploads[fileObject.key])) || "",
                            filename: isNotFileObject ? uploads[fileObject.key] : uploads[fileObject.key]?.name
                        }
                    })
                }
                value={!uploads[fileObject.key] ? true : uploads[fileObject.key]}
                required={!uploads[fileObject.key]}
                hidelabel
            />
        );
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            onBack={onBack}
            onSave={() =>
                createConfirmAlert({
                    title: "Update Company",
                    content: "Are you sure you want to update this company? This cannot be undone.",
                    onConfirm: async (close) => {
                        close();
                        await handleSave();
                    }
                })
            }
            disableSave={(!hasFormChanges && !hasUploadChanges) || isLoading}
            isLoading={isLoading}
            isForm
        >
            <div className="update-modal">
                <div className="update-modal__inner flex column gap-1">
                    <Group title="Company Details">
                        <>
                            <Input
                                type={INPUT_TYPE.TEXT}
                                label={NAME.label}
                                name={NAME.name}
                                value={form[NAME.name]}
                                onChange={handleChange}
                                required={NAME.required}
                                autoFocus
                            />
                            <Input
                                type={INPUT_TYPE.EMAIL}
                                label={EMAIL.label}
                                name={EMAIL.name}
                                value={form[EMAIL.name]}
                                onChange={handleChange}
                                required={EMAIL.required}
                            />
                            <Select
                                name={INDUSTRY_TYPE.name}
                                label={INDUSTRY_TYPE.label}
                                placeholder=""
                                value={industryType}
                                style={{ flex: "30%" }}
                                options={industries}
                                onChange={(d) => handleChange({ target: { name: INDUSTRY_TYPE.name, value: d.value || "" } })}
                                required={INDUSTRY_TYPE.required}
                                isOutlined
                                disabledOutline
                            />
                            <Input
                                type={INPUT_TYPE.NUMBER}
                                label={EMPLOYEE_COUNT.label}
                                name={EMPLOYEE_COUNT.name}
                                value={form[EMPLOYEE_COUNT.name]}
                                onChange={handleChange}
                                required={EMPLOYEE_COUNT.required}
                            />
                            <section className="flex wrap">
                                <Input
                                    type={INPUT_TYPE.TEXT}
                                    label={ADDRESS.label}
                                    name={ADDRESS.name}
                                    value={form[ADDRESS.name]}
                                    onChange={handleChange}
                                    parentStyle={{ flex: "60%", paddingRight: ".5rem" }}
                                    required={ADDRESS.required}
                                />
                                <Select
                                    name={COUNTRY.name}
                                    placeholder="Country"
                                    value={country}
                                    options={countries}
                                    style={{ borderLeft: "1px solid #e1e1e1" }}
                                    onChange={(d) => handleChange({ target: { name: COUNTRY.name, value: d.cca2 } })}
                                    isOutlined
                                    disabledOutline
                                />
                            </section>
                        </>
                    </Group>
                    <Group title="Contract Details">
                        <Input
                            type={INPUT_TYPE.TEXT}
                            label="Number (ID)"
                            name={CONTRACT_NUMBER.name}
                            value={form[CONTRACT_NUMBER.name]}
                            onChange={handleChange}
                            required={CONTRACT_NUMBER.required}
                        />
                        <Input
                            type={INPUT_TYPE.DATE}
                            label="Expiry"
                            name={CONTRACT_EXPIRY.name}
                            selected={form[CONTRACT_EXPIRY.name] && new Date(form[CONTRACT_EXPIRY.name])}
                            onChange={(date) => handleChange({ target: { name: CONTRACT_EXPIRY.name, value: date } })}
                            required={CONTRACT_EXPIRY.required}
                            timezone={timezone}
                            subtext={{ message: <span className="fade">{`Timezone: ${timezone}`}</span> }}
                            noPast
                        />
                        {createInputFile(COMPANY_CONTRACT_COPY, VIEW_MODAL_TYPE.CONTRACT_COPY)}
                    </Group>
                    <Group title="License Trade Details">
                        <Input
                            type={INPUT_TYPE.TEXT}
                            label="Number (ID)"
                            name={LICENCE_TRADE_NUMBER.name}
                            value={form[LICENCE_TRADE_NUMBER.name]}
                            onChange={handleChange}
                            required={LICENCE_TRADE_NUMBER.required}
                        />
                        <Input
                            type={INPUT_TYPE.DATE}
                            label="Expiry"
                            name={LICENCE_TRADE_EXPIRY.name}
                            selected={form[LICENCE_TRADE_EXPIRY.name] && new Date(form[LICENCE_TRADE_EXPIRY.name])}
                            onChange={(date) => handleChange({ target: { name: LICENCE_TRADE_EXPIRY.name, value: date } })}
                            required={LICENCE_TRADE_EXPIRY.required}
                            timezone={timezone}
                            subtext={{ message: <span className="fade">{`Timezone: ${timezone}`}</span> }}
                            noPast
                        />
                        {createInputFile(LICENSE_TRADE_COPY, VIEW_MODAL_TYPE.LICENSE_TRADE_COPY)}
                    </Group>
                    <Group title="License Commercial Details">
                        <Input
                            type={INPUT_TYPE.TEXT}
                            label="Number (ID)"
                            name={LICENCE_COMMERCIAL_NUMBER.name}
                            value={form[LICENCE_COMMERCIAL_NUMBER.name]}
                            onChange={handleChange}
                            required={LICENCE_COMMERCIAL_NUMBER.required}
                        />
                        <Input
                            type={INPUT_TYPE.DATE}
                            label="Expiry"
                            name={LICENCE_COMMERCIAL_EXPIRY.name}
                            selected={form[LICENCE_COMMERCIAL_EXPIRY.name] && new Date(form[LICENCE_COMMERCIAL_EXPIRY.name])}
                            onChange={(date) => handleChange({ target: { name: LICENCE_COMMERCIAL_EXPIRY.name, value: date } })}
                            required={LICENCE_COMMERCIAL_EXPIRY.required}
                            timezone={timezone}
                            subtext={{ message: <span className="fade">{`Timezone: ${timezone}`}</span> }}
                            noPast
                        />
                        {createInputFile(LICENSE_COMMERCIAL_COPY, VIEW_MODAL_TYPE.LICENSE_COMMERCIAL_COPY)}
                    </Group>
                    <Group title="Establishment Details">
                        <Input
                            type={INPUT_TYPE.TEXT}
                            label="Number (ID)"
                            name={ESTABLISHMENT_ID.name}
                            value={form[ESTABLISHMENT_ID.name]}
                            onChange={handleChange}
                            required={ESTABLISHMENT_ID.required}
                        />
                        <Input
                            type={INPUT_TYPE.DATE}
                            label="Expiry"
                            name={ESTABLISHMENT_ID_EXPIRY.name}
                            selected={form[ESTABLISHMENT_ID_EXPIRY.name] && new Date(form[ESTABLISHMENT_ID_EXPIRY.name])}
                            onChange={(date) => handleChange({ target: { name: ESTABLISHMENT_ID_EXPIRY.name, value: date } })}
                            required={ESTABLISHMENT_ID_EXPIRY.required}
                            timezone={timezone}
                            subtext={{ message: <span className="fade">{`Timezone: ${timezone}`}</span> }}
                            noPast
                        />
                        {createInputFile(ESTABLISHMENT_ID_COPY, VIEW_MODAL_TYPE.ESTABLISHMENT_ID_COPY)}
                    </Group>
                    <Group title="Contact Details">
                        <Input
                            type={INPUT_TYPE.TEXT}
                            label={CONTACT_NAME.label}
                            name={CONTACT_NAME.name}
                            value={form[CONTACT_NAME.name]}
                            onChange={handleChange}
                            required={CONTACT_NAME.required}
                        />
                        <Input
                            type={INPUT_TYPE.EMAIL}
                            label={CONTACT_EMAIL.label}
                            name={CONTACT_EMAIL.name}
                            value={form[CONTACT_EMAIL.name]}
                            onChange={handleChange}
                            required={CONTACT_EMAIL.required}
                        />
                        <Input
                            type={INPUT_TYPE.MOBILE}
                            label={CONTACT_NUMBER.label}
                            name={CONTACT_NUMBER.name}
                            style={{ minWidth: "8rem" }}
                            onChange={handleChange}
                            value={form[CONTACT_NUMBER.name]}
                            required={CONTACT_NUMBER.required}
                            menuPlacement="top"
                            portalCodes
                        />
                        <Input
                            type={INPUT_TYPE.TEXT}
                            label={CONTACT_POSITION.label}
                            name={CONTACT_POSITION.name}
                            value={form[CONTACT_POSITION.name]}
                            onChange={handleChange}
                            required
                        />
                    </Group>
                </div>
            </div>
            {!!viewObject.type && (
                <ViewModalSub
                    open={!!viewObject.type}
                    onChange={(bool) => handleViewChange({ type: bool ? viewObject.type : null })}
                    type={viewObject.type}
                    data={viewObject.data}
                />
            )}
        </Modal>
    );
}

UpdateModal.propTypes = {
    open: PropTypes.bool,
    data: PropTypes.object,
    onClose: PropTypes.func,
    onFinish: PropTypes.func,
    onBack: PropTypes.func
};

export default UpdateModal;
