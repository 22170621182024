import { bytesToMegabytes, createToast, TOAST_TYPE } from "../../../utilities/helper";

export async function downscaleImage(dataUrl, maxWidth, maxHeight, imageType, keepImageTypes, ignoreImageTypes, imageQuality) {
    "use strict";
    // Input image values
    const inputImageType = dataUrl.split(";")[0].split(":")[1];

    // Provide default values
    imageType = imageType || "image/jpeg";
    imageQuality = imageQuality || 0.7;

    // Create a temporary image so that we can compute the height of the downscaled image.
    const image = new Image();
    image.src = dataUrl;
    await new Promise((resolve) => {
        image.onload = () => {
            resolve();
        };
    });
    const [newWidth, newHeight] = getDimensions(image.width, image.height, maxWidth, maxHeight);

    // Create a temporary canvas to draw the downscaled image on.
    const canvas = document.createElement("canvas");
    canvas.width = newWidth;
    canvas.height = newHeight;

    const ctx = canvas.getContext("2d");

    // If the type is an jpeg, draw a white background first.
    if (imageType === "image/jpeg") {
        ctx.fillStyle = "#FFFFFF";
        ctx.fillRect(0, 0, image.width, image.height);
    }

    // If the type is included in the ignore list, return the original
    if (ignoreImageTypes?.includes(inputImageType)) {
        return dataUrl;
    }

    // If the type is included in keep type list, fix the image type
    if (keepImageTypes?.includes(inputImageType)) {
        imageType = inputImageType;
    }

    // Draw the downscaled image on the canvas and return the new data URL.
    ctx.drawImage(image, 0, 0, newWidth, newHeight);
    const newDataUrl = canvas.toDataURL(imageType, imageQuality);
    return newDataUrl;
}

export function getDimensions(inputWidth, inputHeight, maxWidth, maxHeight) {
    if (maxWidth && maxHeight && inputWidth <= maxWidth && inputHeight <= maxHeight) {
        return [inputWidth, inputHeight];
    }
    if (maxWidth && inputWidth > maxWidth) {
        const newWidth = maxWidth;
        const newHeight = Math.floor((inputHeight / inputWidth) * newWidth);

        if (maxHeight && newHeight > maxHeight) {
            const newHeight = maxHeight;
            const newWidth = Math.floor((inputWidth / inputHeight) * newHeight);
            return [newWidth, newHeight];
        } else {
            return [newWidth, newHeight];
        }
    }
    if (maxHeight && inputHeight > maxHeight) {
        const newHeight = maxHeight;
        const newWidth = Math.floor((inputWidth / inputHeight) * newHeight);
        return [newWidth, newHeight];
    }
    return [inputHeight, inputWidth];
}

export async function file2b64(file) {
    const fileReader = new FileReader();

    const promise = new Promise((resolve, reject) => {
        fileReader.addEventListener(
            "load",
            () => {
                const base64ImageSrc = fileReader.result?.toString();
                if (!base64ImageSrc) {
                    reject("could not convert file to base64");
                } else {
                    resolve(base64ImageSrc);
                }
            },
            false
        );
    });
    fileReader.readAsDataURL(file);
    return promise;
}

export function IsMatch(fileType) {
    return !!fileType.match(/^image\/(gif|jpe?g|a?png|svg|webp|bmp)/i);
}

export function b64toBlob(dataURI) {
    const byteString = atob(dataURI.split(",")[1]);
    const type = dataURI.slice(5).split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: type });
}

export function validate(files, { defaultSize = 1 } = {}) {
    const exceededSize = [...files].some((file) => bytesToMegabytes(file.size) > defaultSize);
    if (exceededSize) {
        createToast(`Image Size cannot exceed for more than ${defaultSize} MB.`, TOAST_TYPE.ERROR);
        return true;
    }
    return false;
}
