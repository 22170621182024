import React, { useState } from "react";
import PropTypes from "prop-types";
import { sanitizeWords } from "../../../common/utilities/helper";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import Group from "../../../common/components/extra/Group";
import Select from "../../../common/components/extra/select/Select";
import Tag from "../../../common/components/extra/Tag";
import { PENALTY_DEDUCTION_TYPE } from "../../../common/utilities/const";
import Measures from "../../admin/penalties/Measures";
import { INITIAL_UPDATE_FORM } from "./hooks";
import { FIELDS } from "./const";
import SectionCollapseWarning from "../../../common/components/extra/section/SectionCollapseWarning";

const { TITLE, NOTES, DESCRIPTION, DEDUCTION_TYPE, PENALTY_MEASURES } = FIELDS;

const DEDUC_OPT = Object.values(PENALTY_DEDUCTION_TYPE).map((deduction) => ({
    value: deduction,
    label: (
        <div className="flex">
            <Tag>{sanitizeWords(deduction)}</Tag>
        </div>
    )
}));

function UpdateModalContent({ onChange, data = {}, isByLaw, disableMeasureEdit }) {
    const [form, setForm] = useState({ ...INITIAL_UPDATE_FORM, ...data });

    const deductionType = DEDUC_OPT.find((deduction) => deduction.value === form[DEDUCTION_TYPE.name]);

    const handleChange = (e) => {
        const newForm = {
            ...form,
            [e.target.name]: e.target.value
        };
        setForm(newForm);
        typeof onChange == "function" && onChange(newForm);
    };

    return (
        <>
            {disableMeasureEdit && (
                <SectionCollapseWarning show>
                    Editing capabilities will be disabled for measures because current penalty is being used in employee penalties.
                </SectionCollapseWarning>
            )}
            <Group title="Penalty Details">
                {!isByLaw && (
                    <>
                        <Input
                            type={INPUT_TYPE.TEXT}
                            label={TITLE.label}
                            name={TITLE.name}
                            value={form[TITLE.name]}
                            onChange={handleChange}
                            required={TITLE.required}
                            autoFocus
                        />
                        <Select
                            name={DEDUCTION_TYPE.name}
                            label={DEDUCTION_TYPE.label}
                            value={deductionType}
                            options={DEDUC_OPT}
                            style={{ flex: "30%" }}
                            onChange={(d) => handleChange({ target: { name: DEDUCTION_TYPE.name, value: d.value } })}
                            isClearable={false}
                            required
                            isOutlined
                            disabledOutline
                        />
                    </>
                )}
                {!isByLaw && (
                    <Input
                        type={INPUT_TYPE.TEXTAREA}
                        label={DESCRIPTION.label}
                        name={DESCRIPTION.name}
                        value={form[DESCRIPTION.name]}
                        onChange={handleChange}
                        parentStyle={{ marginTop: "1rem" }}
                        required={DESCRIPTION.required}
                    />
                )}
                <Input
                    type={INPUT_TYPE.TEXTAREA}
                    label={NOTES.label}
                    name={NOTES.name}
                    value={form[NOTES.name]}
                    onChange={handleChange}
                    parentStyle={{ marginTop: "1rem" }}
                    required={NOTES.required}
                />
            </Group>
            <Group title="Manage Measures">
                <Measures
                    data={form[PENALTY_MEASURES.name]}
                    onChange={(newMeasures) => handleChange({ target: { name: PENALTY_MEASURES.name, value: newMeasures } })}
                    isByLaw={isByLaw}
                    readOnly={disableMeasureEdit}
                />
            </Group>
        </>
    );
}

UpdateModalContent.propTypes = {
    open: PropTypes.bool,
    isByLaw: PropTypes.bool,
    data: PropTypes.object,
    onChange: PropTypes.func,
    onFinish: PropTypes.func,
    onBack: PropTypes.func,
    disableMeasureEdit: PropTypes.bool
};

export default UpdateModalContent;
