import React, { useState } from "react";
import Subscriptions from "../../features/admin/subscriptions/Subscription";
import { selectLoading } from "../../features/admin/subscriptions/slice";
import { useAppSelector } from "../../common/hooks/reduxHooks";
import PageLayout from "../../common/components/layout/PageLayout";

function SubscriptionPage() {
    const loading = useAppSelector(selectLoading);
    const [openAddModal, setOpenAddModal] = useState(false);

    const handleAdd = () => setOpenAddModal(true);

    return (
        <PageLayout
            className="tk-subscription"
            title="Manage Subscriptions"
            isLoading={loading}
            onAdd={handleAdd}
            styles={{
                body: {
                    height: "calc(100vh - 20.5rem)"
                }
            }}
        >
            <Subscriptions openAddModal={openAddModal} onAddModalClose={() => setOpenAddModal(false)} />
        </PageLayout>
    );
}

export default SubscriptionPage;
