import { useEffect, useMemo, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import { useGetCheckinLogsByHistoryMutation } from "./api";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectCurrent, setCurrent } from "./slice";
import { selectUserSetting } from "../../common/slice";
import { createToast, TOAST_TYPE, toReadableFromDate } from "../../../common/utilities/helper";
import { siteIconProps } from "./helper";

export const useGetCheckinsByHistory = (id) => {
    const [fetching, setFetching] = useState(true);
    const [getDetails] = useGetCheckinLogsByHistoryMutation();

    const [logs, setLogs] = useState([]);
    const [sites, setSites] = useState([]);

    const dispatch = useAppDispatch();
    const current = useAppSelector(selectCurrent);
    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;

    const config = useMemo(() => {
        let temp = { timezone, setting };
        if (!current) return temp;

        temp.markers = logs;
        temp.sites = sites;

        return temp;
    }, [current, logs, sites]);

    const fetch = async (newid, force) => {
        newid = newid || id;
        try {
            if (!force && (!newid || (current && current.id === newid))) {
                setFetching(false);
                return Promise.resolve();
            }
            const result = await getDetails({ extraPath: newid });
            if (result?.error) {
                throw new Error("Failed to fetch checkins. Please try again later");
            }
            const data = cloneDeep(result.data.data);
            dispatch(setCurrent(data));
            setLogs(
                data.logs.map((cur, idx) => ({
                    id: cur.id,
                    title: cur.location_name + " " + "(" + toReadableFromDate(cur.date) + ")",
                    address: cur.address,
                    background: siteIconProps(cur.type).color,
                    color: "white",
                    point: {
                        lat: cur.coordinates.latitude,
                        lng: cur.coordinates.longitude
                    },
                    children: idx + 1
                }))
            );
            setSites(
                data.sites.map((site) => ({
                    title: site.title,
                    polygon: site.coordinates
                }))
            );
            return data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return {};
        } finally {
            setFetching(false);
        }
    };

    const updateCurrent = (newCurrent = {}) => dispatch(setCurrent({ ...current, ...(newCurrent || {}) }));
    const clearCurrent = () => dispatch(setCurrent(null));

    const updateMarker = (index, newObject = {}, forAllObj = {}) => {
        let temp = cloneDeep(logs.map((log) => ({ ...log, ...forAllObj })));
        temp[index] = { ...(temp[index] || {}), ...newObject };
        setLogs(temp);
    };

    useEffect(() => {
        fetch();
    }, []);

    return [current, { isLoading: fetching, config, clearCurrent, updateCurrent, updateMarker, fetch }];
};
