import React from "react";
import PropTypes from "prop-types";
import Select from "./Select";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { selectBanks } from "../../../../features/common/slice";

function SelectBank({ value, onChange, ...rest }) {
    const banks = useAppSelector(selectBanks);
    const options = banks.map((bank) => ({ ...bank, label: <div className="semi-bold">{bank.label}</div> }));

    return (
        <Select
            label="Bank short name"
            options={options}
            onChange={onChange}
            value={options.find((gen) => gen.value === value)}
            isOutlined
            disabledOutline
            {...rest}
        />
    );
}

export default SelectBank;

SelectBank.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func
};
