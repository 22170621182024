import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Navigate, useParams } from "react-router-dom";
import moment from "moment-timezone";
import DownloadIcon from "@mui/icons-material/Download";
import HomeIcon from "@mui/icons-material/Home";
import CancelIcon from "@mui/icons-material/Cancel";
import Button from "../../../common/components/extra/Button";
import { ReactComponent as LeftArrow } from "../../../assets/images/arrow-left.svg";
import useNavigate from "../../../common/hooks/useNavigate";
import Navigation from "../../../common/classes/Navigation";
import { useCancelBankPayment, useConfirmPayment } from "../companyPayment/hooks";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import useConvertHTMLtoPDF from "../../../common/hooks/useConvertHTMLtoPDF";
import VerifyErrorPayment from "./VerifyErrorPayment";
import { CONFIRM_PAYMENT_CONTENT_TYPE } from "../companyPayment/const";
import Invoice from "./Invoice";
import Receipt from "./Receipt";
import VerifyingPayment from "./VerifyingPayment";
import { createConfirmAlert } from "../../../common/utilities/helper";

const { LOADING, SUCCESS, VERIFY_ERROR, BANK_PENDING, CANCELED } = CONFIRM_PAYMENT_CONTENT_TYPE;

const RECEIPT_ELEM_ID = "tk-receipt";

function SubscriptionConfirmation({ isPendingPaymentPage }) {
    const [verifyPayment, { type, result, isLoading }] = useConfirmPayment({ bypassToken: isPendingPaymentPage });
    const [cancelBankPayment, isCancelingPayment] = useCancelBankPayment();

    const isSuccess = type == SUCCESS;
    const isPending = type == BANK_PENDING;

    const [downloadPDF] = useConvertHTMLtoPDF(
        RECEIPT_ELEM_ID,
        `tk-${isPending ? "invoice" : "receipt"}-${moment().format("YYYY-MM-DD-HH-mm-ss")}.pdf`
    );

    const navigate = useNavigate();
    const params = useParams();

    const user = useAppSelector(selectUser);

    useEffect(() => {
        verifyPayment(params.token);
    }, []);

    const contentSwitch = () => {
        switch (type) {
            case SUCCESS:
                return <Receipt data={result} user={user} />;
            case LOADING:
                return <VerifyingPayment />;
            case BANK_PENDING:
                return <Invoice data={result} />;
            case VERIFY_ERROR:
                return <VerifyErrorPayment message={result.error} />;
            case CANCELED:
                return <Navigate to={Navigation.Routes.SUBSCRIPTION_UPGRADE.path} />;
            default:
                break;
        }
    };

    const handleCancelPayment = () =>
        createConfirmAlert({
            title: "Cancel Payment",
            content: "Are you sure you want to cancel bank transfer? You can choose another payment again by going back to upgrade page.",
            onConfirm: (onClose) => {
                onClose();
                cancelBankPayment(result.payment.id).then(() => {
                    window.location.href = Navigation.Routes.SUBSCRIPTION_UPGRADE.path;
                });
            }
        });

    const handleBack = () => {
        if (user && user.validity.subscription.isExpired) {
            navigate(Navigation.Routes.EXPIRED_SUBSCRIPTION.path);
        } else if (!user.isSettingsVerified) {
            navigate(Navigation.Routes.SETTINGS_SETUP.path);
        } else {
            navigate(Navigation.Routes.DASHBOARD.path);
        }
    };

    const handleBackToDashboard = () => {
        if (user && user.validity.subscription.isExpired) {
            navigate(Navigation.Routes.EXPIRED_SUBSCRIPTION.path);
        } else if (!user.isSettingsVerified) {
            navigate(Navigation.Routes.SETTINGS_SETUP.path);
        } else {
            navigate(Navigation.Routes.DASHBOARD.path);
        }
    };

    return (
        <div className="tk-subscription-plan tk-subscription-plan--confirmation">
            <div className="tk-subscription-plan__inner">
                <div className="tk-subscription-plan__header">
                    <div className="left">
                        <LeftArrow onClick={(!isCancelingPayment && handleBack) || null} />
                        <span>
                            <span>Subscription Plan</span>
                            <span>/</span>
                            <span>Payment Confirmation</span>
                        </span>
                    </div>
                </div>
                <div className="tk-subscription-plan__items">
                    <div className="flex gap-05 w100" style={{ marginBottom: "1rem", justifyContent: "space-between" }}>
                        {isSuccess && (
                            <Button
                                options={{ style: { paddingLeft: 0 } }}
                                onClick={handleBackToDashboard}
                                beforeExtra={<HomeIcon />}
                                disabled={isCancelingPayment}
                                transparent
                                small
                            >
                                Dashboard
                            </Button>
                        )}
                        {(isSuccess || isPending) && (
                            <div className="flex gap-1" style={{ marginLeft: "auto" }}>
                                {isPending && (
                                    <Button
                                        onClick={handleCancelPayment}
                                        beforeExtra={<CancelIcon />}
                                        className="danger"
                                        isLoading={isCancelingPayment}
                                        disabled={isCancelingPayment}
                                        small
                                    >
                                        Cancel Payment
                                    </Button>
                                )}
                                <Button
                                    className="small-font primary"
                                    beforeExtra={<DownloadIcon />}
                                    isLoading={isLoading}
                                    onClick={() => downloadPDF()}
                                    small
                                >
                                    Download {isPending ? "Invoice" : "Receipt"}
                                </Button>
                            </div>
                        )}
                    </div>
                    <div id={RECEIPT_ELEM_ID} className="flex column gap-1" style={{ position: "relative" }}>
                        <div className="confirmation-details">{contentSwitch()}</div>
                        <div id="tk-confirm-temp" className="flex column center">
                            <div className="fade">
                                <p>
                                    &copy; {new Date().getFullYear()} {process.env.REACT_APP_COMPANY_NAME} All rights reserved.
                                </p>
                                <div className="flex gap-05">
                                    <div className="flex center">
                                        <img className="img-responsive" src="/images/logo-clean.png" alt="" width={20} />
                                    </div>
                                    <p>
                                        {`"TimeKeeper"`} is a product of {process.env.REACT_APP_COMPANY_NAME}.
                                    </p>
                                </div>
                                <p>
                                    If you have any questions, please contact us at&nbsp;
                                    <a className="bold primary-color" href="mailto:admin@time-keeper.app">
                                        {process.env.REACT_APP_SUPPORT_EMAIL}
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                    {(isSuccess || isPending) && (
                        <span className="fade small-font" style={{ marginBottom: ".5rem" }}>
                            {isPending ? "Invoice" : "Receipt"} has been sent to your email: <span className="bold primary-color">{user.email}</span>
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
}

SubscriptionConfirmation.propTypes = {
    isPendingPaymentPage: PropTypes.bool
};

export default SubscriptionConfirmation;
