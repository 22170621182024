import React from "react";
import PropTypes from "prop-types";
import Modal from "../../../common/components/extra/Modal";
import GoogleMap from "../../../common/components/extra/map/GoogleMap";
import Empty from "../../../common/components/extra/Empty";

function ViewMapModal({ open, onClose, title, coordinates, isLoading }) {
    return (
        <Modal title={`View Site - ${title}`} open={open} onClose={onClose} small>
            {coordinates ? (
                <GoogleMap
                    id="view-site-1"
                    style={{
                        height: "70vh",
                        width: "70vw",
                        minWidth: "30rem"
                    }}
                    coordinates={[{ title, polygon: coordinates }]}
                    zoom={{ default: 12 }}
                    isLoading={isLoading}
                    allowControls
                />
            ) : (
                <Empty />
            )}
        </Modal>
    );
}

ViewMapModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    title: PropTypes.string,
    coordinates: PropTypes.array,
    isLoading: PropTypes.bool
};

export default ViewMapModal;
