import React from "react";
import PropTypes from "prop-types";
import { useGetContractTimeRemaining, useGetSubscriptionTimeRemaining } from "./hooks";
import ButtonContractRequest from "../../../common/components/extra/ButtonContractRequest";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import ContractRequested from "./ContractRequested";

function ContractExpiring({ onlyMessage }) {
    const user = useAppSelector(selectUser);

    const result = useGetContractTimeRemaining();
    const subscriptionTimeRemaining = useGetSubscriptionTimeRemaining(true);
    const isLessThanOneMonth = result.isLessThanOneMonth;

    const isExpired = result.isExpired;
    const isSubExpired = subscriptionTimeRemaining.isExpired && isExpired;
    const status = user.status;
    const isRenewingContract = status.isRenewingContract;

    const defaultMessage = (
        <span>
            Your <strong>Contract</strong> is expiring in {result.text}. Renew your contract now to ensure uninterrupted service.
        </span>
    );
    const expiredMessage = (
        <span>
            You <strong>Contract</strong> is expired. Renew now if you want uninterrupted service after your subscription ends.
        </span>
    );
    const expiredSubMessage = (
        <span>
            Your <strong>Contract</strong> and <strong>Subscription</strong> is expired. Please renew your contract in order to apply for new
            subscription.
        </span>
    );
    const lessThanAMonthMessage = (
        <span>Your Contract Period is less than a month from expiring and is not eligible for any upgrade options. Expiring in {result.text}</span>
    );

    if (isRenewingContract) {
        return <ContractRequested onlyMessage={onlyMessage} />;
    }

    if (!result.showWarning) {
        return <></>;
    }

    return (
        <div className="flex w100 gap-1 small-font semi-bold">
            <div className="flex wrap center w100 gap-05">
                {isSubExpired ? expiredSubMessage : isExpired ? expiredMessage : isLessThanOneMonth ? lessThanAMonthMessage : defaultMessage}
            </div>
            {!onlyMessage && <ButtonContractRequest isRenewal />}
        </div>
    );
}

export default ContractExpiring;

ContractExpiring.propTypes = {
    onlyMessage: PropTypes.bool
};
