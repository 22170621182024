import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { createConfirmAlert, renderNA, sanitizeWords, toReadableFromDate } from "../../../common/utilities/helper";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import Tag from "../../../common/components/extra/Tag";
import { TABLE_ACTION_TYPE_COMMON } from "../../../common/utilities/const";
import { FORM_STATUS } from "./const";

const HEADERS = {
    NAME: "name",
    UPDATED_AT: "updatedAt",
    CREATED_AT: "created_at",
    ACTIONS: "actions"
};

const HEADERS_TO_ARRAY = Object.values(HEADERS);

const CreateTableHeaders = ({ onAction }) => {
    const user = useAppSelector(selectUser);
    const setting = user.Setting;

    return HEADERS_TO_ARRAY.map((head) => {
        const temp = { key: head, style: {} };
        switch (head) {
            case HEADERS.NAME: {
                temp.label = "Name";
                temp.sortKey = "name";
                temp.style.width = "20rem";
                temp.render = (_, row) => {
                    return (
                        <div className="flex gap-05 center" style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                            <span className="text-ellipsis bold" style={{ fontWeight: "bold" }}>
                                {sanitizeWords(row.name)}
                            </span>
                            <Tag className={row.status == FORM_STATUS.ACTIVE ? "green" : "red"}>
                                <span className="text-ellipsis">{sanitizeWords(row.status)}</span>
                            </Tag>
                        </div>
                    );
                };
                break;
            }
            case HEADERS.UPDATED_AT: {
                temp.sortKey = "updatedAt";
                temp.label = "Updated";
                temp.render = (_, row) => (
                    <div className="flex column gap-05 overflow-hidden fade">
                        <span className="text-ellipsis small-font">
                            {toReadableFromDate(
                                toReadableFromDate(row.updatedAt, setting.timezone) == toReadableFromDate(row.createdAt, setting.timezone)
                                    ? ""
                                    : row.updatedAt,
                                setting.timezone
                            ) || renderNA()}
                        </span>
                    </div>
                );
                break;
            }
            case HEADERS.CREATED_AT: {
                temp.sortKey = "createdAt";
                temp.label = "Created";
                temp.render = (_, row) => (
                    <div className="flex column gap-05 overflow-hidden fade">
                        <span className="text-ellipsis small-font">{toReadableFromDate(row.createdAt, setting.timezone)}</span>
                    </div>
                );
                break;
            }
            case HEADERS.ACTIONS: {
                temp.childStyle = {
                    display: "flex",
                    gap: ".5rem",
                    justifyContent: "flex-end",
                    paddingRight: "1rem",
                    alignItems: "center"
                };
                temp.render = (_, row) => (
                    <>
                        <EditIcon onClick={() => typeof onAction === "function" && onAction(row, TABLE_ACTION_TYPE_COMMON.EDIT)} />
                        <DeleteIcon
                            style={{ color: "red" }}
                            onClick={async () => {
                                createConfirmAlert({
                                    title: "Remove Form",
                                    content: "Are you sure you want to remove this form? This cannot be undone.",
                                    onConfirm: async (close) => {
                                        close();
                                        typeof onAction === "function" && onAction(row, TABLE_ACTION_TYPE_COMMON.REMOVE);
                                    }
                                });
                            }}
                        />
                    </>
                );
                break;
            }
            default:
                break;
        }
        return temp;
    });
};

export default CreateTableHeaders;
