import React from "react";
import Card from "../../../common/components/extra/Card";
import Divider from "../../../common/components/extra/Divider";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserCompanySubscription, selectUserSetting } from "../../common/slice";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import { sanitizeWords, toTimeWithTimeZone } from "../../../common/utilities/helper";
import ButtonUpgrade from "../../../common/components/extra/ButtonUpgrade";
import ButtonRenew from "../../../common/components/extra/ButtonRenew";
import { useGetSubscriptionTimeRemaining } from "../auth/hooks";

const DESCRIPTION = `Manage your subscription with the ability to both renew your plan and access detailed information about 
your current subscription package.`;

function SubscriptionInfo() {
    const setting = useAppSelector(selectUserSetting);
    const companySubscription = useAppSelector(selectUserCompanySubscription);

    const result = useGetSubscriptionTimeRemaining(true);

    const subscription = companySubscription.Subscription;

    return (
        <Card className="subscription-info-settings box-shadow-mini">
            <div className="flex column">
                <Divider title="Manage Subscription Settings" />
                <p className="fade small-font">{DESCRIPTION}</p>
                <div className="flex column" style={{ gap: "2rem" }}>
                    <section className="flex column gap-05">
                        <Input type={INPUT_TYPE.TEXT} label="Plan" renderValue={sanitizeWords(subscription.title)} readOnly />
                        <Input type={INPUT_TYPE.TEXT} label="Employee limit" renderValue={subscription.employee_limit} readOnly />
                        <Input
                            type={INPUT_TYPE.TEXT}
                            label="Effective Date"
                            renderValue={toTimeWithTimeZone(companySubscription.effectivity_date, setting.timezone).format("MMM DD, YYYY hh:mm A")}
                            readOnly
                        />
                        <Input
                            type={INPUT_TYPE.TEXT}
                            label="Validity Date"
                            renderValue={toTimeWithTimeZone(companySubscription.expiry_date, setting.timezone).format("MMM DD, YYYY hh:mm A")}
                            readOnly
                        />
                    </section>
                    <section className="flex gap-05" style={{ justifyContent: "flex-end" }}>
                        {result.showWarning && <ButtonRenew primary />}
                        <ButtonUpgrade />
                    </section>
                </div>
            </div>
        </Card>
    );
}

export default SubscriptionInfo;
