import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import InfoIcon from "@mui/icons-material/Info";
import { capitalizeWords } from "../../utilities/helper";
import MyTooltip from "./Tooltip";

function ButtonTab({ items, onSelect, onRender, header, style, disabledTabs = [], tooltip }) {
    const getActualDefValue = () => {
        const findDefIdx = items.findIndex((item) => !disabledTabs.includes(item));
        if (findDefIdx == -1) {
            return items[0];
        }
        return items[findDefIdx];
    };

    const [selected, setSelected] = useState(getActualDefValue());

    const handleSelect = (x) => {
        setSelected(x);
        typeof onSelect === "function" && onSelect(x);
    };

    useEffect(() => {
        setSelected(getActualDefValue());
        typeof onSelect === "function" && onSelect(getActualDefValue());
    }, []);

    return (
        <div className="tk-button-tab" style={style || {}}>
            {header}
            <div className="tk-button-tab__inner">
                <ul>
                    {items.map((x) => {
                        const isCurrDisabled = disabledTabs.includes(x);
                        const className = `flex center gap-05 tk-button-tab__item ${selected == x && "tk-button-tab--active"} ${
                            (isCurrDisabled && "fade") || ""
                        }`.trim();
                        return (
                            <li key={x} onClick={() => !isCurrDisabled && handleSelect(x)} className={className}>
                                <span style={isCurrDisabled ? { opacity: ".5" } : {}}>{(onRender && onRender(x)) || capitalizeWords(x)}</span>
                                {tooltip[x]?.show && (
                                    <MyTooltip message={tooltip[x]?.message}>
                                        <InfoIcon className="hover-svg" style={{ width: "1rem" }} />
                                    </MyTooltip>
                                )}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
}

ButtonTab.propTypes = {
    header: PropTypes.any,
    items: PropTypes.array,
    onSelect: PropTypes.func,
    onRender: PropTypes.func,
    style: PropTypes.object,
    disabledTabs: PropTypes.arrayOf(PropTypes.string),
    tooltip: PropTypes.object
};

export default ButtonTab;
