import React from "react";
import PropTypes from "prop-types";
import MyTooltip, { PLACES } from "./Tooltip";
import { ReactComponent as WarningSVG } from "../../../assets/images/tk-warning.svg";

function WarningColorSwitcher({ danger, warning, color, tooltip, style, className }) {
    const colorSwitch = () => {
        let newColor = color;
        if (danger) {
            newColor = "#F33636";
        } else if (warning) {
            newColor = "orange";
        } else if (color) {
            newColor = color || "#000";
        } else {
            newColor = color;
        }

        return newColor;
    };

    const SVG_STYLE = {
        opacity: 1,
        width: "2rem",
        ...style,
        color: colorSwitch()
    };

    if (!SVG_STYLE.color) {
        delete SVG_STYLE.color;
    }

    if (!tooltip?.message) {
        return <WarningSVG style={SVG_STYLE} />;
    }

    return (
        <MyTooltip {...(tooltip || {})}>
            <WarningSVG className={className} style={SVG_STYLE} />
        </MyTooltip>
    );
}

export default WarningColorSwitcher;

WarningColorSwitcher.propTypes = {
    danger: PropTypes.bool,
    warning: PropTypes.bool,
    color: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
    tooltip: PropTypes.shape({
        message: PropTypes.any,
        position: PropTypes.oneOf(Object.values(PLACES)),
        className: PropTypes.string,
        style: PropTypes.object,
        element: PropTypes.string,
        onClick: PropTypes.func
    })
};
