import React from "react";
import PropTypes from "prop-types";

function ViewEditor({ content }) {
    return <div className="tk-view-editor" dangerouslySetInnerHTML={{ __html: content }}></div>;
}

export default ViewEditor;

ViewEditor.propTypes = {
    content: PropTypes.any
};
