import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { selectTableConfig, selectCurrent, setCurrent } from "./slice";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import CreateTableHeaders from "./CreateTableHeaders";
import TableView from "../../../common/components/extra/table/TableView";
import { useDeleteWorkHistory, usePaginateWorkHistories } from "./hooks";
import ViewModal from "./ViewModal";
import UpdateModal from "./UpdateModal";
import { ACTION_TYPE } from "./const";
import { WORK_HISTORY_STATUS } from "../../../common/utilities/const";
import ViewCheckinLogsModal from "../employeeCheckinLogs/ViewCheckinLogsModal";

function WorkHistories({ openAddModal, onAddModalClose }) {
    const [openViewModal, setOpenViewModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(openAddModal);
    const [openViewCheckinModal, setOpenViewCheckinModal] = useState(openAddModal);
    const [selected, setSelected] = useState(null);

    const [data, { isLoading: isPaginating, fetch, update }] = usePaginateWorkHistories();
    const [remove] = useDeleteWorkHistory();

    const dispatch = useAppDispatch();
    const tableConfig = useAppSelector(selectTableConfig);
    const current = useAppSelector(selectCurrent);

    const updateSelected = (row) => {
        setSelected(row);
        setOpenViewModal(!!row);
    };

    useEffect(() => {
        if (openAddModal && openAddModal !== openEditModal) {
            setOpenEditModal(openAddModal);
            setSelected(null);
            dispatch(setCurrent(null));
        }
    }, [openAddModal]);

    const handleModalBack = () => {
        setOpenViewModal(true);
        setOpenEditModal(false);
    };

    const handleModalEdit = () => {
        setOpenViewModal(false);
        setOpenEditModal(true);
    };

    const handleFinish = (newData) => {
        fetch();
        dispatch(setCurrent(newData));
        setSelected(newData);
        typeof onAddModalClose === "function" && onAddModalClose();
    };

    const handleAction = (row, actionType) => {
        switch (actionType) {
            case ACTION_TYPE.VIEW: {
                updateSelected(row);
                break;
            }
            case ACTION_TYPE.REMOVE: {
                remove(row.id).then(fetch);
                break;
            }
            case ACTION_TYPE.VIEW_CHECKIN: {
                setSelected(row);
                setOpenViewCheckinModal(true);
                break;
            }
            default:
                break;
        }
    };

    const handleViewModalClose = () => updateSelected(null);

    const handleApprove = (id, status) => {
        const isApproved = status.includes(WORK_HISTORY_STATUS.ADMIN_VERIFIED);
        const isPending = status.includes(WORK_HISTORY_STATUS.PENDING);
        const isRejected = status.includes(WORK_HISTORY_STATUS.REJECTED);

        update(id, { status, isApproved, isPending, isRejected });
    };

    const handleUpdateModalClose = () => {
        typeof onAddModalClose === "function" && onAddModalClose();
        setOpenEditModal(false);
    };

    const handleExistingRecordClick = (record) => {
        handleUpdateModalClose();
        updateSelected({ id: record.id });
    };

    return (
        <>
            <TableView
                data={data}
                headers={CreateTableHeaders({ onAction: handleAction })}
                refetch={fetch}
                tableConfig={tableConfig}
                isLoading={isPaginating}
            />
            {openViewModal && (
                <ViewModal id={selected?.id} open={openViewModal} onClose={handleViewModalClose} onEdit={handleModalEdit} onApprove={handleApprove} />
            )}
            {openEditModal && (
                <UpdateModal
                    onBack={handleModalBack}
                    open={openEditModal}
                    onClose={handleUpdateModalClose}
                    onFinish={handleFinish}
                    data={selected?.id ? current : null}
                    onExistingRecordClick={handleExistingRecordClick}
                />
            )}
            {openViewCheckinModal && (
                <ViewCheckinLogsModal id={selected?.id} open={openViewCheckinModal} onClose={() => setOpenViewCheckinModal(false)} />
            )}
        </>
    );
}

WorkHistories.propTypes = {
    openAddModal: PropTypes.bool,
    onAddModalClose: PropTypes.func
};

export default WorkHistories;
