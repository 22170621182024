import React, { useState } from "react";
import PropTypes from "prop-types";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Button from "../../../common/components/extra/Button";
import UpdateFilesModal from "./UpdateFilesModal";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import { useCompanyStatusCheck } from "./hooks";

const LINK_STYLE = {
    textDecoration: "underline",
    fontStyle: "italic",
    color: "red",
    cursor: "pointer"
};

function UpdateFiles({ onClick, noInternalModal, forceInline }) {
    const [isOpen, setOpen] = useState(false);

    const check = useCompanyStatusCheck();

    const isContractExpired = check.isContractExpired;
    const hasContractCopy = check.hasContractCopy;

    const user = useAppSelector(selectUser);

    const handleOnClick = () => {
        setOpen(true);
        onClick?.();
    };

    let label = "Update Files";

    if (isContractExpired || !check.isContractWithinRecurrence) {
        label = "Renew Contract";
    }

    if (!hasContractCopy) {
        label = "Request for a Full Account";
    }

    const renderButton = forceInline ? (
        <span style={LINK_STYLE} className="semi-bold" onClick={handleOnClick}>
            Click to {label}
        </span>
    ) : (
        <Button
            options={{ style: { minWidth: "max-content" } }}
            onClick={handleOnClick}
            className="danger"
            beforeExtra={<AttachFileIcon style={{ width: "1.1rem", pointerEvents: "none" }} />}
            small
            mini
        >
            <span style={{ whiteSpace: "nowrap" }}>{label}</span>
        </Button>
    );

    return (
        <>
            <div className="small-font flex gap-05 wrap">{renderButton}</div>
            {isOpen && !noInternalModal && (
                <UpdateFilesModal open={isOpen} onClose={() => setOpen(false)} onFinish={() => setOpen(false)} data={user} />
            )}
        </>
    );
}

export default UpdateFiles;

UpdateFiles.propTypes = {
    onClick: PropTypes.func,
    onBack: PropTypes.func,
    data: PropTypes.object,
    noInternalModal: PropTypes.bool,
    forceInline: PropTypes.bool
};
