import React from "react";
import PropTypes from "prop-types";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { useGetNotifyAvailability, usePublishCompanyAnnouncement } from "./hooks";
import MyTooltip from "../../../common/components/extra/Tooltip";
import { createConfirmAlert } from "../../../common/utilities/helper";
import Button from "../../../common/components/extra/Button";

function NotifyButton({ id, onlyIcon, onNotify, lastPublishedDate }) {
    const [publish, isLoading] = usePublishCompanyAnnouncement();
    const { isOnCooldown, availabilityDate } = useGetNotifyAvailability({ date: lastPublishedDate });

    const handlePublish = () => {
        createConfirmAlert({
            title: "Employee Notification",
            content: "Are you sure you want to notify all employees with this announcement?",
            onConfirm: async (close) => {
                close();
                publish(id).then(onNotify);
            }
        });
    };

    if (onlyIcon) {
        return (
            <MyTooltip message={isOnCooldown ? `Will be available ${availabilityDate}` : "Notify Employees"}>
                <NotificationsActiveIcon className={isOnCooldown ? "disabled" : "hover-svg"} onClick={handlePublish} />
            </MyTooltip>
        );
    }

    const renderButton = (
        <Button
            className="green"
            onClick={handlePublish}
            afterExtra={<NotificationsActiveIcon />}
            isLoading={isLoading}
            disabled={isOnCooldown}
            small
            mini
        >
            <span>Notify Employees</span>
        </Button>
    );

    if (isOnCooldown) {
        return <MyTooltip message={`Will be available ${availabilityDate}`}>{renderButton}</MyTooltip>;
    }

    return renderButton;
}

export default NotifyButton;

NotifyButton.propTypes = {
    id: PropTypes.number,
    onlyIcon: PropTypes.bool,
    onNotify: PropTypes.func,
    lastPublishedDate: PropTypes.any
};
