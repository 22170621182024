import React from "react";
import PropTypes from "prop-types";
import Modal from "../../extra/Modal";

function BaseUpdateModal({ open, onChange, onClose, children, onBack, onSave, isLoading, disableSave, ...props }) {
    return (
        <Modal
            {...props}
            open={open}
            onClose={onClose}
            onChange={onChange}
            onBack={onBack}
            onSave={onSave}
            isLoading={isLoading}
            disableSave={disableSave}
            styles={{
                ...(props.styles || {}),
                content: {
                    width: "50vw",
                    minWidth: "30rem",
                    ...(props?.styles?.content || {})
                }
            }}
            isForm
        >
            <div className="update-modal h100">
                <div
                    className="update-modal-inner h100"
                    style={{
                        padding: "1.5rem"
                    }}
                >
                    {children}
                </div>
            </div>
        </Modal>
    );
}

BaseUpdateModal.propTypes = {
    open: PropTypes.bool,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    onBack: PropTypes.func,
    onSave: PropTypes.func,
    isLoading: PropTypes.bool,
    disableSave: PropTypes.bool,
    children: PropTypes.any,
    styles: PropTypes.shape({
        parent: PropTypes.object,
        content: PropTypes.object,
        body: PropTypes.object,
        title: PropTypes.object,
        form: PropTypes.object,
        footer: PropTypes.object
    })
};

export default BaseUpdateModal;
