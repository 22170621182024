import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../common/hooks/reduxHooks";
import { selectUser } from "../../features/common/slice";
import { selectLoading, selectTableConfig, setTableConfig } from "../../features/company/companyHolidays/slice";
import PageLayout from "../../common/components/layout/PageLayout";
import CompanyHolidays from "../../features/company/companyHolidays/CompanyHolidays";
import Filter from "../../features/company/companyHolidays/Filter";
import { usePaginateCompanyHolidays } from "../../features/company/companyHolidays/hooks";
import { BASE_CLASS } from "../../features/company/companyHolidays/const";

function CompanyHolidaysPage() {
    const [openAddModal, setOpenAddModal] = useState(false);

    const dispatch = useAppDispatch();
    const loading = useAppSelector(selectLoading);
    const user = useAppSelector(selectUser);
    const config = useAppSelector(selectTableConfig);

    const [, { isSearching, onFilter }] = usePaginateCompanyHolidays({ readOnly: true });

    const handleSearch = (value) => dispatch(setTableConfig({ search: (value && value.toLowerCase().trim()) || "" }));
    const handleFilter = (filter) => onFilter(filter);
    const handleAdd = () => setOpenAddModal(true);

    return (
        <PageLayout
            className={BASE_CLASS}
            title="Manage Holidays"
            isLoading={loading}
            search={{ onSearch: handleSearch, value: config.search }}
            filter={<Filter onFilter={handleFilter} isLoading={isSearching} />}
            onAdd={handleAdd}
            styles={{
                body: {
                    height: "calc(100vh - 20.5rem)"
                }
            }}
            hasFilter
        >
            <CompanyHolidays user={user} openAddModal={openAddModal} onAddModalClose={() => setOpenAddModal(false)} />
        </PageLayout>
    );
}

export default CompanyHolidaysPage;
