import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useCompanyStatusCheck, useGetSubscriptionTimeRemaining } from "./hooks";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import Navigation from "../../../common/classes/Navigation";
import { TABS } from "../../../pages/Settings";

function SubscriptionExpiring({ alwaysShowSubscriptionRemaning }) {
    const user = useAppSelector(selectUser);
    const result = useGetSubscriptionTimeRemaining(false, alwaysShowSubscriptionRemaning);

    const check = useCompanyStatusCheck();

    const isTrial = user.is_trial;
    const isExpired = result.isExpired;

    const defaultMessage = (
        <>
            Your {isTrial ? "Trial Account" : "Subscription"} is expiring in&nbsp;{result.text}.&nbsp;
            {!check.isSettingsSetup && (
                <Link key={3} to={Navigation.Routes.SETTINGS.path + "#" + TABS.BILLING.id}>
                    Please click for further details and renew.
                </Link>
            )}
        </>
    );

    const message = <>Your {isTrial ? "Trial Account" : "Subscription"} is expired.</>;

    if (!result.showWarning && !alwaysShowSubscriptionRemaning) {
        return <></>;
    }

    return <div className="flex w100 small-font semi-bold center">{isExpired ? message : defaultMessage}</div>;
}

export default SubscriptionExpiring;

SubscriptionExpiring.propTypes = {
    alwaysShowSubscriptionRemaning: PropTypes.bool
};
