import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import AddIcon from "@mui/icons-material/Add";
import AsyncSelectLazy from "../../../common/components/extra/AsyncSelectLazy";
import MyTooltip from "../../../common/components/extra/Tooltip";
import { useAppDispatch } from "../../../common/hooks/reduxHooks";
import UpdateModal from "./UpdateModal";
import { setClearCache } from "./slice";
import { useLazyWorkSites } from "./hooks";

function SiteSelectLazy(props) {
    const ref = useRef(null);

    const [openAddModal, setOpenAddModal] = useState(false);

    const [object, , { isLoading, loadMore, search, reset, createRowItem }] = useLazyWorkSites({
        isReadableSelected: props.isReadableSelected,
        defaultValue: Array.isArray(props.value) ? props.value.map((v) => v.id) : props?.value?.id,
        isFilter: props.isFilter
    });

    const dispatch = useAppDispatch();

    const isDisabled = props.isDisabled;

    let getValue = !props.isMulti
        ? props.value && Object.keys(props.value).length && object.data.find((item) => item.id === props.value.id)
        : object.data.filter((item) => (props.value || [])?.map((v) => v.id).includes(item.id));

    if (!props.isMulti ? !!Object.keys(props.value || {}).length : Array.isArray(props.value) && props.value.length) {
        if (!props.isMulti) {
            if (!getValue) {
                getValue = createRowItem(props.value);
            }
        } else {
            getValue = props.value.map((item) => createRowItem(item));
        }
    }

    const handleSearch = (newInput, type) => {
        const allowedActions = ["input-change"];
        if (allowedActions.includes(type.action)) {
            search(newInput);
        }
    };

    const handleChange = (val) => {
        let temp = val;
        if (temp) {
            temp = cloneDeep(temp);
            delete temp.label;
            delete temp.value;
        }
        typeof props.onChange === "function" && props.onChange(temp);
    };

    const handleFinish = async () => {
        dispatch(setClearCache());
        await reset();
        setOpenAddModal(false);
    };

    return (
        <div ref={ref} className="flex gap-1 center" style={props.parentStyle}>
            <AsyncSelectLazy
                {...props}
                filterOption={(options, newInputs) => {
                    const search = newInputs?.toLowerCase().trim();
                    if (options?.data?.title?.toLowerCase().includes(search)) {
                        return true;
                    } else {
                        return false;
                    }
                }}
                onChange={handleChange}
                value={props.isLoading ? null : getValue}
                options={object.data}
                onLoadMore={loadMore}
                onSearch={handleSearch}
                isFetching={props.isLoading || isLoading}
                style={{ flex: 1, height: "100%" }}
            />
            {!props.isFilter && (
                <div className="flex">
                    <MyTooltip message={!isDisabled && "Add Work Site"} style={{ display: "flex" }}>
                        <AddIcon
                            className={`hover-svg primary-color ${isDisabled ? "disabled" : ""}`}
                            onClick={() => setOpenAddModal(!openAddModal)}
                        />
                    </MyTooltip>
                </div>
            )}
            {openAddModal && <UpdateModal open={openAddModal} onChange={(bool) => setOpenAddModal(bool)} onFinish={handleFinish} noworkdetail />}
        </div>
    );
}

SiteSelectLazy.propTypes = {
    onChange: PropTypes.func,
    isOutlined: PropTypes.bool,
    label: PropTypes.string,
    noborder: PropTypes.bool,
    style: PropTypes.object,
    value: PropTypes.any,
    isMulti: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isReadableSelected: PropTypes.bool,
    isFilter: PropTypes.bool,
    parentStyle: PropTypes.object,
    isLoading: PropTypes.bool
};

export default SiteSelectLazy;
