import React from "react";
import PropTypes from "prop-types";

const SUB_TEXT_STYLE = {
    textAlign: "left",
    fontSize: "10px",
    fontWeight: "bold",
    fontStyle: "italic",
    marginBottom: ".3rem"
};
function Text({ children, className, style, useSubTextStyle }) {
    const mainStyle = useSubTextStyle ? { ...(style || {}), ...SUB_TEXT_STYLE } : style;

    if (useSubTextStyle) {
        return (
            <div className={`fade ${className || ""}`} style={mainStyle}>
                <span>{children}</span>
            </div>
        );
    }

    return (
        <span className={`small-font ${className || ""}`} style={mainStyle}>
            {children}
        </span>
    );
}

export default Text;

Text.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.any,
    useSubTextStyle: PropTypes.bool
};
