import React from "react";
import PropTypes from "prop-types";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import Button from "../../../common/components/extra/Button";

function Controls({ beforeExtra, afterExtra, isEditing, onCancel, onEdit, disableSave, isLoading, style, className }) {
    return (
        <div className={`flex gap-1 ${className || ""}`.trim()} style={{ marginLeft: "auto", ...style }}>
            {beforeExtra}
            {isEditing && (
                <Button
                    options={{ type: "button" }}
                    beforeExtra={<CancelIcon style={{ color: "red" }} />}
                    onClick={onCancel}
                    disabled={isLoading}
                    small
                    mini
                >
                    <span>Cancel</span>
                </Button>
            )}
            {!isEditing && (
                <Button
                    options={{ type: "button" }}
                    beforeExtra={<ModeEditIcon />}
                    className="primary"
                    onClick={onEdit}
                    disabled={isLoading}
                    small
                    mini
                >
                    <span>Edit</span>
                </Button>
            )}
            {isEditing && (
                <Button
                    options={{
                        type: "submit"
                    }}
                    beforeExtra={<SaveIcon />}
                    isLoading={isLoading}
                    className="primary"
                    disabled={disableSave}
                    small
                    mini
                >
                    <span>Save</span>
                </Button>
            )}
            {afterExtra}
        </div>
    );
}

export default Controls;

Controls.propTypes = {
    afterExtra: PropTypes.any,
    beforeExtra: PropTypes.any,
    onCancel: PropTypes.func,
    onEdit: PropTypes.func,
    isEditing: PropTypes.bool,
    disableSave: PropTypes.bool,
    isLoading: PropTypes.bool,
    style: PropTypes.object,
    className: PropTypes.string
};
