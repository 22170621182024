import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import BackIcon from "@mui/icons-material/ArrowBackIos";
import Button from "./Button";
import { createConfirmAlert } from "../../utilities/helper";

function ButtonBack({ path, hasAlert, alertMessage, ...props }) {
    const navigate = useNavigate();
    const handleClick = () =>
        hasAlert
            ? createConfirmAlert({
                  title: "Back",
                  content: alertMessage || "Are you sure you want to go back?",
                  onConfirm: async (close) => {
                      close();
                      navigate(path || -1);
                  }
              })
            : navigate(path || -1);

    return (
        <Button {...props} beforeExtra={<BackIcon />} options={{ style: { width: "min-content" } }} onClick={handleClick} small mini>
            Back
        </Button>
    );
}

export default ButtonBack;

ButtonBack.propTypes = {
    path: PropTypes.string,
    hasAlert: PropTypes.bool,
    alertMessage: PropTypes.string
};
