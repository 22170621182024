import React from "react";
import BackIcon from "@mui/icons-material/ArrowBackIos";
import Button from "../common/components/extra/Button";
import useHasUser from "../common/hooks/useHasUser";
import { useNavigate } from "react-router-dom";

const BG_STYLE = {
    backgroundImage: `url(/images/404-not-found.png)`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain"
};

function NotFound() {
    const navigate = useNavigate();
    const { homePath } = useHasUser();

    return (
        <div className="tk-not-found" style={BG_STYLE}>
            <Button className="btn-home primary" onClick={() => navigate(homePath)}>
                <BackIcon />
                Go to Homepage
            </Button>
        </div>
    );
}

export default NotFound;
