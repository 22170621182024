export const getBoundingBox = function (maps, current) {
    if (!maps) {
        return { lat: 0, lng: 0 };
    }
    const bounds = new maps.LatLngBounds();
    current.getPath().forEach(function (element) {
        bounds.extend(element);
    });
    return bounds;
};

/**@description Calculating the center that avoids the hole of a polygon and make the center always inside of a polygon */
export const getApproximateCenter = function (maps, current) {
    if (!maps) {
        return { lat: 0, lng: 0 };
    }
    let boundsHeight = 0,
        boundsWidth = 0,
        centerPoint,
        heightIncr = 0,
        maxSearchLoops,
        maxSearchSteps = 10,
        n = 1,
        northWest,
        polygonBounds = getBoundingBox(maps, current),
        testPos,
        widthIncr = 0;
    // Get polygon Centroid
    centerPoint = polygonBounds.getCenter();
    if (maps.geometry.poly.containsLocation(centerPoint, current)) {
        // Nothing to do Centroid is in polygon use it as is
        return { lat: centerPoint.lat(), lng: centerPoint.lng() };
    } else {
        maxSearchLoops = maxSearchSteps / 2;
        // Calculate NorthWest point so we can work out height of polygon NW->SE
        northWest = new maps.LatLng(polygonBounds.getNorthEast().lat(), polygonBounds.getSouthWest().lng());
        // Work out how tall and wide the bounds are and what our search increment will be
        boundsHeight = maps.geometry.spherical.computeDistanceBetween(northWest, polygonBounds.getSouthWest());
        heightIncr = boundsHeight / maxSearchSteps;
        boundsWidth = maps.geometry.spherical.computeDistanceBetween(northWest, polygonBounds.getNorthEast());
        widthIncr = boundsWidth / maxSearchSteps;

        // Expand out from Centroid and find a point within polygon at 0, 90, 180, 270 degrees
        for (; n <= maxSearchLoops; n++) {
            // Test point North of Centroid
            testPos = maps.geometry.spherical.computeOffset(centerPoint, heightIncr * n, 0);
            if (maps.geometry.poly.containsLocation(testPos, current)) {
                break;
            }
            // Test point East of Centroid
            testPos = maps.geometry.spherical.computeOffset(centerPoint, widthIncr * n, 90);
            if (maps.geometry.poly.containsLocation(testPos, current)) {
                break;
            }
            // Test point South of Centroid
            testPos = maps.geometry.spherical.computeOffset(centerPoint, heightIncr * n, 180);
            if (maps.geometry.poly.containsLocation(testPos, current)) {
                break;
            }
            // Test point West of Centroid
            testPos = maps.geometry.spherical.computeOffset(centerPoint, widthIncr * n, 270);
            if (maps.geometry.poly.containsLocation(testPos, current)) {
                break;
            }
        }
        return { lat: testPos.lat(), lng: testPos.lng() };
    }
};

/**@description Just calculate the average center not minding if the center is a whole of a polygon */
export const calculateCoordinatesCenter = (coordinates = []) => {
    if (coordinates.length === 0) {
        return null;
    }
    let sumLat = 0;
    let sumLng = 0;
    // Calculate sum of latitudes and longitudes
    for (let i = 0; i < coordinates.length; i++) {
        sumLat += coordinates[i].lat;
        sumLng += coordinates[i].lng;
    }
    // Calculate average latitudes and longitudes
    const avgLat = sumLat / coordinates.length;
    const avgLng = sumLng / coordinates.length;
    return { lat: avgLat, lng: avgLng };
};

/**@description get paths from polygons returns an mvcArray. We can use this function to extract just the lat long */
export const mvcArrayGeoValues = (mvcPath = []) => {
    const coordinates = [];
    mvcPath.forEach((path) => coordinates.push(path.toJSON()));
    return coordinates;
};

export const getBoundsLiteral = (maps, newpaths, isMulti) => {
    let retval = null;
    newpaths = newpaths.filter(Boolean);
    if (!maps || !newpaths || (newpaths && !newpaths.length)) {
        return null;
    }

    if (!isMulti) {
        const bounds = new maps.LatLngBounds();
        newpaths.forEach((coordinate) => bounds.extend(coordinate));
        retval = bounds;
    } else {
        const bounds = new maps.LatLngBounds();
        newpaths.forEach((polygon) => {
            polygon.getPaths().forEach((path) => {
                path.forEach((point) => {
                    bounds.extend(point);
                });
            });
        });
        retval = bounds;
    }
    return retval;
};

/**
 * @param {Array} source Input coordinates in google.maps.LatLngs
 * @param {*} kink kink in metres, kinks above this depth kept; kink depth is the height of the triangle abc where a-b and b-c are two consecutive line segments
 * @source 
 * http://stackoverflow.com/questions/16121236/smoothing-gps-tracked-route-coordinates
 * https://stackoverflow.com/questions/31333181/get-multiple-polygons-in-google-maps-api
 * @description
 * Stack-based Douglas Peucker line simplification routine 
   returned is a reduced google.maps.LatLng array 
   After code by  Dr. Gary J. Robinson,
   Environmental Systems Science Centre,
   University of Reading, Reading, UK
    
   The Douglas & Peucker algorithm is a line filtering algorithm, 
   which means that it filters the vertices of the line (or polygon) 
   to only retain the most important ones to preserve the shape of the line.
 * @returns
 */
export const GDouglasPeucker = (source, kink) => {
    let n_source, n_stack, n_dest, start, end, i, sig;
    let dev_sqr, max_dev_sqr, band_sqr;
    let x12, y12, d12, x13, y13, d13, x23, y23, d23;
    const F = (Math.PI / 180.0) * 0.5;
    const index = []; // array of indexes of source points to include in the reduced line
    const sig_start = []; // indices of start & end of working section
    const sig_end = [];

    // Check for simple cases
    if (source.length < 3) return source; // one or two points

    // More complex case. Initialize stack
    n_source = source.length;
    band_sqr = (kink * 360.0) / (2.0 * Math.PI * 6378137.0); // Now in degrees
    band_sqr *= band_sqr;
    n_dest = 0;
    sig_start[0] = 0;
    sig_end[0] = n_source - 1;
    n_stack = 1;

    // While the stack is not empty...
    while (n_stack > 0) {
        // Pop the top-most entries off the stacks
        start = sig_start[n_stack - 1];
        end = sig_end[n_stack - 1];
        n_stack--;

        if (end - start > 1) {
            // Any intermediate points?

            // Find most deviant intermediate point to either side of line joining start & end points
            x12 = source[end].lng() - source[start].lng();
            y12 = source[end].lat() - source[start].lat();
            if (Math.abs(x12) > 180.0) x12 = 360.0 - Math.abs(x12);
            x12 *= Math.cos(F * (source[end].lat() + source[start].lat())); // Use avg lat to reduce lng
            d12 = x12 * x12 + y12 * y12;

            for (i = start + 1, sig = start, max_dev_sqr = -1.0; i < end; i++) {
                x13 = source[i].lng() - source[start].lng();
                y13 = source[i].lat() - source[start].lat();
                if (Math.abs(x13) > 180.0) x13 = 360.0 - Math.abs(x13);
                x13 *= Math.cos(F * (source[i].lat() + source[start].lat()));
                d13 = x13 * x13 + y13 * y13;

                x23 = source[i].lng() - source[end].lng();
                y23 = source[i].lat() - source[end].lat();
                if (Math.abs(x23) > 180.0) x23 = 360.0 - Math.abs(x23);
                x23 *= Math.cos(F * (source[i].lat() + source[end].lat()));
                d23 = x23 * x23 + y23 * y23;

                dev_sqr = d13 >= d12 + d23 ? d23 : d23 >= d12 + d13 ? d13 : (x13 * y12 - y13 * x12) ** 2 / d12;

                if (dev_sqr > max_dev_sqr) {
                    sig = i;
                    max_dev_sqr = dev_sqr;
                }
            }

            if (max_dev_sqr < band_sqr) {
                // No significant intermediate point, transfer current start point
                index[n_dest++] = start;
            } else {
                // Push two sub-sections on stack for further processing
                sig_start[n_stack] = sig;
                sig_end[n_stack] = end;
                n_stack++;
                sig_start[n_stack] = start;
                sig_end[n_stack] = sig;
                n_stack++;
            }
        } else {
            // No intermediate points, transfer current start point
            index[n_dest++] = start;
        }
    }

    // Transfer last point
    index[n_dest++] = n_source - 1;

    // Make return array
    return index.map((i) => source[i]);
};

export const goToBoundary = ({ map, maps, coordinates } = {}) => {
    let latlangLiteral = getBoundsLiteral(maps, coordinates);
    latlangLiteral = Array.isArray(latlangLiteral) ? latlangLiteral?.[0] : latlangLiteral;
    latlangLiteral && map.fitBounds(latlangLiteral);
    return latlangLiteral;
};

export const coordinatesToMVCArray = (instance, isMulti) => {
    if (isMulti) {
        let temp = [];
        if (instance && instance?.getPaths()) {
            const pathobj = instance.getPaths();
            const arr = pathobj.getArray();
            arr.forEach((arr) => {
                temp.push(mvcArrayGeoValues(arr.getArray()));
            });
        }
        return temp;
    }
    return mvcArrayGeoValues(instance.getPath());
};

/**
 * @description
 * BugFix: libraries such as AutoComplete dropdown is not appearing on fullscreen.
 * A quick workaround is to move the pac-container div inside the map div when entering full screen, and move it back on exit.
 * https://stackoverflow.com/questions/44850642/google-maps-autocomplete-dropdown-hidden-when-google-maps-full-screen-is-true/56712072#56712072
 */
export const movePacContainerInsideMap = (event) => {
    let target = event.target;
    let pacContainerElements = document.getElementsByClassName("pac-container");
    if (pacContainerElements.length > 0) {
        let pacContainer = document.getElementsByClassName("pac-container")[0];
        if (pacContainer.parentElement === target) {
            document.getElementsByTagName("body")[0].appendChild(pacContainer);
        } else {
            target.appendChild(pacContainer);
        }
    }
};

export const fitBounds = (map, maps, polygons) => {
    const bounds = polygons.reduce((boundsAccumulator, shape) => boundsAccumulator.union(shape.getBounds()), new maps.LatLngBounds());
    map.fitBounds(bounds);
};
