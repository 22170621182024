import React from "react";
import PropTypes from "prop-types";
import Payments from "../companyPayment/Payments";
import SubscriptionInfo from "../companySubscription/SubscriptionInfo";

function BillingTab({ styles = {} }) {
    return (
        <div className="billing-tab flex column gap-1" style={styles.parent || {}}>
            <SubscriptionInfo />
            <Payments />
        </div>
    );
}

export default BillingTab;

BillingTab.propTypes = {
    styles: PropTypes.shape({
        parent: PropTypes.object
    })
};
