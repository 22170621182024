import React, { useMemo } from "react";
import PropTypes from "prop-types";
import ListItem from "./ListItem";

function Accordion({ options, defaultOpen, alwaysOpen, secondary }) {
    const parentClass = useMemo(() => {
        let cn = "accordion ";
        if (secondary) cn += "secondary";
        return cn.trim();
    }, [secondary]);

    return (
        <div className={parentClass}>
            <ul className="accordion__content">
                {options.map((option, idx) => (
                    <ListItem key={idx} title={option.title} defaultOpen={defaultOpen} alwaysOpen={alwaysOpen}>
                        {option.children}
                    </ListItem>
                ))}
            </ul>
        </div>
    );
}

export default Accordion;

Accordion.propTypes = {
    secondary: PropTypes.bool,
    defaultOpen: PropTypes.bool,
    alwaysOpen: PropTypes.bool,
    onCollapse: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.any,
            children: PropTypes.any
        })
    )
};
