import React from "react";
import PropTypes from "prop-types";
import Modal from "../../../common/components/extra/Modal";
import SubscriptionPlan from "./SubscriptionPlan";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";

function ModalSubscriptionPlan({ open, onChange, onClose, onBack, ...rest }) {
    const user = useAppSelector(selectUser);

    return (
        <Modal
            open={open}
            onChange={onChange}
            onClose={onClose}
            styles={{ content: { width: "auto", minWidth: "50vw", maxWidth: "90vw" }, body: { marginTop: 0 } }}
        >
            <div className="tk-subscription-plan__modal" style={{ height: "100%" }}>
                <SubscriptionPlan
                    onSubscribe={() => typeof onBack === "function" && onBack()}
                    companySubscription={user.CompanySubscription}
                    {...rest}
                />
            </div>
        </Modal>
    );
}

ModalSubscriptionPlan.propTypes = {
    user: PropTypes.object,
    open: PropTypes.bool,
    data: PropTypes.object,
    onChange: PropTypes.func,
    onBack: PropTypes.func,
    onClose: PropTypes.func
};

export default ModalSubscriptionPlan;
