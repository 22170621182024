import React, { useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import {
    TOAST_TYPE,
    createEditingInfo,
    createToast,
    getHourDiff,
    isEqualWithDates,
    sanitizeWords,
    toReadableSelectOptions
} from "../../../common/utilities/helper";
import { SHIFT_TYPE, SORT_ORDER } from "../../../common/utilities/const";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser, selectUserSetting } from "../../common/slice";
import { getTimeRangeValues, isNextDay, isReqHoursSatisfied, checkShiftDayDifferences, createHoursOption, createHourLabel } from "./helper";
import { BREAK_TYPE, FIELDS, FORM_FIELDS, HOL_PREFIX, TIME_TYPE } from "./const";
import Filter from "./Filter";

import {
    useCreateWorkshiftMutation,
    useDeleteWorkshiftMutation,
    useGetWorkshiftByIdsMutation,
    useGetWorkshiftDetailsMutation,
    useLoadAllLazyWorkshiftMutation,
    useLoadAllWorkshiftMutation,
    useUpdateWorkshiftMutation
} from "../employeeWorkShift/api";

import {
    selectLoading as selectRWSLoading,
    selectSearching as selectRWSSearching,
    selectTableConfig as selectRWSTableConfig,
    selectRegularWorkShiftData,
    setLoading as setRWSLoading,
    setSearching as setRWSSearching,
    setState as setRWSState,
    selectFilter as selectRWSFilter,
    setRegularWorkShiftData,
    setTableConfig as setRWSTableConfig,
    setFilter as setRWSFilter,
    defaultFilter as defaultRWSFilter,
    resetFilter as resetRWSFilter,
    setClearCache as setRWSClearCache
} from "./regular/slice";

import {
    selectLoading as selectHWSLoading,
    selectSearching as selectHWSSearching,
    selectTableConfig as selectHWSTableConfig,
    selectHolidayWorkShiftData,
    setLoading as setHWSLoading,
    setSearching as setHWSSearching,
    setState as setHWSState,
    selectFilter as selectHWSFilter,
    setHolidayWorkShiftData,
    setTableConfig as setHWSTableConfig,
    setFilter as setHWSFilter,
    defaultFilter as defaultHWSFilter,
    resetFilter as resetHWSFilter,
    setClearCache as setHWSClearCache
} from "./holiday/slice";

export const rtkSwitcher = ({ isHoliday } = {}) => {
    return {
        selectData: isHoliday ? selectHolidayWorkShiftData : selectRegularWorkShiftData,
        selectTableConfig: isHoliday ? selectHWSTableConfig : selectRWSTableConfig,
        selectSearching: isHoliday ? selectHWSSearching : selectRWSSearching,
        setState: isHoliday ? setHWSState : setRWSState,
        setSearching: isHoliday ? setHWSSearching : setRWSSearching,
        setWorkShiftData: isHoliday ? setHolidayWorkShiftData : setRegularWorkShiftData,
        selectFilter: isHoliday ? selectHWSFilter : selectRWSFilter,
        setFilter: isHoliday ? setHWSFilter : setRWSFilter,
        setLoading: isHoliday ? setHWSLoading : setRWSLoading,
        selectLoading: isHoliday ? selectHWSLoading : selectRWSLoading,
        setTableConfig: isHoliday ? setHWSTableConfig : setRWSTableConfig,
        defaultFilter: isHoliday ? defaultHWSFilter : defaultRWSFilter,
        resetFilter: isHoliday ? resetHWSFilter : resetRWSFilter,
        setClearCache: isHoliday ? setHWSClearCache : setRWSClearCache
    };
};

export const useGetWorkShiftDetails = () => {
    const [data, setData] = useState({});
    const [getDetails, { isLoading }] = useGetWorkshiftDetailsMutation();

    const setting = useAppSelector(selectUserSetting);

    const fetch = async (id) => {
        try {
            const result = await getDetails({ extraPath: id });
            if (result.error) {
                throw new Error("Failed to fetch workshift. Please try again later");
            }
            setData(result.data.data);
            return result.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return {};
        }
    };

    const checkAllowEditTime = () => {
        const hasEmployees = !!data.Employees?.length;
        const isSplit = data.shift_type === SHIFT_TYPE.SPLIT;
        const calculateMinStartTime = data.start_time;
        const calculateMaxEndTime = isSplit ? data.end_time_2 : data.end_time;

        const editInfo = createEditingInfo({
            start: calculateMinStartTime,
            end: calculateMaxEndTime,
            ot: data.max_overtime,
            timezone: setting.timezone
        });

        return {
            isAllowed: !hasEmployees || editInfo.isEditAllowed,
            allowedTime: {
                after: editInfo.afterTime,
                before: editInfo.beforeTime
            }
        };
    };

    return [fetch, { data, isLoading, allowEditInfo: checkAllowEditTime() }];
};

export const useGetWorkShiftByIds = (ids = []) => {
    const [object, setObject] = useState({
        data: [],
        mounted: false
    });

    const [getWorkShifts, { isLoading }] = useGetWorkshiftByIdsMutation();

    const updateObject = (newObj = {}) => setObject({ ...object, ...newObj });

    const fetch = async () => {
        try {
            const response = await getWorkShifts({ body: { ids } });
            if (response.error) {
                throw new Error(response.error?.data?.message);
            }
            if (response.data && response.data.data) {
                const resdata = response.data.data;
                updateObject({ data: resdata });
            }
        } catch (error) {
            createToast(error.message || "Something went wrong with the server. Please contact support.", TOAST_TYPE.ERROR);
            updateObject({ data: [] });
        }
    };

    useEffect(() => {
        updateObject({ mounted: true });
    }, []);

    useEffect(() => {
        if (object.mounted) {
            fetch();
        }
    }, [object.mounted]);

    return [object.data, isLoading, { isMounted: object.mounted }];
};

export const usePaginateWorkShift = ({ readOnly, isHoliday } = {}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isMounted, setMounted] = useState(false);

    const dispatch = useAppDispatch();

    const { selectData, selectTableConfig, selectSearching, setState, setWorkShiftData, setSearching, setClearCache } = rtkSwitcher({ isHoliday });

    const data = useAppSelector(selectData);
    const tableConfig = useAppSelector(selectTableConfig);
    const searching = useAppSelector(selectSearching);

    const [load] = useLoadAllWorkshiftMutation();

    const fetch = async (config) => {
        if (searching) {
            return;
        }
        if (!isLoading) {
            setIsLoading(true);
        }
        try {
            const response = await load({
                body: {
                    ...tableConfig,
                    ...(config || {}),
                    isHoliday
                }
            });
            if (response.data && response.data.data) {
                if (typeof setState === "function") {
                    const result = response.data.data;
                    const oldConfig = { ...tableConfig, ...(config || {}) };
                    dispatch(
                        setState({
                            data: result.data,
                            tableConfig: {
                                ...oldConfig,
                                totalPage: result.totalPage,
                                totalCount: result.totalCount
                            }
                        })
                    );
                }
            }
            if (response.error) {
                throw new Error("Failed to fetch workshift. Please try again later.");
            }
            return response;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearchFetching = async () => {
        try {
            dispatch(setSearching(true));
            await fetch();
        } finally {
            dispatch(setSearching(false));
        }
    };

    useEffect(() => {
        if (isMounted) {
            handleSearchFetching();
        }
    }, [tableConfig.search]);

    useEffect(() => {
        if (isMounted && !readOnly) {
            if (!data.length) {
                fetch();
            } else {
                setIsLoading(false);
            }
        }
    }, [isMounted]);

    useEffect(() => {
        setMounted(true);
    }, []);

    const handleUpdate = (id, newObject = {}) => {
        const newdata = data.map((record) => {
            if (record.id == id) {
                record = {
                    ...record,
                    ...(newObject || {})
                };
            }
            return record;
        });
        dispatch(setWorkShiftData(newdata));
    };

    const handleFilter = async (filter) => {
        try {
            dispatch(setSearching(true));
            await fetch(filter ? { filter } : {}, true);
        } finally {
            dispatch(setSearching(false));
        }
    };

    return [data, { isLoading, fetch, update: handleUpdate, isSearching: searching, onFilter: handleFilter, tableConfig, setClearCache }];
};

export const useLazyWorkShift = ({ noHolidays, onlyHolidays } = {}) => {
    const LOAD_MORE_OFFSET = 5;
    const DEFAULT_SIZE = 15;

    const [object, setObject] = useState({
        data: [],
        sort: { sortBy: FIELDS.TITLE.name, order: SORT_ORDER.ASC },
        totalCount: 0,
        cursor: "",
        search: ""
    });

    const hasMore = object.totalCount > object.data?.length || 0;

    const [load, { isLoading }] = useLoadAllLazyWorkshiftMutation();

    const updateObject = (newObject = {}) => setObject((prev) => ({ ...prev, ...newObject }));

    const createRowItem = (row) => {
        const temp = cloneDeep(row);
        temp.value = temp.id;
        temp.label = (
            <div className="flex gap-05" style={{ alignItems: "center" }}>
                <span className="text-ellipsis semi-bold">{sanitizeWords(temp.title)}</span>
            </div>
        );
        return temp;
    };

    const fetch = async ({ sort, ...config } = {}, isReset) => {
        if (!sort) {
            sort = object.sort;
        }
        if (isReset) {
            config.cursor = "";
        }
        if (noHolidays) {
            config.noHolidays = noHolidays;
        }
        if (onlyHolidays) {
            config.onlyHolidays = onlyHolidays;
        }
        try {
            const response = await load({
                body: {
                    pageSize: DEFAULT_SIZE,
                    more: isReset ? DEFAULT_SIZE : LOAD_MORE_OFFSET,
                    ...object.sort,
                    ...sort,
                    ...(config || {})
                }
            });
            if (response.error) {
                throw new Error(response.error?.data?.message);
            }
            if (response.data && response.data.data) {
                const resdata = response.data.data;
                const newdata = resdata.data.map(createRowItem);
                const temp = {
                    data: isReset ? newdata : object.data.concat(newdata),
                    cursor: resdata.cursor,
                    totalCount: resdata.totalCount
                };
                sort && (temp.sort = sort);
                updateObject(temp);
            }
        } catch (error) {
            createToast(error.message || "Something went wrong with the server. Please contact support.", TOAST_TYPE.ERROR);
            sort && updateObject({ sort, data: [], totalCount: 0 });
        }
    };

    const loadMore = () => hasMore && fetch({ cursor: object.cursor });
    const reset = () => fetch({}, true);
    const search = (value = "") => fetch({ search: value }, true);

    useEffect(() => {
        fetch();
    }, []);

    return [object, updateObject, { isLoading, hasMore, fetch, reset, loadMore, search, createRowItem }];
};

export const useDeleteWorkShift = ({ isHoliday } = {}) => {
    const dispatch = useAppDispatch();

    const [deleteWorkShift] = useDeleteWorkshiftMutation();

    const { selectLoading, setLoading } = rtkSwitcher({ isHoliday });

    const isLoading = useAppSelector(selectLoading);

    const remove = async (id) => {
        if (!isLoading) {
            dispatch(setLoading(true));
        }
        try {
            const response = await deleteWorkShift({ extraPath: id });
            if (response.error) {
                throw new Error(response.error?.data?.message || "Failed to delete work shift.");
            }
            return response.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        } finally {
            dispatch(setLoading(false));
        }
    };

    return [remove, isLoading];
};

export const useUpsertWorkShift = (updateId) => {
    const isCreate = !updateId;

    const [oldInternalForms, setOldInternalForms] = useState({
        breakType: BREAK_TYPE.WITH_BREAK,
        breakTypeOption: toReadableSelectOptions(BREAK_TYPE)
    });
    const [internalForms, setInternalForms] = useState({
        breakType: BREAK_TYPE.WITH_BREAK,
        breakTypeOption: toReadableSelectOptions(BREAK_TYPE)
    });

    const [old, setOld] = useState(FORM_FIELDS);
    const [form, setForm] = useState(FORM_FIELDS);
    const [fetching, setFetching] = useState(true);
    const [error, setError] = useState({});
    const [warning, setWarning] = useState({});

    const [getDetails, { isLoading: isGettingDetails, allowEditInfo }] = useGetWorkShiftDetails();
    const [create, { isLoading: createIsLoading }] = useCreateWorkshiftMutation();
    const [update, { isLoading: updateIsLoading }] = useUpdateWorkshiftMutation();

    const user = useAppSelector(selectUser);
    const setting = user.Setting;
    const timezone = setting.timezone;
    const shiftDayDifferences = checkShiftDayDifferences(form, timezone);
    const isEditAllowed = isCreate || allowEditInfo.isAllowed;
    const hasError = !!(error && Object.values(error).filter(Boolean).length);
    const hasChanges = !isEqualWithDates(form, old);

    const createVars = () => {
        const createTitle = () =>
            (form?.[FIELDS.TITLE.name] && form[FIELDS.TITLE.name].startsWith(HOL_PREFIX) && form[FIELDS.TITLE.name].replace(HOL_PREFIX, "")) ||
            form?.[FIELDS.TITLE.name];

        const config = {
            title: createTitle(),
            isSplit: form.shift_type == SHIFT_TYPE.SPLIT,
            isNormal: form.shift_type == SHIFT_TYPE.NORMAL,
            isLoading: isGettingDetails || createIsLoading || updateIsLoading,
            isHoliday: form.is_holiday,
            hasShiftOne: form.start_time && form.end_time,
            hasShiftTwo: form.shift_type == SHIFT_TYPE.NORMAL || (form.start_time_2 && form.end_time_2),
            isShiftOneNextDay: isNextDay({
                startTimeOne: form[FIELDS.START_TIME.name],
                endTimeOne: form[FIELDS.END_TIME.name]
            }),
            isShiftTwoNextDay: isNextDay({
                startTimeOne: form[FIELDS.START_TIME_2.name],
                endTimeOne: form[FIELDS.END_TIME_2.name]
            })
        };
        // shift vars
        config.shiftDayDifferences = shiftDayDifferences;
        config.shiftOneValue = getTimeRangeValues(form, config.isSplit, setting.timezone)[TIME_TYPE.SHIFT_ONE];
        config.shiftTwoValue = getTimeRangeValues(form, config.isSplit, setting.timezone)[TIME_TYPE.SHIFT_TWO];
        config.shiftOneDiff = getHourDiff(config.shiftOneValue.range.start, config.shiftOneValue.range.end, setting.timezone);
        config.shiftTwoDiff = getHourDiff(config.shiftTwoValue.range.start, config.shiftTwoValue.range.end, setting.timezone);
        config.shiftHoursOption = createHoursOption(FIELDS.REQ_SHIFT_TIME.constraint, "No Required Shift");
        config.shiftHours = createHourLabel(config.shiftHoursOption, form[FIELDS.REQ_SHIFT_TIME.name]);
        // break vars
        config.breakValue = getTimeRangeValues(form, config.isSplit, setting.timezone)[TIME_TYPE.BREAK];
        config.breakTwoValue = getTimeRangeValues(form, config.isSplit, setting.timezone)[TIME_TYPE.BREAK_TWO];
        config.maxBreakDurationOption = createHoursOption(FIELDS.MAX_BREAK_DURATION.constraint, "No Break").filter((option) => !!option.value);
        config.maxBreakDuration = createHourLabel(config.maxBreakDurationOption, form[FIELDS.MAX_BREAK_DURATION.name]);
        config.breakDiff = getHourDiff(config.breakValue.range.start, config.breakValue.range.end, setting.timezone);
        config.breakTwoDiff = getHourDiff(config.breakTwoValue.range.start, config.breakTwoValue.range.end, setting.timezone);
        config.breakOneLimitHours = FIELDS.MAX_BREAK_DURATION.constraint.max - config.breakTwoDiff || 0;
        config.breakTwoLimitHours = FIELDS.MAX_BREAK_DURATION.constraint.max - config.breakDiff || 0;
        config.totalBreakDiff = config.breakTwoDiff + config.breakDiff;
        config.isNoBreak = internalForms.breakType == BREAK_TYPE.NO_BREAK;
        config.isWithBreak = internalForms.breakType == BREAK_TYPE.WITH_BREAK;
        config.isFlexibleBreak = internalForms.breakType == BREAK_TYPE.FLEXIBLE_BREAK;
        // ot vars
        config.maxOTHoursOption = createHoursOption(FIELDS.MAX_OVERTIME.constraint, "No Max OT").map((option) => ({
            ...option,
            isDisabled: option.value > shiftDayDifferences.remainingHrsUntilEndDay
        }));
        config.maxOTHours = createHourLabel(config.maxOTHoursOption, form[FIELDS.MAX_OVERTIME.name]);
        config.remainingOTHoursExceededMax = shiftDayDifferences.remainingHrsUntilEndDay > FIELDS.MAX_OVERTIME.constraint.max;
        config.isAllOtHoursOptionsDisabled = config.maxOTHoursOption.filter((hour) => hour.value != 0).every((hour) => hour.isDisabled);

        config.showEditCapabilityWarning = !isCreate && !isEditAllowed;
        config.showError = !!error?.all;
        config.isShiftOneMaxSelection = config.shiftOneValue.range.end && config.shiftOneValue.range.end.format("hh:mm A") == "11:30 PM";
        return config;
    };

    const vars = createVars();

    const updateForm = (config = {}) => setForm({ ...form, ...config });
    const updateWarning = (obj = {}) => setWarning({ ...warning, ...obj });
    const updateError = (obj = {}) => setError({ ...error, ...obj });

    const upsert = async () => {
        let result = null;
        try {
            const clonedform = cloneDeep(form);

            if (clonedform.is_holiday) {
                if (!clonedform.title.startsWith(HOL_PREFIX)) {
                    clonedform.title = HOL_PREFIX + clonedform.title;
                }
            } else {
                if (clonedform.title.startsWith(HOL_PREFIX)) {
                    clonedform.title = clonedform.title.replace(HOL_PREFIX, "");
                }
            }

            if (vars.isNoBreak || vars.isFlexibleBreak) {
                clonedform[FIELDS.BREAK_TIME.name] = null;
                clonedform[FIELDS.BREAK_END_TIME.name] = null;
                clonedform[FIELDS.BREAK_TIME_2.name] = null;
                clonedform[FIELDS.BREAK_END_TIME_2.name] = null;
            }

            if (vars.isNoBreak) {
                clonedform[FIELDS.MAX_BREAK_DURATION.name] = null;
            } else if (vars.isWithBreak) {
                clonedform[FIELDS.MAX_BREAK_DURATION.name] = vars.totalBreakDiff;
            }

            if (isCreate) {
                result = await create({ body: clonedform });
            } else {
                result = await update({ body: clonedform, extraPath: form.id });
            }
            if (result.error) {
                throw new Error(result.error?.data?.message);
            }
            if (result.data) {
                if (result.data?.data) {
                    createToast(`Work Shift ${isCreate ? "created" : "updated"} succesfully.`, TOAST_TYPE.SUCCESS);
                } else {
                    createToast(result.data.message, TOAST_TYPE.SUCCESS);
                }
            }
            setError({});
            setWarning({});
            return result.data.data;
        } catch (error) {
            createToast(
                `Failed to ${!isCreate ? "update" : "create"} Work Shift. ${error?.message || "Please try again later or contact support."} `,
                TOAST_TYPE.ERROR
            );
            updateError({ all: error.message });
            return { error };
        }
    };

    useEffect(() => {
        const getInternalBreakType = (obj = {}) => {
            const hasBreak = obj[FIELDS.BREAK_TIME.name] || obj[FIELDS.BREAK_TIME_2.name];
            const hasMaxBreak = obj[FIELDS.MAX_BREAK_DURATION.name];
            if (hasBreak && hasMaxBreak) {
                return BREAK_TYPE.WITH_BREAK;
            } else if (!hasBreak && hasMaxBreak) {
                return BREAK_TYPE.FLEXIBLE_BREAK;
            } else {
                return BREAK_TYPE.NO_BREAK;
            }
        };

        if (updateId) {
            getDetails(updateId)
                .then((result) => {
                    setForm(result);
                    setOld(result);
                    setFetching(false);
                    setInternalForms({
                        ...internalForms,
                        breakType: getInternalBreakType(result)
                    });
                    setOldInternalForms({
                        ...internalForms,
                        breakType: getInternalBreakType(result)
                    });
                })
                .catch(() => setFetching(false));
        } else {
            setFetching(false);
        }
    }, []);

    const validateOnChange = (param = {}, name) => {
        const obj = { ...form, ...param };

        if (name == FIELDS.TITLE.name) {
            return obj;
        }

        const selectedOT = obj[FIELDS.MAX_OVERTIME.name];
        const hasShiftOne = obj[FIELDS.START_TIME.name] && obj[FIELDS.END_TIME.name];
        const hasShiftTwo = obj[FIELDS.START_TIME_2.name] && obj[FIELDS.END_TIME_2.name];
        const isSplit = obj[FIELDS.SHIFT_TYPE.name] == SHIFT_TYPE.SPLIT;
        const hasValidShift = (!isSplit && hasShiftOne) || (isSplit && hasShiftOne && hasShiftTwo);

        // validate req hrs
        const isReqHrsSatisfied = !hasValidShift || (hasValidShift && isReqHoursSatisfied(obj[FIELDS.REQ_SHIFT_TIME.name], obj, internalForms));

        if (!isReqHrsSatisfied) {
            updateError({
                [FIELDS.START_TIME.name]: `The total shift duration must meet the specified required shift hours of ${
                    form[FIELDS.REQ_SHIFT_TIME.name]
                }hr. (Break is not included)`,
                [FIELDS.START_TIME_2.name]: `The total shift must meet the specified minimum requirement of ${
                    form[FIELDS.REQ_SHIFT_TIME.name]
                }hr. (Break is not included)`,
                all: null
            });
        } else {
            updateError({ [FIELDS.START_TIME.name]: null, [FIELDS.START_TIME_2.name]: null, all: null });
        }

        // validate OT hrs
        if (hasValidShift) {
            const shiftDayDifferences = checkShiftDayDifferences(obj, timezone);
            const maxOTHoursOption = vars.maxOTHoursOption.map((option) => ({
                ...option,
                isDisabled: isSplit ? option.value > shiftDayDifferences.remainingHrsUntilEndDay : false
            }));
            const remaininingAvailableOTHours = shiftDayDifferences.remainingHrsUntilEndDay;
            const isAllOtHoursOptionsDisabled = maxOTHoursOption.filter((hour) => hour.value != 0).every((hour) => hour.isDisabled);

            const OTOptions = [...maxOTHoursOption];
            OTOptions.sort((a, b) => b.value - a.value);
            const invalidOTSelecton = selectedOT && selectedOT > remaininingAvailableOTHours;

            if (invalidOTSelecton) {
                const newOT = OTOptions.find((option) => option.value <= remaininingAvailableOTHours);
                if (newOT.value != selectedOT) {
                    obj[FIELDS.MAX_OVERTIME.name] = newOT.value;
                    updateWarning({ [FIELDS.MAX_OVERTIME.name]: null });
                }
            } else {
                if (isAllOtHoursOptionsDisabled) {
                    obj[FIELDS.MAX_OVERTIME.name] = FIELDS.MAX_OVERTIME.constraint.min;
                    updateWarning({ [FIELDS.MAX_OVERTIME.name]: null });
                } else {
                    if (!isEqualWithDates(obj, old) && name != FIELDS.MAX_OVERTIME.name) {
                        obj[FIELDS.MAX_OVERTIME.name] = "";
                        updateWarning({ [FIELDS.MAX_OVERTIME.name]: "Value has been cleared due to changes" });
                    }
                }
            }
        }

        return obj;
    };

    return [
        form,
        updateForm,
        {
            upsert,
            isLoading: vars.isLoading || fetching,
            config: vars,
            hasChanges,
            hasInternalChanges: !isEqualWithDates(internalForms, oldInternalForms),
            isEditAllowed,
            allowedTime: allowEditInfo.allowedTime,
            error,
            setError,
            updateError,
            warning,
            setWarning,
            updateWarning,
            internalForms,
            setInternalForms,
            hasError,
            validateOnChange
        }
    ];
};

export const useRegularWorkShiftStates = () => {
    const [, { isSearching, onFilter }] = usePaginateWorkShift({
        readOnly: true,
        isHoliday: false
    });

    const { selectTableConfig, selectData, selectFilter, setTableConfig } = rtkSwitcher({ isHoliday: false });

    const config = useAppSelector(selectTableConfig);
    const filter = useAppSelector(selectFilter);
    const data = useAppSelector(selectData);

    return {
        Filter: <Filter onFilter={onFilter} isLoading={isSearching} filter={filter} />,
        config,
        filter,
        isSearching,
        onFilter,
        setTableConfig,
        searchPlaceholder: "Search workshift title...",
        data
    };
};

export const useHolidayWorkShiftStates = () => {
    const [, { isSearching, onFilter }] = usePaginateWorkShift({
        readOnly: true,
        isHoliday: true
    });

    const { selectTableConfig, selectData, selectFilter, setTableConfig } = rtkSwitcher({ isHoliday: true });

    const config = useAppSelector(selectTableConfig);
    const filter = useAppSelector(selectFilter);
    const data = useAppSelector(selectData);

    return {
        Filter: <Filter onFilter={onFilter} isLoading={isSearching} filter={filter} />,
        config,
        filter,
        isSearching,
        onFilter,
        setTableConfig,
        searchPlaceholder: "Search workshift title...",
        data
    };
};
