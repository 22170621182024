import React from "react";
import CompanySubscriptions from "../../features/admin/companySubscriptions/CompanySubscriptions";
import { selectLoading } from "../../features/admin/companySubscriptions/slice";
import { useAppSelector } from "../../common/hooks/reduxHooks";
import PageLayout from "../../common/components/layout/PageLayout";

function CompanySubscriptionPage() {
    const loading = useAppSelector(selectLoading);

    return (
        <PageLayout
            className="tk-company-subscription"
            title="Manage Company Subscriptions"
            isLoading={loading}
            styles={{
                body: {
                    height: "calc(100vh - 20.5rem)"
                }
            }}
        >
            <CompanySubscriptions />
        </PageLayout>
    );
}

export default CompanySubscriptionPage;
