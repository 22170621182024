import { FILE_MIME_TYPES } from "../../../common/utilities/const";

export const BASE_CLASS = "tk-company-announcements";
export const VIEW_MODAL_BASE_CLASS = BASE_CLASS + "__modal-content-view";
export const VIEW_CONTENT_MODAL_BASE_CLASS = BASE_CLASS + "__modal-content-view-html";
export const UPDATE_MODAL_BASE_CLASS = BASE_CLASS + "__modal-content-update";
export const NOTES_MAX_LEN = 500;

export const FIELD = {
    TITLE: "title",
    DESCRIPTION: "description",
    TYPE: "type",
    CONTENT: "content"
};

export const FIELD_OBJECT = {
    [FIELD.TITLE]: {
        label: "Title",
        default: "",
        required: true
    },
    [FIELD.TYPE]: {
        label: "Type",
        default: "",
        required: true
    },
    [FIELD.DESCRIPTION]: {
        label: "Notes/Description",
        default: "",
        required: true,
        limit: NOTES_MAX_LEN,
        tooltip: "This content will be used for the announcement that will be visible to all employees once published."
    },
    [FIELD.CONTENT]: {
        label: "Content",
        default: "",
        required: true,
        accepts: Object.values(FILE_MIME_TYPES.IMAGE).toString(),
        size: 1, // 1MB,
        limit: 2500,
        placeholder: "Create your announcement here..."
    }
};

export const FILTER_TYPE = {
    TYPE: 0x1,
    DATE: 0x2
};

export const ANNOUNCEMENT_TYPES = {
    GENERAL: "GENERAL",
    INTERNAL_MEMO: "INTERNAL_MEMO",
    HUMAN_RESOURCE: "HUMAN_RESOURCE",
    MANAGEMENT: "MANAGEMENT",
    REGULATORY: "REGULATORY",
    FINANCIAL: "FINANCIAL",
    OPERATIONAL: "OPERATIONAL",
    RECOGNITION: "RECOGNITION",
    TECHNOLOGY: "TECHNOLOGY",
    EVENT: "EVENT",
    EMPLOYEE: "EMPLOYEE",
    HEALTH_AND_SAFETY: "HEALTH_AND_SAFETY",
    MISC: "MISC"
};

export const CUSTOM_ACTION = {
    NOTIFY: "NOTIFY"
};
