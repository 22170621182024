import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Modal from "../../../common/components/extra/Modal";
import FileRenderer, { FILE_FETCH_TYPE } from "../../../common/components/extra/FileRenderer";
import { VIEW_MODAL_TYPE } from "./const";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import { createPublicApi } from "../../../app/apiSlice";
import Empty from "../../../common/components/extra/Empty";

const { ID_COPY, VISA_COPY, PASSPORT_COPY, CONTRACT_COPY, PHOTO } = VIEW_MODAL_TYPE;

function ViewFileModal({ open, onClose, data, type, nofetch }) {
    const [loading, setLoading] = useState(true);
    const user = useAppSelector(selectUser);

    const paths = data?.id ? [user.id, data.id] : [];
    const isPhoto = type == PHOTO;

    useEffect(() => {
        if (loading && isPhoto) {
            setLoading(false);
        }
    }, [type]);

    return (() => {
        let title = "",
            style = { minWidth: "17rem" };
        switch (type) {
            case PHOTO: {
                title = "Photo";
                break;
            }
            case ID_COPY: {
                title = "Residence ID Copy";
                break;
            }
            case VISA_COPY: {
                title = "Visa ID Copy";
                break;
            }
            case PASSPORT_COPY: {
                title = "Passport ID Copy";
                break;
            }
            case CONTRACT_COPY: {
                title = "Contract Copy";
                break;
            }
            default:
                break;
        }

        return (
            <Modal title={"View " + title} open={open} onClose={onClose} styles={{ title: { textAlign: "left" }, content: style }} small>
                <div className="tk-companies__modal-content-view" style={{ padding: "1rem" }}>
                    <div className="tk-employees__modal-content-view__file">
                        {isPhoto ? (
                            <img
                                className="responsive-img"
                                src={
                                    data?.src
                                        ? data?.src
                                        : createPublicApi({
                                              companyId: user.id,
                                              id: data?.id,
                                              filename: data?.filename
                                          })
                                }
                                alt="image"
                                width={10}
                                height={10}
                                crossOrigin="anonymous"
                            />
                        ) : (
                            <FileRenderer
                                title={title}
                                onLoading={(bool) => bool != loading && setLoading(bool)}
                                filename={data?.filename}
                                src={data?.src}
                                fetchType={FILE_FETCH_TYPE.EMPLOYEE}
                                paths={paths}
                                style={{
                                    height: "80vh",
                                    width: "70vw",
                                    position: "relative",
                                    minWidth: "20rem"
                                }}
                                nofetch={nofetch}
                                emptyRender={
                                    <Empty
                                        loadingMessage="Getting File..."
                                        isLoading={loading}
                                        noicon={!loading}
                                        message={<p className="fade">No {title} uploaded.</p>}
                                    />
                                }
                                useViewer
                            />
                        )}
                    </div>
                </div>
            </Modal>
        );
    })();
}

ViewFileModal.propTypes = {
    open: PropTypes.bool,
    data: PropTypes.oneOfType([
        PropTypes.shape({
            filename: PropTypes.any,
            src: PropTypes.string,
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            title: PropTypes.string
        }),
        PropTypes.any
    ]),
    onChange: PropTypes.func,
    type: PropTypes.oneOf(Object.values(VIEW_MODAL_TYPE)),
    nofetch: PropTypes.bool
};

export default ViewFileModal;
