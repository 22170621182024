import React, { useEffect, useState } from "react";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import { FORM_FIELDS } from "./const";
import { FILTER_ALL_VALUE, SORT_ORDER } from "../../../common/utilities/const";
import {
    useCreateSiteMutation,
    useDeleteSiteMutation,
    useGetSiteDetailsMutation,
    useLoadAllSiteEmployeesLazyMutation,
    useLoadAllSitesLazyMutation,
    useUpdateSiteMutation
} from "./api";
import { TOAST_TYPE, createEditingInfo, createToast, sanitizeWords } from "../../../common/utilities/helper";
import { selectUserSetting } from "../../common/slice";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import {
    DEFAULT_SIZE,
    LOAD_MORE_OFFSET,
    defaultConfig,
    selectLoading,
    selectSearching,
    selectTableConfig,
    selectWorkSiteData,
    setLoading,
    setSearching,
    setState,
    setTableConfig
} from "./slice";

export const useGetWorkSites = () => {
    const [data, setData] = useState({});
    const [getDetails, { isLoading }] = useGetSiteDetailsMutation();

    const setting = useAppSelector(selectUserSetting);

    const fetch = async (siteId) => {
        try {
            const result = await getDetails({ body: { siteId } });
            if (result.error) {
                throw new Error("Failed to fetch work sites. Please try again later");
            }
            setData(result.data.data);
            return result.data.data.result;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return {};
        }
    };

    const checkAllowEditTime = () => {
        const editInfo = createEditingInfo({
            start: data.before,
            end: data.after,
            timezone: setting.timezone
        });

        return {
            isAllowed: !data.hasEmployees || editInfo.isEditAllowed,
            allowedTime: {
                after: editInfo.afterTime,
                before: editInfo.beforeTime
            }
        };
    };

    return [fetch, { data, isLoading, allowEditInfo: checkAllowEditTime() }];
};

export const useGetWorkSitesByIds = (siteIds = []) => {
    const [object, setObject] = useState({
        data: [],
        mounted: false
    });

    const [getSites, { isLoading }] = useGetSiteDetailsMutation();

    const updateObject = (newObj = {}) =>
        setObject((prev) => ({
            ...prev,
            ...newObj
        }));

    const fetch = async () => {
        try {
            const response = await getSites({
                body: { siteIds }
            });
            if (response.error) {
                throw new Error(response.error?.data?.message);
            }
            if (response.data && response.data.data) {
                const resdata = response.data.data;
                updateObject({ data: resdata, mounted: true });
            }
        } catch (error) {
            createToast(error.message || "Something went wrong with the server. Please contact support.", TOAST_TYPE.ERROR);
            updateObject({ data: [], mounted: true });
        }
    };

    useEffect(() => {
        fetch();
    }, []);

    return [object.data, isLoading, { isMounted: object.mounted }];
};

export const useLazyWorkSites = ({ startFrom = "", cached = false, isReadableSelected, defaultValue, isFilter } = {}) => {
    const [fetching, setFetching] = useState(true);
    const [object, setObject] = useState({
        data: [],
        sort: { sortBy: defaultConfig.sortBy, order: defaultConfig.order },
        totalCount: 0,
        cursor: "",
        search: ""
    });

    const [load, { isLoading }] = useLoadAllSitesLazyMutation();

    const dispatch = useAppDispatch();
    const siteData = useAppSelector(selectWorkSiteData);
    const tableConfig = useAppSelector(selectTableConfig);
    const hasMore = cached ? tableConfig.totalCount > siteData?.length || false : object.totalCount > object.data?.length || 0;
    const searching = useAppSelector(selectSearching);

    const updateObject = (newObject = {}) => (cached ? dispatch(setTableConfig(newObject)) : setObject((prev) => ({ ...prev, ...newObject })));

    const createRowItem = (row) => {
        const temp = cloneDeep(row);
        const title = sanitizeWords(temp.title);

        temp.value = temp.id;

        if (isFilter) {
            temp.label = (
                <div className="flex gap-05" style={{ alignItems: "center" }}>
                    <span className="text-ellipsis small-font bold">{title}</span>
                </div>
            );
        } else {
            temp.isFixed = isReadableSelected && (Array.isArray(defaultValue) ? defaultValue.includes(temp.id) : defaultValue == temp.id);
            temp.label = (
                <div className="flex gap-05 align-center">
                    <span className="text-ellipsis semi-bold">{title}</span>
                </div>
            );
        }
        return temp;
    };

    const fetch = async ({ sort, ...config } = {}, isReset) => {
        if (searching) {
            return;
        }
        if (!sort) {
            sort = object.sort;
        }
        if (isReset) {
            config.cursor = "";
        }
        try {
            const response = await load({
                body: {
                    pageSize: DEFAULT_SIZE,
                    more: isReset ? DEFAULT_SIZE : LOAD_MORE_OFFSET,
                    ...object.sort,
                    ...sort,
                    ...(config || {})
                }
            });
            if (response.error) {
                throw new Error(response.error?.data?.message);
            }
            if (response.data && response.data.data) {
                const resdata = response.data.data;

                if (cached) {
                    dispatch(
                        setState({
                            data: isReset ? resdata.data : siteData.concat(resdata.data),
                            tableConfig: {
                                ...tableConfig,
                                ...(sort || {}),
                                ...(config || {}),
                                cursor: resdata.cursor,
                                totalCount: resdata.totalCount
                            }
                        })
                    );
                } else {
                    let newdata = [];
                    // used in filter
                    if (isFilter) {
                        newdata = [...resdata.data].map(createRowItem);
                    } else {
                        newdata = resdata.data.map(createRowItem);
                    }

                    const temp = {
                        data: isReset ? newdata : object.data.concat(newdata),
                        cursor: resdata.cursor,
                        totalCount: resdata.totalCount
                    };

                    if (isFilter) {
                        temp.data = temp.data
                            .filter((t) => t.value)
                            .concat({
                                id: FILTER_ALL_VALUE.value,
                                title: "",
                                value: FILTER_ALL_VALUE.value,
                                label: (
                                    <div className="flex gap-05" style={{ alignItems: "center" }}>
                                        <span className="text-ellipsis small-font bold">All Sites</span>
                                    </div>
                                )
                            });
                    }

                    sort && (temp.sort = sort);
                    updateObject(temp);
                }
            }
        } catch (error) {
            createToast(error.message || "Something went wrong with the server. Please contact support.", TOAST_TYPE.ERROR);
            sort && updateObject({ sort, data: [], totalCount: 0 });
        }
    };

    const loadMore = () => hasMore && fetch({ cursor: cached ? tableConfig.cursor : object.cursor });
    const reset = (config = {}) => fetch(config, true);
    const search = (value = "") => fetch({ search: value }, true);

    const handleSearchFetching = async () => {
        try {
            dispatch(setSearching(true));
            await search(tableConfig.search);
            setObject((prev) => ({ ...prev, search: tableConfig.search }));
        } finally {
            dispatch(setSearching(false));
        }
    };

    useEffect(() => {
        reset({ startFrom })
            .then(() => setFetching(false))
            .catch(() => setFetching(false));
    }, []);

    useEffect(() => {
        if (cached && object.search !== tableConfig.search) {
            handleSearchFetching();
        }
    }, [tableConfig.search]);

    return [
        cached ? siteData : object,
        updateObject,
        {
            isLoading: fetching || isLoading,
            hasMore,
            fetch,
            reset,
            loadMore,
            search,
            createRowItem,
            tableConfig // available for cached only
        }
    ];
};

export const useLazyWorkSitesEmployees = ({ siteId, startFrom = "" } = {}) => {
    const LOAD_MORE_OFFSET = 5;
    const DEFAULT_SIZE = 15;

    const [object, setObject] = useState({
        data: [],
        selected: null,
        sort: { sortBy: "residenceID", order: SORT_ORDER.ASC },
        totalCount: 0,
        cursor: "",
        search: ""
    });

    const hasMore = object.totalCount > object.data?.length || 0;

    const [load, { isLoading }] = useLoadAllSiteEmployeesLazyMutation();

    const updateObject = (newObject = {}) => setObject((prev) => ({ ...prev, ...newObject }));

    const fetch = async ({ sort, ...config } = {}, isReset) => {
        if (!sort) {
            sort = object.sort;
        }
        if (isReset) {
            config.cursor = "";
        }
        try {
            const response = await load({
                body: {
                    siteId,
                    pageSize: DEFAULT_SIZE,
                    more: isReset ? DEFAULT_SIZE : LOAD_MORE_OFFSET,
                    ...object.sort,
                    ...sort,
                    ...(config || {})
                }
            });
            if (response.error) {
                throw new Error(response.error?.data?.message);
            }
            if (response.data && response.data.data) {
                const resdata = response.data.data;
                const temp = {
                    data: isReset ? resdata.data : object.data.concat(resdata.data),
                    cursor: resdata.cursor,
                    totalCount: resdata.totalCount
                };
                sort && (temp.sort = sort);
                updateObject(temp);
            }
        } catch (error) {
            createToast(error.message || "Something went wrong with the server. Please contact support.", TOAST_TYPE.ERROR);
            sort && updateObject({ sort, data: [], totalCount: 0 });
        }
    };

    const loadMore = () => hasMore && fetch({ cursor: object.cursor });
    const reset = () => fetch({}, true);
    const search = (value = "") => fetch({ search: value }, true);
    const sort = ({ sortBy, order }) => fetch({ sort: { sortBy, order } }, true);

    useEffect(() => {
        fetch({ startFrom });
    }, []);

    return [object, updateObject, { isLoading, hasMore, fetch, reset, loadMore, search, sort }];
};

export const useDeleteWorkSites = () => {
    const dispatch = useAppDispatch();

    const [deleteWorkSite] = useDeleteSiteMutation();

    const isLoading = useAppSelector(selectLoading);

    const remove = async (id) => {
        if (!isLoading) {
            dispatch(setLoading(true));
        }
        try {
            const response = await deleteWorkSite({ extraPath: id });
            if (response.error) {
                throw new Error(response.error?.data?.message || "Failed to delete work site.");
            }
            return true;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        } finally {
            dispatch(setLoading(false));
        }
    };

    return [remove, isLoading];
};

export const useUpsertWorkSites = (updateId) => {
    const isCreate = !updateId;

    const [old, setOld] = useState(FORM_FIELDS);
    const [form, setForm] = useState(FORM_FIELDS);
    const [fetching, setFetching] = useState(true);

    const [getDetails, { allowEditInfo }] = useGetWorkSites();
    const [create, { isLoading: createIsLoading }] = useCreateSiteMutation();
    const [update, { isLoading: updateIsLoading }] = useUpdateSiteMutation();

    const createVars = () => {
        return {
            isLoading: createIsLoading || updateIsLoading,
            coordinatesChanged: !isEqual(form?.coordinates, old?.coordinates),
            isIncomplete: !isCreate && (form.supervisors?.length <= 0 || form.managers?.length <= 0 || !form.work_detail_id)
        };
    };

    const vars = createVars();

    const updateForm = (config = {}) => setForm((prev) => ({ ...prev, ...config }));

    const upsert = async (thumbnail) => {
        let result = null;
        try {
            const clonedform = cloneDeep(form);
            clonedform.managers = clonedform.managers.map((cm) => cm.id);
            clonedform.supervisors = clonedform.supervisors.map((cm) => cm.id);
            clonedform.EmployeeWorkDetail && delete clonedform.EmployeeWorkDetail;
            if (thumbnail) {
                clonedform.thumbnail = thumbnail;
            }
            if (isCreate) {
                result = await create({ body: clonedform });
            } else {
                result = await update({ body: clonedform, extraPath: updateId });
            }
            if (result.error) {
                throw new Error(result.error?.data?.message);
            }
            return result.data.data;
        } catch (err) {
            throw new Error(err.message);
        }
    };

    useEffect(() => {
        if (updateId) {
            getDetails(updateId)
                .then((result) => {
                    setForm(result);
                    setOld(result);
                    setFetching(false);
                })
                .catch(() => setFetching(false));
        } else {
            setFetching(false);
        }
    }, []);

    return [
        form,
        updateForm,
        {
            upsert,
            isLoading: vars.isLoading,
            isGettingWorkSites: fetching,
            config: vars,
            hasChanges: isEqual(form, old),
            isEditAllowed: isCreate || allowEditInfo.isAllowed,
            allowedTime: allowEditInfo.allowedTime
        }
    ];
};
