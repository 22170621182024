import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import cloneDeep from "lodash/cloneDeep";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import useTimer from "../../../common/hooks/useTimer";
import { selectRole, selectUser, updateUser } from "../../common/slice";
import { createToast, formatTimeToTwoDigits, TOAST_TYPE } from "../../../common/utilities/helper";
import { COMPANY_FILES, RECURRENCE, RECURRENCE_WITH_VALUE, ROLE_TYPE } from "../../../common/utilities/const";
import { useUpdateCompanyProfileMutation } from "./companyAPI";
import { FIELDS, INITIAL_UPLOADS } from "./const";
import { useLocation } from "react-router-dom";
import Navigation from "../../../common/classes/Navigation";

const extractTime = (dura) => {
    const duration = moment.duration(dura);
    // Extract months, days, hours, and minutes
    const months = Math.floor(duration.asMonths());
    duration.subtract(moment.duration(months, "months"));
    const days = Math.floor(duration.asDays());
    duration.subtract(moment.duration(days, "days"));
    const hours = Math.floor(duration.asHours());
    duration.subtract(moment.duration(hours, "hours"));
    const minutes = Math.floor(duration.asMinutes());
    duration.subtract(moment.duration(minutes, "minutes"));
    const seconds = Math.floor(duration.asSeconds());
    return {
        months,
        days,
        hours,
        minutes,
        seconds
    };
};

export const useGetValidityTimeRemaining = ({ key, defaultRemainingWarningPeriod, noTimer, alwaysShow }) => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser) || {};
    const role = useAppSelector(selectRole);
    const validity = cloneDeep(user.validity);

    const isSuperAdmin = role.type == ROLE_TYPE.SUPER_ADMIN;
    const current = user?.validity?.[key] || {};
    const remainingTime = current?.remainingTime || {};
    const remainingDay = remainingTime.day;
    const remainingMonths = remainingTime.months;
    const showWarning = remainingTime.day <= defaultRemainingWarningPeriod;
    const recurrence = user?.CompanySubscription?.Subscription?.recurrence;
    const recurrenceValue = (recurrence && RECURRENCE_WITH_VALUE[recurrence].value) || 0;

    const [time] = useTimer(
        !alwaysShow && (!showWarning || isSuperAdmin || noTimer) ? 0 : remainingTime.duration,
        () =>
            !remainingDay &&
            !noTimer &&
            dispatch(
                updateUser({
                    validity: {
                        ...validity,
                        [key]: {
                            ...(validity?.[key] || {}),
                            isExpired: true
                        }
                    }
                })
            )
    );

    const duration = moment.duration(time);
    const readableForm = `${duration.hours()}:${duration.minutes()}:${duration.seconds()}`;
    const renderMonth = duration.months() >= 1 && `${duration.months()} Month(s)`;

    const renderText = (
        <span style={{ color: "red" }}>
            {renderMonth} {`${duration.days()} Day(s)`} {formatTimeToTwoDigits(readableForm)}
        </span>
    );

    if (isSuperAdmin) {
        return {};
    }

    return {
        showWarning,
        text: renderText,
        isLessThanOneMonth: !current.isExpired && remainingMonths < 1,
        isLessThanSixMonths: !current.isExpired && remainingMonths < 6,
        isLessThanTwelveMonths: !current.isExpired && remainingMonths < 12,
        date: extractTime(remainingTime.duration),
        isExpired: current.isExpired,
        isWithinRecurrence: recurrenceValue <= remainingMonths,
        ...remainingTime
    };
};

export const useGetSubscriptionTimeRemaining = (noTimer, alwaysShow) => {
    const result = useGetValidityTimeRemaining({
        defaultRemainingWarningPeriod: Number(process.env.REACT_APP_SUBSCRIPTION_TIME_REMAINING_WARNING || 5),
        key: "subscription",
        noTimer,
        alwaysShow
    });
    return result;
};

export const useGetContractTimeRemaining = (noTimer) => {
    const result = useGetValidityTimeRemaining({
        defaultRemainingWarningPeriod: Number(process.env.REACT_APP_FILE_EXPIRY_TIME_REMAINING_WARNING || 5),
        key: "contract",
        noTimer
    });
    return result;
};

export const useGetLicenseTimeRemaining = (noTimer, { isCommercial } = {}) => {
    const result = useGetValidityTimeRemaining({
        defaultRemainingWarningPeriod: Number(process.env.REACT_APP_FILE_EXPIRY_TIME_REMAINING_WARNING || 5),
        key: isCommercial ? "licenseCommercial" : "licenseTrade",
        noTimer
    });
    return result;
};

export const useGetEIDTimeRemaining = (noTimer) => {
    const result = useGetValidityTimeRemaining({
        defaultRemainingWarningPeriod: Number(process.env.REACT_APP_FILE_EXPIRY_TIME_REMAINING_WARNING || 5),
        key: "eid",
        noTimer
    });
    return result;
};

export const useCheckEmployeeLimitReached = () => {
    const user = useAppSelector(selectUser);
    const companySubscription = user?.CompanySubscription?.Subscription;
    const employeeLimit = companySubscription?.employee_limit || 0;
    const limitReached = user.totalEmployees >= employeeLimit;

    return { limitReached, employeeLimit, companySubscription, user };
};

export const useUpdateFiles = ({ noDefaultValue } = {}) => {
    const [form, setForm] = useState({
        [FIELDS.ESTABLISHMENT_ID]: "",
        [FIELDS.LICENSE_COMMERCIAL_NUMBER]: "",
        [FIELDS.LICENSE_TRADE_NUMBER]: ""
    });
    const [uploads, setUploads] = useState({
        [COMPANY_FILES.LICENSE_TRADE.key]: "",
        [COMPANY_FILES.LICENSE_COMMERCIAL.key]: "",
        [COMPANY_FILES.ESTABLISHMENT_ID.key]: ""
    });

    const [update, { isLoading }] = useUpdateCompanyProfileMutation();

    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);
    const currentUploads = user.uploads;
    const hasUploads = !!Object.values(uploads).filter(Boolean).length;
    const hasAllUploads = Object.values(uploads).every(Boolean);

    useEffect(() => {
        if (!noDefaultValue) {
            let temp = {};
            Object.keys(INITIAL_UPLOADS).forEach((ks) => (temp[ks] = currentUploads[ks]));
            setUploads(temp);
        }
    }, []);

    const updateCompanyFiles = async (body) => {
        try {
            if (!hasUploads) {
                return;
            }
            const formData = new FormData();
            for (const field in uploads) {
                if (Object.hasOwnProperty.call(uploads, field)) {
                    const file = uploads[field];
                    file && formData.append(field, file);
                }
            }
            formData.append(
                "others",
                JSON.stringify({
                    ...form,
                    ...body
                })
            );
            const result = await update({
                formData: true,
                body: formData,
                extraPaths: [user.id, "with-file"]
            });
            if (result.error) {
                return {
                    error: result?.error?.data?.message || "Failed to update files, please try again later."
                };
            }
            const data = cloneDeep(result.data.data);
            dispatch(updateUser(data));
            createToast("Files updated successfully.", TOAST_TYPE.SUCCESS);
            return data;
        } catch (error) {
            return { error: error.message };
        }
    };

    const updateUploads = (name, file) => setUploads({ ...uploads, [name]: file });
    const updateForm = (newObj = {}) => setForm({ ...form, ...newObj });

    return [updateCompanyFiles, { isLoading, hasUploads, hasAllUploads, uploads, setUploads: updateUploads, form, setForm: updateForm }];
};

export const useCompanyStatusCheck = () => {
    const SHOW_RENEW_PERIOD = Number(process.env.REACT_APP_SUBSCRIPTION_TIME_REMAINING_WARNING || 5);

    const location = useLocation();
    const user = useAppSelector(selectUser);
    const subscriptionResult = useGetSubscriptionTimeRemaining(true);
    const contractResult = useGetContractTimeRemaining(true);
    const eidResult = useGetEIDTimeRemaining(true);
    const tradeLicenseResult = useGetLicenseTimeRemaining(true);
    const commercialLicenseResult = useGetLicenseTimeRemaining(true, { isCommercial: true });

    const isSettingsSetup = location.pathname == Navigation.Routes.SETTINGS_SETUP.path;

    const hasContractCopy = !!user.contractCopy;
    const isNewFileUpload = user.lastFileUploadDate;
    const isTrial = user.is_trial;
    const validity = user.validity;
    const recurrence = user?.CompanySubscription?.Subscription?.recurrence;
    const isPendingPayment = user?.status?.isPendingPayment;
    const recurrenceValue = (recurrence && RECURRENCE_WITH_VALUE[recurrence].value) || 1;

    const contract = validity.contract;
    const licenseTrade = validity.licenseTrade;
    const licenseCommercial = validity.licenseCommercial;
    const eid = validity.eid;
    const subscription = validity.subscription;

    const status = user.status.data;
    const isRequestingContract = status.isRequestingContract;
    const isRequestingRenewingContract = status.isRenewingContract;

    const isContractExpired = contract.isExpired;
    const isLicenseTradeExpired = licenseTrade.isExpired;
    const isLicenseCommercialExpired = licenseCommercial.isExpired;
    const isEIDExpired = eid.isExpired;
    const hasExpiredCompanyFiles = isLicenseTradeExpired || isLicenseCommercialExpired || isEIDExpired;
    const isSubscriptionExpired = subscription.isExpired;

    // within user recurence
    const isContractWithinRecurrence = !isContractExpired || contractResult.isWithinRecurrence;
    const isEIDWithinRecurrence = !isEIDExpired || eidResult.isWithinRecurrence;
    const isCommercialLicenseWithinRecurrence = !isLicenseCommercialExpired || commercialLicenseResult.isWithinRecurrence;
    const isTradeLicenseWithinRecurrence = !isLicenseTradeExpired || tradeLicenseResult.isWithinRecurrence;
    const someFilesNotWithinRecurrence =
        !isContractWithinRecurrence || !isEIDWithinRecurrence || !isCommercialLicenseWithinRecurrence || !isTradeLicenseWithinRecurrence;

    // 1 month
    const isContractLessThanOneMonth = contractResult.isLessThanOneMonth;
    const isEIDLessThanOneMonth = eidResult.isLessThanOneMonth;
    const isCommercialLicenseLessThanOneMonth = commercialLicenseResult.isLessThanOneMonth;
    const isTradeLicenseLessThanOneMonth = tradeLicenseResult.isLessThanOneMonth;
    const isCompanyFilesLessThanOneMonth = isEIDLessThanOneMonth || isCommercialLicenseLessThanOneMonth || isTradeLicenseLessThanOneMonth;
    const someFilesLessThanOneMonth = isContractLessThanOneMonth || isCompanyFilesLessThanOneMonth;

    // 6 months
    const isContractLessThanSixMonths = contractResult.isLessThanSixMonths;
    const isEIDLessThanSixMonths = eidResult.isLessThanSixMonths;
    const isCommercialLicenseLessThanSixMonths = commercialLicenseResult.isLessThanSixMonths;
    const isTradeLicenseLessThanSixMonths = tradeLicenseResult.isLessThanSixMonths;
    const isCompanyFilesLessThanSixMonths = isEIDLessThanSixMonths || isCommercialLicenseLessThanSixMonths || isTradeLicenseLessThanSixMonths;
    const someFilesLessThanSixMonths = isContractLessThanSixMonths || isCompanyFilesLessThanSixMonths;

    // 12 months
    const isContractLessThanTwelveMonths = contractResult.isLessThanTwelveMonths;
    const isEIDLessThanTwelveMonths = eidResult.isLessThanTwelveMonths;
    const isCommercialLicenseLessThanTwelveMonths = commercialLicenseResult.isLessThanTwelveMonths;
    const isTradeLicenseLessThanTwelveMonths = tradeLicenseResult.isLessThanTwelveMonths;
    const isCompanyFilesLessThanTwelveMonths =
        isEIDLessThanTwelveMonths || isCommercialLicenseLessThanTwelveMonths || isTradeLicenseLessThanTwelveMonths;
    const someFilesLessThanTwelveMonths = isContractLessThanTwelveMonths || isCompanyFilesLessThanTwelveMonths;

    const invalidContractAndFiles = isContractExpired && hasExpiredCompanyFiles;
    const validFilesInvalidContract = !isContractExpired && hasExpiredCompanyFiles;
    const contractExpiredAndValidFiles = isContractExpired && !hasExpiredCompanyFiles;
    const hasSubmission = isNewFileUpload || isRequestingRenewingContract;

    const isRenewUpgradeDisabled = !hasContractCopy || isContractExpired || hasExpiredCompanyFiles || someFilesNotWithinRecurrence;

    const checkIfRenewalIsDisabled = () => {
        const remainingDayIsWithinRenewPeriod = subscriptionResult.day <= SHOW_RENEW_PERIOD;
        if (subscriptionResult.day <= 0) {
            return false;
        }
        if (!remainingDayIsWithinRenewPeriod) {
            return true;
        }
        switch (recurrence) {
            case RECURRENCE.MONTHLY:
                return someFilesLessThanOneMonth;
            case RECURRENCE.SEMI_ANNUAL:
                return someFilesLessThanSixMonths;
            case RECURRENCE.ANNUALLY:
                return someFilesLessThanTwelveMonths;
            default:
                return true;
        }
    };

    const getAvailableRecurrence = () => {
        let temp = [];

        if (someFilesLessThanOneMonth && recurrence != RECURRENCE.MONTHLY && recurrence != RECURRENCE.TRIAL) {
            temp.push(RECURRENCE.MONTHLY);
        }
        if (someFilesLessThanSixMonths && recurrence != RECURRENCE.SEMI_ANNUAL) {
            temp.push(RECURRENCE.SEMI_ANNUAL);
        }
        if (someFilesLessThanTwelveMonths && recurrence != RECURRENCE.ANNUALLY) {
            temp.push(RECURRENCE.ANNUALLY);
        }
        return temp;
    };

    return {
        isContractExpired,
        isLicenseTradeExpired,
        isLicenseCommercialExpired,
        isEIDExpired,
        hasExpiredCompanyFiles,
        isSubscriptionExpired,
        isRequestingContract,
        isRequestingRenewingContract,
        isTrial,
        isNewFileUpload,
        hasContractCopy,

        isContractLessThanOneMonth,
        isEIDLessThanOneMonth,
        isCommercialLicenseLessThanOneMonth,
        isTradeLicenseLessThanOneMonth,
        isCompanyFilesLessThanOneMonth,
        someFilesLessThanOneMonth,

        isContractLessThanSixMonths,
        isEIDLessThanSixMonths,
        isCommercialLicenseLessThanSixMonths,
        isTradeLicenseLessThanSixMonths,
        isCompanyFilesLessThanSixMonths,
        someFilesLessThanSixMonths,

        isContractLessThanTwelveMonths,
        isEIDLessThanTwelveMonths,
        isCommercialLicenseLessThanTwelveMonths,
        isTradeLicenseLessThanTwelveMonths,
        isCompanyFilesLessThanTwelveMonths,
        someFilesLessThanTwelveMonths,

        isContractWithinRecurrence,
        isEIDWithinRecurrence,
        isCommercialLicenseWithinRecurrence,
        isTradeLicenseWithinRecurrence,
        someFilesNotWithinRecurrence,

        invalidContractAndFiles,
        validFilesInvalidContract,
        contractExpiredAndValidFiles,
        hasSubmission,
        isRenewUpgradeDisabled,
        isRenewDontAllow: checkIfRenewalIsDisabled(),
        availableRecurrenceSelections: getAvailableRecurrence(),

        isSettingsSetup,
        isPendingPayment,
        recurrenceValue,
        recurrence
    };
};
