import React, { useState } from "react";
import PropTypes from "prop-types";
import { BASE_CLASS_UPDATE_MODAL } from "./const";
import BaseUpdateModal from "../../../common/components/layout/modalViewUpdateLayout/BaseUpdateModal";
import SectionCollapseError from "../../../common/components/extra/section/SectionCollapseError";
import { useAssignEmployeePosition } from "./hooks";
import { createClass, formattedJoiErrorMessage } from "../../../common/utilities/helper";
import AssignDeptPositionInputs from "./AssignDeptPositionInputs";

function AssignDeptPositionModal({ open, onClose, onBack, onFinish, id, transparentOverlay }) {
    const [error, setError] = useState(null);

    const [updateDept, isLoading, { onFormChange, form, current, isGettingEmployee, refetch }] = useAssignEmployeePosition(id);

    const handleSave = async () => {
        try {
            const result = await updateDept();
            if (!result?.error && typeof onFinish === "function") {
                await refetch();
                onFinish(result);
            }
            setError(null);
            onBack?.();
        } catch (error) {
            setError({ message: error?.message || error });
            return { error };
        }
    };

    return (
        <BaseUpdateModal
            title="Assign as Manager/Supervisor"
            open={open}
            onClose={onClose}
            onSave={handleSave}
            isLoading={isLoading}
            disableSave={isLoading || isGettingEmployee}
            styles={{
                content: {
                    margin: "1rem",
                    maxHeight: "90vh",
                    minWidth: "25rem",
                    maxWidth: "40rem"
                },
                body: {
                    overflow: "auto"
                }
            }}
            transparentOverlay={transparentOverlay}
            onBack={onBack}
            isForm
            small
        >
            <div className={createClass(BASE_CLASS_UPDATE_MODAL)} style={{ margin: "0 1rem" }}>
                <div className={createClass("__inner", BASE_CLASS_UPDATE_MODAL)}>
                    <SectionCollapseError show={!!error}>{formattedJoiErrorMessage({ error, isCreate: false })}</SectionCollapseError>
                    <div className={createClass("__inner-row flex column", BASE_CLASS_UPDATE_MODAL)} style={{ marginTop: "1rem" }}>
                        <AssignDeptPositionInputs
                            base={BASE_CLASS_UPDATE_MODAL}
                            form={form}
                            current={current}
                            isFetching={isGettingEmployee}
                            onChange={onFormChange}
                        />
                    </div>
                </div>
            </div>
        </BaseUpdateModal>
    );
}

AssignDeptPositionModal.propTypes = {
    id: PropTypes.any,
    open: PropTypes.bool,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func,
    onFinish: PropTypes.func,
    onBack: PropTypes.func,
    onClose: PropTypes.func,
    transparentOverlay: PropTypes.bool
};

export default AssignDeptPositionModal;
