import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import useNavigate from "../../../common/hooks/useNavigate";
import { setUser } from "../../common/slice";
import { useCompanyLoginMutation } from "./companyAPI";
import { useAppDispatch } from "../../../common/hooks/reduxHooks";
import FormAuthTemplate from "../../../common/components/layout/FormAuthTemplate";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import Navigation from "../../../common/classes/Navigation";
import useLocalStorage from "../../../common/hooks/useLocalStorage";

const smallIconStyle = {
    fontSize: "1.2rem",
    color: "#768192",
    padding: ".3rem .5rem"
};

const BASE_CLASS = "tk-signin";
const createClass = (newStr) => `${BASE_CLASS}${newStr}`;

function Company() {
    const [form, setForm] = useState({ email: "", password: "" });
    const [login, { isLoading }] = useCompanyLoginMutation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [credential, setLocalStorage] = useLocalStorage("tk-temp", {
        email: "",
        password: "",
        isRemember: false
    });

    useEffect(() => {
        try {
            if (credential) {
                setForm({ ...form, ...credential });
            }
        } catch (error) {
            setForm({ email: "", password: "" });
        }
    }, []);

    const handleSubmit = async () => {
        try {
            const { email, password } = form;
            if (!email || !password) throw new Error("Email and Password cannot be empty!");
            if (credential.isRemember) {
                setLocalStorage({ email, password, isRemember: true });
            } else {
                setLocalStorage({ email: "", password: "", isRemember: false });
            }
            const response = await login({ body: { email, password } });
            if (!response.error) {
                const { user, role, accessToken } = response.data.data;
                dispatch(setUser({ user, role, accessToken }));
                navigate(Navigation.Routes.DASHBOARD.path);
            } else throw new Error((response.error && response.error.data && response.error.data.message) || "Login Failed. Please try again later.");
        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleChange = (e) => setForm({ ...form, [e.target.name]: e.target.value });

    const renderFooter = (
        <div className={createClass("__footer")} style={{ pointerEvents: isLoading ? "none" : "auto" }}>
            <div className={createClass("__footer__top")}>
                <div className={createClass("__remember-me")}>
                    <Input
                        type={INPUT_TYPE.TOGGLE}
                        onChange={(e) => setLocalStorage({ ...form, isRemember: e.target.checked })}
                        value={!!credential?.isRemember}
                        disabled={isLoading}
                    />
                    <span style={{ whiteSpace: "nowrap" }}>Remember Me</span>
                </div>
                <div className={createClass("__forgot-password")}>
                    <Link to={Navigation.Routes.RESET_PASSWORD.path}>Forgot Password?</Link>
                </div>
            </div>
            <div className={createClass("__footer__signup")}>
                <Link to={Navigation.Routes.SIGNUP.path}>
                    {`Don't have an account?`} <strong>Sign Up</strong>
                </Link>
            </div>
        </div>
    );

    return (
        <FormAuthTemplate
            title="Welcome! Let's Get You Logged In."
            subtext="Enter your account details"
            submitLabel={`${isLoading ? "Logging in..." : "Log In"}`}
            footer={<div>{renderFooter}</div>}
            onSubmit={handleSubmit}
        >
            <Input
                type={INPUT_TYPE.EMAIL}
                icon={<PersonIcon sx={smallIconStyle} />}
                name="email"
                placeholder="Email"
                onChange={handleChange}
                value={form.email || ""}
                maxLength={80}
                autoComplete="username"
                disabled={isLoading}
                autoFocus
                required
            />
            <Input
                type={INPUT_TYPE.PASSWORD}
                icon={<LockIcon sx={smallIconStyle} />}
                name="password"
                placeholder="Password"
                onChange={handleChange}
                value={form.password || ""}
                autoComplete="username"
                disabled={isLoading}
                required
            />
        </FormAuthTemplate>
    );
}

export default Company;
