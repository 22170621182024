import { useCallback, useEffect, useState } from "react";

function useDetectOutsideClick(ref, onOutsideClick, onPreventTrigger) {
    const [isMounted, setMounted] = useState(false);
    const [isClickedOutside, setClickOutside] = useState(false);

    const handleClickOutside = useCallback(
        (event) => {
            if (ref.current && !ref.current.contains(event.target) && !isClickedOutside) {
                const isPreventTrigger = onPreventTrigger?.(event);
                if (isPreventTrigger) return;
                setClickOutside(true);
                if (typeof onOutsideClick === "function") {
                    onOutsideClick(event.target);
                }
            } else {
                setClickOutside(false);
            }
        },
        [ref, isClickedOutside, onOutsideClick, onPreventTrigger]
    );

    useEffect(() => {
        if (!isMounted) return () => {};
        const handleDocumentClick = (event) => handleClickOutside(event);
        document.addEventListener("click", handleDocumentClick);
        document.addEventListener("touchstart", handleDocumentClick);
        return () => {
            document.removeEventListener("click", handleDocumentClick);
            document.addEventListener("touchstart", handleDocumentClick);
        };
    }, [ref, isMounted]);

    useEffect(() => {
        setMounted(true);
    }, []);

    return [isClickedOutside];
}

export default useDetectOutsideClick;
