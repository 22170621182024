import React, { useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { InfoWindow } from "@vis.gl/react-google-maps";
import { latLngType } from "./types";
import useDetectOutsideClick from "../../../hooks/useDetectOutsideClick";

const MapInfoWindow = ({ title, content, ...props }) => {
    const ref = useRef(null);

    useDetectOutsideClick(ref, props.onCloseClick);

    const newProps = useMemo(() => {
        const temp = { ...props };
        if (temp.anchor) {
            delete temp.position;
        }
        if (temp.position) {
            delete temp.anchor;
        }
        return temp;
    }, [props.anchor, props.position]);

    return (
        <InfoWindow headerContent={title} {...newProps}>
            {content && (
                <div ref={ref} className="info-window-content flex center w100">
                    {typeof content === "string" ? <span>{content}</span> : content}
                </div>
            )}
        </InfoWindow>
    );
};

export default MapInfoWindow;

MapInfoWindow.propTypes = {
    show: PropTypes.bool,
    title: PropTypes.any,
    content: PropTypes.any,
    anchor: PropTypes.any,
    position: latLngType,
    onCloseClick: PropTypes.func
};
