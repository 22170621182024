import { COMPANY_FILES } from "../../../common/utilities/const";

export const FIELDS = {
    NAME: "name",
    EMAIL: "email",
    ADDRESS: "address",
    INDUSTRY_TYPE: "industry_type",
    COUNTRY: "country",
    LICENSE_TRADE_NUMBER: "license_trade_number",
    LICENSE_COMMERCIAL_NUMBER: "license_commercial_number",
    ESTABLISHMENT_ID: "establishment_id",
    CONTACT_NAME: "contact_name",
    CONTACT_EMAIL: "contact_email",
    CONTACT_POSITION: "contact_position",
    CONTACT_NUMBER: "contact_number"
};

export const INITIAL_FIELDS = Object.values(FIELDS).reduce((prev, curr) => ({ ...prev, [curr]: "" }), {});

export const INITIAL_UPLOADS = Object.values(COMPANY_FILES).reduce((prev, curr) => ({ ...prev, [curr.key]: null }), {});
