import React, { useState } from "react";
import PropTypes from "prop-types";
import SiteIcon from "@mui/icons-material/WhereToVote";
import Button from "../../../common/components/extra/Button";
import MyTooltip from "../../../common/components/extra/Tooltip";
import ViewCheckinLogsModal from "./ViewCheckinLogsModal";

const LINK_STYLE = {
    textDecoration: "underline",
    fontStyle: "italic",
    color: "red",
    cursor: "pointer"
};

function ViewCheckinLogsButton({
    onClick,
    noInternalModal,
    forceInline,
    id,
    transparentOverlay,
    hasBack,
    isIcon,
    style = {},
    readOnly,
    disabled,
    withLabel
}) {
    const [isOpen, setOpen] = useState(false);

    const handleOnClick = () => {
        setOpen(true);
        onClick?.();
    };

    const label = "Vew Checkin Logs";

    const renderButton = forceInline ? (
        <span style={{ ...LINK_STYLE, style }} className="semi-bold" onClick={handleOnClick}>
            Click to {label}
        </span>
    ) : (
        <Button
            options={{ style: { minWidth: "max-content", ...style } }}
            onClick={() => !disabled && handleOnClick()}
            className="secondary"
            afterExtra={<SiteIcon style={{ width: "1.2rem", pointerEvents: "none" }} />}
            disabled={disabled}
            small
            mini
        >
            <span style={{ whiteSpace: "nowrap" }}>{label}</span>
        </Button>
    );

    if (isIcon) {
        return (
            <MyTooltip
                className={disabled ? "disabled" : "pointer hover-svg"}
                message={label}
                onClick={() => !disabled && handleOnClick()}
                style={{ ...style, display: "flex", gap: ".5rem", alignItems: "center" }}
            >
                <SiteIcon style={{ width: "1.6rem", height: "auto", pointerEvents: "none" }} />
                {(withLabel && "View Department") || ""}
            </MyTooltip>
        );
    }

    return (
        <>
            <div className="small-font flex gap-05 wrap">{renderButton}</div>
            {isOpen && !noInternalModal && (
                <ViewCheckinLogsModal
                    open={isOpen}
                    onClose={() => setOpen(false)}
                    onFinish={() => setOpen(false)}
                    id={id}
                    transparentOverlay={transparentOverlay}
                    onBack={hasBack && (() => setOpen(false))}
                    readOnly={readOnly}
                />
            )}
        </>
    );
}

export default ViewCheckinLogsButton;

ViewCheckinLogsButton.propTypes = {
    id: PropTypes.number,
    transparentOverlay: PropTypes.bool,
    isIcon: PropTypes.bool,
    hasBack: PropTypes.bool,
    onClick: PropTypes.func,
    noInternalModal: PropTypes.bool,
    disabled: PropTypes.bool,
    forceInline: PropTypes.bool,
    readOnly: PropTypes.bool,
    withLabel: PropTypes.bool,
    style: PropTypes.object
};
