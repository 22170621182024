import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import ArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

function ListItem({ defaultOpen, alwaysOpen, title, children }) {
    const [collapse, setCollapse] = useState(defaultOpen || alwaysOpen ? true : false);

    const toggleCollapse = () => ((alwaysOpen && !collapse) || !alwaysOpen) && setCollapse(alwaysOpen ? true : !collapse);

    const contentClass = useMemo(() => {
        let cn = "accordion__list__item__content ";
        if (collapse) cn += "accordion__list__item__content--open";
        else cn += "accordion__list__item__content--close";
        return cn.trim();
    }, [collapse]);

    return (
        <div className="accordion__list__item">
            <div className="accordion__list__item__title">
                <div className="accordion__list__item__title-value">{title}</div>
                <div className="accordion__list__item__controls">
                    {!alwaysOpen &&
                        (collapse ? (
                            <ArrowDownIcon onClick={toggleCollapse} className="hover-svg" />
                        ) : (
                            <ArrowUpIcon onClick={toggleCollapse} className="hover-svg" />
                        ))}
                </div>
            </div>
            {collapse && <div className={contentClass}>{children}</div>}
        </div>
    );
}

export default ListItem;

ListItem.propTypes = {
    defaultOpen: PropTypes.bool,
    alwaysOpen: PropTypes.bool,
    title: PropTypes.any,
    children: PropTypes.any
};
