import React from "react";
import PropTypes from "prop-types";
import Departments from "../departments/Departments";
import Designations from "../designations/Designations";

function PositionTab({ loading, setParentLoading, styles = {} }) {
    return (
        <div className="work-tab flex column gap-1" style={styles.parent || {}}>
            <Departments loading={loading} setParentLoading={setParentLoading} />
            <Designations loading={loading} setParentLoading={setParentLoading} />
        </div>
    );
}

export default PositionTab;

PositionTab.propTypes = {
    loading: PropTypes.bool,
    setParentLoading: PropTypes.func,
    styles: PropTypes.shape({
        parent: PropTypes.object
    })
};
