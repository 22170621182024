import React from "react";
import { ZOOM_INC, ZOOM_MAX, ZOOM_MIN } from "./const";

export const createZoomLevels = () => {
    const levels = [];
    const min = ZOOM_MIN;
    const max = ZOOM_MAX;
    const INC = ZOOM_INC;

    for (let level = min; level <= max; level += INC) {
        levels.push({
            value: level,
            label: <span key={level}>{level}%</span>
        });
    }

    return levels;
};
