import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import Navigation from "../../../common/classes/Navigation";
import Banner, { BANNER_TYPE } from "../../../common/components/extra/Banner";
import Carousel from "../../../common/components/extra/carousel/Carousel";
import SubscriptionExpiring from "./SubscriptionExpiring";
import ContractExpiring from "./ContractExpiring";
import ContractRequested from "./ContractRequested";
import EIDExpiring from "./EIDExpiring";
import LicenseTradeExpiring from "./LicenseTradeExpiring";
import LicenseCommercialExpiring from "./LicenseCommercialExpiring";
import EmployeeLimitReached from "./EmployeeLimitReached";

import {
    useCheckEmployeeLimitReached,
    useGetContractTimeRemaining,
    useGetEIDTimeRemaining,
    useGetLicenseTimeRemaining,
    useGetSubscriptionTimeRemaining
} from "./hooks";
import EmployeeFilesExpiring from "./EmployeeFilesExpiring";

function NotifBanner({ additional, alwaysShowSubscriptionRemaning, showButtonForContractExpiring, ...rest }) {
    const location = useLocation();
    const user = useAppSelector(selectUser);

    const resultContract = useGetContractTimeRemaining(true);
    const resultSubscription = useGetSubscriptionTimeRemaining(true);
    const resultEID = useGetEIDTimeRemaining(true);
    const resultTradeLicense = useGetLicenseTimeRemaining(true);
    const resultCommercialLicense = useGetLicenseTimeRemaining(true, { isCommercial: true });
    const { limitReached } = useCheckEmployeeLimitReached();

    const idleTime = Number(process.env.REACT_APP_BANNER_IDLE_INTERVAL || 10);
    const playInterval = Number(process.env.REACT_APP_BANNER_PLAY_INTERVAL || 5);

    const status = user.status;
    const isRequestingContract = status?.isRequestingContract;
    const isSettingsSetup = location.pathname == Navigation.Routes.SETTINGS_SETUP.path;
    const hasExpiringEmployees = user.employeeFilesValidity?.hasValue;

    let items = [
        (resultSubscription.showWarning || alwaysShowSubscriptionRemaning) && (
            <Banner key={1} type={resultSubscription.isExpired ? BANNER_TYPE.ERROR : BANNER_TYPE.WARNING} show>
                <SubscriptionExpiring alwaysShowSubscriptionRemaning={alwaysShowSubscriptionRemaning} />
            </Banner>
        ),
        resultContract.showWarning && (
            <Banner key={2} type={resultContract.isExpired ? BANNER_TYPE.ERROR : BANNER_TYPE.WARNING} show>
                <ContractExpiring onlyMessage={!showButtonForContractExpiring} />
            </Banner>
        ),
        isRequestingContract && (
            <Banner key={3} type={BANNER_TYPE.WARNING} show>
                <ContractRequested onlyMessage={isSettingsSetup} />
            </Banner>
        ),
        resultEID.showWarning && (
            <Banner key={4} type={resultEID.isExpired ? BANNER_TYPE.ERROR : BANNER_TYPE.WARNING} show>
                <EIDExpiring />
            </Banner>
        ),
        resultTradeLicense.showWarning && (
            <Banner key={5} type={resultTradeLicense.isExpired ? BANNER_TYPE.ERROR : BANNER_TYPE.WARNING} show>
                <LicenseTradeExpiring />
            </Banner>
        ),
        resultCommercialLicense.showWarning && (
            <Banner key={5} type={resultCommercialLicense.isExpired ? BANNER_TYPE.ERROR : BANNER_TYPE.WARNING} show>
                <LicenseCommercialExpiring />
            </Banner>
        ),
        limitReached && (
            <Banner key={6} type={BANNER_TYPE.ERROR} show>
                <EmployeeLimitReached />
            </Banner>
        ),
        hasExpiringEmployees && (
            <Banner key={7} type={BANNER_TYPE.INFO} show>
                <EmployeeFilesExpiring />
            </Banner>
        )
    ];

    if (additional && additional.length) {
        items = items.concat(additional);
    }

    return (
        <Carousel
            className="tk-notif-banner"
            items={items}
            item={{ height: "3rem" }}
            interval={playInterval}
            idleTime={idleTime}
            {...rest}
            autoplay
            disableAutoPlayOnHover
        />
    );
}

NotifBanner.propTypes = {
    user: PropTypes.object,
    isSuperAdmin: PropTypes.bool,
    hasLogo: PropTypes.bool,
    showButtonForContractExpiring: PropTypes.bool,
    alwaysShowSubscriptionRemaning: PropTypes.bool,
    additional: PropTypes.array
};

export default NotifBanner;
