import React from "react";
import PropTypes from "prop-types";
import useNavigate from "../../../common/hooks/useNavigate";
import { useLogoutMutation } from "../../common/api";
import FormAuthTemplate from "../../../common/components/layout/FormAuthTemplate";
import Navigation from "../../../common/classes/Navigation";
import Button from "../../../common/components/extra/Button";
import { RECURRENCE } from "../../../common/utilities/const";
import ButtonUpgrade from "../../../common/components/extra/ButtonUpgrade";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import { useCompanyStatusCheck } from "./hooks";
import ButtonRenew from "../../../common/components/extra/ButtonRenew";
import ContactUs from "./ContactUs";

const ExpiredSubscriptionMessageSwitcher = () => {
    const check = useCompanyStatusCheck();

    let message = (
        <>
            <span>{`You ${check.isTrial ? "Trial" : "Subscription"} is expired. Renew now to continue using our service.`}</span>
        </>
    );

    if (!check.hasContractCopy) {
        message = (
            <>
                <span>{`You ${check.isTrial ? "Trial" : "Subscription"} is expired. Request for a Full Account to continue using our service.`}</span>
            </>
        );
    }

    const createExpiredList = () => {
        const list = [];

        if (check.isContractExpired || !check.hasContractCopy || !check.isContractWithinRecurrence) {
            list.push(check.hasContractCopy ? "Contract" : "Missing Contract");
        }
        if (check.isLicenseCommercialExpired || !check.isCommercialLicenseWithinRecurrence) {
            list.push("Commercial License");
        }
        if (check.isLicenseTradeExpired || !check.isTradeLicenseWithinRecurrence) {
            list.push("Trade License");
        }
        if (check.isEIDExpired || !check.isEIDWithinRecurrence) {
            list.push("EID");
        }
        return list.map((txt) => (
            <li className="flex danger-color" key={txt}>
                - {txt}
            </li>
        ));
    };

    if (check.invalidContractAndFiles || check.validFilesInvalidContract || check.someFilesNotWithinRecurrence) {
        message = (
            <div className="flex column small-font semi-bold" style={{ justifyContent: "flex-start", textAlign: "left" }}>
                <span> The following needs to be valid before applying a new subscription:</span>
                <ul style={{ marginTop: ".5rem" }}>{createExpiredList()}</ul>
            </div>
        );
    } else if (check.contractExpiredAndValidFiles) {
        message = (
            <>
                <span>Your Contract and Subscription is expired. Please renew your contract in order to apply for new subscription.</span>
            </>
        );
    }

    if (check.hasSubmission) {
        message = (
            <>
                <span>
                    Request has been successfully submitted. Applying for new subscription will be disabled until one of our staff member confirm the
                    new uploaded files.
                </span>
            </>
        );
    }
    return message;
};

function ExpiredSubscriptionAccount() {
    const [logout] = useLogoutMutation();

    const navigate = useNavigate();
    const user = useAppSelector(selectUser);

    const companySubscription = user?.CompanySubscription;
    const isTrial = companySubscription && companySubscription.Subscription.recurrence === RECURRENCE.TRIAL;

    return (
        <div className="tk-sub-ended">
            <FormAuthTemplate
                title={isTrial ? "Free Trial Ended" : "Subscription Ended"}
                footer={
                    <div className="tk-sub-ended__footer">
                        <ContactUs />
                    </div>
                }
                buttonExtra={
                    <div className="flex center gap-05 w100">
                        <Button onClick={() => logout().then(() => navigate(Navigation.Routes.SIGNIN.path))}>Go to login</Button>
                        <ButtonUpgrade />
                        <ButtonRenew primary />
                    </div>
                }
                noSubmit
            >
                <div className="flex center" style={{ maxWidth: "15rem", margin: "auto" }}>
                    <ExpiredSubscriptionMessageSwitcher />
                </div>
            </FormAuthTemplate>
        </div>
    );
}

ExpiredSubscriptionAccount.propTypes = {
    user: PropTypes.object
};

export default ExpiredSubscriptionAccount;
