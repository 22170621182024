import React, { Component } from "react";
import PropTypes from "prop-types";
import { COMPLETED_DRAWING_EVENTS, DRAWING_MODE_EVENT } from "./const";

export default class DrawingManager extends Component {
    #instance = null;
    #map = null;
    #maps = null;
    #listeners = [];

    constructor(props) {
        super(props);
        this.#instance = props.instance;
        this.#map = props.map;
        this.#maps = props.maps;
    }

    get Options() {
        return {
            instance: this.#instance,
            map: this.#map,
            maps: this.#maps,
            onComplete: this.props.onComplete,
            onDrawingChange: this.props.onDrawingChange
        };
    }

    componentDidMount() {
        this.init();
    }

    componentWillUnmount() {
        this.#listeners.length && this.#listeners.forEach((listener) => listener.remove());
    }

    init() {
        const { instance, map, maps, onComplete, onDrawingChange } = this.Options;

        if (!instance || !map || !maps) {
            return;
        }

        Object.values(COMPLETED_DRAWING_EVENTS).forEach((event) =>
            this.#listeners.push(instance.addListener(event, (conf) => onComplete?.(conf, event, instance)))
        );

        this.#listeners.push(
            instance.addListener(DRAWING_MODE_EVENT, () =>
                onDrawingChange?.({
                    drawingMode: instance.getDrawingMode(),
                    instance
                })
            )
        );
    }

    render() {
        return <></>;
    }
}

DrawingManager.propTypes = {
    instance: PropTypes.object,
    map: PropTypes.object,
    maps: PropTypes.object,
    onComplete: PropTypes.func,
    onDrawingChange: PropTypes.func
};
