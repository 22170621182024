import React from "react";
import { useNavigate, Navigate } from "react-router-dom";
import EmailVerification from "../../features/company/auth/EmailVerification";
import Button from "../../common/components/extra/Button";
import Navigation from "../../common/classes/Navigation";
import { useAppSelector } from "../../common/hooks/reduxHooks";
import { selectSignup } from "../../features/common/slice";

function SignUpVerify() {
    const navigate = useNavigate();
    const signupdata = useAppSelector(selectSignup);
    if (!signupdata?.data?.token || !signupdata?.data?.email) {
        return <Navigate to={Navigation.Routes.SIGNUP.path} />;
    }
    return (
        <div className="tk-signup-flow">
            <EmailVerification
                buttonExtra={<Button onClick={() => navigate(Navigation.Routes.SIGNIN.path)}>Go to login</Button>}
                {...(signupdata?.data || {})}
            />
        </div>
    );
}

export default SignUpVerify;
