import React from "react";
import CompanyPenalties from "../companyPenalties/CompanyPenalties";
import { ROUTE_TYPE } from "../companyPenalties/const";
import SectionCollapseInfo from "../../../common/components/extra/section/SectionCollapseInfo";

function PenaltySettings() {
    return (
        <div className="w100 flex column gap-1" style={{ maxWidth: "none" }}>
            <CompanyPenalties type={ROUTE_TYPE.LAW} />
            <SectionCollapseInfo title="Important Notice" show>
                You have the option to customize your penalties within the settings tab should the desired penalty not be available in the current
                selection
            </SectionCollapseInfo>
        </div>
    );
}

export default PenaltySettings;
