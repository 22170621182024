import React from "react";
import { useCheckEmployeeLimitReached } from "./hooks";

function EmployeeLimitReached() {
    const { limitReached, employeeLimit } = useCheckEmployeeLimitReached();

    if (!limitReached) {
        return <></>;
    }

    return (
        <div className="flex w100 small-font semi-bold center">
            You have reached your employee limit of&nbsp;<strong style={{ color: "inherit" }}> {employeeLimit}</strong>. Upgrade now to add more.
        </div>
    );
}

export default EmployeeLimitReached;
