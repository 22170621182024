import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { useEffect } from "react";
import { useAppSelector } from "../../hooks/reduxHooks";
import { selectUser } from "../../../features/common/slice";

function RealTimeClock({ isTwelveHour }) {
    const user = useAppSelector(selectUser);
    const timezone = user?.Setting?.timezone;
    const format = isTwelveHour ? "hh:mm:ss A" : "HH:mm:ss A";
    const currentTime = useRef(moment.tz(timezone).format(format));
    const [, forceUpdate] = useState(0); // Dummy state for re-render

    useEffect(() => {
        if (timezone) {
            const ref = setInterval(() => {
                currentTime.current = moment.tz(timezone).format(format);
                forceUpdate((prev) => prev + 1); // Trigger re-render
            }, 1000);
            return () => clearInterval(ref);
        }
    }, [timezone, format]);

    return <div className="tk-time-clock bold fade">{currentTime.current}</div>;
}

RealTimeClock.propTypes = {
    isTwelveHour: PropTypes.bool
};

export default RealTimeClock;
