import React, { useState } from "react";
import Penalty from "../../features/admin/penalties/Penalty";
import PageLayout from "../../common/components/layout/PageLayout";

function PenaltiesPage() {
    const [openAddModal, setOpenAddModal] = useState(false);

    const handleAdd = () => setOpenAddModal(true);

    return (
        <PageLayout
            className="tk-penalties"
            title="Manage Penalties"
            onAdd={handleAdd}
            styles={{
                body: {
                    height: "calc(100vh - 20.5rem)"
                }
            }}
        >
            <Penalty openAddModal={openAddModal} onAddModalClose={() => setOpenAddModal(false)} />
        </PageLayout>
    );
}

export default PenaltiesPage;
