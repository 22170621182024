import React from "react";
import PropTypes from "prop-types";
import LetteredAvatar from "../../../common/components/extra/avatar/LetteredAvatar";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import { COMPANY_FILES } from "../../../common/utilities/const";

const CompanyAvatar = ({ editMode, onChange, size }) => {
    const user = useAppSelector(selectUser);

    return (
        <LetteredAvatar
            name={user.name}
            fetchConfig={{
                paths: [user.id],
                filename: user?.uploads?.[COMPANY_FILES.LOGO.key]
            }}
            src={user.logoSrc}
            size={size}
            onChange={onChange}
            style={{ boxShadow: "0 2px 3px #c7c7c7" }}
            editmode={editMode}
            isCompany
            hasborder
            forceLogo
        />
    );
};

export default CompanyAvatar;

CompanyAvatar.propTypes = {
    editMode: PropTypes.bool,
    onChange: PropTypes.func,
    size: PropTypes.number
};
