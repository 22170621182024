import React from "react";
import PropTypes from "prop-types";
import SelectConstant from "../../../common/components/extra/select/SelectConstant";
import { USER_LEVEL } from "./const";

const createBaseOpt = (exclude) => {
    return Object.keys(USER_LEVEL)
        .filter((key) => !exclude.includes(USER_LEVEL[key]))
        .reduce(
            (prev, key) => ({
                ...prev,
                [key]: USER_LEVEL[key]
            }),
            {}
        );
};

function UserLevelSelect({ fixed, exclude, ...props }) {
    return (
        <SelectConstant
            base={createBaseOpt(exclude || [])}
            transformBase={(value) => {
                let temp = {
                    value,
                    label: "",
                    isFixed: (fixed || []).some((val) => val == value)
                };
                switch (value) {
                    case USER_LEVEL.ZERO: {
                        temp.label = "Employee (0)";
                        break;
                    }
                    case USER_LEVEL.ONE: {
                        temp.label = "Supervisor (1)";
                        break;
                    }
                    case USER_LEVEL.TWO: {
                        temp.label = "Manager (2)";
                        break;
                    }
                    case USER_LEVEL.THREE: {
                        temp.label = "HR/Finance/Accounts/IT (3)";
                        break;
                    }
                    case USER_LEVEL.FOUR: {
                        temp.label = "Top-level (4)";
                        break;
                    }
                    default: {
                        break;
                    }
                }
                temp.label = (
                    <div className="flex gap-05 semi-bold">
                        <span>{temp.label}</span>
                    </div>
                );
                return temp;
            }}
            {...props}
        />
    );
}

export default UserLevelSelect;

UserLevelSelect.propTypes = {
    fixed: PropTypes.arrayOf(PropTypes.oneOf(Object.values(USER_LEVEL))),
    exclude: PropTypes.arrayOf(PropTypes.oneOf(Object.values(USER_LEVEL)))
};
