import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Tooltip } from "react-tooltip";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

const domNode = document.createElement("div");
document.body.appendChild(domNode);

function BodyPortal({ children }) {
    return ReactDOM.createPortal(children, domNode);
}

export const PLACES = {
    TOP: "top",
    TOP_START: "top-start",
    TOP_END: "top-end",
    RIGHT: "right",
    RIGHT_START: "right-start",
    RIGHT_END: "right-end",
    BOTTOM: "bottom",
    BOTTOM_START: "bottom-start",
    BOTTOM_END: "bottom-end",
    LEFT: "left",
    LEFT_START: "left-start",
    LEFT_END: "left-end"
};

function MyTooltip({ message, children, className, style, element, onClick, position = PLACES.TOP }) {
    const [object] = useState({ id: uuidv4().split("-").shift() });
    const WrapperElement = element || "div";
    const customid = "my" + object.id;
    return (
        <>
            <WrapperElement data-tooltip-id={customid} className={("tk-tooltip " + (className || "")).trim()} style={style || {}} onClick={onClick}>
                {children}
            </WrapperElement>
            <BodyPortal>
                <Tooltip id={customid} place={position} style={{ zIndex: 999999 }} opacity={1}>
                    {message}
                </Tooltip>
            </BodyPortal>
        </>
    );
}

MyTooltip.propTypes = {
    children: PropTypes.any,
    message: PropTypes.any,
    position: PropTypes.oneOf(Object.values(PLACES)),
    className: PropTypes.string,
    style: PropTypes.object,
    element: PropTypes.string,
    onClick: PropTypes.func
};

export default MyTooltip;
