import React, { useState } from "react";
import PropTypes from "prop-types";
import Button, { BTN_TYPE } from "../../../common/components/extra/Button";
import AssignDeptPositionModal from "./AssignDeptPositionModal";

function AssignDeptPositionButton({ onClick, noInternalModal, id, transparentOverlay, hasBack, readOnly, disabled, ...rest }) {
    const [isOpen, setOpen] = useState(false);

    const handleOnClick = () => {
        setOpen(true);
        onClick?.();
    };

    return (
        <>
            <Button type={BTN_TYPE.ADD} disabled={disabled} onClick={() => !disabled && handleOnClick()} {...rest}>
                Assign as Manager/Supervisor
            </Button>
            {isOpen && !noInternalModal && (
                <AssignDeptPositionModal
                    open={isOpen}
                    onClose={() => setOpen(false)}
                    onFinish={() => setOpen(false)}
                    id={id}
                    transparentOverlay={transparentOverlay}
                    onBack={hasBack && (() => setOpen(false))}
                    readOnly={readOnly}
                />
            )}
        </>
    );
}

export default AssignDeptPositionButton;

AssignDeptPositionButton.propTypes = {
    id: PropTypes.number,
    transparentOverlay: PropTypes.bool,
    isIcon: PropTypes.bool,
    hasBack: PropTypes.bool,
    onClick: PropTypes.func,
    noInternalModal: PropTypes.bool,
    disabled: PropTypes.bool,
    forceInline: PropTypes.bool,
    readOnly: PropTypes.bool,
    style: PropTypes.object
};
