import { useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { useChangeCompanyPasswordMutation, useCompanyDetailsMutation, useUpdateCompanyProfileMutation } from "../auth/companyAPI";
import { selectProfile, setProfile, updateProfile } from "./slice";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import {
    assignDefaultToBase,
    containsOnlyArabicCharacters,
    createConfirmAlert,
    createToast,
    isFileObject,
    isPhoneValid,
    TOAST_TYPE,
    toFormObject,
    transformStringToObject
} from "../../../common/utilities/helper";
import { selectUser, selectUserSetting, updateUser } from "../../common/slice";
import {
    GENERAL_FORM_FIELDS,
    PASSWORD_FIELDS,
    PROFILE_FIELDS,
    PROFILE_FIELDS_UPLOADS,
    PROFILE_FORM_FIELDS,
    SALARY_FIELDS,
    TIMESHEET_TYPE
} from "./const";
import { useGetCountrySettingsMutation, useUpdateCompanySettingsMutation } from "./api";

export const useGetCompanyDetails = (id, useCache = true) => {
    const [isMounted, setMounted] = useState(false);
    const [fetching, setFetching] = useState(true);
    const [old, setOld] = useState(null);

    const [getDetails] = useCompanyDetailsMutation();

    const dispatch = useAppDispatch();
    const current = useAppSelector(selectProfile) || {};
    const setting = useAppSelector(selectUserSetting);

    const hasChanges = !isEqual(old, current);

    const createVars = (data) => {
        if (!data) return {};
        return { setting };
    };

    const fetch = async () => {
        try {
            if (useCache && current && current.id === id) {
                fetching && setFetching(false);
                return current;
            }
            const result = await getDetails({ extraPath: id });
            if (result.error) {
                throw new Error("Failed to fetch Details. Please try again later");
            }
            const newres = assignDefaultToBase(PROFILE_FORM_FIELDS, result.data.data);
            setOld(newres);
            dispatch(setProfile(newres));
            return result.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return { error };
        } finally {
            setFetching(false);
        }
    };

    const updateCurrent = (newCurrent = {}) => dispatch(updateProfile(newCurrent || {}));

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        if (isMounted) {
            fetch();
        }
    }, [isMounted]);

    return [current, { isLoading: fetching, config: createVars(current), updateCurrent, fetch, old, setOld, hasChanges }];
};

export const useChangePassword = () => {
    const [config, setConfig] = useState(toFormObject(PASSWORD_FIELDS));

    const [changePassword, { isLoading }] = useChangeCompanyPasswordMutation();

    const updateConfig = (newConf = {}) => setConfig({ ...config, ...newConf });

    const func = async () => {
        if (isLoading) {
            return;
        }
        try {
            const response = await changePassword({
                body: {
                    newPassword: config[PASSWORD_FIELDS.NEW_PASSWORD.name],
                    oldPassword: config[PASSWORD_FIELDS.OLD_PASSWORD.name]
                }
            });
            if (response?.error) {
                throw new Error(response.error?.data?.message || "Failed to update Password.");
            }
            createToast("Password successfully updated.", TOAST_TYPE.SUCCESS);
            updateConfig(toFormObject(PASSWORD_FIELDS));
            return response?.data?.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return { error };
        }
    };

    return [func, isLoading, { config, updateConfig }];
};

export const useUpdateProfile = () => {
    const user = useAppSelector(selectUser);
    const id = user.id;

    const [error, setError] = useState({});
    const [mobileCode, setMobileCode] = useState("");

    const [profile, { isLoading: isGettingProfile, updateCurrent, hasChanges, old, setOld }] = useGetCompanyDetails(null, true);
    const [updateCompanyProfile, { isLoading: isUpdating }] = useUpdateCompanyProfileMutation();

    const dispatch = useAppDispatch();

    const hasError = Object.values(error).filter(Boolean).length > 0;
    const hasContract = !!user.contractCopy;

    const isLoading = isGettingProfile || isUpdating;
    const hasUploads = profile.uploads && Object.values(profile.uploads).some((file) => isFileObject(file));

    const checkPhone = (object = {}) => {
        const mobileNumber = object[PROFILE_FIELDS.CONTACT_NUMBER];
        if (!mobileCode || !mobileNumber) {
            return;
        }
        const phone = isPhoneValid(mobileNumber);
        let isValid = phone.isValid;
        if (!isValid && mobileCode) {
            const newMobileNumber = mobileCode + mobileNumber;
            isValid = isPhoneValid(newMobileNumber).isValid;
            if (!isValid) {
                throw new Error("Contact number is not valid.");
            } else {
                object[PROFILE_FIELDS.CONTACT_NUMBER] = newMobileNumber;
            }
        } else {
            const splitphone = phone.phoneNumber.split(phone.countryCode);
            splitphone[0] = mobileCode;
            const newphone = splitphone.join("");
            if (isPhoneValid(newphone).isValid) {
                object[PROFILE_FIELDS.CONTACT_NUMBER] = newphone;
            } else {
                throw new Error("Contact number is not valid.");
            }
        }
    };

    const validateSanitizeObject = (object = {}) => {
        try {
            checkPhone(object);
            return { error: null };
        } catch (error) {
            return { error: error.message };
        }
    };

    const handleChange = (e, extra = {}) => {
        let name = e.target.name,
            value = e.target.value,
            config = cloneDeep(profile);

        switch (name) {
            case PROFILE_FIELDS.CONTACT_NUMBER: {
                setMobileCode(e.target.mobileCode);
                break;
            }
            case PROFILE_FIELDS.ARABIC_NAME: {
                if (value && !containsOnlyArabicCharacters(value)) {
                    updateError(name, "Must only contain Arabic words Punctuations and Numeric values.");
                } else {
                    error[name] && updateError(name, "");
                }
                break;
            }
            case PROFILE_FIELDS_UPLOADS.LOGO.name: {
                config.logoSrc = extra.src;
                break;
            }
            default:
                break;
        }

        const isChainedObject = name.split(".").length > 1;

        if (e.target?.files) {
            if (name == PROFILE_FIELDS_UPLOADS.LOGO.name && !extra.src) {
                value = null;
            } else {
                value = e.target.files[0];
            }
        }
        if (isChainedObject) {
            const parsedObject = transformStringToObject(name, value, profile);
            config = parsedObject;
        } else {
            config[name] = value;
        }
        updateCurrent(config);
    };

    const update = async (newObject = {}, removeLogo) => {
        try {
            const clonedForm = cloneDeep(profile);
            const uploads = clonedForm.uploads;

            // we reuse this again here to sanitize the object
            const response = validateSanitizeObject(clonedForm);
            if (response.error) throw new Error(response.error);

            const body = { ...clonedForm, ...newObject, removeLogo };

            const formData = new FormData();

            for (const field in uploads) {
                if (Object.hasOwnProperty.call(uploads, field)) {
                    const file = uploads[field];
                    isFileObject(file) && formData.append(field, file);
                }
            }

            formData.append("others", JSON.stringify(body));

            const result = await updateCompanyProfile({
                formData: hasUploads,
                body: hasUploads ? formData : body,
                extraPaths: [id, hasUploads ? "with-file" : "no-file"].filter(Boolean)
            });

            if (result && result.error) {
                throw new Error(result?.error?.data?.message || "Failed to update profile, please try again later.");
            }

            const data = cloneDeep(result.data.data);
            const newProfileData = {
                ...profile,
                [PROFILE_FIELDS.CONTACT_NUMBER]: clonedForm[PROFILE_FIELDS.CONTACT_NUMBER]
            };

            dispatch(updateUser(data));
            setOld(newProfileData);
            dispatch(updateProfile(newProfileData));

            return data;
        } catch (error) {
            return { error: error.message };
        }
    };

    const updateWithAlert = () =>
        new Promise((resolve, reject) => {
            const clonedForm = cloneDeep(profile);
            const result = validateSanitizeObject(clonedForm);

            if (result.error) {
                return reject(result.error);
            }

            createConfirmAlert({
                title: "Update Profile",
                content: "Are you sure you want to update your profile?",
                onConfirm: async (close) => {
                    close();
                    const result = await update();
                    if (result?.error) {
                        reject(result.error);
                    } else {
                        resolve(result);
                    }
                }
            });
        });

    const updateError = (name, message) => setError({ ...error, [name]: message });

    const backToDefault = () => old && dispatch(setProfile(old));

    return [
        updateWithAlert,
        isLoading,
        {
            onFormChange: handleChange,
            profile,
            isUpdating,
            isGettingProfile,
            hasChanges,
            updateError,
            error,
            hasError,
            hasContract,
            old,
            backToDefault
        }
    ];
};

export const useGetCountrySettings = () => {
    const [data, setData] = useState(null);
    const [isMounted, setMounted] = useState(false);
    const [fetching, setFetching] = useState(true);

    const [getDetails] = useGetCountrySettingsMutation();

    const fetch = async () => {
        try {
            const result = await getDetails();
            if (result.error) {
                throw new Error("Failed to country settings. Please try again later");
            }
            setData(result.data.data);
            return result.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return { error };
        } finally {
            setFetching(false);
        }
    };

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        if (isMounted && !data) {
            fetch();
        }
    }, [isMounted]);

    return [data, { isLoading: fetching, fetch }];
};

export const useUpdateGeneral = () => {
    const [error, setError] = useState({});
    const [old, setOld] = useState(null);

    const [updateSetting, { isLoading }] = useUpdateCompanySettingsMutation();

    const dispatch = useAppDispatch();
    const setting = assignDefaultToBase(GENERAL_FORM_FIELDS, useAppSelector(selectUserSetting));
    const hasError = Object.values(error).filter(Boolean).length > 0;
    const hasChanges = !isEqual(old, setting);
    const salary = setting.salary;
    const timesheetType = salary?.timesheetEndDay == 31 ? TIMESHEET_TYPE.FULL_MONTH : TIMESHEET_TYPE.BY_CUTOFF;
    const isCutOff = timesheetType == TIMESHEET_TYPE.BY_CUTOFF;

    const updateSettings = (payload = {}) => dispatch(updateUser({ Setting: { ...setting, ...payload } }));

    const validateSanitizeObject = (temp = {}) => {
        if (typeof temp == "object" && "prefix" in temp) delete temp.prefix;
        return { error: null };
    };

    useEffect(() => {
        !old && setOld(setting);
    }, []);

    const handleChange = (e) => {
        let name = e.target.name,
            value = e.target.value,
            config = cloneDeep(setting);

        const isChainedObject = name.split(".").length > 1;

        if (e.target?.files) {
            value = e.target.files[0];
        }
        if (isChainedObject) {
            const parsedObject = transformStringToObject(name.toString(), value, setting);
            config = parsedObject;
        } else {
            config[name] = value;
        }

        if (name == SALARY_FIELDS.TIMESHEET_END_DAY) {
            if (isCutOff) {
                const nextDay = value + 1;
                config.salary[SALARY_FIELDS.TIMESHEET_START_DAY] = nextDay == 32 ? 1 : nextDay;
                config.salary[SALARY_FIELDS.TIMESHEET_END_DAY] = value;
            } else {
                config.salary[SALARY_FIELDS.TIMESHEET_START_DAY] = 1;
                config.salary[SALARY_FIELDS.TIMESHEET_END_DAY] = 31;
            }
        }

        if (name == "timesheet") {
            if (value == TIMESHEET_TYPE.BY_CUTOFF) {
                config.salary[SALARY_FIELDS.TIMESHEET_START_DAY] = 3;
                config.salary[SALARY_FIELDS.TIMESHEET_END_DAY] = 2;
            }
            if (value == TIMESHEET_TYPE.FULL_MONTH) {
                config.salary[SALARY_FIELDS.TIMESHEET_START_DAY] = 1;
                config.salary[SALARY_FIELDS.TIMESHEET_END_DAY] = 31;
            }
        }
        updateSettings(config);
    };

    const update = async (newObject = {}) => {
        try {
            if (hasError) {
                setError({});
            }
            const clonedForm = cloneDeep(assignDefaultToBase(GENERAL_FORM_FIELDS, setting));
            // we reuse this again here to sanitize the object
            const body = { ...clonedForm, ...newObject };
            const response = validateSanitizeObject(body);
            if (response.error) throw new Error(response.error);
            const result = await updateSetting({ body });
            if (result && result.error) {
                throw new Error(result?.error?.data?.message || "Failed to update settings, please try again later.");
            }
            const data = result.data.data;
            setOld(clonedForm);
            return data;
        } catch (error) {
            setError({ message: error.message });
            return { error: error.message };
        }
    };

    const updateWithAlert = () =>
        new Promise((resolve, reject) => {
            const clonedForm = cloneDeep(setting);
            const result = validateSanitizeObject(clonedForm);
            if (result.error) {
                return reject(result.error);
            }

            createConfirmAlert({
                title: "Update Setting",
                content: "Are you sure you want to update your setting?",
                onConfirm: async (close) => {
                    close();
                    const result = await update();
                    if (result?.error) {
                        reject(result.error);
                    } else {
                        resolve(result);
                    }
                }
            });
        });

    const updateError = (name, message) => setError({ ...error, [name]: message });

    const backToDefault = () => old && dispatch(updateSettings(old));

    return [
        updateWithAlert,
        isLoading,
        {
            salary,
            isCutOff,
            onFormChange: handleChange,
            setting,
            hasChanges,
            updateError,
            error,
            hasError,
            old,
            backToDefault
        }
    ];
};

export const useManageProfileState = (cb) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isChangePass, setChangePass] = useState(false);
    const [viewObject, setViewObject] = useState({ type: null, data: null });

    const [
        update,
        isLoading,
        { onFormChange: handleChange, profile, isGettingProfile, isUpdating, hasChanges, error, hasError, hasContract, backToDefault }
    ] = useUpdateProfile();

    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);
    const setting = useAppSelector(selectUserSetting);

    useEffect(() => {
        cb?.(isUpdating);
    }, [isUpdating]);

    const toggleChangePass = () => setChangePass(!isChangePass);

    const handleLogoChange = (newsrc, fileObj) => {
        dispatch(updateUser({ logoSrc: newsrc }));
        handleChange(
            {
                target: {
                    name: PROFILE_FIELDS_UPLOADS.LOGO.name,
                    files: [fileObj]
                }
            },
            { src: newsrc }
        );
    };

    const handleToggle = (e) => handleChange({ target: { name: e.target.name, value: e.target.checked } });

    const toggleEdit = ({ isCancel } = {}) => {
        if (isCancel) {
            backToDefault();
        }
        setIsEditing((prev) => !prev);
    };

    const handleCancel = () => {
        toggleEdit({ isCancel: true });
        if (isChangePass) toggleChangePass();
    };

    const handleViewChange = (newObject = {}) =>
        setViewObject({
            ...viewObject,
            ...newObject
        });

    const handleSubmit = async () => {
        try {
            const response = await update();
            if (!response?.error) {
                toggleEdit();
                createToast("Profile updated successfully!", TOAST_TYPE.SUCCESS);
            }
        } catch (error) {
            createToast(`Failed to update Profile. ${error?.message || error || "Please try again later or contact support."} `, TOAST_TYPE.ERROR);
        }
    };

    return [
        profile,
        isGettingProfile,
        {
            isLoading,
            isUpdating,
            hasChanges,
            error,
            hasError,
            hasContract,
            handleCancel,
            handleViewChange,
            handleSubmit,
            handleLogoChange,
            handleToggle,
            user,
            setting,
            isEditing,
            setIsEditing,
            toggleEdit,
            isChangePass,
            setChangePass,
            toggleChangePass,
            viewObject,
            handleChange
        }
    ];
};

export const useManageGeneralState = (cb) => {
    const [isEditing, setIsEditing] = useState(false);

    const [update, isLoading, { onFormChange: handleChange, setting, hasChanges, error, hasError, backToDefault, salary, isCutOff }] =
        useUpdateGeneral();

    const user = useAppSelector(selectUser);
    const countrySettings = user.countrySettings;
    const timesheetType = salary?.timesheetEndDay == 31 ? TIMESHEET_TYPE.FULL_MONTH : TIMESHEET_TYPE.BY_CUTOFF;

    useEffect(() => {
        cb?.(isLoading);
    }, [isLoading]);

    const handleToggle = (e) => handleChange({ target: { name: e.target.name, value: e.target.checked } });

    const toggleEdit = ({ isCancel } = {}) => {
        if (isCancel) {
            backToDefault();
        }
        setIsEditing((prev) => !prev);
    };

    const handleCancel = () => {
        toggleEdit({ isCancel: true });
    };

    const handleSubmit = async () => {
        try {
            const response = await update();
            if (!response?.error) {
                toggleEdit();
                createToast("Settings updated successfully!", TOAST_TYPE.SUCCESS);
            }
        } catch (error) {
            createToast(`Failed to update Settings.`, TOAST_TYPE.ERROR);
        }
    };

    return [
        setting,
        isLoading,
        {
            countrySettings,
            hasChanges,
            error,
            hasError,
            user,
            isEditing,
            handleCancel,
            handleSubmit,
            handleToggle,
            setIsEditing,
            toggleEdit,
            handleChange,
            salary,
            isCutOff,
            timesheetType
        }
    ];
};
