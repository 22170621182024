import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "../extra/Button";

function FormAuthTemplate({
    title,
    subtext,
    children,
    submitLabel = "Submit",
    onSubmit,
    footer,
    buttonExtra,
    disabled,
    noSubmit,
    isLoading,
    styles = {},
    customSubtext
}) {
    const [loading, setIsLoading] = useState(!!isLoading);

    useEffect(() => {
        if (typeof isLoading === "boolean" && loading != isLoading) {
            setIsLoading(isLoading);
        }
    }, [isLoading]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (noSubmit) {
            return;
        }
        setIsLoading(true);
        await onSubmit();
        setIsLoading(false);
    };

    return (
        <div className="tk-form-auth-template" style={styles.parent || {}}>
            {title && (
                <div className="tk-form-auth-template__title" style={styles.title || {}}>
                    <h2>{title}</h2>
                </div>
            )}
            {subtext && (
                <div className="tk-form-auth-template__subtext" style={styles.subtext || {}}>
                    {customSubtext ? subtext : <p className="normal-font">{subtext}</p>}
                </div>
            )}
            {(children || buttonExtra || !noSubmit) && (
                <form className="tk-form-auth-template__content" onSubmit={handleSubmit}>
                    {children}
                    <div className="tk-form-auth-template__footer" style={!children ? { marginTop: 0 } : {}}>
                        {buttonExtra}
                        {!noSubmit && (
                            <Button options={{ type: "submit" }} className="primary with-shadow" disabled={disabled} isLoading={loading}>
                                {submitLabel}
                            </Button>
                        )}
                    </div>
                </form>
            )}
            {footer}
        </div>
    );
}

FormAuthTemplate.propTypes = {
    buttonExtra: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]),
    subtext: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]),
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]),
    footer: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]),
    submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]),
    onSubmit: PropTypes.func,
    disabled: PropTypes.bool,
    noSubmit: PropTypes.bool,
    isLoading: PropTypes.bool,
    customSubtext: PropTypes.bool,
    styles: PropTypes.shape({
        parent: PropTypes.any,
        title: PropTypes.any,
        subtext: PropTypes.any
    })
};

export default FormAuthTemplate;
