import React from "react";
import PropTypes from "prop-types";
import WarningIcon from "@mui/icons-material/Warning";
import SectionCollapse from "./SectionCollapse";

const styles = {
    icon: { color: "#937409" },
    header: { fontWeight: "600" },
    parent: { backgroundColor: "#FFEBA4", color: "#937409" }
};

const SectionCollapseWarning = ({ show, children, collapse = true, onCollapse, alwaysOpen, style }) => {
    if (!show) {
        return <></>;
    }

    return (
        <SectionCollapse
            header={
                <div className="flex center gap-05">
                    <WarningIcon style={styles.icon} /> Warning
                </div>
            }
            styles={{
                header: styles.header,
                parent: { ...styles.parent, ...(style || {}) }
            }}
            collapse={collapse}
            onCollapse={onCollapse}
            alwaysOpen={alwaysOpen}
        >
            {children}
        </SectionCollapse>
    );
};

SectionCollapseWarning.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    collapse: PropTypes.bool,
    show: PropTypes.bool,
    alwaysOpen: PropTypes.bool,
    onCollapse: PropTypes.func,
    style: PropTypes.object
};

export default SectionCollapseWarning;
