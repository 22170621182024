import React from "react";
import PropTypes from "prop-types";
import AsyncSelectLazy from "../../../common/components/extra/AsyncSelectLazy";
import { useLazyDepartments } from "./hooks";

function DepartmentsSelectLazy(props) {
    const [object, , { loadMore, search, isLoading, createRowItem }] = useLazyDepartments({
        initializing: props.isLoading,
        value: props?.value,
        isMulti: props?.isMulti,
        defaultValue: Array.isArray(props.value) ? props.value.map((v) => v.id) : props?.value?.id,
        isFilter: props.isFilter,
        forEmpId: props.forEmpId
    });

    let getValue = !props.isMulti
        ? props.value && Object.keys(props.value).length && object.data.find((item) => item.id === props.value.id)
        : object.data.filter((item) => props.value.map((v) => v.id).includes(item.id));

    if (!props.isMulti ? !!Object.keys(props.value || {}).length : Array.isArray(props.value) && props.value.length) {
        if (!props.isMulti) {
            if (!getValue) {
                getValue = createRowItem(props.value);
            }
        } else {
            getValue = props.value.map((item) => createRowItem(item));
        }
    }

    const handleSearch = (newInput, type) => {
        const allowedActions = ["input-change"];
        if (allowedActions.includes(type.action)) {
            search(newInput);
        }
    };

    return (
        <AsyncSelectLazy
            {...props}
            filterOption={(options, newInputs) => {
                const index1 = options?.data?.title?.trim()?.toLowerCase() || "";
                const search = newInputs?.toLowerCase().trim();
                if (index1.includes(search)) {
                    return true;
                } else {
                    return false;
                }
            }}
            value={props.isLoading ? null : getValue}
            options={object.data}
            onLoadMore={loadMore}
            onSearch={handleSearch}
            isFetching={props.isLoading || isLoading}
        />
    );
}

DepartmentsSelectLazy.propTypes = {
    onChange: PropTypes.func,
    isOutlined: PropTypes.bool,
    label: PropTypes.string,
    noborder: PropTypes.bool,
    style: PropTypes.object,
    value: PropTypes.any,
    isMulti: PropTypes.bool,
    allowOnShift: PropTypes.bool,
    isLoading: PropTypes.bool,
    allowSelectOnShift: PropTypes.bool,
    isFilter: PropTypes.bool,
    forEmpId: PropTypes.number
};

export default DepartmentsSelectLazy;
