export const ZOOM_TYPE = {
    MOUNTED: "MOUNTED",
    IN: "IN",
    OUT: "OUT",
    INPUT: "INPUT"
};

export const ZOOM_MIN = 25;
export const ZOOM_MAX = 200;
export const ZOOM_INC = 25;
export const ZOOM_DEFAULT = 100;
