import React from "react";
import PropTypes from "prop-types";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { TOAST_TYPE, createConfirmAlert, createToast } from "../../../common/utilities/helper";
import Button from "../../../common/components/extra/Button";
import Card from "../../../common/components/extra/Card";
import Divider from "../../../common/components/extra/Divider";
import PasswordStrength from "../../../common/components/extra/passwordStrength/PasswordStrength";
import { useChangePassword } from "./hooks";
import { PASSWORD_FIELDS } from "./const";

function ChangePassword({ onCancel, onSubmit }) {
    const [changePassword, loading, { config, updateConfig }] = useChangePassword();

    const validate = () => {
        try {
            if (!config[PASSWORD_FIELDS.CONFIRM_PASSWORD.name]) {
                throw new Error("Confirm password is required.");
            }
            if (!config[PASSWORD_FIELDS.NEW_PASSWORD.name]) {
                throw new Error("New password is required.");
            }
            if (config[PASSWORD_FIELDS.NEW_PASSWORD.name] !== config[PASSWORD_FIELDS.CONFIRM_PASSWORD.name]) {
                throw new Error("Password do not match.");
            }
            return true;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return false;
        }
    };

    const handleSave = async () => {
        const response = await changePassword();
        if (!response?.error) onSubmit?.(response);
    };

    const handleChange = (e) => {
        const config = {};
        const name = e.target.name;
        const value = e.target.value;
        config[name] = value;
        updateConfig(config);
    };

    const handleCancel = () => {
        typeof onCancel === "function" && onCancel();
    };

    const renderControls = (
        <div className="flex gap-1" style={{ marginLeft: "auto" }}>
            {typeof onCancel === "function" && (
                <Button
                    options={{ type: "button" }}
                    beforeExtra={<CancelIcon style={{ color: "red" }} />}
                    onClick={handleCancel}
                    disabled={loading}
                    small
                >
                    Cancel
                </Button>
            )}
            <Button options={{ type: "submit" }} beforeExtra={<SaveIcon />} className="primary" disabled={loading} isLoading={loading} small>
                Save
            </Button>
        </div>
    );

    return (
        <Card className="box-shadow-mini">
            <form
                className="change-password flex column"
                style={{ gap: "2rem" }}
                onSubmit={(e) => {
                    e.preventDefault();
                    const isValid = validate();
                    if (isValid) {
                        createConfirmAlert({
                            title: "Save Profile",
                            content: "Are you certain about this action? It will result in password update, and once completed, cannot be reversed",
                            onConfirm: (onClose) => {
                                onClose();
                                handleSave();
                            }
                        });
                    }
                }}
            >
                <div className="flex column">
                    <Divider title="Change Password" />
                    <p className="fade small-font">For enhanced account security, we recommend changing your password regularly.</p>
                    <div className="flex column gap-1" style={{ gap: "2rem" }}>
                        <section className="flex column gap-05">
                            <PasswordStrength
                                name={PASSWORD_FIELDS.OLD_PASSWORD.name}
                                label={PASSWORD_FIELDS.OLD_PASSWORD.label}
                                value={config[PASSWORD_FIELDS.OLD_PASSWORD.name]}
                                placeholder=""
                                onChange={handleChange}
                                isLarge={false}
                                autoFocus
                                required
                                noMeter
                            />
                            <PasswordStrength
                                name={PASSWORD_FIELDS.NEW_PASSWORD.name}
                                label={PASSWORD_FIELDS.NEW_PASSWORD.label}
                                value={config[PASSWORD_FIELDS.NEW_PASSWORD.name]}
                                placeholder=""
                                onChange={handleChange}
                                isLarge={false}
                                required
                            />
                            <PasswordStrength
                                name={PASSWORD_FIELDS.CONFIRM_PASSWORD.name}
                                label={PASSWORD_FIELDS.CONFIRM_PASSWORD.label}
                                value={config[PASSWORD_FIELDS.CONFIRM_PASSWORD.name]}
                                placeholder=""
                                onChange={handleChange}
                                isLarge={false}
                                required
                                noMeter
                            />
                        </section>
                    </div>
                </div>
                {renderControls}
            </form>
        </Card>
    );
}

export default ChangePassword;

ChangePassword.propTypes = {
    beforeExtra: PropTypes.any,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func
};
