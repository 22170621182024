import React, { useState } from "react";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import { createFullName, createGroup } from "../../../common/utilities/helper";
import DepartmentsSelectLazy from "../departments/DepartmentsSelectLazy";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import SectionCollapseInfo from "../../../common/components/extra/section/SectionCollapseInfo";

const POSITION = {
    MG: "1",
    SV: "2",
    BOTH: "3"
};

function AssignDeptPositionInputs({ base, isFetching, onChange, current }) {
    const [position, setPosition] = useState("");
    const [selected, setSelected] = useState(null);

    const currentEmpFname = current && createFullName(current.first_name, current.last_name);

    const isBoth = position == POSITION.BOTH;
    const isPosSV = isBoth || position == POSITION.SV;
    const isPosMG = isBoth || position == POSITION.MG;

    const onChangePos = (e) => {
        const cloneSelected = cloneDeep(selected);
        const value = e.target.value;
        switch (value) {
            case POSITION.MG: {
                cloneSelected.manager_id = current.id;
                break;
            }
            case POSITION.SV: {
                cloneSelected.supervisor_id = current.id;
                break;
            }
            case POSITION.BOTH: {
                cloneSelected.manager_id = current.id;
                cloneSelected.supervisor_id = current.id;
                break;
            }
            default:
                break;
        }
        setPosition?.(value);
        onChange?.({
            id: cloneSelected.id,
            title: cloneSelected.title,
            supervisor_id: cloneSelected.supervisor_id,
            manager_id: cloneSelected.manager_id
        });
    };

    const onChangeDept = (val) => {
        setSelected(val);
        setPosition(null);
    };

    const createLineThrough = (fullname, newFullname) => {
        return (
            <div className="flex gap-05 align-center">
                <span
                    className={`semi-bold ${(!newFullname && "primary-color") || ""}`.trim()}
                    style={{ textDecoration: newFullname ? "line-through" : "none" }}
                >
                    {fullname}
                </span>
                {newFullname && (
                    <>
                        <span>&rarr;</span>
                        <span className={`semi-bold primary-color`}>{newFullname}</span>
                    </>
                )}
            </div>
        );
    };

    return (
        <>
            {createGroup({
                base,
                title: "",
                body: (
                    <>
                        <DepartmentsSelectLazy
                            label="Department"
                            name="department"
                            onChange={onChangeDept}
                            value={selected}
                            menuPortalTarget={document.body}
                            forEmpId={current.id}
                            isOutlined
                            disabledOutline
                            isClearable
                            required
                        />
                        {!!selected && (
                            <SectionCollapseInfo title="Department Details" show alwaysOpen>
                                <div className="flex column gap-05">
                                    <div className="flex gap-05">
                                        <span>Supervisor:</span>
                                        {createLineThrough(
                                            createFullName(selected.supervisor.first_name, selected.supervisor.last_name),
                                            isPosSV && currentEmpFname
                                        )}
                                    </div>
                                    <div className="flex gap-05">
                                        <span>Manager:</span>
                                        {createLineThrough(
                                            createFullName(selected.manager.first_name, selected.manager.last_name),
                                            isPosMG && currentEmpFname
                                        )}
                                    </div>
                                </div>
                            </SectionCollapseInfo>
                        )}
                        <Input
                            type={INPUT_TYPE.RADIO}
                            name="position"
                            label="Assign as"
                            value={position}
                            onChange={onChangePos}
                            options={[
                                { value: POSITION.SV, label: "Supervisor" },
                                { value: POSITION.MG, label: "Manager" },
                                { value: POSITION.BOTH, label: "Both" }
                            ]}
                            disabled={!selected || isFetching}
                            required
                        />
                    </>
                )
            })}
        </>
    );
}

export default AssignDeptPositionInputs;

AssignDeptPositionInputs.propTypes = {
    base: PropTypes.string,
    form: PropTypes.object,
    current: PropTypes.object,
    isFetching: PropTypes.bool,
    onChange: PropTypes.func,
    onFinish: PropTypes.func
};
