import React from "react";
import PropTypes from "prop-types";
import CompanyPenalties from "../companyPenalties/CompanyPenalties";
import { ROUTE_TYPE } from "../companyPenalties/const";

function PenaltiesTab({ styles = {} }) {
    return (
        <div className="penalties-tab flex column gap-1" style={styles.parent || {}}>
            <CompanyPenalties type={ROUTE_TYPE.LAW} />
            <CompanyPenalties type={ROUTE_TYPE.CUSTOM} />
        </div>
    );
}

export default PenaltiesTab;

PenaltiesTab.propTypes = {
    styles: PropTypes.shape({
        parent: PropTypes.object
    })
};
