import React, { useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as ID_SVG } from "../../../assets/images/id-icon.svg";
import Modal from "../../../common/components/extra/Modal";
import Group from "../../../common/components/extra/Group";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import { TOAST_TYPE, createConfirmAlert, createToast, toTimeWithTimeZone } from "../../../common/utilities/helper";
import { UPLOAD_FIELDS, VIEW_MODAL_TYPE } from "../../admin/companies/const";
import { useCompanyStatusCheck, useUpdateFiles } from "./hooks";
import ViewModalSub from "../../admin/companies/ViewModalSub";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import { FIELDS } from "./const";
import SectionCollapseInfo from "../../../common/components/extra/section/SectionCollapseInfo";
import { COMPANY_STATUS } from "../../../common/utilities/const";

const { LICENSE_TRADE_NUMBER, LICENSE_COMMERCIAL_NUMBER, ESTABLISHMENT_ID } = FIELDS;
const { LICENSE_TRADE: LICENSE_TRADE_COPY, LICENSE_COMMERCIAL: LICENSE_COMMERCIAL_COPY, ESTABLISHMENT_ID: ESTABLISHMENT_ID_COPY } = UPLOAD_FIELDS;

function UpdateFilesModal({ open, onClose, onFinish, ...rest }) {
    const [viewObject, setViewObject] = useState({
        type: null,
        data: null
    });

    const [updateFiles, { isLoading, hasAllUploads, uploads, setUploads, form, setForm }] = useUpdateFiles({ noDefaultValue: true });

    const user = useAppSelector(selectUser);
    const check = useCompanyStatusCheck();

    const status = user.status.data;
    const timezone = user?.Setting?.timezone;
    const isContractExpired = check.isContractExpired;
    const hasContractCopy = check.hasContractCopy;

    const handleUploadsChange = (e) => setUploads(e.target.name, e.target?.files?.[0]);

    const handleSave = async () => {
        const today = toTimeWithTimeZone(new Date(), timezone).toDate();

        let temp = {
            last_file_upload_date: today,
            status
        };

        if (hasContractCopy && isContractExpired) {
            temp.last_renewal_date = today;
        }

        if (!hasContractCopy || (hasContractCopy && isContractExpired)) {
            if (!temp.status.includes(COMPANY_STATUS.REQUESTING_CONTRACT)) {
                temp.status = temp.status.concat(COMPANY_STATUS.REQUESTING_CONTRACT);
            }
            delete temp.last_file_upload_date;
        }

        try {
            const res1 = await updateFiles(temp);
            if (res1.error) {
                throw new Error(res1.error);
            }
            onFinish?.(res1);
        } catch (error) {
            createToast(error.message || "Failed to update files. Please try again later or contact support.", TOAST_TYPE.ERROR);
        }
    };

    const handleViewChange = (newObject = {}) => setViewObject({ ...viewObject, ...newObject });

    const handleChange = (e) => setForm({ [e.target.name]: e.target.value });

    const createInputFile = (fileObject = {}, modalType, options = {}) => {
        const isNotFileObject = typeof uploads[fileObject.key] === "string";
        return (
            <>
                <Input
                    type={INPUT_TYPE.UPLOAD}
                    name={fileObject.key}
                    icon={<ID_SVG />}
                    accept={fileObject.accepts}
                    sizeLimit={fileObject.size}
                    label={fileObject.label}
                    onChange={handleUploadsChange}
                    onView={() =>
                        handleViewChange({
                            type: modalType,
                            data: {
                                id: user?.id,
                                src: (!isNotFileObject && uploads[fileObject.key] && URL.createObjectURL(uploads[fileObject.key])) || "",
                                filename: isNotFileObject ? uploads[fileObject.key] : uploads[fileObject.key]?.name
                            }
                        })
                    }
                    value={!uploads[fileObject.key] ? true : uploads[fileObject.key]}
                    required
                    hidelabel
                />
                {options.subtext && <span className="danger-color small-font semi-bold">{options.subtext}</span>}
            </>
        );
    };

    const createSubTextInputFile = ({ label, hide } = {}) => {
        if (hide) {
            return "";
        }
        return (
            <span className="danger-color">
                Your {label} expiry date is less than <span className="bold">{check.recurrenceValue}</span> month(s) which is not eligible for any
                subscription renew/upgrade.
            </span>
        );
    };

    return (
        <Modal
            title="Update Company Files"
            open={open}
            onClose={onClose}
            onSave={() =>
                createConfirmAlert({
                    title: "Update Company Files",
                    content: "Are you sure you want to proceed? This cannot be undone.",
                    onConfirm: async (close) => {
                        close();
                        await handleSave();
                    }
                })
            }
            styles={{
                content: {
                    margin: "1rem",
                    maxHeight: "90vh",
                    minWidth: "25rem",
                    maxWidth: "40rem"
                },
                body: {
                    overflow: "auto"
                }
            }}
            disableSave={!hasAllUploads || isLoading}
            isLoading={isLoading}
            saveLabel="Submit"
            isForm
            small
            isPortal
            {...rest}
        >
            <div className="update-modal" style={{ margin: "0 1rem" }}>
                <div className="update-modal__inner flex column gap-1" style={{ padding: "1rem 0" }}>
                    <SectionCollapseInfo title="Important Notice" style={{ marginBottom: "1rem" }} show alwaysOpen>
                        <div className="flex column gap-05 small-font semi-bold">
                            <span>
                                Please ensure that all uploaded files have a valid expiry date. Upon submission, our staff members will review the
                                files for verification. We will contact you regarding the status of your submission.
                            </span>
                            {hasContractCopy && isContractExpired && (
                                <span className="danger-color">
                                    Your contract is already expired. Your renewal request will be submitted along with the new uploaded files.
                                </span>
                            )}
                            {hasContractCopy && !isContractExpired && !check.isContractWithinRecurrence && (
                                <span className="danger-color">
                                    Your contract expiry date is less than <span className="bold">{check.recurrenceValue}</span> month(s) which is not
                                    eligible for any subscription renew/upgrade. Clicking submit will send a contract renewal along with the new
                                    uploaded files.
                                </span>
                            )}
                            {!hasContractCopy && (
                                <span className="danger-color">
                                    A contract is needed before applying for a subscription. Clicking submit will send a contract request along with
                                    the new uploaded files.
                                </span>
                            )}
                        </div>
                    </SectionCollapseInfo>
                    <Group title="License Trade Details">
                        <Input
                            type={INPUT_TYPE.TEXT}
                            label="Number (ID)"
                            name={LICENSE_TRADE_NUMBER}
                            value={form[LICENSE_TRADE_NUMBER]}
                            onChange={handleChange}
                            required
                        />
                        {createInputFile(LICENSE_TRADE_COPY, VIEW_MODAL_TYPE.LICENSE_TRADE_COPY, {
                            subtext: createSubTextInputFile({
                                label: "Trade License",
                                hide: check.isTradeLicenseWithinRecurrence || !check.isLicenseTradeExpired
                            })
                        })}
                    </Group>
                    <Group title="License Commercial Details">
                        <Input
                            type={INPUT_TYPE.TEXT}
                            label="Number (ID)"
                            name={LICENSE_COMMERCIAL_NUMBER}
                            value={form[LICENSE_COMMERCIAL_NUMBER]}
                            onChange={handleChange}
                            required
                        />
                        {createInputFile(LICENSE_COMMERCIAL_COPY, VIEW_MODAL_TYPE.LICENSE_COMMERCIAL_COPY, {
                            subtext: createSubTextInputFile({
                                label: "Commercial License",
                                hide: check.isCommercialLicenseWithinRecurrence || !check.isLicenseCommercialExpired
                            })
                        })}
                    </Group>
                    <Group title="Establishment Details">
                        <Input
                            type={INPUT_TYPE.TEXT}
                            label="Number (ID)"
                            name={ESTABLISHMENT_ID}
                            value={form[ESTABLISHMENT_ID]}
                            onChange={handleChange}
                            required
                        />
                        {createInputFile(ESTABLISHMENT_ID_COPY, VIEW_MODAL_TYPE.ESTABLISHMENT_ID_COPY, {
                            subtext: createSubTextInputFile({
                                label: "Commercial License",
                                hide: check.isEIDWithinRecurrence || !check.isEIDExpired
                            })
                        })}
                    </Group>
                </div>
            </div>
            {!!viewObject.type && (
                <ViewModalSub
                    open={!!viewObject.type}
                    onChange={(bool) => handleViewChange({ type: bool ? viewObject.type : null })}
                    type={viewObject.type}
                    data={viewObject.data}
                />
            )}
        </Modal>
    );
}

UpdateFilesModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onFinish: PropTypes.func
};

export default UpdateFilesModal;
