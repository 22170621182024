import React, { useState } from "react";
import PropTypes from "prop-types";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Cancel";
import Input from "../../../common/components/extra/form/Input";
import { createMiniTootlip, sanitizeWords } from "../../../common/utilities/helper";
import { FORM_TYPE } from "../../../common/utilities/const";
import Popover from "../../../common/components/extra/Popover";
import Button from "../../../common/components/extra/Button";
import SelectConstant from "../../../common/components/extra/select/SelectConstant";
import { FORM_APPROVAL_TYPE, FORM_CATEGORY, FIELD, FIELD_OBJECT, FORM_STATUS, USER_LEVEL } from "./const";
import UserLevelSelect from "./UserLevelSelect";

function FormHeader({ form, onChange, isLoading }) {
    const [open, setOpen] = useState(true);

    return (
        <div className="tk-company-forms-upsert__header">
            <div className="group flex gap-1">
                <Popover
                    open={open}
                    onChange={(bool) => setOpen(bool)}
                    content={
                        <div className="flex column gap-1" style={{ width: "25rem", padding: ".5rem" }}>
                            <h3>Edit Form Details</h3>
                            <div className="left flex column gap-05">
                                <Input
                                    name={FIELD.NAME}
                                    label={FIELD_OBJECT[FIELD.NAME].label}
                                    value={sanitizeWords(form[FIELD.NAME])}
                                    onChange={(e) =>
                                        onChange({
                                            target: {
                                                name: e.target.name,
                                                value: e.target.value
                                            }
                                        })
                                    }
                                    isLoading={isLoading}
                                    required
                                />
                                <SelectConstant
                                    name={FIELD.TYPE}
                                    label={FIELD_OBJECT[FIELD.TYPE].label}
                                    base={FORM_TYPE}
                                    value={form[FIELD.TYPE]}
                                    onChange={(val) =>
                                        onChange({
                                            target: {
                                                name: FIELD.TYPE,
                                                value: val.value
                                            }
                                        })
                                    }
                                    isLoading={isLoading}
                                    isOutlined
                                    disabledOutline
                                    required
                                />
                                <SelectConstant
                                    name={FIELD.CATEGORY}
                                    label={FIELD_OBJECT[FIELD.CATEGORY].label}
                                    base={FORM_CATEGORY}
                                    value={form[FIELD.CATEGORY]}
                                    onChange={(val) =>
                                        onChange({
                                            target: {
                                                name: FIELD.CATEGORY,
                                                value: val.value
                                            }
                                        })
                                    }
                                    tooltip={createMiniTootlip(
                                        "If set to EMPLOYEE, form will be sent from the mobile app. If set to HR, form will be sent directly by the Admin to the selected Employee to review"
                                    )}
                                    isLoading={isLoading}
                                    isOutlined
                                    disabledOutline
                                    required
                                />
                                <SelectConstant
                                    name={FIELD.APPROVAL_TYPE}
                                    label={FIELD_OBJECT[FIELD.APPROVAL_TYPE].label}
                                    base={FORM_APPROVAL_TYPE}
                                    value={form[FIELD.APPROVAL_TYPE]}
                                    onChange={(val) =>
                                        onChange({
                                            target: {
                                                name: FIELD.APPROVAL_TYPE,
                                                value: val.value
                                            }
                                        })
                                    }
                                    tooltip={createMiniTootlip("Determines the approval flow of the form either by Department or Site.")}
                                    isLoading={isLoading}
                                    isOutlined
                                    disabledOutline
                                    required
                                />
                                <UserLevelSelect
                                    name={FIELD.MIN_USER_LEVEL}
                                    label={FIELD_OBJECT[FIELD.MIN_USER_LEVEL].label}
                                    value={form[FIELD.MIN_USER_LEVEL]}
                                    onChange={(val) =>
                                        onChange({
                                            target: {
                                                name: FIELD.MIN_USER_LEVEL,
                                                value: val.value
                                            }
                                        })
                                    }
                                    tooltip={createMiniTootlip(
                                        "The minimum user level for submission, display or listing of forms in the mobile app."
                                    )}
                                    isLoading={isLoading}
                                    isOutlined
                                    disabledOutline
                                    required
                                />
                                <UserLevelSelect
                                    name={FIELD.APPROVAL_LEVELS}
                                    label={FIELD_OBJECT[FIELD.APPROVAL_LEVELS].label}
                                    value={form[FIELD.APPROVAL_LEVELS]}
                                    onChange={(val) =>
                                        onChange({
                                            target: {
                                                name: FIELD.APPROVAL_LEVELS,
                                                value: val.map((val) => val.value)
                                            }
                                        })
                                    }
                                    fixed={[USER_LEVEL.THREE]}
                                    exclude={[USER_LEVEL.ZERO]}
                                    tooltip={createMiniTootlip(
                                        "Determines which user levels will be needing the approval of the submitted form. By default, all Level 3 Departments will always have the access for viewing of the submitted forms."
                                    )}
                                    isLoading={isLoading}
                                    isOutlined
                                    disabledOutline
                                    required
                                    isMulti
                                />
                                <SelectConstant
                                    name={FIELD.STATUS}
                                    label={FIELD_OBJECT[FIELD.STATUS].label}
                                    base={FORM_STATUS}
                                    value={form[FIELD.STATUS]}
                                    tooltip={createMiniTootlip("Make this active if you want to use this form immediately.")}
                                    onChange={(val) =>
                                        onChange({
                                            target: {
                                                name: FIELD.STATUS,
                                                value: val.value
                                            }
                                        })
                                    }
                                    isLoading={isLoading}
                                    isOutlined
                                    disabledOutline
                                    required
                                />
                            </div>
                            <div className="footer flex" style={{ justifyContent: "flex-end" }}>
                                <Button beforeExtra={<CloseIcon />} className="danger" onClick={() => setOpen(false)} small mini>
                                    Close
                                </Button>
                            </div>
                        </div>
                    }
                    controlled
                >
                    <Button options={{ style: { padding: 0 } }} transparent>
                        <div className="flex align-center">
                            <span className="semi-bold">Click to Edit Form Details</span>
                            <span className="danger-color">*</span>
                            <EditIcon className="pointer primary-color hover-svg" style={{ width: "1.2rem", opacity: ".7" }} />
                        </div>
                    </Button>
                </Popover>
            </div>
        </div>
    );
}

export default FormHeader;

FormHeader.propTypes = {
    form: PropTypes.object,
    onChange: PropTypes.func,
    isLoading: PropTypes.bool
};
