export class MaxLength {
    quill = null;
    options = {};

    constructor(quill, options) {
        this.quill = quill;
        this.options = options;

        quill.on("text-change", this.handleTextChange.bind(this));
    }

    handleTextChange() {
        if (this.quill.getText().length > this.options.value) {
            this.quill.history.undo();
        }
    }
}

export default MaxLength;
