import React, { useMemo } from "react";
import PropTypes from "prop-types";
import PushPinIcon from "@mui/icons-material/PushPin";
import { useMarkerRef, AdvancedMarker, Pin } from "@vis.gl/react-google-maps";

export const MARKER_TYPE = {
    DEFAULT: 0x1,
    RED: 0x2,
    GREEN: 0x3
};

function Marker({ children, map, lat, lng, style, hover, isCenter, onClick, title, type, scale = 1, color, background }) {
    const [markerRef] = useMarkerRef();

    const centerPinProps = useMemo(() => {
        switch (type) {
            case MARKER_TYPE.GREEN:
                return { background: "#039D00", glyphColor: "white" };
            case MARKER_TYPE.RED:
                return { background: "#BE0404", glyphColor: "white" };
            default:
                return { background: background || "#FBBC04", glyphColor: color || "#000" };
        }
    }, [type]);

    return (
        <div
            className={`tk-map-marker ${hover ? "tk-map-marker--hover" : ""} ${isCenter ? "tk-map-marker--centered" : ""}`.trim()}
            style={style || {}}
        >
            <AdvancedMarker title={title} map={map} ref={markerRef} position={{ lat, lng }} onClick={onClick}>
                {isCenter ? (
                    <Pin {...centerPinProps} borderColor="#000" scale={scale}>
                        <div className="semi-bold">{children}</div>
                    </Pin>
                ) : (
                    <PushPinIcon style={{ color: "#0052CC" }} />
                )}
            </AdvancedMarker>
        </div>
    );
}

export default Marker;

Marker.propTypes = {
    title: PropTypes.string,
    children: PropTypes.any,
    map: PropTypes.object,
    maps: PropTypes.object,
    hover: PropTypes.bool,
    style: PropTypes.object,
    isCenter: PropTypes.bool,
    zoom: PropTypes.number,
    lat: PropTypes.number,
    lng: PropTypes.number,
    scale: PropTypes.number,
    color: PropTypes.string,
    background: PropTypes.string,
    onClick: PropTypes.func,
    type: PropTypes.oneOf(Object.values(MARKER_TYPE))
};
