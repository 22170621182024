import cloneDeep from "lodash/cloneDeep";
import { createSlice } from "@reduxjs/toolkit";
import { PAGE_OPTIONS } from "../../../common/components/extra/table/const";
import { DATE_RANGE, FILTER_ALL_VALUE } from "../../../common/utilities/const";
import { FILTER_TYPE } from "./const";

const SLICE_NAME_KEY = "companyAnnouncements";

export const defaultFilter = {
    [FILTER_TYPE.TYPE]: FILTER_ALL_VALUE.value,
    [FILTER_TYPE.DATE]: {
        [DATE_RANGE.TYPE]: "",
        [DATE_RANGE.FROM]: FILTER_ALL_VALUE.value,
        [DATE_RANGE.TO]: FILTER_ALL_VALUE.value
    }
};

export const defaultConfig = {
    page: 1,
    pageSize: PAGE_OPTIONS[0],
    search: "",
    sortBy: "createdAt",
    order: "DESC",
    totalPage: 0,
    totalCount: 0,
    filter: defaultFilter
};

const initialState = {
    data: [],
    tableConfig: defaultConfig,
    current: null,
    loading: false,
    isSearching: false
};

export const companyAnnouncementsSlice = createSlice({
    name: SLICE_NAME_KEY,
    initialState,
    reducers: {
        setFilter: (state, action) => {
            const clone = cloneDeep(state);
            const { key, value, newObject } = action.payload || {};
            if (newObject) {
                clone.tableConfig.filter = newObject;
            } else {
                key && (clone.tableConfig.filter[key] = value);
            }
            return clone;
        },
        resetFilter: (state) => {
            const clone = cloneDeep(state);
            clone.tableConfig.filter = defaultFilter;
            return clone;
        },
        setSearching: (state, action) => {
            const clone = cloneDeep(state);
            clone.isSearching = action.payload;
            return clone;
        },
        setClearCache: (state) => {
            let clone = cloneDeep(state);
            clone.data = [];
            clone.current = null;
            clone.loading = false;
            clone.tableConfig = defaultConfig;
            return clone;
        },
        setCompanyAnnouncementsData: (state, action) => {
            let clone = cloneDeep(state);
            const data = action.payload;
            Array.isArray(data) && (clone.data = data || []);
            return clone;
        },
        updateCompanyAnnouncementsData: (state, action) => {
            let clone = cloneDeep(state);
            const updateId = action.payload.id;
            const newdata = action.payload.data || {};
            updateId && (clone.data = clone.data.map((d) => (d.id == updateId ? { ...d, ...newdata } : d)));
            return clone;
        },
        setTableConfig: (state, action) => {
            let clone = cloneDeep(state);
            const tableConfig = action.payload;
            tableConfig && (clone.tableConfig = { ...state.tableConfig, ...tableConfig });
            return clone;
        },
        setLoading: (state, action) => {
            let clone = cloneDeep(state);
            const loading = action.payload;
            typeof loading === "boolean" && loading !== state.loading && (clone.loading = loading);
            return clone;
        },
        setCurrent: (state, action) => {
            let clone = cloneDeep(state);
            const current = action.payload;
            clone.current = current;
            return clone;
        },
        setState: (state, action) => {
            let clone = cloneDeep(state);
            const { tableConfig, view, data, current, loading } = action.payload;
            tableConfig && (clone.tableConfig = { ...state.tableConfig, ...tableConfig });
            data && (clone.data = data || []);
            view && view !== state.view && (clone.view = view);
            typeof loading === "boolean" && loading !== state.loading && (clone.loading = loading);
            current && (clone.current = current);
            return clone;
        },
        reset: () => initialState
    }
});

export const selectFilter = (state) => state[SLICE_NAME_KEY].tableConfig.filter;
export const selectSearching = (state) => state[SLICE_NAME_KEY].isSearching;
export const selectCompanyAnnouncementsData = (state) => state[SLICE_NAME_KEY].data;
export const selectTableConfig = (state) => state[SLICE_NAME_KEY].tableConfig;
export const selectLoading = (state) => state[SLICE_NAME_KEY].loading;
export const selectCurrent = (state) => state[SLICE_NAME_KEY].current;
export const selectState = (state) => state[SLICE_NAME_KEY];
export const {
    setFilter,
    resetFilter,
    updateCompanyAnnouncementsData,
    setSearching,
    setClearCache,
    setCompanyAnnouncementsData,
    setTableConfig,
    setLoading,
    setCurrent,
    setState,
    reset
} = companyAnnouncementsSlice.actions;
export default companyAnnouncementsSlice.reducer;
