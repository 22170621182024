import React from "react";
import SubscriptionConfirmation from "../../features/company/companySubscription/SubscriptionConfirmation";
import { Navigate } from "react-router-dom";
import Navigation from "../../common/classes/Navigation";
import { useAppSelector } from "../../common/hooks/reduxHooks";
import { selectUser } from "../../features/common/slice";

function PendingPaymentPage() {
    const user = useAppSelector(selectUser);
    const isPendingPayment = user?.status?.isPendingPayment;

    if (!isPendingPayment) {
        return <Navigate to={Navigation.Routes.DASHBOARD.path} />;
    }

    return <SubscriptionConfirmation isPendingPaymentPage />;
}

export default PendingPaymentPage;
