import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { MapControl } from "@vis.gl/react-google-maps";
import SiteIcon from "@mui/icons-material/FmdGood";
import FitBoundsIcon from "@mui/icons-material/Adjust";
import MyTooltip from "../Tooltip";
import { MAP_CONTROL_POSITION } from "./const";
import { latLngType } from "./types";
import { getBoundsLiteral, goToBoundary } from "./helper";
import Select from "../select/Select";

function CustomMapControls({ map, maps, polygons, markers, position = MAP_CONTROL_POSITION.TOP_LEFT, style = {} }) {
    const [selectedSite, setSelectedSite] = useState(null);

    const polygonsOption = useMemo(() => {
        return polygons.map((poly, idx) => ({
            ...poly,
            value: idx,
            label: <span className="bold small-font">{poly.title}</span>
        }));
    }, [polygons]);

    const fitBounds = () => {
        const bounds = getBoundsLiteral(
            maps,
            (polygons.length && polygons.map((item) => item.polygon).flat()) || (markers.length && markers.map((marker) => marker.point)) || []
        );
        bounds && map.fitBounds(bounds, 0);
        bounds && map.setCenter(bounds.getCenter());
    };

    const showWideSiteSelect = polygonsOption.length > 1;

    return (
        <MapControl position={position}>
            {showWideSiteSelect ? (
                <div className="tk-map-controls select flex center gap-05" style={style || {}}>
                    <Select
                        style={{ width: "8rem" }}
                        options={polygonsOption}
                        onChange={setSelectedSite}
                        menuPortalTarget={document.body}
                        placeholder="Select a Site"
                    />
                    <MyTooltip
                        className={!selectedSite ? "disabled" : "hover-svg"}
                        message={selectedSite ? `Go to ${selectedSite.title}` : "Select a Site"}
                    >
                        <SiteIcon onClick={() => selectedSite && goToBoundary({ map, maps, coordinates: selectedSite.polygon })} />
                    </MyTooltip>
                    <MyTooltip className="hover-svg" message="Fit Bounds">
                        <FitBoundsIcon onClick={fitBounds} />
                    </MyTooltip>
                </div>
            ) : (
                <>
                    <div className="tk-map-controls flex center" style={style || {}}>
                        <MyTooltip className="hover-svg" message="Fit Bounds">
                            <FitBoundsIcon onClick={fitBounds} />
                        </MyTooltip>
                    </div>
                </>
            )}
        </MapControl>
    );
}

export default CustomMapControls;

CustomMapControls.propTypes = {
    polygons: PropTypes.arrayOf(PropTypes.shape({ title: PropTypes.string, polygon: PropTypes.arrayOf(latLngType) })),
    markers: PropTypes.arrayOf(latLngType),
    map: PropTypes.object,
    maps: PropTypes.object,
    style: PropTypes.object,
    onGoToBoundary: PropTypes.func,
    position: PropTypes.oneOf(Object.values(MAP_CONTROL_POSITION))
};
