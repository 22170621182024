import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Navigation from "../../classes/Navigation";
import LayoutWithLogo from "./LayoutWithLogo";

function CompanyUnauthlayout() {
    const location = useLocation();
    const isSignUp = location.pathname === Navigation.Routes.SIGNUP.path;

    return (
        <LayoutWithLogo
            classNames={{
                parent: "tk-company-unauthlayout",
                right: isSignUp ? "signup" : ""
            }}
            styles={
                isSignUp
                    ? {
                          divider: {
                              marginTop: "1rem"
                          }
                      }
                    : {}
            }
        >
            <Outlet />
        </LayoutWithLogo>
    );
}

export default CompanyUnauthlayout;
