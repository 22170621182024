import React, { useState } from "react";
import PropTypes from "prop-types";
import HorizontalIcon from "@mui/icons-material/MoreHoriz";
import VericalIcon from "@mui/icons-material/MoreVert";
import Popover, { POPOVER_POSITION } from "../extra/Popover";
import Button, { BTN_TYPE } from "../extra/Button";

function More({ isVertical, items = [], onSelect, styles, isButton, buttonProps, position = POPOVER_POSITION.LEFT }) {
    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const content = (
        <ul className="tk-more__items" style={styles?.items || {}}>
            {items.map((item, idx) => {
                return (
                    <li key={idx} onClick={() => onSelect?.(item, idx)}>
                        <div className="flex gap-05">
                            {item.icon} {item.render}
                        </div>
                    </li>
                );
            })}
        </ul>
    );

    const renderDefault = isVertical ? <VericalIcon className="main-icon" /> : <HorizontalIcon className="main-icon" />;

    return (
        <Popover content={content} position={position} styles={{ content: { width: "max-content" }, parent: {} }} onChange={setDropdownOpen} isPortal>
            <div className={`tk-more flex ${(isDropdownOpen && "active") || ""}`.trim()} style={styles?.parent || {}}>
                {isButton ? (
                    <Button afterExtra={renderDefault} {...(buttonProps || {})} mini small>
                        More
                    </Button>
                ) : (
                    renderDefault
                )}
            </div>
        </Popover>
    );
}

export default More;

More.propTypes = {
    isVertical: PropTypes.bool,
    isButton: PropTypes.bool,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            render: PropTypes.any
        })
    ),
    onSelect: PropTypes.func,
    styles: PropTypes.shape({
        parent: PropTypes.object,
        items: PropTypes.object
    }),
    buttonProps: PropTypes.shape({
        small: PropTypes.bool,
        transparent: PropTypes.bool,
        className: PropTypes.string,
        type: PropTypes.oneOf([0, ...Object.values(BTN_TYPE)]),
        children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
        beforeExtra: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
        afterExtra: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
        disabled: PropTypes.bool,
        isLoading: PropTypes.bool,
        onClick: PropTypes.func,
        options: PropTypes.object,
        mini: PropTypes.bool,
        noLabel: PropTypes.bool,
        tooltipProps: PropTypes.object
    }),
    position: PropTypes.oneOf(Object.values(POPOVER_POSITION))
};
