import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as EditSVG } from "../../../assets/images/pencil.svg";

function FormSection({ className, header, onEdit, children, nohead, style }) {
    return (
        <section className={`tk-form-section ${className || ""}`.trim()} style={style || {}}>
            {!nohead && (
                <div className="tk-form-section__header">
                    {header || "Details"}
                    {onEdit && (
                        <div className="edit">
                            <EditSVG onClick={onEdit} />
                        </div>
                    )}
                </div>
            )}
            <div className="tk-form-section__body">
                <div className="tk-form-section__inner">{children}</div>
            </div>
        </section>
    );
}

FormSection.propTypes = {
    nohead: PropTypes.bool,
    className: PropTypes.string,
    onEdit: PropTypes.func,
    style: PropTypes.object,
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node])
};

export default FormSection;
