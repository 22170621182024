import React from "react";
import { Link } from "react-router-dom";
import { useCompanyStatusCheck, useGetEIDTimeRemaining } from "./hooks";
import Navigation from "../../../common/classes/Navigation";
import { TABS } from "../../../pages/Settings";

function EIDExpiring() {
    const check = useCompanyStatusCheck();
    const eidTimeRemaining = useGetEIDTimeRemaining();

    const isProfile = location.pathname == Navigation.Routes.SETTINGS.path && location.hash == "#profile";
    const isExpired = eidTimeRemaining.isExpired;
    const showGotoProfile = !check.isSubscriptionExpired && !check.isSettingsSetup && !isProfile;

    const defaultMsg = (
        <span>
            Your <strong>Establishment ID</strong> is expiring in {eidTimeRemaining.text}. Please upload a new Establishment ID to ensure
            uninterrupted service.{" "}
            {showGotoProfile && (
                <Link key={3} to={Navigation.Routes.SETTINGS.path + "#" + TABS.PROFILE.id}>
                    Click to go to Profile
                </Link>
            )}
        </span>
    );

    const expMsg = (
        <span>
            Your <strong>Establishment ID</strong> is expired. Update your Establishment ID in the profile section if you want uninterrupted service
            after your subscription ends.{" "}
            {showGotoProfile && (
                <Link key={3} to={Navigation.Routes.SETTINGS.path + "#" + TABS.PROFILE.id}>
                    Click to go to Profile
                </Link>
            )}
        </span>
    );

    if (!eidTimeRemaining.showWarning) {
        return <></>;
    }

    return (
        <div className="flex w100 gap-1 small-font semi-bold center" style={{ textAlign: "center" }}>
            {isExpired ? expMsg : defaultMsg}
        </div>
    );
}

export default EIDExpiring;
