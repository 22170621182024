import moment from "moment-timezone";
import { toTimeWithTimeZone } from "../../../common/utilities/helper";

export const isDateRangeSame = (startDate, endDate, timezone) => {
    if (!startDate || !endDate) {
        return false;
    }
    const startWithTz = toTimeWithTimeZone(startDate, timezone).startOf("day");
    const endWithTz = toTimeWithTimeZone(endDate, timezone).startOf("day");
    const isSame = moment(startWithTz.format()).isSame(endWithTz.format());

    return isSame;
};
