import PropTypes from "prop-types";
import React, { Component } from "react";
import ArrowDown from "@mui/icons-material/ExpandMore";
import ClearIcon from "@mui/icons-material/Clear";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import MenuList, { MENU_EVENT_TYPE } from "./MenuList";
import Popover, { POPOVER_POSITION } from "../Popover";

const IGNORE_CLASSES = [
    "tk-dropdown-menu__option",
    "tk-dropdown-menu__option__inner",
    "tk-dropdown-menu__option__label",
    "tk-dropdown-menu__option__extra"
];

export const getDropdownOptionValue = ({ options, depthLevel, value, isRaw }) => {
    const result = {};

    const findOption = (opts, depth) => {
        for (const option of opts) {
            if (option.value === value) {
                result[depth] = {
                    depthLevel: depth,
                    value: option.value,
                    ...(isRaw ? option : {})
                };
                return true;
            }
            if (option.submenu) {
                result[depth] = {
                    depthLevel: depth,
                    value: option.value,
                    ...(isRaw ? option : {})
                };
                if (findOption(option.submenu, depth + 1)) {
                    return true;
                }
            }
        }
        return false;
    };

    findOption(options, 0);

    if (!result[depthLevel]) {
        return {};
    }

    const selected = Object.values(result).pop();

    return {
        value: result,
        selected: {
            value: selected.value,
            depthLevel: selected.depthLevel
        }
    };
};

export default class Dropdown extends Component {
    static propTypes = {
        label: PropTypes.any,
        value: PropTypes.shape({
            depthLevel: PropTypes.number,
            value: PropTypes.any
        }),
        defaultValue: PropTypes.shape({
            depthLevel: PropTypes.number,
            value: PropTypes.any
        }),
        rounded: PropTypes.bool,
        mini: PropTypes.bool,
        isPortal: PropTypes.bool,
        isClearable: PropTypes.bool,
        onChange: PropTypes.func,
        prefix: PropTypes.any,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.any,
                value: PropTypes.any,
                submenu: PropTypes.oneOfType([
                    PropTypes.array,
                    PropTypes.shape({
                        content: PropTypes.any
                    })
                ])
            })
        )
    };

    state = {
        open: false,
        depths: {}
    };

    constructor(props) {
        super(props);
        this.state = {
            depths: this.defaultValue.value
        };
    }

    get defaultValue() {
        // default value is prio since this is just for the initial value incase the select becomes empty
        const value = this.props.defaultValue || this.props.value;
        if (!value) return { value: {}, selected: {} };
        return getDropdownOptionValue({
            options: this.props.options,
            depthLevel: value.depthLevel,
            value: value.value,
            isRaw: true
        });
    }

    get isValueOnDefault() {
        return this.props.value && this.defaultValue.selected.value == this.props.value.value;
    }

    componentDidUpdate(prevProps) {
        if (this.isValueOnDefault && !isEqual(prevProps.value, this.props.value)) {
            if (!isEqual(this.defaultValue.value, this.state.depths)) {
                this.setState({ depths: this.defaultValue.value });
            }
        }
    }

    closeMainMenu() {
        this.setState({ open: false });
    }

    getCurrentDepth() {
        return Object.keys(this.state.depths || {}).length;
    }

    updateDepth(depthLevel, option, type) {
        this.setState(
            (prev) => {
                const cloneDepth = cloneDeep(prev.depths);
                if (type == MENU_EVENT_TYPE.REMOVE) {
                    delete cloneDepth[depthLevel];
                } else {
                    cloneDepth[depthLevel] = option;
                }
                return { depths: this.sanitizeDepthOnUpdate(cloneDepth) };
            },
            () => {
                if (type != MENU_EVENT_TYPE.REMOVE) {
                    !option?.submenu && this.closeMainMenu();
                    this.props.onChange?.(this.toReadableDepths(this.state.depths), {
                        value: option.value,
                        depthLevel,
                        hasSubmenu: !!option?.submenu
                    });
                }
            }
        );
    }

    sanitizeDepthOnUpdate(depths) {
        const idx = Object.values(depths).findIndex((depth) => !depth.submenu);
        const newDepths = Object.keys(depths)
            .filter((_, i) => i <= idx)
            .reduce((prev, curr) => ({ ...prev, [curr]: depths[curr] }), {});
        return newDepths;
    }

    toReadableDepths(depths = this.state.depths) {
        return Object.keys(depths)
            .map((key) => ({ depthLevel: depths[key].depthLevel, value: depths[key].value }))
            .reduce((prev, depth) => ({ ...prev, [depth.depthLevel]: depth }), {});
    }

    createClassName(className = "tk-dropdown ") {
        if (this.state.open) {
            className += "active ";
        }
        if (this.props.rounded) {
            className += "rounded ";
        }
        if (this.props.mini) {
            className += "mini ";
        }
        return className.trim();
    }

    createLabel() {
        const label = this.props.label;
        const depths = this.state.depths;
        if (depths && Object.keys(depths).length && !label) {
            const labels = Object.values(depths).map((depth, index) => {
                return (
                    <span key={`${depth.depthLevel}-${depth.value}`}>
                        {depth.label}
                        {index < Object.values(depths).length - 1 && <> &#8594; </>}
                    </span>
                );
            });
            return labels;
        } else {
            return <span className="fade">{label || "Select an Option"}</span>;
        }
    }

    handlePreventOpenTrigger(e) {
        const target = e.target;
        return target.classList.contains("clear-icon");
    }

    handlePreventOutsideTrigger(e) {
        return IGNORE_CLASSES.some((className) => e.target.classList.contains(className));
    }

    handleReset() {
        this.setState({ depths: this.defaultValue.value }, () => {
            this.closeMainMenu();
            this.props.onChange?.(this.toReadableDepths(this.defaultValue.value), this.defaultValue.selected);
        });
    }

    handleClose() {
        if (!this.getCurrentDepth()) {
            this.handleReset();
        }
    }

    render() {
        const hideClear = !this.props.isClearable || !this.getCurrentDepth() || this.isValueOnDefault;
        return (
            <Popover
                open={this.state.open}
                content={
                    <MenuList
                        depthLevel={0}
                        depths={this.state.depths}
                        options={this.props.options}
                        onChange={(depthLevel, option, type) => this.updateDepth(depthLevel, option, type)}
                        mini={this.props.mini}
                        isPortal={this.props.isPortal}
                    />
                }
                position={POPOVER_POSITION.BOTTOM}
                styles={{
                    parent: { width: this.props.isPortal ? "unset" : "100%" },
                    content: { width: "max-content", marginTop: ".5rem", padding: "0" }
                }}
                onChange={(open) => this.setState({ open })}
                onPreventOpenTrigger={this.handlePreventOpenTrigger.bind(this)}
                onPreventOutsideTrigger={this.handlePreventOutsideTrigger.bind(this)}
                isPortal={this.props.isPortal}
                onClose={this.handleClose.bind(this)}
                noArrow
                unCentered
            >
                <div className={this.createClassName()}>
                    {this.props.prefix && (
                        <>
                            <div className="tk-dropdown__prefix fade">{this.props.prefix}</div>
                            <div className="tk-dropdown__divider"></div>
                        </>
                    )}
                    <div className="tk-dropdown__inner">{this.props.label || this.createLabel()}</div>
                    <div className="tk-dropdown__divider"></div>
                    <div className="tk-dropdown__controls flex align-center">
                        {!hideClear && (
                            <ClearIcon
                                className={`clear-icon hover-svg ${!this.getCurrentDepth() || this.isValueOnDefault ? "disabled" : ""}`.trim()}
                                onClick={this.handleReset.bind(this)}
                            />
                        )}
                        <ArrowDown className="arrow-icon hover-svg" />
                    </div>
                </div>
            </Popover>
        );
    }
}
