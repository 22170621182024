import React from "react";
import PreviewIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { createConfirmAlert, renderNA, sanitizeTimestamp, sanitizeWords, toReadableFromDate } from "../../../common/utilities/helper";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import { TABLE_ACTION_TYPE_COMMON } from "../../../common/utilities/const";
import Tag from "../../../common/components/extra/Tag";
import MyTooltip from "../../../common/components/extra/Tooltip";
import NotifyButton from "./NotifyButton";

const HEADERS = {
    TITLE: "title",
    LAST_PUBLISH: "last_publish_date",
    UPDATED_AT: "updatedAt",
    CREATED_AT: "createdAt",
    ACTIONS: "actions"
};

const HEADERS_TO_ARRAY = Object.values(HEADERS);

const CreateTableHeaders = ({ onAction }) => {
    const user = useAppSelector(selectUser);
    const setting = user.Setting;
    return HEADERS_TO_ARRAY.map((head) => {
        const temp = { key: head, style: {} };
        switch (head) {
            case HEADERS.TITLE: {
                temp.sortKey = "index1";
                temp.childStyle = {
                    alignItems: "center",
                    gap: "1rem",
                    margin: ".5rem 0"
                };
                temp.label = "Title/Description";
                temp.style.width = "20rem";
                temp.fixed = true;
                temp.render = (_, row) => {
                    return (
                        <>
                            <div className="flex gap-05 column w100" style={{ justifyContent: "center" }}>
                                <div className="flex gap-05 align-center">
                                    <div style={{ fontWeight: "bold" }}>{sanitizeWords(row.title)}</div>
                                    <Tag>{sanitizeWords(row.type)}</Tag>
                                </div>
                                <MyTooltip
                                    element="span"
                                    className="fade small-font text-ellipsis w100"
                                    message={<div style={{ maxWidth: "20rem" }}>{row.description}</div>}
                                >
                                    {row.description}
                                </MyTooltip>
                            </div>
                        </>
                    );
                };
                break;
            }
            case HEADERS.LAST_PUBLISH: {
                temp.sortKey = "last_published_date";
                temp.label = "Last Publish";
                temp.render = (_, row) => (
                    <div className="flex column gap-05 overflow-hidden fade">
                        <span className="text-ellipsis small-font">
                            {toReadableFromDate(row.last_published_date, setting.timezone) || renderNA("Not Published")}
                        </span>
                    </div>
                );
                break;
            }
            case HEADERS.UPDATED_AT: {
                temp.sortKey = "updatedAt";
                temp.label = "Updated";
                temp.render = (_, row) => (
                    <div className="flex column gap-05 overflow-hidden fade">
                        <span className="text-ellipsis small-font">
                            {sanitizeTimestamp(row?.updatedAt, row?.createdAt, setting.timezone) || renderNA()}
                        </span>
                    </div>
                );
                break;
            }
            case HEADERS.CREATED_AT: {
                temp.sortKey = "createdAt";
                temp.label = "Created";
                temp.render = (_, row) => (
                    <div className="flex column gap-05 overflow-hidden fade">
                        <span className="text-ellipsis small-font">{toReadableFromDate(row.createdAt, setting.timezone)}</span>
                    </div>
                );
                break;
            }
            case HEADERS.ACTIONS: {
                temp.childStyle = {
                    display: "flex",
                    gap: ".5rem",
                    justifyContent: "flex-end",
                    paddingRight: "1rem",
                    overflow: "unset"
                };
                temp.render = (_, row) => {
                    return (
                        <>
                            <NotifyButton id={row.id} lastPublishedDate={row.last_published_date} onlyIcon />
                            <PreviewIcon onClick={() => onAction(row, TABLE_ACTION_TYPE_COMMON.PREVIEW)} />
                            <DeleteIcon
                                style={{ color: "red" }}
                                className="hover-svg"
                                onClick={() => {
                                    createConfirmAlert({
                                        title: "Remove Announcement",
                                        content: "Are you sure you want to remove this announcement? This cannot be undone.",
                                        onConfirm: (close) => {
                                            close();
                                            typeof onAction === "function" && onAction(row, TABLE_ACTION_TYPE_COMMON.REMOVE);
                                        }
                                    });
                                }}
                            />
                        </>
                    );
                };
                break;
            }
            default:
                break;
        }
        return temp;
    });
};

export default CreateTableHeaders;
