// eslint-disable-next-line no-unused-vars
import Quill from "quill";
import { ImageDrop } from "./ImageDrop";
import { downscaleImage, file2b64, b64toBlob, validate } from "./helper";

/**
 * @typedef {Object} Option
 * @property {Number} Option.quality
 * @property {Number} Option.maxWidth
 * @property {Number} Option.maxHeight
 * @property {String} Option.imageType
 * @property {Boolean} Option.handleOnPaste
 * @property {Boolean} Option.keepImageTypes
 * @property {Boolean} Option.ignoreImageTypes
 * @property {Function} Option.insertIntoEditor
 */

/**
 * @description
 * Based on: https://github.com/benwinding/quill-image-compress
 */
export class ImageCompressor {
    quill;
    range;
    options;
    imageDrop;
    fileHolder;

    /**
     *
     * @param {Quill} quill
     * @param {*} options
     */
    constructor(quill, options) {
        this.quill = quill;
        this.options = options || {};

        const onImageDrop = async (dataUrl) => {
            const dataUrlCompressed = await this.downscaleImageFromUrl(dataUrl);
            this.insertToEditor(dataUrlCompressed, b64toBlob(dataUrlCompressed));
        };
        this.imageDrop = new ImageDrop(quill, onImageDrop, this.options.handleOnPaste !== false, this.options.size);
        const toolbar = this.quill.getModule("toolbar");
        if (toolbar) {
            toolbar.addHandler("image", () => this.selectLocalImage());
        }
    }

    selectLocalImage(onFileChanged) {
        this.range = this.quill.getSelection();
        this.fileHolder = document.createElement("input");
        this.fileHolder.setAttribute("type", "file");
        this.fileHolder.setAttribute("accept", "image/*");
        this.fileHolder.setAttribute("style", "visibility:hidden");

        this.fileHolder.onchange = () => this.fileChanged().then(() => onFileChanged && onFileChanged());

        document.body.appendChild(this.fileHolder);

        this.fileHolder.click();

        window.requestAnimationFrame(() => {
            this.fileHolder && document.body.removeChild(this.fileHolder);
        });
    }

    async fileChanged(externallyProvidedFiles) {
        const files = externallyProvidedFiles || this.fileHolder?.files;
        if (!files || !files.length) {
            return;
        }
        const file = files[0];
        if (!file) {
            return;
        }
        const defaultType = file.type;
        const hasError = await validate([file], { defaultSize: this.options.size });
        if (hasError) {
            return;
        }
        this.options.imageType = this.options.imageType || defaultType;
        const base64ImageSrc = await file2b64(file);
        const base64ImageSmallSrc = await this.downscaleImageFromUrl(base64ImageSrc);
        this.insertToEditor(base64ImageSmallSrc, b64toBlob(base64ImageSmallSrc));
    }

    async downscaleImageFromUrl(dataUrl) {
        const dataUrlCompressed = await downscaleImage(
            dataUrl,
            this.options.maxWidth,
            this.options.maxHeight,
            this.options.imageType,
            this.options.keepImageTypes,
            this.options.ignoreImageTypes,
            this.options.quality
        );
        return dataUrlCompressed;
    }

    insertToEditor(url, blob) {
        if (this.options.insertIntoEditor) {
            this.options.insertIntoEditor(url, blob, this.quill);
        } else {
            this.range = this.quill.getSelection();
            const range = this.range;
            if (!range) {
                return;
            }
            // Insert the compressed image
            this.quill.insertEmbed(range.index, "image", `${url}`, "user");
            // Move cursor to next position
            range.index++;
            this.quill.setSelection(range, "api");
        }
    }
}

export default ImageCompressor;
