import React, { useState } from "react";
import PropTypes from "prop-types";
import { createClass, createConfirmAlert, createGroup, sanitizeWords } from "../../../common/utilities/helper";
import ViewGeozonesModal from "../companySites/ViewGeozonesModal";
import Input from "../../../common/components/extra/form/Input";
import SectionCollapseError from "../../../common/components/extra/section/SectionCollapseError";
import SectionCollapseWarning from "../../../common/components/extra/section/SectionCollapseWarning";
import SiteSelectLazy from "../companySites/SiteSelectLazy";
import { BASE_CLASS, FIELDS } from "./const";
import { useUpsertWorkDetails } from "./hooks";
import BaseUpdateModal from "../../../common/components/layout/modalViewUpdateLayout/BaseUpdateModal";
import { WORK_TYPE } from "../../../common/utilities/const";
import SelectConstant from "../../../common/components/extra/select/SelectConstant";

const { NAME, CODE, TYPE, COMPANY_SITES } = FIELDS;

function UpdateModal({ open, onChange, onClose, onBack, id, onFinish, nosite, readOnly, transparentOverlay }) {
    const [viewObject, setViewObject] = useState({ type: null, data: null });

    const [form, updateForm, { upsert, isLoading, isGettingDetails, config, hasChanges, isEditAllowed, allowedTime }] = useUpsertWorkDetails(id);
    const handleViewChange = (newObject = {}) => setViewObject({ ...viewObject, ...newObject });

    const isCreate = !id;
    const { invalidSiteCount, isIncomplete } = config;

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const config = { ...form };
        config[name] = value;
        if (name === COMPANY_SITES.name) {
            config.siteIds = value?.map((cs) => cs.id) || [];
        }
        updateForm(config);
    };

    const handleSave = async () => {
        const result = await upsert();
        result && typeof onFinish === "function" && onFinish(result, isCreate);
    };

    return (
        <BaseUpdateModal
            open={open}
            onChange={onChange}
            onClose={onClose}
            onBack={(!isCreate && onBack) || null}
            onSave={
                readOnly
                    ? null
                    : () =>
                          createConfirmAlert({
                              title: !isCreate ? "Update Work Type" : "Create Work Type",
                              content: `Are you sure you want to ${isCreate ? "create" : "update"} this work type? This cannot be undone.`,
                              onConfirm: async (close) => {
                                  close();
                                  await handleSave();
                              }
                          })
            }
            disableSave={invalidSiteCount || hasChanges}
            styles={{ content: { width: "50vw", maxWidth: "45rem", minWidth: "40rem", height: "max-content" } }}
            isLoading={isLoading}
            isForm={!readOnly}
            transparentOverlay={transparentOverlay}
        >
            <div className={createClass(" flex column gap-05", BASE_CLASS)}>
                {!readOnly && !isGettingDetails && (
                    <div className="flex column gap-05" style={{ marginBottom: "2rem" }}>
                        <SectionCollapseWarning show={!isGettingDetails && (isIncomplete || (!isCreate && !isEditAllowed))}>
                            {isIncomplete ? (
                                <span>Incomplete fields, must have at least one site to be able to use.</span>
                            ) : (
                                <span>
                                    Editing capabilities are limited while the work type is active. Editing will be available before{" "}
                                    {allowedTime.before}
                                    &nbsp;or after {allowedTime.after}.
                                </span>
                            )}
                        </SectionCollapseWarning>
                        <SectionCollapseError show={invalidSiteCount}>Office and Branch can only have one site.</SectionCollapseError>
                    </div>
                )}
                <div className={createClass("__inner", BASE_CLASS)}>
                    <div className={createClass("__inner-row", BASE_CLASS)}>
                        {createGroup({
                            base: BASE_CLASS,
                            title: "Work Type Information",
                            body: (
                                <>
                                    <Input
                                        label={NAME.label}
                                        name={NAME.name}
                                        onChange={handleChange}
                                        value={sanitizeWords(form[NAME.name]) || ""}
                                        required={NAME.required}
                                        readOnly={readOnly}
                                        renderValue={readOnly && sanitizeWords(form[NAME.name])}
                                        isLoading={isGettingDetails}
                                    />
                                    <Input
                                        label={CODE.label}
                                        name={CODE.name}
                                        onChange={handleChange}
                                        value={form[CODE.name] || ""}
                                        required={CODE.required}
                                        readOnly={readOnly}
                                        renderValue={readOnly && sanitizeWords(form[CODE.name])}
                                        isLoading={isGettingDetails}
                                    />
                                    <SelectConstant
                                        label={TYPE.label}
                                        name={TYPE.name}
                                        base={WORK_TYPE}
                                        value={form[TYPE.name]}
                                        onChange={(val) =>
                                            handleChange({
                                                target: {
                                                    name: TYPE.name,
                                                    value: val.value
                                                }
                                            })
                                        }
                                        menuPlacement="bottom"
                                        required={TYPE.required}
                                        readOnly={readOnly}
                                        menuPortalTarget={document.body}
                                        isLoading={isGettingDetails}
                                        isOutlined
                                        disabledOutline
                                    />
                                    {!nosite && (
                                        <SiteSelectLazy
                                            label={COMPANY_SITES.label}
                                            name={COMPANY_SITES.name}
                                            onChange={(val) =>
                                                handleChange({
                                                    target: {
                                                        name: COMPANY_SITES.name,
                                                        value: val
                                                    }
                                                })
                                            }
                                            value={form[COMPANY_SITES.name]}
                                            isDisabled={!isEditAllowed || readOnly}
                                            isLoading={isGettingDetails}
                                            menuPortalTarget={document.body}
                                            isMulti
                                            isOutlined
                                            disabledOutline
                                        />
                                    )}
                                </>
                            )
                        })}
                    </div>
                </div>
            </div>
            {!!viewObject.type && (
                <ViewGeozonesModal
                    open={!!viewObject.type}
                    onChange={(bool) => handleViewChange({ type: bool ? viewObject.type : null })}
                    type={viewObject.type}
                    data={viewObject.data}
                />
            )}
        </BaseUpdateModal>
    );
}

UpdateModal.propTypes = {
    open: PropTypes.bool,
    id: PropTypes.any,
    onChange: PropTypes.func,
    onFinish: PropTypes.func,
    onBack: PropTypes.func,
    nosite: PropTypes.bool,
    onClose: PropTypes.func,
    readOnly: PropTypes.bool,
    transparentOverlay: PropTypes.bool
};

export default UpdateModal;
