import React, { useState } from "react";
import Employees from "../../features/company/employees/Employees";
import { selectLoading, selectTableConfig } from "../../features/company/employees/slice";
import { useAppSelector } from "../../common/hooks/reduxHooks";
import PageLayout from "../../common/components/layout/PageLayout";
import ModalSubscriptionPlan from "../../features/admin/subscriptions/ModalSubscriptionPlan";
import { useCheckEmployeeLimitReached, useCompanyStatusCheck } from "../../features/company/auth/hooks";
import { usePaginateEmployees } from "../../features/company/employees/hooks";
import Filter from "../../features/company/employees/Filter";

function EmployeesPage() {
    const [openAddModal, setOpenAddModal] = useState(false);

    const { limitReached } = useCheckEmployeeLimitReached();
    const [, { isSearching, onFilter, onSearch }] = usePaginateEmployees({ readOnly: true });

    const loading = useAppSelector(selectLoading);
    const config = useAppSelector(selectTableConfig);
    const check = useCompanyStatusCheck();

    const isRenewUpgradeDisabled = check.isRenewUpgradeDisabled;
    const openEmployeeModal = openAddModal && !limitReached;
    const showPromotion = openAddModal && limitReached;

    const handleAdd = () => setOpenAddModal(true);

    return (
        <PageLayout
            className="tk-employees"
            title="Manage Employees"
            isLoading={loading}
            search={{ onSearch, value: config.search }}
            filter={<Filter onFilter={onFilter} isLoading={isSearching} />}
            onAdd={isRenewUpgradeDisabled ? null : handleAdd}
            styles={{
                body: {
                    height: "calc(100vh - 20.5rem)"
                }
            }}
            hasFilter
        >
            <Employees openAddModal={openEmployeeModal} onAddModalClose={() => !limitReached && setOpenAddModal(false)} />
            {showPromotion && <ModalSubscriptionPlan open={openAddModal} onClose={() => setOpenAddModal(false)} />}
        </PageLayout>
    );
}

export default EmployeesPage;
