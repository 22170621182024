import React from "react";
import PreviewIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { createConfirmAlert, getCountryName } from "../../../common/utilities/helper";
import { FIELDS } from "./const";
import MyTooltip, { PLACES } from "../../../common/components/extra/Tooltip";
import Button from "../../../common/components/extra/Button";
import { TABLE_ACTION_TYPE_COMMON } from "../../../common/utilities/const";

const { PREVIEW, REMOVE } = TABLE_ACTION_TYPE_COMMON;

const HEADERS = {
    TITLE: FIELDS.TITLE.name,
    COUNTRY: FIELDS.COUNTRY.name,
    ACTIONS: ""
};

const HEADERS_TO_ARRAY = Object.values(HEADERS);

const CreateTableHeaders = ({ onAction }) => {
    return HEADERS_TO_ARRAY.map((head) => {
        const temp = { key: head, style: { width: `${100 / HEADERS_TO_ARRAY.length}%` } };
        switch (head) {
            case HEADERS.TITLE: {
                temp.sortKey = "id";
                temp.label = FIELDS.TITLE.label;
                temp.render = (val) => (
                    <div className="flex column gap-05">
                        <span className="bold capitalize">{val}</span>
                    </div>
                );
                break;
            }
            case HEADERS.COUNTRY: {
                temp.sortKey = HEADERS.COUNTRY;
                temp.label = FIELDS.COUNTRY.label;
                temp.center = true;
                temp.render = (val) => getCountryName(val);
                break;
            }
            case HEADERS.ACTIONS: {
                temp.childStyle = {
                    display: "flex",
                    gap: ".5rem",
                    justifyContent: "flex-end",
                    paddingRight: "1rem"
                };
                temp.render = (_, row) => (
                    <>
                        <PreviewIcon onClick={() => typeof onAction === "function" && onAction(row, PREVIEW)} />
                        <MyTooltip position={PLACES.LEFT} message={!!row.usageCount && `Used by ${row.usageCount} Employees.`}>
                            <Button
                                onClick={() =>
                                    createConfirmAlert({
                                        title: "Remove Penalty",
                                        content: "Are you sure? This cannot be undone.",
                                        onConfirm: (onClose) => {
                                            typeof onAction === "function" && onAction(row, REMOVE);
                                            onClose();
                                        }
                                    })
                                }
                                options={{ style: { padding: 0, boxShadow: "unset", marginLeft: "auto", maxWidth: "max-content" } }}
                                disabled={!!row.usageCount}
                                transparent
                            >
                                <DeleteIcon className="hover-svg" style={{ color: "red", width: "1em" }} />
                            </Button>
                        </MyTooltip>
                    </>
                );
                break;
            }
            default:
                break;
        }
        return temp;
    });
};

export default CreateTableHeaders;
