import React, { forwardRef, useEffect, useLayoutEffect, useRef, useState } from "react";
import Quill from "quill";
import PropTypes from "prop-types";
import ImageCompressor from "./ImageCompressor";
import ImageResize from "./imageResize";
import MaxLength from "./MaxLength";
import IndentAttributor from "./IndentAttributor";

const Parchment = Quill.import("parchment");

// preserve text-indent attribute // currently indention toolbar is removed
const indent = new Parchment.StyleAttributor("text-indent", "text-indent");
// convert class to text-indent style approach
const IndentStyle = new IndentAttributor("indent", "text-indent", {
    scope: Parchment.Scope.BLOCK,
    whitelist: ["1em", "2em", "3em", "4em", "5em", "6em", "7em", "8em", "9em"]
});
Quill.register(indent, true);
Quill.register(IndentStyle, true);

Quill.register(Quill.import("attributors/style/direction"), true);
Quill.register(Quill.import("attributors/style/align"), true);
Quill.register("modules/maxlength", MaxLength);
Quill.register("modules/imageResize", ImageResize);
Quill.register("modules/imageCompressor", ImageCompressor);

const DEFAULT_LIMIT = 1000;
const HEADER = [{ header: [1, 2, 3, 4, false] }];
const TYPOGRAPHY = ["bold", "italic", "underline"];
const LIST = [{ list: "ordered" }, { list: "bullet" }];
const UPLOAD = ["image"];
const POSITION = [{ align: null }, { align: "center" }, { align: "right" }, { align: "justify" }];
const QUOTE = ["blockquote", "code-block"];
const MISC = ["link", { color: [] }, { background: [] }];

const FULL_TOOLBAR = [HEADER, TYPOGRAPHY, POSITION, LIST, QUOTE, MISC, UPLOAD];
const WITHOUT_UPLOAD_TOOLBAR = [HEADER, TYPOGRAPHY, POSITION, LIST, QUOTE, MISC];

/**
 * @description
 * !NOTE: Observe issue @ https://github.com/slab/quill/pull/4319
 * Currently white space is not being preserved when viewing an html with whitespaces
 */
const Editor = forwardRef(
    (
        {
            readOnly,
            defaultValue,
            onTextChange,
            onSelectionChange,
            onRenderChange,
            limit,
            includeImageUpload,
            uploadConfig,
            placeholder,
            hasHTMLwrapper
        },
        ref
    ) => {
        const [isMounted, setMounted] = useState(false);

        const CHAR_LIMIT = (limit || DEFAULT_LIMIT) + 1;

        const containerRef = useRef(null);
        const defaultValueRef = useRef(defaultValue);
        const onTextChangeRef = useRef(onTextChange);
        const onSelectionChangeRef = useRef(onSelectionChange);

        const getToolbar = ({ hasImage } = {}) => (hasImage ? FULL_TOOLBAR : WITHOUT_UPLOAD_TOOLBAR);

        const sanitizeResult = (instance) => {
            const semantic = instance.getSemanticHTML();
            const htmlContent = `
            <!DOCTYPE html>
            <html>
            <head>
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <style>
                    .tk-an-preview {
                        padding: 12px 15px;
                        font-family: Inter, Tahoma, Geneva, Verdana, Arial, sans-serif;
                        word-wrap: break-word;
                        tab-size: 4;
                        line-height: 1.42;
                        font-size: 16px;
                    }
            
                    .tk-an-preview h1 {
                        font-size: 24px;
                    }
            
                    .tk-an-preview h2 {
                        font-size: 20px; 
                    }
            
                    .tk-an-preview h3 {
                        font-size: 18px;
                    }
            
                    .tk-an-preview h4 {
                        font-size: 16px; 
                    }
            
                    .tk-an-preview h5 {
                        font-size: 14px;
                    }
            
                    .tk-an-preview h6 {
                        font-size: 12px;
                    }
            
                    .tk-an-preview b,
                    .tk-an-preview strong {
                        font-weight: bolder;
                        font-size: inherit;
                    }
                    
                    .tk-an-preview p {
                        font-size: 16px;
                        white-space: pre-wrap
                    }
                </style>
            </head>
            <body>
                <div class="tk-an-preview">
                    ${semantic}
                </div>
            </body>
            </html>`.trim();

            return {
                htmlContent: hasHTMLwrapper ? htmlContent : semantic,
                textContent: instance.getText().replace("\n", ""),
                semantic
            };
        };

        const handleTextChange = (quill, delta) => {
            const currentLength = quill.getLength();
            const { htmlContent, textContent, semantic } = sanitizeResult(quill);
            const totalChars = CHAR_LIMIT - currentLength;

            onTextChangeRef.current?.(
                delta,
                {
                    html: htmlContent,
                    text: textContent,
                    semantic
                },
                totalChars > 0 ? 0 : totalChars
            );
        };

        useLayoutEffect(() => {
            onTextChangeRef.current = onTextChange;
            onSelectionChangeRef.current = onSelectionChange;
        });

        useEffect(() => {
            setMounted(true);
        }, []);

        useEffect(() => {
            ref?.current?.enable(!readOnly);
        }, [ref, readOnly]);

        useEffect(() => {
            if (isMounted) {
                const container = containerRef.current;
                const editorContainer = container.appendChild(container.ownerDocument.createElement("div"));
                const option = {
                    theme: "snow",
                    modules: {
                        toolbar: getToolbar({ hasImage: includeImageUpload }),
                        imageResize: {},
                        maxlength: { value: CHAR_LIMIT },
                        history: { delay: 100, userOnly: true }
                    }
                };

                if (placeholder) {
                    option.placeholder = placeholder;
                }

                if (includeImageUpload) {
                    option.modules.imageCompressor = {
                        quality: 0.9,
                        maxWidth: 500,
                        maxHeight: 500,
                        size: uploadConfig?.image?.size || 1
                    };
                }

                const quill = new Quill(editorContainer, option);

                "current" in ref && (ref.current = quill);

                if (defaultValueRef.current) {
                    const delta = quill.clipboard.convert({ html: defaultValueRef.current });
                    quill.setContents(delta);
                }

                quill.on(Quill.events.TEXT_CHANGE, (delta) => handleTextChange(quill, delta));
                quill.on(Quill.events.SELECTION_CHANGE, (...args) => onSelectionChangeRef.current?.(...args));

                // clear format on paste
                quill.clipboard.addMatcher(Node.ELEMENT_NODE, function (node) {
                    const plaintext = node.innerText;
                    const Delta = Quill.import("delta");
                    return new Delta().insert(plaintext);
                });

                if (typeof onRenderChange == "function") {
                    const totalChars = quill.getLength() - 2;
                    onRenderChange(quill.container.innerText.trim(), { length: totalChars < 0 ? 0 : totalChars });
                }

                return () => {
                    ref?.current && (ref.current = null);
                    container.innerHTML = "";
                };
            }
        }, [ref, isMounted]);

        return <div className="tk-editor" ref={containerRef}></div>;
    }
);

Editor.displayName = "Editor";

export default Editor;

Editor.propTypes = {
    readOnly: PropTypes.bool,
    defaultValue: PropTypes.any,
    onTextChange: PropTypes.func,
    onSelectionChange: PropTypes.func,
    limit: PropTypes.number,
    onRenderChange: PropTypes.func,
    placeholder: PropTypes.string,
    includeImageUpload: PropTypes.bool,
    uploadConfig: PropTypes.shape({
        image: PropTypes.shape({ size: PropTypes.number })
    }),
    hasHTMLwrapper: PropTypes.bool
};
