export const VERIFY_TOKEN_CODES = {
    TRANS_PAID: "000",
    AUTHORIZED: "001",
    TRANS_OVER_UNDER_PAID: "002",
    PENDING_BANK: "003",
    QUEUE_AUTH: "005",
    PENDING_SPLIT_PAY: "007",
    MISSING_COMP_TOKEN: "801",
    INVALID_COMP_TOKEN: "802",
    NO_REQUEST: "803",
    XML_ERROR: "804",
    TRANS_NOT_PAID: "900",
    TRANS_DECLINED: "901",
    DATA_MISMATCHED: "902",
    PTL_EXPIRED: "903",
    TRANS_CANCELLED: "904"
};

export const LOCAL_STORAGE_KEYS = {
    PAYMENT: {
        key: "payment",
        default: { key: "", expires: "" }
    },
    DAILY_PROMOTION: {
        key: "daily-promotion",
        default: { date: "" }
    }
};

export const CONFIRM_PAYMENT_CONTENT_TYPE = {
    LOADING: 0x1,
    SUCCESS: 0x2,
    VERIFY_ERROR: 0x3,
    BANK_PENDING: 0x4,
    CANCELED: 0x5
};
