import React, { useEffect, useMemo, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import {
    TOAST_TYPE,
    createTextFromDate,
    createToast,
    isEqualWithDates,
    renderNA,
    sanitizeWords,
    toReadableFromDate,
    toReadableSelectOptions,
    toTimeFormat,
    toTimeWithTimeZone
} from "../../../common/utilities/helper";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import {
    selectCurrent,
    selectLoading,
    selectSearching,
    selectTableConfig,
    selectWorkHistoryData,
    setCurrent,
    setData,
    setLoading,
    setSearching,
    setState
} from "./slice";
import {
    useCreateWorkHistoryMutation,
    useDeleteWorkHistoryMutation,
    useGetDetailWorkHistoryMutation,
    useGetUpdateRecordWorkHistoryMutation,
    useLoadAllLazyWorkHistoryMutation,
    useLoadAllWorkHistoryMutation,
    useUpdateWorkHistoryMutation,
    useUpdateWorkHistoryStatusMutation
} from "./api";
import { FIELDS, INCOMPLETE_STATUS_IDENTIFIER, EDIT_TYPE } from "./const";
import { chekIncStatus, createShiftStatus, getTimeRangeValues } from "./helper";
import { TIME_TYPE } from "./const";
import {
    SHIFT_TYPE,
    SORT_ORDER,
    WORK_HISTORY_SHIFT_STATUS,
    WORK_HISTORY_SHIFT_STATUS_ABNORMALITIES,
    WORK_HISTORY_STATUS,
    WORK_HISTORY_TYPE
} from "../../../common/utilities/const";
import { selectUserSetting } from "../../common/slice";
import Tag from "../../../common/components/extra/Tag";
import { COMPANY_HOLIDAY_LEVEL, COMPANY_HOLIDAY_TYPE } from "../companyHolidays/const";

const LOAD_MORE_OFFSET = 10;
const DEFAULT_SIZE = 20;

const { BY_WORK_TYPE, BY_WORK_SITE, BY_WORK_SHIFT } = COMPANY_HOLIDAY_LEVEL;

const {
    TYPE,
    START_SHIFT_TIME,
    END_SHIFT_TIME,
    START_SHIFT_TIME_TWO,
    END_SHIFT_TIME_TWO,
    START_BREAK_TIME,
    END_BREAK_TIME,
    START_BREAK_TIME_TWO,
    END_BREAK_TIME_TWO,
    START_OVERTIME,
    END_OVERTIME,
    EMPLOYEE_ID,
    WORK_TYPE_ID,
    WORK_SHIFT_ID,
    SITE_ONE_ID,
    SITE_TWO_ID,
    OVERTIME_SITE_ID,
    NOTE
} = FIELDS;

export const useGetWorkHistory = (id) => {
    const [fetching, setFetching] = useState(true);
    const [getDetails] = useGetDetailWorkHistoryMutation();

    const dispatch = useAppDispatch();
    const current = useAppSelector(selectCurrent);
    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;

    const createVars = (data) => {
        if (!data) return {};

        const metadata = data.metadata;
        const workShift = metadata.work_shift;
        const isSplit = workShift.shift_type == SHIFT_TYPE.SPLIT;

        const firstShift = {
            ...(metadata.first_shift || {}),
            timedIn: toTimeFormat(data.start_shift_time, timezone),
            timedOut: toTimeFormat(data.end_shift_time, timezone),
            breakStart: toTimeFormat(data.start_break_time, timezone),
            breakEnd: toTimeFormat(data.end_break_time, timezone),
            timeOutStatus: sanitizeWords(metadata.first_shift.timeOutStatus),
            date: data.start_shift_time && toTimeWithTimeZone(data.start_shift_time, timezone).format("MMM DD YYYY"),
            isIncomplete: chekIncStatus(data.start_shift_time, data.end_shift_time, timezone, INCOMPLETE_STATUS_IDENTIFIER)
        };

        const secondShift = {
            ...(metadata.second_shift || {}),
            timedIn: isSplit && toTimeFormat(data.start_shift_time_2, timezone),
            timedOut: isSplit && toTimeFormat(data.end_shift_time_2, timezone),
            breakStart: isSplit && toTimeFormat(data.start_break_time_2, timezone),
            breakEnd: isSplit && toTimeFormat(data.end_break_time_2, timezone),
            timeOutStatus: isSplit && sanitizeWords(metadata.second_shift.timeOutStatus),
            date: isSplit && data.end_shift_time_2 && toTimeWithTimeZone(data.end_shift_time_2, timezone).format("MMM DD YYYY"),
            isIncomplete: chekIncStatus(data.start_shift_time_2, data.end_shift_time_2, timezone, INCOMPLETE_STATUS_IDENTIFIER)
        };

        const overtime = {
            ...(metadata.over_time || {}),
            start: toTimeFormat(data.start_overtime, timezone),
            end: toTimeFormat(data.end_overtime, timezone),
            date: secondShift.date || firstShift.date
        };

        return {
            dateText: createTextFromDate(firstShift.date, secondShift.date, timezone).date,
            fullname: sanitizeWords(data.employee.full_name),
            department: sanitizeWords(data?.employee?.department),
            designation: sanitizeWords(data?.employee?.designation),
            firstShift,
            secondShift,
            overtime,
            timedInPhoto: {
                src: data.timeInPhotoSrc,
                filename: data.timeInPhotoFileName
            },
            photo: {
                src: data.photoSrc,
                filename: data.photoFileName
            },
            workType: {
                name: sanitizeWords(metadata.work_detail.name),
                type: sanitizeWords(metadata.work_detail.type),
                code: metadata.work_detail.code || renderNA("Not Available")
            },
            workShift: {
                title: sanitizeWords(workShift.title),
                date: createTextFromDate(workShift.start_time, workShift.end_time_2, timezone).date,
                shiftOne: createTextFromDate(workShift.start_time, workShift.end_time, timezone).time,
                breakOne: createTextFromDate(workShift.break_time, workShift.break_end_time, timezone).time,
                shiftTwo: createTextFromDate(workShift.start_time_2, workShift.end_time_2, timezone).time,
                breakTwo: createTextFromDate(workShift.break_time_2, workShift.break_end_time_2, timezone).time,
                maxOT: workShift.max_overtime,
                maxBreak: workShift.max_break_duration
            },
            isSplit,
            residenceID: data?.employee?.residenceID,
            isOffdayOT: data?.type == WORK_HISTORY_TYPE.OT_OFF_DAY,
            isPending: data?.isPending,
            isApproved: data?.isApproved,
            isRejected: data?.isRejected,
            modified_by_admin_id: data?.modified_by_admin_id,
            created_by_admin_id: data?.created_by_admin_id,
            note: data?.notes,
            shiftType: workShift.shift_type,
            statuses: data.shift_status,
            recordInfo: {
                createdAt: toReadableFromDate(data.createdAt, timezone),
                updatedAt: toReadableFromDate(data.updatedAt, timezone),
                isCreatedByAdmin: !!data.created_by_admin_id
            }
        };
    };

    const fetch = async (newid, force) => {
        newid = newid || id;
        try {
            if (!force && (!newid || (current && current.id === newid))) {
                setFetching(false);
                return Promise.resolve();
            }
            const result = await getDetails({ extraPath: newid });
            if (result?.error) {
                throw new Error("Failed to fetch work history. Please try again later");
            }
            const data = cloneDeep(result.data.data);
            dispatch(setCurrent(data));
            return data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return {};
        } finally {
            setFetching(false);
        }
    };

    const updateCurrent = (newCurrent = {}) => dispatch(setCurrent({ ...current, ...(newCurrent || {}) }));
    const clearCurrent = () => dispatch(setCurrent(null));

    useEffect(() => {
        fetch();
    }, []);

    return [current, { isLoading: fetching, config: createVars(current), update: updateCurrent, fetch, clearCurrent }];
};

export const usePaginateWorkHistories = () => {
    const [isLoading, setIsLoading] = useState(true);

    const dispatch = useAppDispatch();
    const data = useAppSelector(selectWorkHistoryData);
    const tableConfig = useAppSelector(selectTableConfig);
    const searching = useAppSelector(selectSearching);

    const [load] = useLoadAllWorkHistoryMutation();

    const fetch = async (config, isReset) => {
        if (searching) {
            return;
        }
        if (!isLoading) {
            setIsLoading(true);
        }
        if (isReset) {
            config.cursor = "";
        }
        try {
            const response = await load({
                body: {
                    ...tableConfig,
                    ...(config || {}),
                    filter: { ...(tableConfig?.filter || {}), ...(config?.filter || {}) }
                }
            });
            if (response.data && response.data.data) {
                if (typeof setState === "function") {
                    const result = response.data.data;
                    const oldConfig = { ...tableConfig, ...(config || {}) };
                    dispatch(
                        setState({
                            data: result.data,
                            tableConfig: {
                                ...oldConfig,
                                totalPage: result.totalPage,
                                totalCount: result?.totalCount
                            }
                        })
                    );
                }
            }
            if (response.error) {
                throw new Error("Failed to fetch work histories. Please try again later.");
            }
            return response;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearchFetching = async () => {
        try {
            dispatch(setSearching(true));
            await fetch();
        } finally {
            dispatch(setSearching(false));
        }
    };

    useEffect(() => {
        if (!data.length) {
            fetch();
        } else {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        handleSearchFetching();
    }, [tableConfig.search]);

    const handleFilter = async (filter) => {
        try {
            dispatch(setSearching(true));
            await fetch(filter ? { filter } : {}, true);
        } finally {
            dispatch(setSearching(false));
        }
    };

    const handleUpdate = (id, newObject = {}) => {
        const newdata = data.map((record) => {
            if (record.id == id) {
                record = {
                    ...record,
                    ...(newObject || {})
                };
            }
            return record;
        });
        dispatch(setData(newdata));
    };

    return [data, { isLoading, fetch, update: handleUpdate, isSearching: searching, onFilter: handleFilter }];
};

export const useUpsertWorkHistory = (data, date, noSuccessMessage, employee, editType) => {
    const isCreate = !data;
    const updateId = data?.id;

    const [holiday, setHoliday] = useState(null);
    const [old, setOld] = useState(null);
    const [form, setForm] = useState({
        [TYPE.name]: data?.[TYPE.name] || TYPE.default,
        [START_SHIFT_TIME.name]: data?.[START_SHIFT_TIME.name] || START_SHIFT_TIME.default,
        [END_SHIFT_TIME.name]: data?.[END_SHIFT_TIME.name] || END_SHIFT_TIME.default,
        [START_SHIFT_TIME_TWO.name]: data?.[START_SHIFT_TIME_TWO.name] || START_SHIFT_TIME_TWO.default,
        [END_SHIFT_TIME_TWO.name]: data?.[END_SHIFT_TIME_TWO.name] || END_SHIFT_TIME_TWO.default,
        [START_BREAK_TIME.name]: data?.[START_BREAK_TIME.name] || START_BREAK_TIME.default,
        [END_BREAK_TIME.name]: data?.[END_BREAK_TIME.name] || END_BREAK_TIME.default,
        [START_BREAK_TIME_TWO.name]: data?.[START_BREAK_TIME_TWO.name] || START_BREAK_TIME_TWO.default,
        [END_BREAK_TIME_TWO.name]: data?.[END_BREAK_TIME_TWO.name] || END_BREAK_TIME_TWO.default,
        [START_OVERTIME.name]: data?.[START_OVERTIME.name] || START_OVERTIME.default,
        [END_OVERTIME.name]: data?.[END_OVERTIME.name] || END_OVERTIME.default,
        [NOTE.name]: data?.[NOTE.name] || NOTE.default,
        [EMPLOYEE_ID.name]: employee || data?.employee || EMPLOYEE_ID.default,
        [WORK_TYPE_ID.name]: data?.metadata?.work_detail || WORK_TYPE_ID.default,
        [WORK_SHIFT_ID.name]: data?.metadata?.work_shift || WORK_SHIFT_ID.default,
        [SITE_ONE_ID.name]: data?.metadata?.first_shift?.site || SITE_ONE_ID.default,
        [SITE_TWO_ID.name]: data?.metadata?.second_shift?.site || SITE_TWO_ID.default,
        [OVERTIME_SITE_ID.name]: data?.metadata?.over_time?.site || OVERTIME_SITE_ID.default
    });

    const [create, { isLoading: createIsLoading }] = useCreateWorkHistoryMutation();
    const [update, { isLoading: updateIsLoading }] = useUpdateWorkHistoryMutation();
    const [getUpdateRecord, isGettingUpdateRecord, { record, oldDate, config }] = useGetUpdateRecord(date);

    const workShift = form[WORK_SHIFT_ID.name];
    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;
    const mobileAppSetting = setting.mobile_app;

    const getHolidayInfo = ({ workTypeId, siteOneId, siteTwoId, workShiftId } = {}) => {
        let isAffected = false;
        let type = null;
        let shiftToUseId = null;
        let level = null;
        let affected = [];

        workTypeId = workTypeId || form[WORK_TYPE_ID.name]?.["id"];
        siteOneId = siteOneId || form[SITE_ONE_ID.name]?.["id"];
        siteTwoId = siteTwoId || form[SITE_TWO_ID.name]?.["id"];
        workShiftId = workShiftId || form[WORK_SHIFT_ID.name]?.["id"];

        let temp = holiday?.value;

        if (temp) {
            shiftToUseId = temp?.HolidayShift?.id;
            type = temp.type;
            level = temp.level;

            switch (level) {
                case BY_WORK_TYPE: {
                    isAffected = workTypeId && temp.EmployeeWorkDetails && temp.EmployeeWorkDetails.some((wd) => wd.id == workTypeId);
                    affected = (temp.EmployeeWorkDetails && temp.EmployeeWorkDetails.map((wd) => wd.name)) || [];
                    break;
                }
                case BY_WORK_SITE: {
                    isAffected = temp.CompanySites && temp.CompanySites.some((site) => [siteOneId, siteTwoId].filter(Boolean).includes(site.id));
                    affected = (temp.CompanySites && temp.CompanySites.map((site) => site.title)) || [];
                    break;
                }
                case BY_WORK_SHIFT: {
                    isAffected = workShiftId && temp.Shifts && temp.Shifts.some((shift) => shift.id == workShiftId);
                    affected = (temp.Shifts && temp.Shifts.map((shift) => shift.title)) || [];
                    break;
                }
                default:
                    break;
            }
        }

        return {
            isAffected: !!isAffected,
            shiftToUseId,
            affected,
            type,
            level,
            isOff: type == COMPANY_HOLIDAY_TYPE.OFF,
            isAffectedAll: level == COMPANY_HOLIDAY_LEVEL.COMPANY_WIDE,
            holiday: temp
        };
    };

    const getShift = () => {
        return {
            timeInOne: form[START_SHIFT_TIME.name],
            timeOutOne: form[END_SHIFT_TIME.name],
            timeInTwo: form[START_SHIFT_TIME_TWO.name],
            timeOutTwo: form[END_SHIFT_TIME_TWO.name],
            breakStartOne: form[START_BREAK_TIME.name],
            breakEndOne: form[END_BREAK_TIME.name],
            breakStartTwo: form[START_BREAK_TIME_TWO.name],
            breakEndTwo: form[END_BREAK_TIME_TWO.name],
            overTimeStart: form[START_OVERTIME.name],
            overTimeEnd: form[END_OVERTIME.name]
        };
    };

    const shiftStatus = useMemo(() => {
        const shift = getShift();
        return createShiftStatus(
            {
                shiftOne: { timeIn: shift.timeInOne, timeOut: shift.timeOutOne, breakStart: shift.breakStartOne, breakEnd: shift.breakEndOne },
                shiftTwo: { timeIn: shift.timeInTwo, timeOut: shift.timeOutTwo, breakStart: shift.breakStartTwo, breakEnd: shift.breakEndTwo },
                overtime: { start: shift.overTimeStart, end: shift.overTimeEnd }
            },
            workShift,
            {
                maxOT: { value: workShift?.max_overtime, format: "hours" },
                lateGP: { value: mobileAppSetting.defaultLateGracePeriod, format: "minutes" },
                breakGP: { value: mobileAppSetting.breakGracePeriod, format: "minutes" },
                earlyTimeOutGP: { value: mobileAppSetting.earlyTimeOut, format: "minutes" }
            },
            timezone
        );
    }, [form]);

    const createVars = () => {
        const typeOpt = toReadableSelectOptions(WORK_HISTORY_TYPE).map((t) => ({
            ...t,
            label: <Tag className="flex">{t.label}</Tag>
        }));

        const workShift = form[WORK_SHIFT_ID.name];
        const isNormal = workShift?.shift_type == SHIFT_TYPE.NORMAL;
        const isSplit = workShift?.shift_type == SHIFT_TYPE.SPLIT;
        const maxBreakDuration = workShift.max_break_duration;
        const hasShiftTwo = !!workShift.start_time_2 && !!workShift.end_time_2;

        const shift = getShift();

        const hasBreakOne = maxBreakDuration || (workShift.break_time && workShift.break_end_time);
        const hasBreakTwo = maxBreakDuration || (workShift.break_time_2 && workShift.break_end_time_2);

        return {
            isNormal,
            isSplit,
            isLoading: createIsLoading || updateIsLoading,
            typeOpt,
            type: typeOpt.find((type) => type.value == form[TYPE.name]) || "",
            shiftOne: getTimeRangeValues(shift, timezone)[TIME_TYPE.SHIFT_ONE],
            shiftTwo: getTimeRangeValues(shift, timezone)[TIME_TYPE.SHIFT_TWO],
            breakOne: getTimeRangeValues(shift, timezone)[TIME_TYPE.BREAK_ONE],
            breakTwo: getTimeRangeValues(shift, timezone)[TIME_TYPE.BREAK_TWO],
            overtime: getTimeRangeValues(shift, timezone)[TIME_TYPE.OVERTIME],
            setHoliday,
            holiday,
            getUpdateRecord,
            getHolidayInfo,
            hasBreakOne,
            hasBreakTwo,
            hasShiftTwo,
            workShift,
            shiftStatus,
            shiftOneIsInc: !!chekIncStatus(shift.timeInOne, shift.timeOutOne, timezone, INCOMPLETE_STATUS_IDENTIFIER),
            shiftTwoIsInc: !!chekIncStatus(shift.timeInTwo, shift.timeOutTwo, timezone, INCOMPLETE_STATUS_IDENTIFIER),
            record: {
                ...(record || {}),
                isLoading: isGettingUpdateRecord,
                oldDate,
                isFetched: !!record,
                ...config
            }
        };
    };

    const vars = createVars();

    const updateForm = (config = {}) => {
        const newconf = cloneDeep(config);
        for (const key in newconf) {
            if (Object.hasOwnProperty.call(newconf, key)) {
                const value = newconf[key];
                if (value == null) newconf[key] = "";
            }
        }
        setForm({ ...form, ...newconf });
    };

    const validateByEditType = () => {
        if (editType == EDIT_TYPE.TIMING) {
            const foundAnormalities = [
                ...new Set(shiftStatus.filter((st) => WORK_HISTORY_SHIFT_STATUS_ABNORMALITIES.includes(st)).map((st) => sanitizeWords(st)))
            ];
            if (foundAnormalities.length) {
                throw new Error(`Invalid update, timing should not have abnormalities. Found: ${foundAnormalities.join(", ")}`);
            }
        }
        if (editType == EDIT_TYPE.OVERTIME) {
            const hasOT = shiftStatus.includes(WORK_HISTORY_SHIFT_STATUS.OVERTIME);
            const doneOT = shiftStatus.includes(WORK_HISTORY_SHIFT_STATUS.OVERTIME_DONE);
            if (hasOT && !doneOT) {
                throw new Error("Invalid update, overtime should be completed.");
            }
            if (!hasOT && !doneOT) {
                throw new Error("Invalid update, overtime is required.");
            }
        }
    };

    const upsert = async (holidayInfo) => {
        let result = null;
        try {
            validateByEditType();

            const clonedform = cloneDeep(form);

            if (form[EMPLOYEE_ID.name]) {
                clonedform[EMPLOYEE_ID.name] = form[EMPLOYEE_ID.name].id;
            }
            if (form[WORK_TYPE_ID.name]) {
                clonedform[WORK_TYPE_ID.name] = form[WORK_TYPE_ID.name].id;
            }
            if (form[WORK_SHIFT_ID.name]) {
                clonedform[WORK_SHIFT_ID.name] = form[WORK_SHIFT_ID.name].id;
            }
            if (form[SITE_ONE_ID.name]) {
                clonedform[SITE_ONE_ID.name] = form[SITE_ONE_ID.name].id;
            }
            if (form[SITE_TWO_ID.name]) {
                clonedform[SITE_TWO_ID.name] = form[SITE_TWO_ID.name].id;
            }
            if (form[OVERTIME_SITE_ID.name]) {
                clonedform[OVERTIME_SITE_ID.name] = form[OVERTIME_SITE_ID.name].id;
            }
            if (holidayInfo) {
                clonedform.holiday = {
                    id: holidayInfo.id,
                    shiftToUseId: holidayInfo.shiftToUseId
                };
            }
            if (shiftStatus) {
                clonedform.shift_status = shiftStatus;
            }

            if (isCreate) {
                result = await create({ body: clonedform });
            } else {
                result = await update({ body: clonedform, extraPath: updateId });
            }
            if (result.error) {
                throw new Error(result.error?.data?.message);
            }
            if (result.data && !noSuccessMessage) {
                if (result.data?.data) {
                    createToast(`Work History ${isCreate ? "created" : "updated"} succesfully.`, TOAST_TYPE.SUCCESS);
                } else {
                    createToast(result.data.message, TOAST_TYPE.SUCCESS);
                }
            }
            return result.data.data;
        } catch (error) {
            createToast(
                `Failed to ${!isCreate ? "update" : "create"} Work History. ${error?.message || "Please try again later or contact support."} `,
                TOAST_TYPE.ERROR
            );
            return { error };
        }
    };

    useEffect(() => {
        if (!isGettingUpdateRecord && !isCreate && !old) {
            setOld(form);
        }
    }, [isGettingUpdateRecord]);

    return [
        form,
        updateForm,
        {
            upsert,
            isLoading: vars.isLoading,
            config: vars,
            hasChanges: !isEqualWithDates(form, old)
        }
    ];
};

export const useApproveTimeInPhoto = (updateId) => {
    const [update, { isLoading: updateIsLoading }] = useUpdateWorkHistoryStatusMutation();

    const approvePhoto = async (isApproved) => {
        try {
            const result = await update({
                body: { status: isApproved ? WORK_HISTORY_STATUS.ADMIN_VERIFIED : WORK_HISTORY_STATUS.REJECTED },
                extraPath: updateId
            });
            if (result.error) {
                throw new Error(result.error?.data?.message);
            }
            if (result.data) {
                if (result.data?.data) {
                    createToast(`Record succesfully ${isApproved ? "Approved" : "Rejected"}`, TOAST_TYPE.SUCCESS);
                } else {
                    createToast(result.data.message, TOAST_TYPE.SUCCESS);
                }
            }
            return result.data.data;
        } catch (error) {
            return createToast(`Failed to update Work History. ${error?.message || "Please try again later or contact support."} `, TOAST_TYPE.ERROR);
        }
    };

    return [approvePhoto, updateIsLoading];
};

export const useGetUpdateRecord = (date) => {
    const [cache, setCache] = useState();
    const [fetch, { data, isLoading }] = useGetUpdateRecordWorkHistoryMutation();

    const setting = useAppSelector(selectUserSetting);

    const getUpdateRecord = async (newdate, employee, isCreate) => {
        if (!employee || !employee.id) {
            throw new Error("Please select an Employee.");
        }
        try {
            const result = await fetch({ extraPaths: [newdate || date, employee.id, isCreate ? 1 : 0] });
            if (result.error) {
                throw new Error(result.error?.data?.message);
            }
            setCache(date);
            return result.data.data;
        } catch (error) {
            return createToast(`Failed to get update record. ${error?.message || "Please try again later or contact support."} `, TOAST_TYPE.ERROR);
        }
    };

    const createVars = () => {
        const record = data?.data;

        return {
            record,
            hasHolidays: !!record?.holidays?.length,
            options:
                record &&
                record.holidays &&
                record.holidays.map((hol) => ({
                    label: (
                        <div key={hol.id} className="flex gap-05">
                            <Tag className="red">
                                {sanitizeWords(hol.name)} ({createTextFromDate(hol.start_date, hol.end_date, setting.timezone).date})
                            </Tag>
                            <Tag>{sanitizeWords(hol.type)}</Tag>
                        </div>
                    ),
                    value: hol
                }))
        };
    };

    const vars = createVars();

    return [getUpdateRecord, isLoading, { record: vars.record, oldDate: cache, config: vars }];
};

export const useDeleteWorkHistory = () => {
    const dispatch = useAppDispatch();

    const [deleteRecord] = useDeleteWorkHistoryMutation();

    const isLoading = useAppSelector(selectLoading);

    const remove = async (id) => {
        if (!isLoading) {
            dispatch(setLoading(true));
        }
        try {
            const response = await deleteRecord({ extraPath: id });
            if (response.error) {
                throw new Error(response.error?.data?.message || "Failed to delete record.");
            }
            return response.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        } finally {
            dispatch(setLoading(false));
        }
    };

    return [remove, isLoading];
};

export const useDailyLoginActivities = () => {
    const [initialLoading, setInitialLoading] = useState(false);
    const [isLoadingMore, setLoadingMore] = useState(false);
    const [isMounted, setMounted] = useState(false);
    const [object, setObject] = useState({
        data: [],
        sort: { sortBy: "updatedAt", order: SORT_ORDER.DESC },
        totalCount: 0,
        cursor: "",
        search: ""
    });

    const hasMore = object.totalCount > object.data?.length || 0;

    const [load] = useLoadAllLazyWorkHistoryMutation();

    const updateObject = (newObject = {}) => setObject((prev) => ({ ...prev, ...newObject }));

    const fetch = async ({ sort, ...config } = {}, isReset) => {
        if (!sort) {
            sort = object.sort;
        }
        if (isReset) {
            config.cursor = "";
        }
        try {
            const response = await load({
                body: {
                    isCurrentDay: true,
                    pageSize: DEFAULT_SIZE,
                    more: isReset ? DEFAULT_SIZE : LOAD_MORE_OFFSET,
                    ...object.sort,
                    ...sort,
                    ...(config || {})
                }
            });
            if (response.error) {
                throw new Error(response.error?.data?.message);
            }
            if (response.data && response.data.data) {
                const resdata = response.data.data || [];
                const newdata = resdata.data;
                const sameCursor = isEqual(object.cursor, resdata.cursor);
                const temp = {
                    data: isReset ? newdata : !sameCursor ? object.data.concat(newdata) : object.data,
                    cursor: resdata.cursor,
                    totalCount: resdata.totalCount
                };
                sort && (temp.sort = sort);
                updateObject(temp);
                return temp.data;
            }
        } catch (error) {
            createToast(error.message || "Something went wrong with the server. Please contact support.", TOAST_TYPE.ERROR);
            sort && updateObject({ sort, data: [], totalCount: 0 });
        }
    };

    const loadMore = async () => {
        setLoadingMore(true);
        hasMore && (await fetch({ cursor: object.cursor }));
        setLoadingMore(false);
    };
    const reset = () => {
        setInitialLoading(true);
        fetch({}, true).finally(() => setInitialLoading(false));
    };
    const search = (value = "") => fetch({ search: value }, true);
    const sort = ({ sortBy, order }) => fetch({ sort: { sortBy, order } }, true);

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        setInitialLoading(true);
        fetch().finally(() => setInitialLoading(false));
    }, [isMounted]);

    return [object, updateObject, { isLoading: initialLoading, hasMore, fetch, reset, loadMore, search, sort, isLoadingMore }];
};
