import React from "react";
import PropTypes from "prop-types";
import Divider from "../../../common/components/extra/Divider";

const Copyright = ({ hideDivider, styles = {} }) => {
    return (
        <div className="tk-copyright-container">
            {!hideDivider && <Divider style={{ margin: "1rem 0", marginTop: "2.5rem", width: "100%", ...styles.divider }} />}
            <footer className="tk-copyright semi-bold flex column" style={styles.footer || {}}>
                <span>
                    Timekeeper is a product of&nbsp;
                    <a href={process.env.REACT_APP_GRT_COMPANY_URI} target="_blank" rel="noopener noreferrer">
                        GRT Mobile Applications and Development LLC.
                    </a>
                </span>
                <span>© 2024 GRT Mobile Applications and Development LLC. All rights reserved.</span>
                <span>Business Center 1, M Floor, The Meydan Hotel, Nad Al Sheba, Dubai, U.A.E.</span>
            </footer>
        </div>
    );
};

export default Copyright;

Copyright.propTypes = {
    hideDivider: PropTypes.bool,
    styles: PropTypes.shape({
        divider: PropTypes.object,
        footer: PropTypes.object
    })
};
