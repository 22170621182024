import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as LogoSVG } from "../../../assets/images/logo.svg";

function Logo({ size = 3, classNames = {}, styles = {}, hide, svg, shadow }) {
    if (hide) {
        return <></>;
    }

    const createClassName = () => {
        let classname = "tk-logo";
        if (classNames.parent) {
            classname += " " + classNames.parent;
        }
        if (shadow) {
            classname += " shadow";
        }
        return classname.trim();
    };

    return (
        <div className={createClassName()} style={styles.parent}>
            {svg ? (
                <LogoSVG className="responsive-img" style={{ ...styles.image, width: `${size}rem` }} />
            ) : (
                <img className="responsive-img" src="/images/logo-clean.png" alt="" style={{ ...styles.image, width: `${size}rem` }} />
            )}
        </div>
    );
}

export default Logo;

Logo.propTypes = {
    hide: PropTypes.bool,
    svg: PropTypes.bool,
    shadow: PropTypes.bool,
    size: PropTypes.number,
    classNames: PropTypes.shape({
        parent: PropTypes.string,
        image: PropTypes.string
    }),
    styles: PropTypes.shape({
        parent: PropTypes.object,
        image: PropTypes.object
    })
};
