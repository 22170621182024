import React from "react";
import useNavigate from "../../../common/hooks/useNavigate";
import { useLogoutMutation } from "../../common/api";
import Navigation from "../../../common/classes/Navigation";
import FormAuthTemplate from "../../../common/components/layout/FormAuthTemplate";
import ContactUs from "./ContactUs";

function DeactivatedAccount() {
    const navigate = useNavigate();
    const [logout] = useLogoutMutation();
    return (
        <div className="tk-deactivated-account">
            <FormAuthTemplate
                title="Account Deactivated"
                subtext="Your account has been deactivated. For assistance, please contact our support team."
                footer={
                    <div className="tk-deactivated-account__footer">
                        <ContactUs />
                    </div>
                }
                submitLabel="Go to Login"
                onSubmit={async () => logout().then(() => navigate(Navigation.Routes.SIGNIN.path))}
            ></FormAuthTemplate>
        </div>
    );
}

export default DeactivatedAccount;
