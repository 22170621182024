import React from "react";
import PropTypes from "prop-types";
import ResetDeviceIcon from "@mui/icons-material/PhonelinkErase";
import Button from "../../../common/components/extra/Button";
import { createConfirmAlert, createToast, TOAST_TYPE } from "../../../common/utilities/helper";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectCurrent, setCurrent, updateData } from "./slice";
import { useResetDeviceMutation } from "./api";
import MyTooltip from "../../../common/components/extra/Tooltip";

function ResetDeviceButton({ id, device, onResetDevice, isIcon, style, disabled, withLabel }) {
    const [resetDevice, { isLoading }] = useResetDeviceMutation();

    const dispatch = useAppDispatch();

    const current = useAppSelector(selectCurrent);

    const handleResetDevice = async () => {
        if (!id) {
            return;
        }

        await resetDevice({ extraPath: id });

        if (current && current.id == id) {
            dispatch(setCurrent({ ...current, device: null }));
        }
        dispatch(updateData({ id, data: { device: null } }));
        onResetDevice?.();
        createToast("Device has been cleared successfully", TOAST_TYPE.SUCCESS);
    };

    const handleClick = () =>
        createConfirmAlert({
            title: "Reset Device",
            content: (
                <div className="flex column gap-05">
                    <span>Are you sure you want to reset this device:</span>
                    <div className="flex gap-05">
                        <span className="fade">Name:</span>
                        <span>{device?.name}</span>
                    </div>
                    <div className="flex gap-05">
                        <span className="fade">Model:</span>
                        <span>{device?.model}</span>
                    </div>
                </div>
            ),
            onConfirm: async (close) => {
                close();
                await handleResetDevice(current);
            }
        });

    if (isIcon) {
        return (
            <MyTooltip
                message="Reset Device"
                className={disabled ? "disabled" : ""}
                style={{
                    display: "flex",
                    alignItems: "center",
                    gap: ".5rem",
                    ...style
                }}
                onClick={() => !disabled && handleClick()}
            >
                <ResetDeviceIcon style={{ width: "1.3rem", height: "auto" }} />
                {(withLabel && "Reset Device") || ""}
            </MyTooltip>
        );
    }

    return (
        <Button
            onClick={() => !disabled && handleClick()}
            isLoading={isLoading}
            afterExtra={<ResetDeviceIcon />}
            className="danger"
            style={style}
            disabled={disabled}
            small
            mini
        >
            <span>Reset Device</span>
        </Button>
    );
}

export default ResetDeviceButton;

ResetDeviceButton.propTypes = {
    id: PropTypes.any,
    isIcon: PropTypes.bool,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    onResetDevice: PropTypes.func,
    device: PropTypes.shape({
        name: PropTypes.string,
        model: PropTypes.string
    }),
    withLabel: PropTypes.bool
};
