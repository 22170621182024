import React from "react";
import Companies from "../../features/admin/companies/Companies";
import { VIEW_TYPES } from "../../common/utilities/const";
import PageLayout from "../../common/components/layout/PageLayout";

function CompaniesPage() {
    return (
        <PageLayout
            className="tk-companies"
            title="Manage Companies"
            styles={{
                body: {
                    height: "calc(100vh - 20.5rem)"
                }
            }}
        >
            <Companies view={VIEW_TYPES.TABLE} />
        </PageLayout>
    );
}

export default CompaniesPage;
