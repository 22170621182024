import { apiSlice } from "../../../app/apiSlice";

const BASE_PATH = "/api/company/settings";

const END_POINTS = {
    getCompanySettings: { method: "get", path: "" },
    getCountrySettings: { method: "get", path: "country" },
    updateCompanySettings: { method: "put", path: "update" }
};

export const companySettingsAPI = apiSlice.injectEndpoints({
    reducerPath: "companySettingsAPI",
    endpoints: (builder) => {
        const temp = {};
        Object.entries(END_POINTS).forEach(([key, config]) => {
            temp[key] = builder.mutation({
                query: (queryParams = {}) => {
                    const path = `${BASE_PATH}/${config.path}`;
                    const conf = {
                        url: path,
                        method: config.method,
                        credentials: "include"
                    };
                    if (typeof queryParams.extraPath === "string" || typeof queryParams.extraPath === "number")
                        conf.url = `${path}/${queryParams.extraPath}`;
                    if (Array.isArray(queryParams.extraPaths)) conf.url = `${path}/${queryParams.extraPaths.join("/")}`;
                    if (queryParams.params) conf.params = queryParams.params;
                    if (queryParams.body) conf.body = queryParams.body;
                    if (queryParams.formData) conf.formData = true;
                    return conf;
                }
            });
        });
        return temp;
    }
});

export const { useGetCompanySettingsMutation, useGetCountrySettingsMutation, useUpdateCompanySettingsMutation } = companySettingsAPI;

export default companySettingsAPI.reducer;
