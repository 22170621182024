import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import Zoom from "./Zoom";
import { ZOOM_DEFAULT } from "./const";

export class ImageViewer extends Component {
    #innerRef = createRef(null);

    static propTypes = {
        image: PropTypes.shape({
            src: PropTypes.string,
            alt: PropTypes.string
        })
    };

    state = {
        zoom: ZOOM_DEFAULT
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.#innerRef.current.addEventListener("contextmenu", (e) => {
            e.preventDefault();
        });
    }

    render() {
        return (
            <div className="image-viewer">
                <div ref={this.#innerRef} className="image-viewer__inner">
                    <img
                        className="responsive-img prevent-select"
                        src={this.props.image.src}
                        alt="image"
                        width={10}
                        height={10}
                        style={{ transform: `scale(${this.state.zoom / 100})` }}
                        crossOrigin="anonymous"
                    />
                </div>
                <div className="image-viewer__controls">
                    <Zoom attachRef={this.#innerRef} value={this.state.zoom} onChange={(conf) => this.setState({ zoom: conf.value })} />
                </div>
            </div>
        );
    }
}

export default ImageViewer;
