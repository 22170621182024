import React, { useCallback, useMemo } from "react";
import Select from "react-select";
import { createFilter } from "react-select";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import Loader from "./Loader";

const AsyncSelectLazy = ({
    onLoadMore,
    onChange,
    onSearch,
    isFetching,
    isOutlined,
    label,
    noborder,
    style,
    customNoOption,
    isClearable,
    disabledOutline,
    ...rest
}) => {
    const isMulti = rest.isMulti;

    const NoOptionsMessage = () => {
        return <div className="tk-select--no-option">{customNoOption || "No options"}</div>;
    };

    const handleChange = (data, ...rest) => {
        if (!data) {
            data = { value: "" };
        }
        typeof onChange === "function" && onChange(data, ...rest);
    };

    const selectProps = {
        ...rest,
        placeholder: rest.placeholder || "",
        components: rest.components || customNoOption ? { NoOptionsMessage: customNoOption && NoOptionsMessage, ...rest.components } : {},
        classNamePrefix: "tk-select-inner",
        isClearable: rest.required ? false : isClearable,
        onChange: handleChange,
        styles: {
            ...rest.styles,
            menuPortal: (base) => ({ ...base, zIndex: 99999, maxHeight: 200 }),
            control: (base) => ({ ...base, ...(rest?.styles?.control || {}), ...(disabledOutline ? { border: 0, boxShadow: "none" } : {}) }),
            multiValue: (base) => ({ ...base, borderRadius: "15px" }),
            multiValueRemove: (base, state) => (state.data.isFixed ? { ...base, display: "none" } : base)
        }
    };

    const options = useMemo(
        () =>
            [
                ...(rest?.options || []),
                isFetching
                    ? {
                          value: "loading",
                          label: (
                              <div className="flex center gap-05">
                                  <Loader relative style={{ width: "2rem" }} /> Loading...
                              </div>
                          ),
                          isDisabled: true
                      }
                    : null
            ].filter(Boolean),
        [isFetching, rest?.options.length]
    );

    const handleScrollBottom = useCallback(() => {
        onLoadMore?.();
    }, [onLoadMore]);

    return (
        <div
            className={`tk-select ${isMulti ? "tk-select--multi" : ""} ${isOutlined ? "tk-select--outlined" : ""} ${
                label ? "tk-select--right" : ""
            } ${noborder ? "tk-select--noborder" : ""}`.trim()}
            style={style || {}}
        >
            {label && (
                <div className="tk-select__label">
                    <span>
                        {label}
                        {rest.required ? <span className="danger-color bold">*</span> : ""}
                    </span>
                </div>
            )}
            <Select
                {...selectProps}
                filterOption={selectProps.filterOption ? selectProps.filterOption : createFilter({ ignoreAccents: false })}
                onChange={onChange}
                options={options}
                onInputChange={debounce(onSearch, 500)}
                onMenuScrollToBottom={handleScrollBottom}
                isLoading={isFetching}
                cacheOptions={false}
                defaultOptions={false}
            />
            <input type="hidden" name="" required={rest.required} />
        </div>
    );
};

AsyncSelectLazy.propTypes = {
    options: PropTypes.array,
    onLoadMore: PropTypes.func,
    isFetching: PropTypes.bool,
    onChange: PropTypes.func,
    onSearch: PropTypes.func,
    isOutlined: PropTypes.bool,
    label: PropTypes.string,
    noborder: PropTypes.bool,
    style: PropTypes.object,
    styles: PropTypes.object,
    disabledOutline: PropTypes.bool,
    customNoOption: PropTypes.any,
    isClearable: PropTypes.bool
};

export default AsyncSelectLazy;
