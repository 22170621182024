import React, { useState } from "react";
import PropTypes from "prop-types";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { DEVICE_FILTER, FILTER_TYPE } from "./const";
import { defaultFilter, resetFilter, selectFilter, setFilter } from "./slice";
import useFilterManager from "../../../common/hooks/useFilterManager";
import FilterControl from "../../../common/components/extra/filter/FilterControl";
import DepartmentsSelectLazy from "../departments/DepartmentsSelectLazy";
import DesignationsSelectLazy from "../designations/DesignationsSelectLazy";
import FilterIDS from "./FilterIDS";
import { toFilterOptions } from "../../../common/utilities/helper";
import Select from "../../../common/components/extra/select/Select";

const COMMON_PROPS = {
    style: { minWidth: "10rem", height: "2rem" },
    styles: { control: { borderRadius: "25rem" } },
    menuPortalTarget: document.body,
    isClearable: true
};

function Filter({ onFilter, isLoading }) {
    const [filterConfig] = useState({
        [FILTER_TYPE.DEVICE]: {
            options: toFilterOptions(Object.values(DEVICE_FILTER)).map((opt) => {
                if (!opt.value) {
                    opt.label = <span className="bold small-font">Device/No Device</span>;
                }
                return {
                    value: opt.value,
                    label: <span className="bold small-font">{opt.label}</span>
                };
            })
        }
    });

    const recordFilter = useAppSelector(selectFilter) || {};

    const [filter, { isClearDisabled, isFilterDisabled, getValueFromOpt, handleChange, handleReset, handleFilter }] = useFilterManager({
        onFilter,
        filterConfig,
        defaultFilter,
        filter: recordFilter,
        isLoading,
        setFilter,
        resetFilter
    });

    return (
        <div className="tk-filter flex gap-05 w100" style={{ alignItems: "center" }}>
            <div className="flex gap-05 wrap" style={{ alignItems: "center" }}>
                <DepartmentsSelectLazy
                    {...COMMON_PROPS}
                    value={{ value: filter[FILTER_TYPE.DEPARTMENT], id: filter[FILTER_TYPE.DEPARTMENT] }}
                    onChange={(conf) => handleChange(FILTER_TYPE.DEPARTMENT, conf?.id || "")}
                    parentStyle={{ ...COMMON_PROPS.style }}
                    placeholder="Department"
                    isClearable={!!filter[FILTER_TYPE.DEPARTMENT]}
                    isDisabled={isLoading}
                    isFilter
                />
                <DesignationsSelectLazy
                    {...COMMON_PROPS}
                    value={{ value: filter[FILTER_TYPE.DESIGNATION], id: filter[FILTER_TYPE.DESIGNATION] }}
                    onChange={(conf) => handleChange(FILTER_TYPE.DESIGNATION, conf?.id || "")}
                    parentStyle={{ ...COMMON_PROPS.style }}
                    placeholder="Designation"
                    isClearable={!!filter[FILTER_TYPE.DESIGNATION]}
                    isDisabled={isLoading}
                    isFilter
                />
                <Select
                    {...COMMON_PROPS}
                    options={filterConfig[FILTER_TYPE.DEVICE].options}
                    value={getValueFromOpt(FILTER_TYPE.DEVICE, filter[FILTER_TYPE.DEVICE])}
                    onChange={(conf) => handleChange(FILTER_TYPE.DEVICE, conf.value)}
                    placeholder="Device/No Device"
                    isClearable={!!filter[FILTER_TYPE.DEVICE]}
                    isDisabled={isLoading}
                />
                <FilterIDS onChange={(conf) => handleChange(FILTER_TYPE.EXPIRY_DATE, conf)} value={filter[FILTER_TYPE.EXPIRY_DATE]} />
            </div>
            <FilterControl
                isClearDisabled={isClearDisabled}
                isFilterDisabled={isFilterDisabled}
                onReset={handleReset}
                onFilter={handleFilter}
                isLoading={isLoading}
            />
        </div>
    );
}

export default Filter;

Filter.propTypes = {
    onFilter: PropTypes.func,
    isLoading: PropTypes.bool
};
