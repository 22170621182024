export const COMPONENT_LIBRARY = {
    POLYGON: 0x1,
    DRAWING: 0x2,
    SEARCH: 0x3,
    POLYLINE: 0x4
};

export const LIBRARIES = {
    GEOMETRY: "geometry",
    PLACES: "places",
    DRAWING: "drawing"
};

export const ZOOM_IN_VAL = {
    ENTIRE_WORLD: 1,
    LANDMASS: 5,
    CITY: 10,
    STREET: 15,
    BUILDING: 20,
    MAX: 22
};

export const MAX_ZOOM_IN = ZOOM_IN_VAL.MAX;

export const OVERLAY_TYPE = {
    MARKER: "marker",
    POLYGON: "polygon",
    POLYLINE: "polyline",
    RECTANGLE: "rectangle",
    CIRCLE: "circle"
};

export const MAP_TYPE_ID = {
    ROADMAP: "roadmap",
    SATELLITE: "satellite",
    HYBRID: "hybrid",
    TERRAIN: "terrain"
};

export const COMPLETED_DRAWING_EVENTS = {
    CIRCLE_COMPLETE: "circlecomplete",
    MARKER_COMPLETE: "markercomplete",
    OVERLAY_COMPLETE: "overlaycomplete",
    POLYGON_COMPLETE: "polygoncomplete",
    POLYLINE_COMPLETE: "polylinecomplete",
    REACTANGLE_COMPLETE: "rectanglecomplete"
};

export const DRAWING_MODE_EVENT = {
    ON_CHANGE: "drawingmode_changed"
};

export const MAP_TYPE_CONTROL_STYLE = {
    DEFAULT: 0,
    HORIZONTAL_BAR: 1,
    DROPDOWN_MENU: 2,
    INSET: 3,
    INSET_LARGE: 4
};

export const MOUSE_EVENTS = {
    CLICK: "click",
    RIGHT_CLICK: "contextmenu",
    DOUBLE_CLICK: "dblclick",
    DRAG: "drag",
    DRAG_START: "dragstart",
    DRAG_END: "dragend",
    MOUSEDOWN: "mousedown",
    MOUSEMOVE: "mousemove",
    MOUSEOUT: "mouseout",
    MOUSEOVER: "mouseover",
    MOUSEUP: "mouseup"
};

export const MVC_PATH_EVENT = {
    SET_AT: "set_at",
    INSERT_AT: "insert_at",
    REMOVE_AT: "remove_at"
};

export const AUTO_COMPLETE_EVENT = {
    PLACE_CHANGE: "place_changed"
};

export const MAP_CONTROL_POSITION = {
    TOP_LEFT: 1,
    TOP_CENTER: 2,
    TOP: 2,
    TOP_RIGHT: 3,
    LEFT_CENTER: 4,
    LEFT_TOP: 5,
    LEFT: 5,
    LEFT_BOTTOM: 6,
    RIGHT_TOP: 7,
    RIGHT: 7,
    RIGHT_CENTER: 8,
    RIGHT_BOTTOM: 9,
    BOTTOM_LEFT: 10,
    BOTTOM_CENTER: 11,
    BOTTOM: 11,
    BOTTOM_RIGHT: 12,
    CENTER: 13,
    BLOCK_START_INLINE_START: 14,
    BLOCK_START_INLINE_CENTER: 15,
    BLOCK_START_INLINE_END: 16,
    INLINE_START_BLOCK_CENTER: 17,
    INLINE_START_BLOCK_START: 18,
    INLINE_START_BLOCK_END: 19,
    INLINE_END_BLOCK_START: 20,
    INLINE_END_BLOCK_CENTER: 21,
    INLINE_END_BLOCK_END: 22,
    BLOCK_END_INLINE_START: 23,
    BLOCK_END_INLINE_CENTER: 24,
    BLOCK_END_INLINE_END: 25
};

// https://developers.google.com/maps/documentation/javascript/events
export const MAP_EVENT = {
    BOUNDS_CHANGED: "bounds_changed",
    IDLE: "idle",
    TILES_LOADED: "tilesloaded",
    DRAG: "drag",
    DRAG_START: "dragstart",
    DRAG_END: "dragend"
};

export const DEFAULT_RPOPS = {
    zoom: MAX_ZOOM_IN - 13,
    styles: {
        parent: {
            height: "10rem",
            width: "100%"
        }
    }
};

export const POLYGON_DEFAULT_OPTIONS = {
    geodesic: true,
    strokeColor: "#0052CC",
    fillColor: "#3083ff",
    strokeOpacity: 1.0,
    strokeWeight: 2
};

export const FALLBACK_CENTER = {
    lat: 25.244526999999998,
    lng: 51.46556725
};

export const MAP_CHANGE_TYPE = {
    MAP_INTIIALIZED: "initialized",
    POLY_PATH_CHANGED: "poly-path-changed",
    POLY_INITIALIZED: "poly-initialized",
    POLY_REMOVE: "poly-remove",
    DRAW_COMPLETE: "draw-complete"
};
