import React from "react";
import useNavigate from "../../../common/hooks/useNavigate";
import { useLogoutMutation } from "../../common/api";
import FormAuthTemplate from "../../../common/components/layout/FormAuthTemplate";
import Navigation from "../../../common/classes/Navigation";
import ContactUs from "./ContactUs";

function WaitingApproval() {
    const navigate = useNavigate();
    const [logout] = useLogoutMutation();

    return (
        <div className="tk-waiting-approval">
            <FormAuthTemplate
                title="Waiting for Approval"
                subtext="Kindly wait confirmation of your account's approval."
                footer={
                    <div className="tk-waiting-approval__footer">
                        <ContactUs />
                    </div>
                }
                submitLabel="Go to Login"
                onSubmit={() => logout().then(() => navigate(Navigation.Routes.SIGNIN.path))}
            ></FormAuthTemplate>
        </div>
    );
}

export default WaitingApproval;
