import React, { forwardRef, useRef, useState } from "react";
import PropTypes from "prop-types";
import ArrowRight from "@mui/icons-material/KeyboardArrowRight";
import useDeepCompareEffect from "use-deep-compare-effect";
import Popover, { POPOVER_POSITION } from "../Popover";

export const MENU_EVENT_TYPE = {
    UPDATE: "UPDATE",
    REMOVE: "REMOVE"
};

const createLabel = (option) => {
    return (
        <div className="tk-dropdown-menu__option__inner">
            <div className="tk-dropdown-menu__option__label">{option.label}</div>
            {option.submenu && (
                <div className="tk-dropdown-menu__option__extra">
                    <ArrowRight className="arrow-icon hover-svg" />
                </div>
            )}
        </div>
    );
};
const createClassName = ({ mini } = {}) => {
    let className = "tk-dropdown-menu ";
    if (mini) {
        className += "mini ";
    }
    return className.trim();
};

const MenuContent = forwardRef(function MenuContent({ mini, children }, ref) {
    return (
        <div ref={ref} className={createClassName({ mini })}>
            <div className="tk-dropdown-menu__list tk-dropdown-menu__list--content">{children}</div>
        </div>
    );
});

function MenuList({ depths, onChange, options, depthLevel = 0, mini, isPortal }) {
    const menuContentRef = useRef(null);
    const listRef = useRef(null);

    const [isDeepMenuOpen, setDeepMenuOpen] = useState(false);
    const [selected, setSelected] = useState(null);

    const maxDepths = Object.keys(depths || {}).length;
    const hasDepths = maxDepths > 0;
    const depth = depths?.[depthLevel] || null;
    const defaultOpenMenu = depthLevel < maxDepths;
    const isInvalidOption = !(depth && options.find((option) => option.value == depth.value));

    useDeepCompareEffect(() => {
        if (hasDepths) {
            setSelected(depth);
            if (depth && depth.submenu && defaultOpenMenu) {
                setDeepMenuOpen(true);
            }
            if (isInvalidOption) {
                onChange?.(depthLevel, depth, MENU_EVENT_TYPE.REMOVE);
            }
        }
    }, [depths]);

    const isCurrentlyActive = (option) => selected && selected.value == option.value && isDeepMenuOpen;

    const handleSelect = (event, option) => {
        setSelected(option);
        onChange?.(option.depthLevel, option, MENU_EVENT_TYPE.UPDATE);
        setDeepMenuOpen(!isCurrentlyActive(option));
    };

    const getActiveSelection = (option) => selected && selected.value == option.value && ((option.submenu && isDeepMenuOpen) || !option.submenu);

    const getActiveMenu = (option) => {
        const isSelected = getActiveSelection(option);
        const isContent = option.submenu && option.submenu.content;
        if (!isSelected) {
            return "";
        }
        return isContent ? (
            <MenuContent ref={menuContentRef} mini={mini}>
                {option.submenu.content}
            </MenuContent>
        ) : (
            <MenuList depths={depths} options={option.submenu} depthLevel={depthLevel + 1} mini={mini} onChange={onChange} />
        );
    };

    return (
        <div className={createClassName({ mini })}>
            <div ref={listRef} className="tk-dropdown-menu__list">
                {options.map((option, idx) => {
                    let className = "tk-dropdown-menu__option ";
                    className += `depth-${depthLevel} `;
                    if (option.submenu) className += "has-submenu ";
                    if (getActiveSelection(option)) className += "tk-dropdown-menu__option--active ";
                    // we make value unique now when we retrieve this we have to remove the suffix then compare
                    return (
                        <div
                            key={idx + option.value + depthLevel}
                            className={className.trim()}
                            onClick={(event) => handleSelect(event, { ...option, depthLevel }, idx)}
                        >
                            {option.submenu ? (
                                <Popover
                                    open={isCurrentlyActive(option) || false}
                                    content={getActiveMenu(option)}
                                    position={POPOVER_POSITION.RIGHT}
                                    styles={{
                                        parent: { width: "100%" },
                                        content: { width: "max-content", marginLeft: ".5rem", padding: "0" }
                                    }}
                                    onChange={setDeepMenuOpen}
                                    isPortal={isPortal}
                                    noArrow
                                    staticParent
                                    controlled
                                >
                                    {createLabel(option)}
                                </Popover>
                            ) : (
                                createLabel(option)
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default MenuList;

MenuList.propTypes = {
    depthLevel: PropTypes.number,
    mini: PropTypes.bool,
    isPortal: PropTypes.bool,
    onChange: PropTypes.func,
    depths: PropTypes.object,
    options: PropTypes.oneOfType([
        PropTypes.shape({
            content: PropTypes.any
        }),
        PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.any,
                value: PropTypes.any,
                submenu: PropTypes.oneOfType([
                    PropTypes.array,
                    PropTypes.shape({
                        content: PropTypes.any
                    })
                ])
            })
        )
    ])
};

MenuContent.propTypes = {
    mini: PropTypes.bool,
    children: PropTypes.any
};
