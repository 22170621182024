import React, { useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";
import { useNavigate } from "react-router-dom";
import FormAuthTemplate from "../../../common/components/layout/FormAuthTemplate";
import { useResetCompanyPasswordRequestMutation } from "./companyAPI";
import useTimer from "../../../common/hooks/useTimer";
import Loader from "../../../common/components/extra/Loader";
import Input from "../../../common/components/extra/Input";
import Button from "../../../common/components/extra/Button";
import Navigation from "../../../common/classes/Navigation";
import ContactUs from "./ContactUs";

const DEFAULT_TIMEOUT = 60 * 1000;

function ResetPassword() {
    const [config, setConfig] = useState({ email: "", ctr: 1, isExpired: false });
    const [sendPasswordRequest, { isLoading }] = useResetCompanyPasswordRequestMutation();
    const navigate = useNavigate();
    const [timer, startTimer] = useTimer(0);

    let label = isLoading ? `${config.ctr === 1 ? "Sending" : "Resending"} email...` : `${config.ctr === 1 ? "Send" : "Resend"} Email`;
    if (timer > 0) label = `${timer / 1000}s`;

    const updateConfig = (obj = {}) => setConfig((prev) => ({ ...prev, ...obj }));

    const handleSubmit = async () => {
        try {
            startTimer(DEFAULT_TIMEOUT);
            updateConfig({ ctr: config.ctr++ });
            const resp = await sendPasswordRequest({ body: { email: config.email } });
            if (resp.error) {
                throw new Error(resp.error?.data?.message);
            }
            toast.info("Check your inbox for an email with instructions to reset your password.");
        } catch (error) {
            toast.error(error.message || "Failed to reset password.");
        }
    };

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        updateConfig({ [name]: value });
    };

    return (
        <div className="tk-reset-password">
            {isLoading && <Loader />}
            <FormAuthTemplate
                title="Forgot Password?"
                subtext="No worries, we'll send you a reset instructions."
                submitLabel={label}
                onSubmit={debounce(handleSubmit, timer)}
                footer={
                    <div className="tk-reset-password__footer">
                        <ContactUs />
                    </div>
                }
                buttonExtra={<Button onClick={() => navigate(Navigation.Routes.SIGNIN.path)}>Go to login</Button>}
                disabled={!!timer || isLoading}
            >
                <Input name="email" type="email" value={config.email_address} onChange={handleChange} placeholder="Email Address" required isLarge />
            </FormAuthTemplate>
        </div>
    );
}
ResetPassword.propTypes = {
    email: PropTypes.string,
    buttonExtra: PropTypes.any
};

export default ResetPassword;
