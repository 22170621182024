import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import FitScreenIcon from "@mui/icons-material/FitScreen";
import { ZOOM_MIN, ZOOM_INC, ZOOM_TYPE, ZOOM_MAX, ZOOM_DEFAULT } from "./const";
import { isNaN } from "lodash";
import Select from "../select/Select";
import { createZoomLevels } from "./helper";

const Zoom = ({ attachRef, value, onChange }) => {
    const [zoom, setZoom] = useState(value);
    const [levels] = useState(createZoomLevels());

    const getZoomLevel = () => {
        return levels.find((level) => level.value == zoom);
    };

    const handleChange = (type, inputValue) => {
        let value = zoom;

        switch (type) {
            case ZOOM_TYPE.IN:
                if (zoom >= ZOOM_MAX) return;
                value = zoom + ZOOM_MIN;
                value = value > ZOOM_MAX ? ZOOM_MAX : value;
                break;
            case ZOOM_TYPE.OUT:
                if (zoom <= ZOOM_MIN) return;
                value = Math.max(zoom - ZOOM_MIN, ZOOM_INC);
                break;
            case ZOOM_TYPE.INPUT: {
                if (zoom <= ZOOM_MIN || zoom >= ZOOM_MAX) {
                    return;
                }
                value = Number(inputValue);
                if (isNaN(value)) {
                    value = zoom;
                }
                break;
            }
            default:
                break;
        }

        setZoom(value);
        onChange?.({ type, value });
    };

    useEffect(() => {
        const element = attachRef.current;

        const handleLeftClick = () => handleChange(ZOOM_TYPE.IN);
        const handleRightClick = () => handleChange(ZOOM_TYPE.OUT);

        if (element) {
            element.addEventListener("click", handleLeftClick);
            element.addEventListener("contextmenu", handleRightClick);
        }
        return () => {
            if (element) {
                element.removeEventListener("click", handleLeftClick);
                element.removeEventListener("contextmenu", handleRightClick);
            }
        };
    }, [attachRef, zoom]);

    return (
        <div className="zoom-control">
            <div className="zoom-control__in">
                <ZoomInIcon className="hover-svg" onClick={() => handleChange(ZOOM_TYPE.IN)} />
            </div>
            <div className="zoom-control__out">
                <ZoomOutIcon className="hover-svg" onClick={() => handleChange(ZOOM_TYPE.OUT)} />
            </div>
            <div className="zoom-control__value">
                <Select options={levels} value={getZoomLevel()} menuPlacement="top" onChange={(conf) => handleChange(ZOOM_TYPE.INPUT, conf.value)} />
            </div>
            <div className={`zoom-control__fit ${(zoom == ZOOM_DEFAULT && "disabled") || ""}`.trim()}>
                <FitScreenIcon className="hover-svg" onClick={() => handleChange(ZOOM_TYPE.INPUT, ZOOM_DEFAULT)} />
            </div>
        </div>
    );
};

export default Zoom;

Zoom.propTypes = {
    attachRef: PropTypes.any,
    value: PropTypes.number,
    onChange: PropTypes.func
};
