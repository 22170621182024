import React, { useState } from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import EditIcon from "@mui/icons-material/Edit";
import RemoveIcon from "@mui/icons-material/Delete";
import Divider from "../../../common/components/extra/Divider";
import Card from "../../../common/components/extra/Card";
import { createConfirmAlert } from "../../../common/utilities/helper";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import TableList from "../../../common/components/extra/table/TableList";
import Button, { BTN_TYPE } from "../../../common/components/extra/Button";
import UpdateModal from "./UpdateModal";
import MyTooltip, { PLACES } from "../../../common/components/extra/Tooltip";
import { useLazyDesignations, useRemoveDesignation } from "./hooks";

const DESCRIPTIONS = `Designations define roles and responsibilities within your organization, providing clarity and structure.`;

function Designations() {
    const [openModal, setOpenModal] = useState(false);

    const [object, updateObject, { isLoading, loadMore, onSort, search, reset }] = useLazyDesignations();
    const [onRemove] = useRemoveDesignation();

    const { data: headers } = CreateTableHeaders({
        onUpdate: (row) => {
            updateObject({ selected: row });
            setOpenModal(true);
        },
        onRemove: (row) => onRemove(row).then(reset)
    });

    const handleFinish = async (result, isCreate) => {
        if (isCreate) {
            await reset({}, true);
        } else {
            updateObject({
                data: object.data.map((item) => ({
                    ...item,
                    ...(result && result.id == item.id && result)
                })),
                selected: null
            });
        }
        setOpenModal(false);
    };

    const handleAdd = () => {
        setOpenModal(!openModal);
        updateObject({ selected: null });
    };

    return (
        <Card className="designation-settings box-shadow-mini">
            <div className="flex column">
                <Divider title="Manage Designations" />
                <p className="fade small-font">{DESCRIPTIONS}</p>
                <div className="flex column gap-05">
                    <div className="flex gap-05" style={{ alignItems: "center" }}>
                        <Input
                            type={INPUT_TYPE.SEARCH}
                            onChange={debounce(search, 500)}
                            placeholder="Search title..."
                            parentStyle={{
                                minHeight: "1rem",
                                height: "100%"
                            }}
                            wrapperStyle={{ flex: 1 }}
                        />
                        <Button type={BTN_TYPE.ADD} onClick={handleAdd}>
                            Add
                        </Button>
                    </div>
                    <TableList
                        headers={headers}
                        data={object.data}
                        onSort={onSort}
                        activeSort={object.sort}
                        onLoadMore={loadMore}
                        height={35}
                        isLoadingMore={isLoading}
                        small
                    />
                </div>
            </div>
            {openModal && <UpdateModal data={object.selected} open={openModal} onChange={setOpenModal} onFinish={handleFinish} />}
        </Card>
    );
}

export default Designations;

Designations.propTypes = {
    loading: PropTypes.bool,
    setParentLoading: PropTypes.func
};

const CreateTableHeaders = ({ onUpdate, onRemove }) => {
    const headers = {
        EMPLOYEE: {
            key: "title",
            sortKey: "title",
            label: "Title",
            render: (row) => (
                <span className="text-ellipsis">
                    <span className="bold">{row.title}</span>
                </span>
            )
        },
        ACTION: {
            key: "action",
            style: { display: "flex", justifyContent: "flex-end", paddingRight: ".5rem" },
            render: (row) => {
                return (
                    <div className="flex gap-05">
                        <Button
                            onClick={() => typeof onUpdate === "function" && onUpdate(row)}
                            options={{ style: { padding: 0, boxShadow: "unset", marginLeft: "auto", maxWidth: "max-content" } }}
                            transparent
                        >
                            <EditIcon className="fade hover-svg" style={{ width: "1.4rem" }} />
                        </Button>
                        <MyTooltip position={PLACES.LEFT} message={!!row.usageCount && `Used by ${row.usageCount} Employees.`}>
                            <Button
                                onClick={() =>
                                    createConfirmAlert({
                                        title: "Remove Designation",
                                        content: "Are you sure? This cannot be undone.",
                                        onConfirm: (onClose) => {
                                            typeof onRemove === "function" && onRemove(row);
                                            onClose();
                                        }
                                    })
                                }
                                options={{ style: { padding: 0, boxShadow: "unset", marginLeft: "auto", maxWidth: "max-content" } }}
                                disabled={!!row.usageCount}
                                transparent
                            >
                                <RemoveIcon className="hover-svg" style={{ width: "1.4rem", color: "red" }} />
                            </Button>
                        </MyTooltip>
                    </div>
                );
            }
        }
    };
    return { data: Object.values(headers), original: headers };
};
