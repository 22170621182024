import React, { useEffect, useState } from "react";
import { useParams, Navigate, useNavigate } from "react-router-dom";
import useNavigateHook from "../../../common/hooks/useNavigate";
import { useVerifyCompanyEmailMutation } from "./companyAPI";
import FormAuthTemplate from "../../../common/components/layout/FormAuthTemplate";
import Loader from "../../../common/components/extra/Loader";
import Navigation from "../../../common/classes/Navigation";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import { clearSignUp, selectUser, setUnverfiedStatus } from "../../common/slice";
import { TOAST_TYPE, createToast } from "../../../common/utilities/helper";
import { UNVERIFIED_TOKEN_TYPE } from "../../common/const";
import ContactUs from "./ContactUs";

function VerifyCompanyEmail() {
    const [mounted, setMounted] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [renderType, setRenderType] = useState(UNVERIFIED_TOKEN_TYPE.DEFAULT);

    const params = useParams();
    const user = useAppSelector(selectUser);
    const navigate = user ? useNavigateHook() : useNavigate(); // use custom navigate when logged in else use default to not include prev data
    const dispatch = useAppDispatch();

    const [verifyCompanyEmail] = useVerifyCompanyEmailMutation();

    let title = "";
    let subtext = "";

    useEffect(() => {
        setMounted(true);
    }, []);

    const verify = async () => {
        const resp = await verifyCompanyEmail({ body: params });
        let msg = "",
            type;
        if (resp.error) {
            msg = resp.error?.data?.message || "Invalid Link";
            if (msg.includes("expire")) {
                msg = "Link is expired";
                type = UNVERIFIED_TOKEN_TYPE.EXPIRED;
            } else {
                type = UNVERIFIED_TOKEN_TYPE.INVALID;
            }
            createToast(msg, TOAST_TYPE.ERROR);
            dispatch(clearSignUp()); // reset signup flow
        } else {
            msg = "Account verified successfully.";
            type = UNVERIFIED_TOKEN_TYPE.VERIFED;
            createToast(msg, TOAST_TYPE.SUCCESS);
        }
        setRenderType(type);
        dispatch(setUnverfiedStatus(type));
        setLoading(false);
    };

    useEffect(() => {
        mounted && verify();
    }, [mounted]);

    const gotoLogin = () => navigate(Navigation.Routes.SIGNIN.path);

    if (user) {
        return <Navigate to={Navigation.Routes.DASHBOARD.path} />;
    }
    if (isLoading) {
        return <Loader style={{ position: "absolute" }} centered />;
    }
    switch (renderType) {
        case UNVERIFIED_TOKEN_TYPE.EXPIRED: {
            title = "Verification Link Expired";
            subtext = "Link is not available and already expired.";
            break;
        }
        case UNVERIFIED_TOKEN_TYPE.INVALID: {
            title = "Invalid Verification Link";
            subtext = "Verification link is not valid.";
            break;
        }
        case UNVERIFIED_TOKEN_TYPE.VERIFED: {
            title = "Email Verified Successfully";
            subtext =
                "Access to TimeKeeper is currently restricted until the associated account has received approval. A notification will be dispatched via email once the account approval process is completed.";
            break;
        }
        default: {
            title = "Verify Email";
            subtext = "To start using TimeKeeper confirm your email address with the email we sent to:";
            break;
        }
    }
    return (
        <FormAuthTemplate
            title={title}
            subtext={subtext}
            submitLabel="Go to Login Page"
            onSubmit={gotoLogin}
            footer={
                <div style={{ marginTop: "1.5rem" }}>
                    <ContactUs />
                </div>
            }
        ></FormAuthTemplate>
    );
}

export default VerifyCompanyEmail;
