import { useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import { TOAST_TYPE, createToast, isPhoneValid } from "../../../common/utilities/helper";
import { useAdminCompanyUploadFilesMutation, useGetAllCompaniesMutation, useGetCompanyDetailsByAdminMutation, useUpdateCompanyMutation } from "./api";
import { selectCurrent, selectData, selectTableConfig, setCurrent, setState, updateData } from "./slice";
import { FIELDS } from "./const";
import { COMPANY_FILES } from "../../../common/utilities/const";

const { LICENSE_COMMERCIAL, LICENSE_TRADE, ESTABLISHMENT_ID, COMPANY_CONTRACT } = COMPANY_FILES;

const INITIAL_UPDATE_FORM = Object.values(FIELDS).reduce(
    (prev, curr) => ({
        ...prev,
        [curr.name]: curr.defaultValue
    }),
    {}
);

export const useUpdateStatus = () => {
    const dispatch = useAppDispatch();

    const [update, { isLoading: isStatusUpdating }] = useUpdateCompanyMutation();

    const updateStatus = async (id, newstatus) => {
        let result = null;
        let retval = null;
        try {
            result = await update({
                body: { status: newstatus },
                extraPath: id
            });
            if (result.error) {
                throw new Error(result.error?.data?.message);
            }
            if (result.data) {
                const respdata = { ...(result.data?.data || {}) };
                if (result.data?.data) {
                    createToast("Company status updated succesfully.", TOAST_TYPE.SUCCESS);
                } else {
                    createToast(result.data.message, TOAST_TYPE.SUCCESS);
                }
                retval = respdata;
            }
            dispatch(setCurrent(retval));
            dispatch(updateData(retval));
            return retval;
        } catch (error) {
            createToast(`Failed to update company. ${error?.message || "Please try again later or contact support."} `, TOAST_TYPE.ERROR);
            return { error };
        }
    };

    return [updateStatus, isStatusUpdating];
};

export const usePaginateCompanies = () => {
    const [fetching, setFetching] = useState(true);

    const dispatch = useAppDispatch();
    const data = useAppSelector(selectData);
    const tableConfig = useAppSelector(selectTableConfig);
    const [updateStatus, isStatusUpdating] = useUpdateStatus();

    const [load] = useGetAllCompaniesMutation();

    const fetch = async (config) => {
        if (!fetching) {
            setFetching(true);
        }
        const response = await load({ params: { ...tableConfig, ...(config || {}) } });
        if (response.data && response.data.data) {
            if (typeof setState === "function") {
                const result = response.data.data;
                const oldConfig = { ...tableConfig, ...(config || {}) };
                dispatch(
                    setState({
                        data: result.data,
                        tableConfig: { ...oldConfig, totalPage: result.totalPage }
                    })
                );
            }
        }
        if (response.error) {
            createToast("Failed to fetch data. Please try again later.", TOAST_TYPE.ERROR);
        }
        setFetching(false);
        return response;
    };

    useEffect(() => {
        if (!data.length) {
            fetch();
        } else {
            setFetching(false);
        }
    }, []);

    return [data, fetching, fetch, { updateStatus, isStatusUpdating }];
};

export const useFetchCompany = ({ id, cached } = {}) => {
    const [isLoading, setLoading] = useState(true);

    const [getDetails] = useGetCompanyDetailsByAdminMutation();
    const [updateStatus, isStatusUpdating] = useUpdateStatus();

    const dispatch = useAppDispatch();
    const current = useAppSelector(selectCurrent);
    const data = useAppSelector(selectCurrent);

    const fetch = async () => {
        if ((cached && current && current.id === id) || !id) {
            setLoading(false);
            return Promise.resolve();
        }
        const res = await getDetails({ extraPath: id });
        if (res.error) {
            createToast("Failed to fetch company. Please try again later or contact support", TOAST_TYPE.ERROR);
        }
        if (res.data) {
            dispatch(setCurrent(res.data.data));
        }
        setLoading(false);
        return res;
    };

    useEffect(() => {
        fetch();
    }, []);

    return [
        data || {},
        isLoading,
        {
            updateStatus: (newstatus) => updateStatus(id, newstatus),
            isStatusUpdating
        }
    ];
};

export const useUpdateCompanies = ({ id, initial = {} } = {}) => {
    const [mobileCode, setMobileCode] = useState("");
    const [form, setForm] = useState({ ...INITIAL_UPDATE_FORM, ...initial });

    const [update, { isLoading }] = useUpdateCompanyMutation();

    const dispatch = useAppDispatch();
    const hasChanges = !isEqual(form, initial);
    const current = useAppSelector(selectCurrent);

    const onSave = async (additional = {}) => {
        let result = null;
        let retval = null;
        try {
            const clonedform = cloneDeep({
                ...form,
                ...(additional || {})
            });
            if (mobileCode) {
                const newMobileNumber = mobileCode + clonedform.contact_number;
                const isValid = isPhoneValid(newMobileNumber).isValid;
                if (!isValid) {
                    throw new Error("Mobile number is not valid.");
                } else {
                    clonedform.contact_number = newMobileNumber;
                }
            } else {
                const isValid = isPhoneValid(clonedform.contact_number).isValid;
                if (!isValid) {
                    throw new Error("Mobile code is required.");
                }
            }

            // clean
            delete clonedform.Role;
            delete clonedform.CompanySetting;
            delete clonedform.index1;
            delete clonedform.index2;
            delete clonedform.index3;
            delete clonedform.index4;
            delete clonedform.index5;
            delete clonedform.SuperAdmin;
            delete clonedform.createdAt;
            delete clonedform.updatedAt;
            delete clonedform.verified_by;
            delete clonedform.photo;

            result = await update({ body: clonedform, extraPath: id });
            if (result.error) {
                throw new Error(result.error?.data?.message);
            }
            if (result.data) {
                let respdata = { ...(result.data?.data || {}) };
                if (result.data?.data) {
                    createToast("Company updated succesfully.", TOAST_TYPE.SUCCESS);
                } else {
                    const msg = result.data.message;
                    if (msg && result.data.message.toLowerCase().includes("no changes")) {
                        respdata = current;
                        createToast("Company updated succesfully.", TOAST_TYPE.SUCCESS);
                    } else {
                        createToast(result.data.message, TOAST_TYPE.SUCCESS);
                    }
                }
                retval = respdata;
            }
            dispatch(setCurrent(retval));
            dispatch(updateData(retval));
            return retval;
        } catch (error) {
            createToast(`Failed to update company. ${error?.message || "Please try again later or contact support."} `, TOAST_TYPE.ERROR);
            return { error };
        }
    };

    return [form, setForm, isLoading, onSave, setMobileCode, hasChanges];
};

export const useUploadCompanyFiles = ({ id, initial = {} } = {}) => {
    const [uploads, setUploads] = useState({
        [LICENSE_TRADE.key]: "",
        [LICENSE_COMMERCIAL.key]: "",
        [ESTABLISHMENT_ID.key]: "",
        [COMPANY_CONTRACT.key]: "",
        ...initial
    });

    const [upload, { isLoading }] = useAdminCompanyUploadFilesMutation();

    const dispatch = useAppDispatch();
    const current = useAppSelector(selectCurrent);
    const hasChanges = !isEqual(uploads, initial);

    const onStartUpload = async (data = {}) => {
        try {
            const formData = new FormData();
            for (const field in uploads) {
                if (Object.hasOwnProperty.call(uploads, field)) {
                    const file = uploads[field];
                    file && formData.append(field, file);
                }
            }
            const result = await upload({ body: formData, extraPath: id, formData: true });
            if (result.error) {
                throw new Error(result?.error?.data?.message || "Something went wrong!. Please try again later.");
            }
            const newObj = {
                ...current,
                ...data,
                uploads: result.data.data
            };
            dispatch(setCurrent(newObj));
            dispatch(updateData(newObj));
            return result.data.data;
        } catch (error) {
            createToast(`Failed to upload company fiels. ${error?.message || "Please try again later or contact support."} `, TOAST_TYPE.ERROR);
            return { error };
        }
    };

    return [uploads, setUploads, isLoading, onStartUpload, hasChanges];
};
