import React, { useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import Tag from "../../../common/components/extra/Tag";
import { capitalizeWords } from "../../../common/utilities/helper";
import MyTooltip from "../../../common/components/extra/Tooltip";
import ExpiringEmployeesModal from "../employees/ExpiringEmployeesModal";

function EmployeeFilesExpiring() {
    const [openMoreDetails, setOpenMoreDetails] = useState(false);
    const user = useAppSelector(selectUser);
    const summary = cloneDeep(user.employeeFilesValidity);
    delete summary.hasValue;

    const TAG_PROPS = {
        style: { padding: "2px 5px", height: "max-content" }
    };

    return (
        <div className="flex column w100 center">
            <span className="small-font flex gap-05" style={{ fontWeight: "normal", fontSize: "11px" }}>
                <span>The following Employee Documents are expiring/expired:</span>
                <a href="#" className="semi-bold" onClick={() => setOpenMoreDetails(true)}>
                    Click for More Details
                </a>
            </span>
            <span className="flex gap-1 align-center small-font" style={{ paddingTop: "3px", gap: "1.5rem" }}>
                {Object.keys(summary).map((key, i) => {
                    const expired = summary[key].expired;
                    const expiring = summary[key].warning;
                    if (!expired && !expiring) return <React.Fragment key={key + i}></React.Fragment>;
                    return (
                        <div key={key + i} className="flex gap-05 align-center">
                            {capitalizeWords(key)}
                            {!!summary[key].warning && (
                                <MyTooltip
                                    message={`${expiring} ${capitalizeWords(key)} ${expiring > 1 ? "are" : "is"} Expiring`}
                                    element="div"
                                    className="flex gap-05 align-center"
                                >
                                    <Tag {...TAG_PROPS} className="yellow">
                                        {expiring}
                                    </Tag>
                                </MyTooltip>
                            )}
                            <MyTooltip
                                message={`${expired} ${capitalizeWords(key)} ${expired > 1 ? "are" : "is"} Expired`}
                                element="div"
                                className="flex gap-05 align-center"
                            >
                                <Tag {...TAG_PROPS} className="red-inverse">
                                    {expired}
                                </Tag>
                            </MyTooltip>
                        </div>
                    );
                })}
            </span>

            {openMoreDetails && <ExpiringEmployeesModal open={openMoreDetails} onClose={() => setOpenMoreDetails(false)} />}
        </div>
    );
}

export default EmployeeFilesExpiring;
