import React from "react";
import PropTypes from "prop-types";
import AsyncSelectLazy from "../../../common/components/extra/AsyncSelectLazy";
import { useLazySelectPenalties } from "./hooks";
import { ROUTE_TYPE } from "./const";

function CompanyPenaltiesSelectLazy(props) {
    const [object, , { loadMore, search, isLoading, createRowItem }] = useLazySelectPenalties({
        initializing: props.isLoading,
        value: props?.value,
        type: props.type,
        isMulti: props?.isMulti,
        defaultValue: Array.isArray(props.value) ? props.value.map((v) => v.id) : props?.value?.id,
        isFilter: props.isFilter
    });

    let getValue = !props.isMulti
        ? props.value && Object.keys(props.value).length && object.data.find((item) => item.id === props.value.id)
        : object.data.filter((item) => props.value.map((v) => v.id).includes(item.id));

    if (!props.isMulti ? !!Object.keys(props.value || {}).length : Array.isArray(props.value) && props.value.length) {
        if (!props.isMulti) {
            if (!getValue) {
                getValue = createRowItem(props.value);
            }
        } else {
            getValue = props.value.map((item) => createRowItem(item));
        }
    }

    const handleSearch = (newInput, type) => {
        const allowedActions = ["input-change"];
        if (allowedActions.includes(type.action)) {
            search(newInput);
        }
    };

    return (
        <AsyncSelectLazy
            {...props}
            filterOption={(options, newInputs) => {
                const keywords = options?.data?.keywords?.trim()?.toLowerCase() || "";
                const search = newInputs?.toLowerCase().trim();
                if (keywords.includes(search)) {
                    return true;
                } else {
                    return false;
                }
            }}
            value={props.isLoading ? null : getValue}
            options={object.data}
            onLoadMore={loadMore}
            onSearch={handleSearch}
            isFetching={props.isLoading || isLoading}
        />
    );
}

CompanyPenaltiesSelectLazy.propTypes = {
    onChange: PropTypes.func,
    isOutlined: PropTypes.bool,
    label: PropTypes.string,
    noborder: PropTypes.bool,
    style: PropTypes.object,
    value: PropTypes.any,
    isMulti: PropTypes.bool,
    type: PropTypes.oneOf(Object.values(ROUTE_TYPE)),
    isLoading: PropTypes.bool,
    isFilter: PropTypes.bool
};

export default CompanyPenaltiesSelectLazy;
