import PropTypes from "prop-types";

export const latLngType = PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
});

export const zoomType = PropTypes.shape({
    default: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    onChange: PropTypes.func
});
