import React, { useState } from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import EditIcon from "@mui/icons-material/Edit";
import RemoveIcon from "@mui/icons-material/Delete";
import Divider from "../../../common/components/extra/Divider";
import Card from "../../../common/components/extra/Card";
import { createConfirmAlert, sanitizeWords } from "../../../common/utilities/helper";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import TableList from "../../../common/components/extra/table/TableList";
import Button, { BTN_TYPE } from "../../../common/components/extra/Button";
import MyTooltip, { PLACES } from "../../../common/components/extra/Tooltip";
import UpdateModal from "./UpdateModal";
import { useLazyDepartments, useRemoveDepartment } from "./hooks";
import SectionCollapseWarning from "../../../common/components/extra/section/SectionCollapseWarning";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import Tag from "../../../common/components/extra/Tag";
import InvalidDeptWarning from "./InvalidDeptWarning";
import { setDeptCurrent } from "./slice";

function Departments() {
    const [openModal, setOpenModal] = useState(false);

    const [object, updateObject, { isLoading, loadMore, onSort, search, reset }] = useLazyDepartments();
    const [onRemove] = useRemoveDepartment();

    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);
    const hasInvalidDepartment = user.hasInvalidDepartment;

    const { data: headers } = CreateTableHeaders({
        onUpdate: (row) => {
            updateObject({ selected: row });
            setOpenModal(true);
        },
        onRemove: (row) => onRemove(row).then(reset)
    });

    const handleFinish = async (result, isCreate) => {
        if (isCreate) {
            await reset();
        } else {
            updateObject({
                data: object.data.map((item) => ({
                    ...item,
                    ...(result && result.id == item.id && result)
                })),
                selected: null
            });
        }
        setOpenModal(false);
    };

    const handleAdd = () => {
        setOpenModal(!openModal);
        updateObject({ selected: null });
        dispatch(setDeptCurrent(null));
    };

    return (
        <Card className="department-settings box-shadow-mini">
            <div className="flex column gap-05">
                <Divider title="Manage Departments" />
                <p className="fade small-font">Efficiently organize your workforce with departments.</p>
                {hasInvalidDepartment && (
                    <SectionCollapseWarning title="Important Notice" show alwaysOpen>
                        A Department must have both a Manager and a Supervisor assigned for it to be available to employees.
                    </SectionCollapseWarning>
                )}
                <div className="flex column gap-05">
                    <div className="flex gap-05" style={{ alignItems: "center" }}>
                        <Input
                            type={INPUT_TYPE.SEARCH}
                            onChange={debounce(search, 500)}
                            placeholder="Search title..."
                            parentStyle={{
                                minHeight: "1rem",
                                height: "100%"
                            }}
                            wrapperStyle={{ flex: 1 }}
                        />
                        <Button type={BTN_TYPE.ADD} onClick={handleAdd}>
                            Add
                        </Button>
                    </div>
                    <TableList
                        headers={headers}
                        data={object.data}
                        onSort={onSort}
                        activeSort={object.sort}
                        onLoadMore={loadMore}
                        height={35}
                        isLoadingMore={isLoading}
                        small
                    />
                </div>
            </div>
            {openModal && <UpdateModal id={object.selected?.id} open={openModal} onClose={() => setOpenModal(false)} onFinish={handleFinish} />}
        </Card>
    );
}

export default Departments;

Departments.propTypes = {
    loading: PropTypes.bool,
    setParentLoading: PropTypes.func
};

const CreateTableHeaders = ({ onUpdate, onRemove }) => {
    const headers = {
        EMPLOYEE: {
            key: "title",
            sortKey: "title",
            label: "Title",
            render: (row) => (
                <div className="flex column gap-05" style={{ margin: "1rem 0" }}>
                    <span className="text-ellipsis">
                        <span className="bold">{row.title}</span>
                    </span>
                    {!!row.supervisor_id && row.supervisor && (
                        <div className="flex gap-05 wrap small-font" style={{ alignItems: "center" }}>
                            <span className="small-font fade">Supervisor:</span>
                            <span className="semi-bold">{sanitizeWords(`${row.supervisor.first_name} ${row.supervisor.last_name}`)}</span>
                        </div>
                    )}
                    {!!row.manager_id && row.manager && (
                        <div className="flex gap-05 wrap small-font" style={{ alignItems: "center" }}>
                            <span className="small-font fade">Manager:</span>
                            <span className="semi-bold">{sanitizeWords(`${row.manager.first_name} ${row.manager.last_name}`)}</span>
                        </div>
                    )}
                    {!!row.usageCount && (
                        <div className="flex gap-05 wrap small-font" style={{ alignItems: "center" }}>
                            <span className="small-font fade">Total Employees:</span>
                            <span className="semi-bold">
                                <Tag>{(row.usageCount + "").padStart(2, "0")}</Tag>
                            </span>
                        </div>
                    )}
                </div>
            )
        },
        ACTION: {
            key: "action",
            style: { display: "flex", justifyContent: "flex-end", paddingRight: ".5rem" },
            render: (row) => {
                return (
                    <div className="flex gap-05 align-center">
                        <InvalidDeptWarning data={row} />
                        <Button
                            onClick={() => typeof onUpdate === "function" && onUpdate(row)}
                            options={{ style: { padding: 0, boxShadow: "unset", marginLeft: "auto", maxWidth: "max-content" } }}
                            transparent
                        >
                            <EditIcon className="fade hover-svg" style={{ width: "1.4rem" }} />
                        </Button>
                        <MyTooltip position={PLACES.LEFT} message={!!row.usageCount && `Used by ${row.usageCount} Employees.`}>
                            <Button
                                onClick={() =>
                                    createConfirmAlert({
                                        title: "Remove Department",
                                        content: "Are you sure? This cannot be undone.",
                                        onConfirm: (onClose) => {
                                            typeof onRemove === "function" && onRemove(row);
                                            onClose();
                                        }
                                    })
                                }
                                options={{ style: { padding: 0, boxShadow: "unset", marginLeft: "auto", maxWidth: "max-content" } }}
                                disabled={!!row.usageCount}
                                transparent
                            >
                                <RemoveIcon className="hover-svg" style={{ width: "1.4rem", color: "red" }} />
                            </Button>
                        </MyTooltip>
                    </div>
                );
            }
        }
    };
    return { data: Object.values(headers), original: headers };
};
