import React, { useEffect, useState } from "react";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import isNull from "lodash/isNull";
import { FILTER_ALL_VALUE, ROLE_LEVEL, ROLE_TYPE, SORT_ORDER } from "../../../common/utilities/const";
import {
    TOAST_TYPE,
    assignDefaultToBase,
    createConfirmAlert,
    createFullName,
    createToast,
    isFileObject,
    isPhoneValid,
    sanitizeWords,
    transformStringToObject
} from "../../../common/utilities/helper";
import {
    useCreateEmployeeMutation,
    useDeleteEmployeeMutation,
    useEmployeeDetailsMutation,
    useEmployeeUploadFilesMutation,
    useLoadEmployeeRolesMutation,
    useLoadEmployeesLazyMutation,
    useLoadEmployeesMutation,
    useUpdateEmployeeDeptMutation,
    useUpdateEmployeeFilesMutation,
    useUpdateEmployeeMutation
} from "./api";
import Tag, { TAG_TYPE } from "../../../common/components/extra/Tag";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser, selectUserSetting, updateUser } from "../../common/slice";
import { createDefaultCheckId } from "./helper";
import {
    ASSIGN_DEPARTMENT_FIELDS,
    ASSIGN_TYPE,
    DEFAULT_DEPARTMENT_FIELDS,
    DEFAULT_FIELDS,
    DEFAULT_FILE_FIELDS,
    LAZY_REQ_TYPE,
    PERSONAL_FIELDS
} from "./const";
import LazyTableHeaders from "./LazyTableHeaders";
import {
    defaultFilter,
    selectCurrent,
    selectData,
    selectEmployeeRoles,
    selectFilter,
    selectLoading,
    selectSearching,
    selectTableConfig,
    setCurrent,
    setEmployeeRoles,
    setLoading,
    setSearching,
    setState,
    setTableConfig,
    updateData
} from "./slice";
import useFetchCountries from "../../../common/hooks/useFetchCountries";
import { useUpsertDepartments } from "../departments/hooks";

export const usePaginateEmployees = ({ readOnly } = {}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isMounted, setMounted] = useState(false);

    const dispatch = useAppDispatch();
    const current = useAppSelector(selectCurrent);
    const data = useAppSelector(selectData);
    const tableConfig = useAppSelector(selectTableConfig);
    const searching = useAppSelector(selectSearching);
    const filter = useAppSelector(selectFilter) || {};
    const isFilterOnDefault = isEqual(defaultFilter, filter);

    const [load] = useLoadEmployeesMutation();

    const fetch = async (config = {}, isReset) => {
        if (searching) {
            return;
        }

        if (!isLoading) {
            setIsLoading(true);
        }

        if (isReset) {
            config.cursor = "";
        }

        const body = {
            page: config.page || tableConfig.page,
            pageSize: config.pageSize || tableConfig.pageSize,
            sortBy: config.sortBy || tableConfig.sortBy,
            order: config.order || tableConfig.order,
            search: config.search || tableConfig.search || "",
            filter: config.filter || tableConfig.filter
        };

        try {
            const response = await load({ body });
            if (response.data && response.data.data) {
                if (typeof setState === "function") {
                    const result = response.data.data;
                    const oldConfig = { ...tableConfig, ...(config || {}) };
                    dispatch(
                        setState({
                            data: result.data,
                            tableConfig: {
                                ...oldConfig,
                                totalPage: result.totalPage,
                                totalCount: result.totalCount
                            }
                        })
                    );
                }
            }
            if (response.error) {
                throw new Error("Failed to fetch employees. Please try again later.");
            }
            return response;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearch = (value) => dispatch(setTableConfig({ search: (value && value.toLowerCase().trim()) || "" }));

    const handleSearchFetching = async () => {
        try {
            dispatch(setSearching(true));
            await fetch();
        } finally {
            dispatch(setSearching(false));
        }
    };

    useEffect(() => {
        if (isMounted) {
            handleSearchFetching();
        }
    }, [tableConfig.search]);

    useEffect(() => {
        if (isMounted && !readOnly) {
            if (!data.length) {
                fetch();
            } else {
                setIsLoading(false);
            }
        }
    }, [isMounted]);

    useEffect(() => {
        setMounted(true);
    }, []);

    const handleUpdate = (id, newObject = {}) => {
        if (current && current.id == newObject.id) {
            dispatch(setCurrent({ ...current, ...newObject }));
        }
        dispatch(updateData({ id, data: newObject }));
    };

    const handleFilter = async (filter) => {
        try {
            dispatch(setSearching(true));
            await fetch(filter ? { filter } : {}, true);
        } finally {
            dispatch(setSearching(false));
        }
    };

    const handleReset = async (conf = {}) => fetch(conf, true);

    return [
        data,
        {
            isLoading,
            fetch,
            update: handleUpdate,
            isSearching: searching,
            onFilter: handleFilter,
            onSearch: handleSearch,
            reset: handleReset,
            isFilterOnDefault
        }
    ];
};

export const useLazyEmployees = ({
    allowOnShift,
    excludeIds = [],
    withoutShift,
    initializing,
    allowSelectOnShift,
    defaultValue,
    isFilter,
    workShiftId,
    excludeWorkShiftId,
    excludeWorkDetailIds,
    workDetailIds,
    getExpiring,
    onMount,
    showDepartment
} = {}) => {
    const LOAD_MORE_OFFSET = 10;
    const DEFAULT_SIZE = 20;

    const [isMounted, setMounted] = useState(false);
    const [object, setObject] = useState({
        data: [],
        sort: { sortBy: "index1", order: SORT_ORDER.ASC },
        totalCount: 0,
        cursor: "",
        search: ""
    });

    const hasMore = object.totalCount > object.data?.length || 0;

    const [load, { isLoading }] = useLoadEmployeesLazyMutation();

    const updateObject = (newObject = {}) => setObject((prev) => ({ ...prev, ...newObject }));

    const isCurrentValue = (id) => (Array.isArray(defaultValue) ? defaultValue.includes(id) : defaultValue == id);

    const createRowItem = (row) => {
        const temp = cloneDeep(row);
        const fullName = createFullName(temp?.first_name, temp?.last_name) || "";

        temp.value = temp.id || "";
        temp.id = !temp.id ? temp.value : temp.id;
        temp.index1 = temp?.index1 || "";

        if (isFilter) {
            temp.fullName = fullName || "";

            temp.label = (
                <div className="flex gap-05" style={{ alignItems: "center" }}>
                    <span className="text-ellipsis small-font bold">{fullName}</span>
                </div>
            );
        }

        if (!isFilter) {
            const isOnShift = !allowOnShift && temp.isOnShift;
            const deptTitle = temp.CompanyDepartment && sanitizeWords(temp.CompanyDepartment.title);

            temp.isFixed = allowSelectOnShift && !isCurrentValue(temp.id) ? false : isOnShift;
            temp.isDisabled = allowSelectOnShift ? false : isOnShift;

            temp.label = (
                <div className="flex gap-05" style={{ alignItems: "center" }}>
                    {!allowOnShift && <Tag type={isOnShift ? TAG_TYPE.ON_SHIFT : TAG_TYPE.AVAILABLE} />}
                    <span className="semi-bold" style={{ paddingRight: "4px" }}>
                        {fullName}
                    </span>
                    {showDepartment && deptTitle && <Tag>{deptTitle}</Tag>}
                </div>
            );
        }

        return temp;
    };

    const fetch = async ({ sort, ...config } = {}, isReset) => {
        let extraPath = "";

        if (!sort) {
            sort = object.sort;
        }
        if (isReset) {
            config.cursor = "";
        }

        if (getExpiring) {
            extraPath = LAZY_REQ_TYPE.EXPIRING;
        }
        try {
            const response = await load({
                extraPath,
                body: {
                    pageSize: DEFAULT_SIZE,
                    more: isReset ? DEFAULT_SIZE : LOAD_MORE_OFFSET,
                    excludeIds,
                    withoutShift,
                    workShiftId,
                    excludeWorkShiftId,
                    excludeWorkDetailIds,
                    workDetailIds,
                    getExpiring,
                    ...object.sort,
                    ...sort,
                    ...(config || {})
                }
            });

            if (response.error) {
                throw new Error(response.error?.data?.message);
            }
            if (response.data && response.data.data) {
                let resdata = response.data.data || [];
                let newdata = [];

                if (isFilter) {
                    newdata = [...resdata.data].map(createRowItem);
                } else {
                    newdata = resdata.data.map(createRowItem);
                }

                const sameCursor = isEqual(object.cursor, resdata.cursor);
                const temp = {
                    data: isReset ? newdata : !sameCursor ? object.data.concat(newdata) : object.data,
                    cursor: resdata.cursor,
                    totalCount: resdata.totalCount
                };

                if (isFilter) {
                    temp.data = temp.data
                        .filter((t) => t.value)
                        .concat({
                            id: FILTER_ALL_VALUE.value,
                            value: FILTER_ALL_VALUE.value,
                            label: (
                                <div className="flex gap-05" style={{ alignItems: "center" }}>
                                    <span className="text-ellipsis small-font bold">All Employees</span>
                                </div>
                            )
                        });
                }

                sort && (temp.sort = sort);

                updateObject(temp);
                return temp.data;
            }
        } catch (error) {
            createToast(error.message || "Something went wrong with the server. Please contact support.", TOAST_TYPE.ERROR);
            sort && updateObject({ sort, data: [], totalCount: 0 });
        }
    };

    const loadMore = () => hasMore && fetch({ cursor: object.cursor });
    const reset = () => fetch({}, true);
    const search = (value = "") => fetch({ search: value }, true);
    const sort = ({ sortBy, order }) => fetch({ sort: { sortBy, order } }, true);

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        if (isMounted && !initializing) {
            fetch().then(onMount);
        }
    }, [isMounted, initializing]);

    return [object, updateObject, { isLoading, hasMore, fetch, reset, loadMore, search, sort, createRowItem }];
};

export const useLazyEmployeeManager = ({
    excludeIds,
    withoutShift,
    enableUncheckedOnshift,
    uniqueKey,
    onChange,
    onMount,
    selected,
    readOnly,
    type = ASSIGN_TYPE.DEFAULT,
    workShiftId,
    hasInternalSelections,
    excludeWorkShiftId,
    excludeWorkDetailIds,
    workDetailIds,
    title,
    disableSupervisors,
    showSites,
    isExpiringWideUI
} = {}) => {
    const [object, setObject] = useState({
        selected: null,
        checked: [],
        unchecked: [],
        viewSites: false,
        viewRecord: false,
        viewFile: false
    });

    const [config, , { loadMore, search, isLoading, sort, reset }] = useLazyEmployees({
        excludeIds,
        withoutShift,
        workShiftId,
        excludeWorkShiftId,
        excludeWorkDetailIds,
        workDetailIds,
        getExpiring: type == ASSIGN_TYPE.EXPIRING,
        onMount: (result) => result && onMount?.({ defaultCheckedIds: !hasInternalSelections ? createDefaultCheckId(result, selected, type) : [] })
    });

    const user = useAppSelector(selectUser);
    const setting = user.Setting || {};
    const timezone = setting.timezone;

    // can be used to determine which values can be unchecked
    const defaultCheckedId = !hasInternalSelections ? createDefaultCheckId(config.data, selected, type) : [];

    let { data: headers } = LazyTableHeaders({
        type,
        onViewRecord: (row) => updateObject({ selected: row, viewRecord: true }),
        onViewSites: (row) => updateObject({ selected: row, viewSites: true }),
        onViewFile: (row) => updateObject({ selected: row, viewFile: true }),
        readOnly,
        timezone,
        defaultCheckedId,
        enableUncheckedOnshift,
        title,
        workShiftId,
        disableSupervisors,
        showSites,
        selected,
        isExpiringWideUI
    });

    const updateObject = (newObj = {}) => setObject((prev) => ({ ...prev, ...newObj }));

    const handleCheckChange = (val, key, keys, type, ids, removedKeys) => {
        let temp = { [uniqueKey]: [] };
        if (keys[uniqueKey]) {
            temp[uniqueKey] = keys[uniqueKey];
        } else {
            temp[uniqueKey] = keys;
        }
        updateObject({ checked: temp[uniqueKey], unchecked: removedKeys });
        typeof onChange == "function" && onChange({ checked: temp[uniqueKey], unchecked: removedKeys });
    };

    const handleSearch = (e) => {
        const value = typeof e == "string" ? e : e.target.value;
        search((value && value.toLowerCase().trim()) || "");
    };

    return {
        unique: uniqueKey,
        headers,
        data: config.data,
        onSort: sort,
        sort: config.sort,
        defaultChecked: defaultCheckedId,
        onLoadMore: loadMore,
        onCheckChange: handleCheckChange,
        isLoading: isLoading,
        onSearch: handleSearch,
        object,
        updateObject,
        totalCount: config.totalCount,
        reset
    };
};

export const useGetEmployee = (id, useCache = true) => {
    const [isMounted, setMounted] = useState(false);
    const [fetching, setFetching] = useState(!!id);

    const [getDetails] = useEmployeeDetailsMutation();

    const dispatch = useAppDispatch();
    const current = useAppSelector(selectCurrent) || {};
    const setting = useAppSelector(selectUserSetting);

    const timezone = setting.timezone;
    const countries = useFetchCountries();

    const createVars = (data) => {
        if (!data) return {};
        const details = cloneDeep(data || {});
        const fullName = `${data.first_name || ""} ${data.last_name || ""}`.trim();
        const nationality = data.nationality ? countries.find((ctr) => ctr.cca2 == data.nationality) : "";
        const isSuperVisor = data.Role && data.Role?.type == ROLE_TYPE.EMPLOYEE && data.Role?.level == ROLE_LEVEL.HIGH;
        const isDeptSupervisor = !!data.supervisingDepts?.length;
        const isDeptManager = !!data.managingDepts?.length;

        return {
            fullName,
            nationality,
            isSuperVisor,
            timezone,
            department: details.CompanyDepartment || {},
            designation: details.CompanyDesignation || {},
            contract: details.EmployeeContract || {},
            bank: details.EmployeeBankDetail || {},
            workshift: details.EmployeeWorkShift || {},
            role: details.Role || {},
            sites: (details.CompanySites || []).map((site) => site.id),
            countries,
            isDeptSupervisor,
            isDeptManager
        };
    };

    const fetch = async ({ isForce } = {}) => {
        if (!id) {
            return;
        }
        try {
            if (!isForce && useCache && current && current.id === id) {
                fetching && setFetching(false);
                return current;
            }
            const result = await getDetails({ extraPath: id });
            if (result.error) {
                throw new Error("Failed to fetch Employee. Please try again later");
            }
            dispatch(setCurrent(result.data.data));
            dispatch(
                updateData({
                    id,
                    data: {
                        supervisingDepts: result.data.data.supervisingDepts,
                        managingDepts: result.data.data.managingDepts
                    }
                })
            );
            return result.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return { error };
        } finally {
            setFetching(false);
        }
    };

    const updateCurrent = (newCurrent = {}) => {
        dispatch(setCurrent({ ...current, ...(newCurrent || {}) }));
    };

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        if (isMounted) {
            fetch();
        }
    }, [isMounted]);

    return [current, { isLoading: fetching, config: createVars(current), update: updateCurrent, fetch }];
};

export const useUpsertEmployee = (id) => {
    const [mobileCode, setMobileCode] = useState("");
    const [form, setForm] = useState(DEFAULT_FIELDS);

    const [current, { isLoading: isGettingEmployee }] = useGetEmployee(id, true);
    const [createEmployee, { isLoading: createEmployeeLoading }] = useCreateEmployeeMutation();
    const [updateEmployee, { isLoading: updateEmployeeLoading }] = useUpdateEmployeeMutation();
    const [upload, { isLoading: isUploading }] = useEmployeeUploadFilesMutation();

    const dispatch = useAppDispatch();

    const isLoading = createEmployeeLoading || updateEmployeeLoading || isUploading;
    const hasUploads = Object.values(form.uploads).some((file) => isFileObject(file));
    const isCreate = !id;
    const isUpdate = !isCreate;

    const checkPhone = (object = {}) => {
        const mobileNumber = object[PERSONAL_FIELDS.MOBILE_NUMBER];
        if (!mobileCode || !mobileNumber) {
            return;
        }
        const phone = isPhoneValid(mobileNumber);
        let isValid = phone.isValid;
        if (!isValid && mobileCode) {
            const newMobileNumber = mobileCode + mobileNumber;
            isValid = isPhoneValid(newMobileNumber).isValid;
            if (!isValid) {
                throw new Error("Mobile number is not valid.");
            } else {
                object[PERSONAL_FIELDS.MOBILE_NUMBER] = newMobileNumber;
            }
        } else {
            const splitphone = phone.phoneNumber.split(phone.countryCode);
            splitphone[0] = mobileCode;
            const newphone = splitphone.join("");
            if (isPhoneValid(newphone).isValid) {
                object[PERSONAL_FIELDS.MOBILE_NUMBER] = newphone;
            } else {
                throw new Error("Mobile number is not valid.");
            }
        }
    };

    const validateSanitizeObject = (object = {}) => {
        try {
            // validate password key to use
            const passwordKeyToUse = form[PERSONAL_FIELDS.PASSWORD_KEY_TO_USE];
            const passwordKeyToUseValue = form[passwordKeyToUse];
            if (!passwordKeyToUseValue) {
                const message = `The key that you will use for the password should have a value. Found Empty for ${passwordKeyToUse.replace(
                    "ID",
                    " ID"
                )}.`;

                throw new Error(message);
            }

            checkPhone(object);

            // sanitize site ids; current object has CompanySites key but form does not have it
            const newSiteIds = (object.CompanySites || []).map((cs) => cs.id);
            const oldSiteIds = (current?.CompanySites || []).map((cs) => cs.id);

            if (!isEqual(newSiteIds, oldSiteIds)) {
                object.site_ids = newSiteIds;
            }

            object.department_id = object.CompanyDepartment?.id || null;
            object.designation_id = object.CompanyDesignation?.id || null;
            object.work_shift_id = object.EmployeeWorkShift?.id || null;
            object.role_id = object.Role?.id || null;
            object.direct_manager_id = object[PERSONAL_FIELDS.DIRECT_MANAGER]?.id;
            object.direct_supervisor_id = object[PERSONAL_FIELDS.DIRECT_SUPERVISOR]?.id;

            delete object.CompanyDepartment;
            delete object.CompanyDesignation;
            delete object.EmployeeWorkShift;
            delete object.Role;
            delete object.CompanySites;
            delete object.uploads;
            delete object[PERSONAL_FIELDS.DIRECT_MANAGER];
            delete object[PERSONAL_FIELDS.DIRECT_SUPERVISOR];

            return object;
        } catch (error) {
            return { error: error.message };
        }
    };

    const updateForm = (config = {}) => setForm({ ...form, ...config });

    const handleChange = (e) => {
        let name = e.target.name,
            value = e.target.value,
            config = { ...form };

        if (name == "mobile_number") {
            setMobileCode(e.target.mobileCode);
        }

        const isChainedObject = name.split(".").length > 1;
        const isWOffDays = name === "number_weekly_off_days";

        // reset off days when weekly off days is changed
        if (isWOffDays && config["off_days"]?.length) {
            config["off_days"] = [];
        }
        if (e.target?.files) {
            value = e.target.files[0];
        }
        if (isChainedObject) {
            const parsedObject = transformStringToObject(name, value, form);
            config = parsedObject;
        } else {
            config[name] = value;
        }
        updateForm(config);
    };

    const upsert = async (newObject = {}) => {
        try {
            const func = id ? updateEmployee : createEmployee;

            const clonedForm = cloneDeep(form);
            const uploads = clonedForm.uploads;

            // we reuse this again here to sanitize the object
            validateSanitizeObject(clonedForm);

            const body = { ...clonedForm, ...newObject, toRemoveFile: {} };

            const formData = new FormData();

            for (const field in uploads) {
                if (Object.hasOwnProperty.call(uploads, field)) {
                    const file = uploads[field];
                    isFileObject(file) && formData.append(field, file);
                    isNull(file) && (body.toRemoveFile[field] = true);
                }
            }

            if (isUpdate) {
                formData.append("others", JSON.stringify(body));
            }

            let result = await func({
                formData: hasUploads && isUpdate,
                body: hasUploads && isUpdate ? formData : body,
                extraPaths: [id, hasUploads ? "with-file" : "no-file"].filter(Boolean)
            });

            if (result.error) {
                throw new Error(result?.error?.data?.message || `Failed to ${id ? "update" : "create"} employee, please try again later.`);
            }

            let data = result.data.data;

            if (isCreate && hasUploads) {
                const uploadres = await upload({ formData: true, body: formData, extraPath: data.id });
                if (uploadres.error) {
                    createToast("Something went wrong and Failed to upload employee files. Please reupload and try again.", TOAST_TYPE.ERROR);
                } else {
                    data = uploadres.data.data;
                }
            }

            if (isUpdate) {
                dispatch(updateData({ id, data }));
            }

            dispatch(setCurrent({ ...(current || {}), ...data }));

            return data;
        } catch (error) {
            return { error: error.message };
        }
    };

    const upsertWithAlert = () =>
        new Promise((resolve, reject) => {
            const createConfirmAlertContent = () => {
                const isChangesAffectSalaryComputation = () => {
                    const keysAffectSalaryComputation = [PERSONAL_FIELDS.SCHEDULE_TYPE];
                    return keysAffectSalaryComputation.some((key) => !isEqual(form[key], current[key]));
                };

                let def = `Are you sure you want to ${isCreate ? "create" : "update"} this employee? This cannot be undone.`;
                if (!isCreate && isChangesAffectSalaryComputation()) {
                    def =
                        "Are you sure you want to update this employee? Changing the schedule type will affect the salary computation. This cannot be undone.";
                }
                return def;
            };

            const clonedForm = cloneDeep(form);
            const result = validateSanitizeObject(clonedForm);

            if (result.error) {
                return reject(result.error);
            }

            createConfirmAlert({
                title: !isCreate ? "Update Employee" : "Create Employee",
                content: createConfirmAlertContent(),
                onConfirm: async (close) => {
                    close();
                    const result = await upsert();
                    if (result.error) {
                        reject(result.error);
                    } else {
                        resolve(result);
                    }
                }
            });
        });

    useEffect(() => {
        if (current?.id && current?.id == id) {
            setForm(assignDefaultToBase(form, current));
        }
    }, [current?.id]);

    return [upsertWithAlert, isLoading, { onFormChange: handleChange, isCreate, form, current, isGettingEmployee }];
};

export const useUpdateEmployeeFiles = (id) => {
    const [form, setForm] = useState(DEFAULT_FILE_FIELDS);

    const [current, { isLoading: isGettingEmployee }] = useGetEmployee(id, true);
    const [updateEmployee, { isLoading: updateEmployeeLoading }] = useUpdateEmployeeFilesMutation();

    const dispatch = useAppDispatch();

    const isLoading = updateEmployeeLoading;
    const hasUploads = Object.values(form.uploads).some((file) => isFileObject(file));

    const validateSanitizeObject = (object = {}) => {
        try {
            delete object.CompanyDepartment;
            delete object.CompanyDesignation;
            delete object.EmployeeWorkShift;
            delete object.Role;
            delete object.CompanySites;
            delete object.uploads;

            return object;
        } catch (error) {
            return { error: error.message };
        }
    };

    const updateForm = (config = {}) => setForm({ ...form, ...config });

    const handleChange = (e) => {
        let name = e.target.name,
            value = e.target.value,
            config = { ...form };

        const isChainedObject = name.split(".").length > 1;

        if (e.target?.files) {
            value = e.target.files[0];
        }
        if (isChainedObject) {
            const parsedObject = transformStringToObject(name, value, form);
            config = parsedObject;
        } else {
            config[name] = value;
        }
        updateForm(config);
    };

    const updateFiles = async (newObject = {}) => {
        if (!id) return;
        try {
            const func = updateEmployee;

            const clonedForm = cloneDeep(form);
            const uploads = clonedForm.uploads;

            // we reuse this again here to sanitize the object
            validateSanitizeObject(clonedForm);

            const body = { ...clonedForm, ...newObject, toRemoveFile: {} };

            const formData = new FormData();

            for (const field in uploads) {
                if (Object.hasOwnProperty.call(uploads, field)) {
                    const file = uploads[field];
                    isFileObject(file) && formData.append(field, file);
                    isNull(file) && (body.toRemoveFile[field] = true);
                }
            }

            formData.append("others", JSON.stringify(body));

            const result = await func({
                formData: hasUploads,
                body: hasUploads ? formData : body,
                extraPaths: [id, hasUploads ? "with-file" : "no-file"].filter(Boolean)
            });

            if (result.error) {
                throw new Error(result?.error?.data?.message || `Failed to update employee, please try again later.`);
            }

            const data = result.data.data;

            dispatch(updateUser({ employeeFilesValidity: data.summary }));
            dispatch(updateData({ id, data }));
            dispatch(setCurrent({ ...(current || {}), ...data }));

            return data;
        } catch (error) {
            return { error: error.message };
        }
    };

    const updateFileWithAlert = () =>
        new Promise((resolve, reject) => {
            const clonedForm = cloneDeep(form);
            const result = validateSanitizeObject(clonedForm);

            if (result.error) {
                return reject(result.error);
            }

            createConfirmAlert({
                title: "Update Employee",
                content: "Are you sure you want to update this employee? This cannot be undone.",
                onConfirm: async (close) => {
                    close();
                    const result = await updateFiles();
                    if (result.error) {
                        reject(result.error);
                    } else {
                        resolve(result);
                    }
                }
            });
        });

    useEffect(() => {
        if (current?.id && current?.id == id) {
            setForm(assignDefaultToBase(form, current));
        }
    }, [current?.id]);

    return [updateFileWithAlert, isLoading, { onFormChange: handleChange, form, current, isGettingEmployee }];
};

export const useUpdateEmployeeDepartment = (id) => {
    const [form, setForm] = useState(DEFAULT_DEPARTMENT_FIELDS);

    const [current, { isLoading: isGettingEmployee, fetch }] = useGetEmployee(id, true);
    const [updateEmployee, { isLoading: updateEmployeeLoading }] = useUpdateEmployeeDeptMutation();

    const dispatch = useAppDispatch();

    const isLoading = updateEmployeeLoading;

    const validateSanitizeObject = (object = {}) => {
        try {
            return object;
        } catch (error) {
            return { error: error.message };
        }
    };

    const updateForm = (config = {}) => setForm({ ...form, ...config });

    const handleChange = (e) => {
        let name = e.target.name,
            value = e.target.value,
            config = { ...form };

        const isChainedObject = name.split(".").length > 1;

        if (e.target?.files) {
            value = e.target.files[0];
        }
        if (isChainedObject) {
            const parsedObject = transformStringToObject(name, value, form);
            config = parsedObject;
        } else {
            config[name] = value;
        }
        updateForm(config);
    };

    const updateDept = async (newObject = {}) => {
        if (!id) {
            return;
        }
        try {
            const func = updateEmployee;
            const clonedForm = cloneDeep(form);
            validateSanitizeObject(clonedForm);
            const body = {
                ...clonedForm,
                ...newObject
            };
            const result = await func({
                body: {
                    department_id: body.CompanyDepartment?.id || null,
                    designation_id: body.CompanyDesignation?.id || null,
                    direct_supervisor_id: body.directSupervisor?.id || null,
                    direct_manager_id: body.directManager?.id || null
                },
                extraPaths: [id]
            });

            if (result.error) {
                throw new Error(result?.error?.data?.message || `Failed to update employee, please try again later.`);
            }

            const data = result.data.data;

            dispatch(updateData({ id, data }));
            dispatch(setCurrent({ ...(current || {}), ...data }));

            return data;
        } catch (error) {
            return { error: error.message };
        }
    };

    const updateWithAlert = () =>
        new Promise((resolve, reject) => {
            const clonedForm = cloneDeep(form);
            const result = validateSanitizeObject(clonedForm);

            if (result.error) {
                return reject(result.error);
            }

            createConfirmAlert({
                title: "Update Employee",
                content: "Are you sure you want to update this employee? This cannot be undone.",
                onConfirm: async (close) => {
                    close();
                    const result = await updateDept();
                    if (result.error) {
                        reject(result.error);
                    } else {
                        resolve(result);
                    }
                }
            });
        });

    useEffect(() => {
        if (current?.id && current?.id == id) {
            setForm(assignDefaultToBase(form, current));
        }
    }, [current?.id]);

    return [updateWithAlert, isLoading, { onFormChange: handleChange, form, current, isGettingEmployee, refetch: () => fetch({ isForce: true }) }];
};

export const useAssignEmployeePosition = (id) => {
    const [form, setForm] = useState(ASSIGN_DEPARTMENT_FIELDS);

    const [current, { isLoading: isGettingEmployee, fetch }] = useGetEmployee(id, true);
    const [, isLoading, { onSave }] = useUpsertDepartments();

    const validateSanitizeObject = (object = {}) => {
        try {
            return object;
        } catch (error) {
            return { error: error.message };
        }
    };

    const updateForm = (config = {}) => setForm({ ...form, ...config });

    const handleChange = (value) => updateForm(value);

    const update = async () => {
        if (!id) {
            return;
        }
        try {
            const clonedForm = cloneDeep(form);
            validateSanitizeObject(clonedForm);
            return await onSave(clonedForm);
        } catch (error) {
            return { error: error.message };
        }
    };

    const updateWithAlert = () =>
        new Promise((resolve, reject) => {
            const clonedForm = cloneDeep(form);
            const result = validateSanitizeObject(clonedForm);

            if (result.error) {
                return reject(result.error);
            }

            createConfirmAlert({
                title: "Assign Employee",
                content: "Are you sure you want to assign this employee to the selected department? This cannot be undone.",
                onConfirm: async (close) => {
                    close();
                    const result = await update();
                    if (result?.error) {
                        reject(result.error);
                    } else {
                        resolve(result);
                    }
                }
            });
        });

    return [updateWithAlert, isLoading, { onFormChange: handleChange, form, current, isGettingEmployee, refetch: () => fetch({ isForce: true }) }];
};

export const useLoadAppRoles = () => {
    const [isMounted, setMounted] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [loadEmployeeRoles] = useLoadEmployeeRolesMutation();

    const dispatch = useAppDispatch();
    const roles = useAppSelector(selectEmployeeRoles);

    useEffect(() => {
        setMounted(true);
    }, []);

    const fetch = async () => {
        try {
            if (!roles || (Array.isArray(roles) && !!roles.length)) {
                isLoading && setLoading(false);
                return roles;
            }
            const result = await loadEmployeeRoles();
            if (result.error) {
                throw new Error("Failed to get roles for employees. Please try again later");
            }
            dispatch(setEmployeeRoles(result.data.data));
            return result.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return { error };
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isMounted) {
            fetch();
        }
    }, [isMounted]);

    const toSelectOptions = () => {
        return roles.map((r) => ({
            ...r,
            value: r.id,
            label: (
                <div className="flex gap-05" style={{ alignItems: "center" }}>
                    <Tag>{sanitizeWords(r.name)}</Tag>
                </div>
            )
        }));
    };

    return [roles, isLoading, { options: toSelectOptions() }];
};

export const useDeleteEmployee = () => {
    const dispatch = useAppDispatch();

    const [deleteEmployee] = useDeleteEmployeeMutation();

    const isLoading = useAppSelector(selectLoading);

    const remove = async (id) => {
        if (!isLoading) {
            dispatch(setLoading(true));
        }
        try {
            const response = await deleteEmployee({ extraPath: id });
            if (response.error) {
                throw new Error(response.error?.data?.message || "Failed to delete Employee.");
            }
            createToast("Employee successfully deleted.", TOAST_TYPE.SUCCESS);
            return response.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        } finally {
            dispatch(setLoading(false));
        }
    };

    return [remove, isLoading];
};
