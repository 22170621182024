import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { AUTH_RENDER_TYPE, useAuthSwitcher } from "./hooks";
import { isTokenValidHex } from "../company/companyPayment/helper";

const { UNVERIFIED_EMAIL, WAITING_APPROVAL, DEACTIVATED_ACCOUNT, EXPIRED_SUBSCRIPTION, SETTING_SETUP } = AUTH_RENDER_TYPE;

function RouteChecking() {
    const navigate = useNavigate();
    const config = useAuthSwitcher();

    const hasUser = config.hasUser;
    const toCheckRoutes = config.toCheckRoutes;
    const prevPath = config.prevPath;
    const currentPath = config.currentPath;
    const routes = config.routes;
    const renderType = config.renderType;
    const isExpired = config.isExpired;
    const isPendingPayment = config.isPendingPayment;
    const isSettingsVerified = config.isSettingsVerified;
    const isUpgradingRoute = config.isUpgradingRoute;
    const token = config.token;

    const excludeSettingsPath = [WAITING_APPROVAL, UNVERIFIED_EMAIL, DEACTIVATED_ACCOUNT];

    useEffect(() => {
        switch (config.renderType) {
            case UNVERIFIED_EMAIL: {
                navigate(routes.UNVERIFIED_EMAIL.path);
                break;
            }
            case WAITING_APPROVAL: {
                navigate(routes.WAITING_APPROVAL.path);
                break;
            }
            case DEACTIVATED_ACCOUNT: {
                navigate(routes.DEACTIVATED_ACCOUNT.path);
                break;
            }
            case EXPIRED_SUBSCRIPTION: {
                navigate(routes.EXPIRED_SUBSCRIPTION.path);
                break;
            }
            case SETTING_SETUP: {
                navigate(routes.SETTINGS_SETUP.path);
                break;
            }
            default:
                break;
        }
    }, []);

    if (!excludeSettingsPath.includes(renderType)) {
        if (isUpgradingRoute && token && !isTokenValidHex(token)) {
            return <Navigate to={routes.DASHBOARD.path} />;
        }

        if (hasUser && !isExpired && !isPendingPayment && !isSettingsVerified && !isUpgradingRoute) {
            return <Navigate to={routes.SETTINGS_SETUP.path} />;
        }

        if (hasUser && currentPath !== routes.EXPIRED_SUBSCRIPTION.path && renderType == EXPIRED_SUBSCRIPTION) {
            return <Navigate to={routes.EXPIRED_SUBSCRIPTION.path} />;
        }
    }

    for (const route of toCheckRoutes) {
        if (route == currentPath) {
            if (renderType != UNVERIFIED_EMAIL && currentPath == routes.UNVERIFIED_EMAIL.path) {
                return <Navigate to={prevPath ? -1 : routes.DASHBOARD.path} />;
            }
            if (renderType != WAITING_APPROVAL && currentPath == routes.WAITING_APPROVAL.path) {
                return <Navigate to={prevPath ? -1 : routes.DASHBOARD.path} />;
            }
            if (renderType != DEACTIVATED_ACCOUNT && currentPath == routes.DEACTIVATED_ACCOUNT.path) {
                return <Navigate to={prevPath ? -1 : prevPath ? -1 : routes.DASHBOARD.path} />;
            }
            if (renderType != EXPIRED_SUBSCRIPTION && currentPath == routes.EXPIRED_SUBSCRIPTION.path) {
                return <Navigate to={prevPath ? -1 : routes.DASHBOARD.path} />;
            }
            if (renderType != SETTING_SETUP && currentPath == routes.SETTINGS_SETUP.path) {
                return <Navigate to={prevPath ? -1 : routes.DASHBOARD.path} />;
            }
        }
    }

    return <></>;
}

export default RouteChecking;
