import React, { useState } from "react";
import CardPage from "../common/components/extra/CardPage";
import Loader from "../common/components/extra/Loader";
import Tabs from "../common/components/extra/Tabs";
import ProfileCompanyTab from "../features/company/settings/ProfileCompanyTab";
import ProfileAdminTab from "../features/admin/settings/ProfileAdminTab";
import GeneralTab from "../features/company/settings/GeneralTab";
import WorkTab from "../features/company/settings/WorkTab";
import BillingTab from "../features/company/settings/BillingTab";
import PenaltiesTab from "../features/company/settings/PenaltiesTab";
import { useAppSelector } from "../common/hooks/reduxHooks";
import { selectRole } from "../features/common/slice";
import { ROLE_TYPE } from "../common/utilities/const";
import PositionTab from "../features/company/settings/Position";

export const TABS = {
    PROFILE: { id: "profile", label: "Profile" },
    GENERAL: { id: "general", label: "General" },
    WORK: { id: "work", label: "Work/Salary" },
    POSITION: { id: "position", label: "Department/Designation" },
    BILLING: { id: "billing", label: "Subscription/Payments" },
    PENALTIES: { id: "penalties", label: "Penalties" }
};

const toTabsWithComponents = (tabs, props, isAdmin) => {
    return Object.values(tabs).map((tab) => {
        const STYLES = { parent: { minWidth: "40%", width: "60vw", maxWidth: "65rem" } };

        switch (tab.id) {
            case TABS.PROFILE.id: {
                if (isAdmin) {
                    tab.component = <ProfileAdminTab {...props} styles={STYLES} />;
                } else {
                    tab.component = <ProfileCompanyTab {...props} styles={STYLES} />;
                }
                break;
            }
            case TABS.GENERAL.id: {
                tab.component = <GeneralTab {...props} styles={STYLES} />;
                break;
            }
            case TABS.WORK.id: {
                tab.component = <WorkTab {...props} styles={STYLES} />;
                break;
            }
            case TABS.POSITION.id: {
                tab.component = <PositionTab {...props} styles={STYLES} />;
                break;
            }
            case TABS.BILLING.id: {
                tab.component = <BillingTab {...props} styles={STYLES} />;
                break;
            }
            case TABS.PENALTIES.id: {
                tab.component = <PenaltiesTab {...props} styles={STYLES} />;
                break;
            }
            default: {
                break;
            }
        }
        return tab;
    });
};

function Settings() {
    const [loading, setLoading] = useState(false);

    const role = useAppSelector(selectRole);
    const isAdmin = role.type === ROLE_TYPE.SUPER_ADMIN;
    const TAB_PROPS = { loading, setParentLoading: setLoading };

    return (
        <div className="tk-settings">
            {loading && <Loader />}
            <div className="tk-settings__inner">
                <CardPage title="Settings" contentStyle={{ padding: 0, height: "calc(100vh - 11.3rem)" }}>
                    <div className="tk-settings__content w100">
                        <div className="tk-settings__body h100">
                            <Tabs
                                className="h100"
                                items={toTabsWithComponents(isAdmin ? [TABS.PROFILE] : TABS, TAB_PROPS, isAdmin)}
                                parentElClass=".tk-card-page"
                                styles={{
                                    content: { height: "unset" },
                                    body: { paddingTop: 0 }
                                }}
                                useParentWidth
                                hasBackground
                            />
                        </div>
                    </div>
                </CardPage>
            </div>
        </div>
    );
}

export default Settings;
