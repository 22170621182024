import { useEffect, useState } from "react";
import { TOAST_TYPE, createToast } from "../../../common/utilities/helper";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import { PAYMENT_STATUS } from "../../../common/utilities/const";
import { selectUser, updateUser } from "../../common/slice";
import { CONFIRM_PAYMENT_CONTENT_TYPE, VERIFY_TOKEN_CODES } from "./const";
import { DEFAULT_SIZE, LOAD_MORE_OFFSET, defaultConfig, setSubscriptionToUpgradeId } from "./slice";
import {
    useCancelPaymentTokenMutation,
    useConfirmPaymentMutation,
    useCreatePaymentTokenMutation,
    useGetBankTransferOptionsMutation,
    useGetCancelBankPaymentMutation,
    useGetSubscriptionByPaymentMutation,
    useLoadAllCompanyPaymentLazyMutation,
    useVerifyPaymentTokenMutation
} from "./api";

export const useLazyCompanyPayments = (newConfig = {}) => {
    const [isFetching, setIsFetching] = useState(true);

    const [object, setObject] = useState({
        payments: [],
        sort: {
            sortBy: defaultConfig.sortBy,
            order: defaultConfig.order
        },
        totalCount: 0,
        cursor: "",
        search: "",
        selected: null
    });

    const [load] = useLoadAllCompanyPaymentLazyMutation();

    const hasMore = object.totalCount > object.payments?.length || 0;

    const updateObject = (newObject = {}) => setObject({ ...object, ...newObject });

    const fetch = async ({ sort, ...config } = {}, isReset) => {
        if (!isFetching) {
            setIsFetching(true);
        }
        if (!sort) {
            sort = object.sort;
        }
        if (isReset) {
            config.cursor = "";
        }
        try {
            const response = await load({
                body: {
                    pageSize: DEFAULT_SIZE,
                    more: isReset ? DEFAULT_SIZE : LOAD_MORE_OFFSET,
                    ...object.sort,
                    ...sort,
                    ...(config || {})
                }
            });
            if (response.error) {
                throw new Error(response.error?.data?.message);
            }
            if (response.data && response.data.data) {
                const resdata = response.data.data.data;
                const temp = {
                    payments: isReset ? resdata : object.payments.concat(resdata),
                    cursor: resdata.cursor,
                    totalCount: resdata.totalCount
                };
                sort && (temp.sort = sort);
                updateObject(temp);
            }
        } catch (error) {
            createToast(error.message || "Failed to load paymnets. Please try again later.", TOAST_TYPE.ERROR);
            sort && updateObject({ sort, payments: [], totalCount: 0 });
        } finally {
            setIsFetching(false);
        }
    };

    const loadMore = () => {
        hasMore && fetch({ cursor: object.cursor });
    };

    useEffect(() => {
        fetch(newConfig);
    }, []);

    const onSearch = (newInput) => {
        fetch({ search: newInput.trim().toLowerCase() }, true);
    };

    const onSort = ({ sortBy, order }) => {
        fetch({ sort: { sortBy, order } }, true);
    };

    const reset = () => {
        return fetch({}, true);
    };

    return [object, { loadMore, onSort, onSearch, reset, isFetching, updateObject }];
};

export const useGetSubscriptionByPayment = (id, onMount) => {
    const [result, setResult] = useState({});
    const [fetching, setFetching] = useState(true);
    const [getSubscription] = useGetSubscriptionByPaymentMutation();

    const fetch = async () => {
        const response = await getSubscription({ extraPath: id });
        if (response.error) {
            return {};
        }
        setFetching(false);
        setResult(response.data.data);
        typeof onMount == "function" && onMount(response.data.data);
        return response.data.data;
    };

    useEffect(() => {
        id && fetch();
    }, []);

    useEffect(() => {
        id && fetch();
    }, [id]);

    return [result, fetching];
};

export const useGetBankTransferOptions = () => {
    const [getBankTransferOptions] = useGetBankTransferOptionsMutation();

    const fetch = async (token) => {
        const response = await getBankTransferOptions({ body: { token } });
        if (response.error) {
            return [];
        }
        return response.data.data;
    };

    return [fetch];
};

export const useCancelPaymentToken = (token) => {
    const [cancelToken, { isLoading }] = useCancelPaymentTokenMutation();

    const cancel = async () => {
        const response = await cancelToken({ body: { token } });
        if (response.error) {
            throw new Error("Failed to cancel token.");
        }
        return response.data.data;
    };

    return [cancel, isLoading];
};

export const useCreatePaymentToken = ({ isRenew } = {}) => {
    const [token, setToken] = useState("");
    const [createToken, { isLoading }] = useCreatePaymentTokenMutation();

    const create = async (subscription_id) => {
        try {
            const result = await createToken({
                body: { subscription_id, isRenew }
            });
            if (result.error) {
                throw new Error("Failed to confirm payment. Please try again later.");
            }
            setToken(result.data.data);
            return result.data.data;
        } catch (error) {
            throw new Error(error);
        }
    };

    return [create, { token, isCreatingToken: isLoading }];
};

export const useVerifyPaymentToken = (token) => {
    const [verfying, setVerifying] = useState(true);
    const [result, setResult] = useState(false);

    const [verifyToken] = useVerifyPaymentTokenMutation();

    const dispatch = useAppDispatch();

    const verify = async () => {
        let error;
        let result = await verifyToken({ body: { token } });
        if (result.error) {
            const msg = result.error?.data?.message;
            const isBadDecrypt = msg.includes("bad decrypt");
            if (isBadDecrypt) {
                error = { code: "custom", isBadDecrypt: true };
            } else {
                error = { code: "custom", invalid: true };
            }
        } else {
            result = result.data.data;
            if (result.Result && (result.Result.toString().startsWith(8) || result.Result.toString().startsWith(9))) {
                error = {
                    ...(error || {}),
                    code: result.Result,
                    invalid: true
                };
            }
            if (result.Result == VERIFY_TOKEN_CODES.PTL_EXPIRED) {
                error = {
                    ...(error || {}),
                    code: result.Result,
                    expired: true
                };
            }
        }
        const isNotPaid = result.Result == VERIFY_TOKEN_CODES.TRANS_NOT_PAID;
        const newresult = error && !isNotPaid ? { error } : result;

        if (!newresult.error) {
            dispatch(setSubscriptionToUpgradeId(result.subscription_id));
        }

        setResult(newresult);
        setVerifying(false);

        return newresult;
    };

    return [verify, { result, verfying, setVerifying }];
};

export const useConfirmPayment = ({ bypassToken } = {}) => {
    const { LOADING, VERIFY_ERROR, SUCCESS, BANK_PENDING, CANCELED } = CONFIRM_PAYMENT_CONTENT_TYPE;

    const [type, setType] = useState(LOADING);
    const [result, setResult] = useState(null);
    const [confirmPayment, { isLoading }] = useConfirmPaymentMutation();

    const verifyPayment = async (token) => {
        try {
            const result = await confirmPayment({ body: { token, bypassToken } });
            if (result.error) {
                throw new Error(result.error.data.message || "Failed to verify payment.");
            }
            const newresult = result.data.data;
            const paymentStatus = newresult.payment.status;

            setResult(newresult);

            if (paymentStatus == PAYMENT_STATUS.PENDING) {
                setType(BANK_PENDING);
            }
            if (paymentStatus == PAYMENT_STATUS.COMPLETED) {
                setType(SUCCESS);
            }
            if (paymentStatus == PAYMENT_STATUS.CANCELED) {
                setType(CANCELED);
            }

            return newresult;
        } catch (error) {
            setResult({ error: error.message });
            setType(VERIFY_ERROR);
        }
    };

    return [verifyPayment, { type, result, isLoading }];
};

export const useCancelBankPayment = () => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);
    const status = user.status;

    const [cancelPayment, { isLoading }] = useGetCancelBankPaymentMutation();

    const cancel = async (id) => {
        try {
            if (!id) {
                throw new Error("Failed to cancel payment. Please try again later.");
            }
            const response = await cancelPayment({ extraPath: id });
            if (response.error) {
                throw new Error("Failed to cancel payment. Please try again later.");
            }
            dispatch(
                updateUser({
                    status: {
                        ...status,
                        isPendingPayment: false
                    }
                })
            );
            return response.data.data;
        } catch (error) {
            return createToast("Failed to cancel payment. Please try again later.", TOAST_TYPE.ERROR);
        }
    };

    return [cancel, isLoading];
};
