import React from "react";
import PropTypes from "prop-types";
import Logo from "../extra/Logo";
import Copyright from "../../../features/company/auth/Copyright";

const LayoutWithLogo = ({ children, classNames = {}, styles = {} }) => {
    const createClassNames = (base = "", additional = "") => {
        let classname = base;
        if (additional) {
            classname += " " + additional;
        }
        return classname.trim();
    };

    return (
        <div className={createClassNames("tk-layout-with-logo", classNames.parent)} style={styles.parent || {}}>
            <div className="tk-layout-with-logo__inner">
                <div className={createClassNames("tk-layout-with-logo__left", classNames.left)} style={styles.left || {}}>
                    <div className="tk-layout-with-logo__logo-info">
                        <span>{process.env.REACT_APP_NAME}</span>
                        <h3>Company Portal</h3>
                    </div>
                    <div className="tk-layout-with-logo__logo">
                        <Logo size={10} shadow />
                    </div>
                </div>
                <div className={createClassNames("tk-layout-with-logo__right", classNames.right)} style={styles.right || {}}>
                    <div className="tk-layout-with-logo__right__inner">
                        <div className="tk-layout-with-logo__resp-logo-info">
                            <Logo
                                styles={{
                                    image: {
                                        borderRadius: "8px"
                                    }
                                }}
                                size={5}
                                shadow
                            />
                            <div className="flex column info">
                                <span>{process.env.REACT_APP_NAME}</span>
                                <h3>Company Portal</h3>
                            </div>
                        </div>
                        {children}
                        <Copyright styles={{ divider: { ...styles.divider } }} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LayoutWithLogo;

LayoutWithLogo.propTypes = {
    children: PropTypes.any,
    classNames: PropTypes.shape({
        parent: PropTypes.string,
        left: PropTypes.string,
        right: PropTypes.string
    }),
    styles: PropTypes.shape({
        parent: PropTypes.object,
        left: PropTypes.object,
        right: PropTypes.object,
        divider: PropTypes.object
    })
};
