import React from "react";
import PropTypes from "prop-types";
import { useLoadAppRoles } from "./hooks";
import Select from "../../../common/components/extra/select/Select";

function AppRoleSelect({ label, value, onChange, ...rest }) {
    const [, isLoading, { options }] = useLoadAppRoles();

    const getValue = options.find((r) => r.value === value) || "";

    return (
        <Select
            label={label || "App Role"}
            options={options}
            value={getValue}
            onChange={onChange}
            menuPlacement="bottom"
            isLoading={isLoading}
            isOutlined
            disabledOutline
            required
            {...rest}
        />
    );
}

export default AppRoleSelect;

AppRoleSelect.propTypes = {
    label: PropTypes.any,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func
};
