import React from "react";
import PropTypes from "prop-types";
import Navigation from "../../classes/Navigation";
import Logo from "../extra/Logo";
import HeadLogo from "./HeadLogo";
import { useLocation } from "react-router-dom";
import ButtonUpgrade from "../extra/ButtonUpgrade";
import NotifBanner from "../../../features/company/auth/NotifBanner";

function Header({ user, isSuperAdmin, hasLogo }) {
    const location = useLocation();

    const isSettingsSetup = location.pathname == Navigation.Routes.SETTINGS_SETUP.path;
    const showUpgradeButton = !isSettingsSetup;
    const setting = user.Setting || {};
    const use_arabic_name = setting.use_arabic_name;
    const name = use_arabic_name && user.arabic_name ? user.arabic_name : user.name;

    return (
        <div className="tk-header" style={isSettingsSetup ? { paddingLeft: ".5rem", paddingRight: ".5rem" } : {}}>
            <Logo styles={{ parent: { marginBottom: "-.4rem" } }} size={4} hide={!hasLogo} svg />
            {!isSettingsSetup && <NotifBanner showButtonForContractExpiring />}
            <div className="tk-header__right">
                <div className="flex column" style={{ gap: ".2rem" }}>
                    <span className="attention" style={{ textAlign: "right", fontWeight: "bold", whiteSpace: "nowrap" }}>
                        {name}
                    </span>
                    {showUpgradeButton && <div className="tk-header__upgrade">{!isSuperAdmin && <ButtonUpgrade />}</div>}
                </div>
                <HeadLogo user={user} />
            </div>
        </div>
    );
}

Header.propTypes = {
    user: PropTypes.object,
    isSuperAdmin: PropTypes.bool,
    hasLogo: PropTypes.bool
};

export default Header;
