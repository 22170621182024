import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import ArrowIcon from "@mui/icons-material/ArrowForwardIos";
import DotIcon from "@mui/icons-material/FiberManualRecord";
import { useInterval } from "../../../hooks/useInterval";

const CONTROLS = {
    LEFT: 0x1,
    RIGHT: 0x2,
    NAV_DOT: 0x3
};

const MOUSE_EVENT = {
    OUTSIDE: 0x1,
    INSIDE: 0x2
};

function Carousel({ className, items: newItems, item, enableDotNav, autoplay = false, interval = 3, disableAutoPlayOnHover = false, idleTime = 10 }) {
    let idleTimerRef = useRef(null);

    const [active, setActive] = useState(0);
    const [mouseEvent, setMouseEvent] = useState(MOUSE_EVENT.OUTSIDE);
    const [isStartAutoPlay, setStartAutoPlay] = useState(false);

    const items = newItems.filter((items) => items);
    const maxLen = items.length;
    const lastIdx = maxLen - 1;
    const isOnlyOne = maxLen == 1;

    const isInside = mouseEvent == MOUSE_EVENT.INSIDE;
    const isOutside = mouseEvent == MOUSE_EVENT.OUTSIDE;
    const isAutoPlayOn = autoplay && maxLen > 1 && isOutside;

    useInterval(
        () => {
            handleControl(CONTROLS.RIGHT);
        },
        isAutoPlayOn && isStartAutoPlay ? interval * 1000 : null
    );

    const normalizeIndex = (idx) => {
        if (idx > lastIdx) {
            idx = 0;
        } else if (idx < 0) {
            idx = lastIdx;
        }
        setActive(idx);
        return idx;
    };

    const resetIdleTimer = () => {
        if (isStartAutoPlay) {
            setStartAutoPlay(false);
        }
        clearIdleTimer();
        idleTimerRef.current = setTimeout(() => setStartAutoPlay(true), idleTime * 1000);
    };

    const clearIdleTimer = () => {
        if (idleTimerRef.current) {
            clearInterval(idleTimerRef.current);
        }
    };

    const handleControl = (type, idx) => {
        resetIdleTimer();
        switch (type) {
            case CONTROLS.LEFT:
                normalizeIndex(active - 1);
                break;
            case CONTROLS.RIGHT:
                normalizeIndex(active + 1);
                break;
            case CONTROLS.NAV_DOT:
                normalizeIndex(idx);
                break;
            default:
                break;
        }
    };

    const createItemStyle = (idx) => {
        const offset = idx - active;
        return {
            transform: `translateX(${offset * 100}%)`,
            transition: "transform 0.5s ease",
            height: item?.height || "3rem"
        };
    };

    const createItemClass = (idx) => {
        let name = "tk-carousel__item";
        if (active === idx) {
            name += " tk-carousel__item--active";
        }
        return name;
    };

    const createDotClass = (idx) => {
        let name = "tk-carousel__dot";
        if (active === idx) {
            name += " tk-carousel__dot--active";
        }
        return name;
    };

    const handleMouseEnter = () => {
        if (isAutoPlayOn && isOutside && disableAutoPlayOnHover) {
            setMouseEvent(MOUSE_EVENT.INSIDE);
        }
    };

    const handleMouseLeave = () => {
        if (isInside && disableAutoPlayOnHover) {
            setMouseEvent(MOUSE_EVENT.OUTSIDE);
        }
    };

    useEffect(() => {
        resetIdleTimer();
        return () => {
            if (idleTimerRef.current) {
                clearTimeout(idleTimerRef.current);
            }
        };
    }, []);

    if (!maxLen) {
        return <></>;
    }

    return (
        <div className={`tk-carousel ${className || ""}`.trim()} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div className="tk-carousel__container">
                {!isOnlyOne && (
                    <div className="tk-carousel__left">
                        {" "}
                        <ArrowIcon onClick={() => handleControl(CONTROLS.LEFT)} />
                    </div>
                )}
                <div className="tk-carousel__content">
                    <ul className="tk-carousel__items">
                        {items.map((item, idx) => {
                            return (
                                <li key={idx} className={createItemClass(idx)} style={createItemStyle(idx)}>
                                    {item}
                                </li>
                            );
                        })}
                    </ul>
                </div>
                {!isOnlyOne && (
                    <div className="tk-carousel__right">
                        {" "}
                        <ArrowIcon onClick={() => handleControl(CONTROLS.RIGHT)} />
                    </div>
                )}
            </div>
            {enableDotNav && (
                <div className="tk-carousel__footer">
                    <div className="tk-carousel__dots">
                        {items.map((_, idx) => (
                            <DotIcon key={idx} onClick={() => handleControl(CONTROLS.NAV_DOT, idx)} className={createDotClass(idx)} />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Carousel;

Carousel.propTypes = {
    items: PropTypes.array.isRequired,
    item: PropTypes.shape({
        height: PropTypes.string
    }),
    enableDotNav: PropTypes.bool,
    autoplay: PropTypes.bool,
    disableAutoPlayOnHover: PropTypes.bool,
    idleTime: PropTypes.number,
    interval: PropTypes.number,
    className: PropTypes.string
};
