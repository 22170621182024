import React from "react";
import PropTypes from "prop-types";
import BaseViewModal from "../../../common/components/layout/modalViewUpdateLayout/BaseViewModal";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { VIEW_CONTENT_MODAL_BASE_CLASS } from "./const";
import { selectCurrent } from "./slice";
import ViewEditor from "../../../common/components/extra/richTextEditor/ViewEditor";

function ViewContentModal({ open, onClose, noOverlay }) {
    const current = useAppSelector(selectCurrent);
    const content = current.content;

    return (
        <BaseViewModal className={VIEW_CONTENT_MODAL_BASE_CLASS} open={open} onClose={onClose} transparentOverlay={noOverlay} onBack={onClose}>
            <div className="h100">
                <ViewEditor content={content} />
            </div>
        </BaseViewModal>
    );
}

ViewContentModal.propTypes = {
    open: PropTypes.bool,
    noOverlay: PropTypes.bool,
    onClose: PropTypes.func
};

export default ViewContentModal;
