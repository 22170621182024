import React from "react";
import PositionTab from "./Position";

function PositionSettings() {
    return (
        <div className="w100 flex column gap-1" style={{ maxWidth: "none" }}>
            <PositionTab />
        </div>
    );
}

export default PositionSettings;
