import React from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import Button from "./Button";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { selectUser, selectUserSetting, updateUser } from "../../../features/common/slice";
import { useUpdateCompanyProfileMutation } from "../../../features/company/auth/companyAPI";
import { COMPANY_STATUS } from "../../utilities/const";
import { createConfirmAlert, createToast, TOAST_TYPE } from "../../utilities/helper";

function ButtonContractRequest({ isRenewal }) {
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);
    const setting = useAppSelector(selectUserSetting);

    const [updateCompany, { isLoading }] = useUpdateCompanyProfileMutation();

    const status = user.status;
    const isRequestingContract = status?.isRequestingContract;
    const isRenewingContract = status.isRenewingContract;

    const onClick = async () => {
        createConfirmAlert({
            title: isRenewal ? "Renew Contract" : "Request for Full Account",
            content: "Please make sure that your contact details are correct.",
            onConfirm: (onClose) => {
                onClose();
                const temp = {
                    last_renewal_date: moment().tz(setting.timezone),
                    status: isRenewal ? user.status.data : [...user.status.data, COMPANY_STATUS.REQUESTING_CONTRACT]
                };
                updateCompany({
                    body: temp,
                    extraPaths: [user.id, "no-file"]
                }).then((result) => {
                    if (result.error) {
                        return createToast("Sending request failed. Please try again later.", TOAST_TYPE.ERROR);
                    } else {
                        createToast("Request successfully sent. We will reach yout to you soon.", TOAST_TYPE.SUCCESS);
                    }
                    dispatch(
                        updateUser({
                            status: {
                                ...user.status,
                                data: temp.status,
                                isRequestingContract: !isRenewal,
                                isRenewingContract: isRenewal
                            }
                        })
                    );
                });
            }
        });
    };

    if (isRequestingContract || isRenewingContract) {
        return null;
    }

    return (
        <Button
            options={{ style: { minWidth: "max-content" } }}
            className="danger with-shadow"
            onClick={!isRequestingContract && onClick}
            isLoading={isLoading}
            mini
            small
        >
            <span style={{ whiteSpace: "nowrap" }}>{isRenewal ? "Renew Contract" : "Request for a Full Account"}</span>
        </Button>
    );
}

export default ButtonContractRequest;

ButtonContractRequest.propTypes = {
    isRenewal: PropTypes.bool
};
