import React from "react";
import PropTypes from "prop-types";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import PaymentIcon from "@mui/icons-material/Payment";
import Button from "./Button";
import useNavigate from "../../hooks/useNavigate";
import Navigation from "../../classes/Navigation";
import { useCompanyStatusCheck } from "../../../features/company/auth/hooks";
import UpdateFiles from "../../../features/company/auth/UpdateFiles";

function ButtonUpgrade({ title }) {
    const navigate = useNavigate();

    const check = useCompanyStatusCheck();

    const isPendingPayment = check.isPendingPayment;

    const handleUpgrade = () => {
        if (isPendingPayment) {
            navigate(Navigation.Routes.PENDING_PAYMENT.path);
        } else {
            navigate(Navigation.Routes.SUBSCRIPTION_UPGRADE.path);
        }
    };

    const buttonProps = {
        onClick: handleUpgrade,
        options: { style: { height: "2.2rem" } },
        small: true
    };

    if (check.hasSubmission) {
        return <></>;
    }

    if (isPendingPayment) {
        return (
            <Button className="secondary with-shadow" {...buttonProps}>
                <PaymentIcon />
                <span>Update Payment</span>
            </Button>
        );
    }

    return (
        <>
            {check.isRenewUpgradeDisabled && <UpdateFiles />}
            {check.hasContractCopy && (!check.isSubscriptionExpired || (check.isSubscriptionExpired && !check.isRenewUpgradeDisabled)) && (
                <Button className="primary with-shadow" {...buttonProps}>
                    <AutoAwesomeIcon style={{ color: "yellow", opacity: 1 }} />
                    <span>Upgrade</span>
                    {title}
                </Button>
            )}
        </>
    );
}

export default ButtonUpgrade;

ButtonUpgrade.propTypes = {
    title: PropTypes.any
};
