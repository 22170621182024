import React from "react";
import FilePresentIcon from "@mui/icons-material/FilePresent";

import { COMPANY_FILES } from "../../../common/utilities/const";

export const VIEW_MODAL_TYPE = {
    LICENSE_TRADE_COPY: 0x1,
    LICENSE_COMMERCIAL_COPY: 0x2,
    CONTACT_DETAILS: 0x3,
    PREVIEW: 0x4,
    ESTABLISHMENT_ID_COPY: 0x5,
    CONTRACT_COPY: 0x6
};

export const FIELDS = {
    NAME: {
        name: "name",
        label: "Name",
        defaultValue: "",
        required: true
    },
    EMAIL: {
        name: "email",
        label: "Email",
        defaultValue: "",
        required: true
    },
    INDUSTRY_TYPE: {
        name: "industry_type",
        label: "Industry",
        defaultValue: "",
        required: true
    },
    LICENCE_TRADE_NUMBER: {
        name: "license_trade_number",
        label: "License Trade #",
        defaultValue: "",
        required: true
    },
    LICENCE_TRADE_EXPIRY: {
        name: "license_trade_expiry_date",
        label: "License Trade Expiry",
        defaultValue: "",
        required: true
    },
    LICENCE_COMMERCIAL_NUMBER: {
        name: "license_commercial_number",
        label: "License Commercial #",
        defaultValue: "",
        required: true
    },
    LICENCE_COMMERCIAL_EXPIRY: {
        name: "license_commercial_expiry_date",
        label: "License Commercial Expiry",
        defaultValue: "",
        required: true
    },
    CONTRACT_NUMBER: {
        name: "contract_number",
        label: "Contract #",
        defaultValue: "",
        required: true
    },
    CONTRACT_EXPIRY: {
        name: "contract_expiry_date",
        label: "Contract Expiry",
        defaultValue: "",
        required: true
    },
    ESTABLISHMENT_ID: {
        name: "establishment_id",
        label: "Establishment ID",
        defaultValue: "",
        required: true
    },
    ESTABLISHMENT_ID_EXPIRY: {
        name: "establishment_id_expiry_date",
        label: "Establishment ID Expiry",
        defaultValue: "",
        required: true
    },
    EMPLOYEE_COUNT: {
        name: "employee_count",
        label: "# of Employees",
        defaultValue: "",
        required: true
    },
    ADDRESS: {
        name: "address",
        label: "Address",
        defaultValue: "",
        required: true
    },
    COUNTRY: {
        name: "country",
        label: "Country",
        defaultValue: "",
        required: true
    },
    CONTACT_NAME: {
        name: "contact_name",
        label: "Name",
        defaultValue: "",
        required: true
    },
    CONTACT_EMAIL: {
        name: "contact_email",
        label: "Email",
        defaultValue: "",
        required: true
    },
    CONTACT_NUMBER: {
        name: "contact_number",
        label: "Number",
        defaultValue: "",
        required: true
    },
    CONTACT_POSITION: {
        name: "contact_position",
        label: "Position",
        defaultValue: "",
        required: true
    },
    STATUS: {
        name: "status",
        label: "Status",
        defaultValue: [],
        required: true
    }
};

export const UPLOAD_FIELDS = {
    LOGO: {
        key: COMPANY_FILES.LOGO.key,
        defaultValue: "",
        size: 3, // 3MB
        accepts: COMPANY_FILES.LOGO.accepts.toString(),
        label: "Logo"
    },
    LICENSE_TRADE: {
        key: COMPANY_FILES.LICENSE_TRADE.key,
        defaultValue: "",
        size: 3, // 3MB,
        accepts: COMPANY_FILES.LICENSE_TRADE.accepts.toString(),
        label: "License Trade Copy"
    },
    LICENSE_COMMERCIAL: {
        key: COMPANY_FILES.LICENSE_COMMERCIAL.key,
        defaultValue: "",
        size: 3, // 3MB,
        accepts: COMPANY_FILES.LICENSE_COMMERCIAL.accepts.toString(),
        label: "License Commercial Copy"
    },
    ESTABLISHMENT_ID: {
        key: COMPANY_FILES.ESTABLISHMENT_ID.key,
        defaultValue: "",
        size: 3, // 3MB,
        accepts: COMPANY_FILES.ESTABLISHMENT_ID.accepts.toString(),
        label: "Establishment ID Copy"
    },
    COMPANY_CONTRACT: {
        key: COMPANY_FILES.COMPANY_CONTRACT.key,
        defaultValue: "",
        size: 3, // 3MB,
        accepts: COMPANY_FILES.COMPANY_CONTRACT.accepts.toString(),
        label: "Contract Copy"
    }
};

export const MORE_ACTIONS = {
    VIEW_CONTRACT: {
        id: 0x2,
        label: "View Contract",
        icon: <FilePresentIcon style={{ width: "1.4rem" }} />
    },
    VIEW_LICENSE: {
        id: 0x1,
        label: "View License",
        icon: <FilePresentIcon style={{ width: "1.4rem" }} />
    },
    VIEW_EID: {
        id: 0x3,
        label: "View EID",
        icon: <FilePresentIcon style={{ width: "1.4rem" }} />
    }
};

export const STATUS_ACTIONS = {
    DISAPPROVE: 0x2,
    ACTIVATE: 0x3,
    DEACTIVATE: 0x4,
    COMPLETE_PAYMENT: 0x5,
    PENDING_PAYMENT: 0x6
};

export const UPDATE_FILE_TYPE = {
    STAND_ALONE: 0x1,
    CONTRACT_RENEWAL: 0x2,
    FILE_CHANGED: 0x3,
    APPROVAL: 0x4,
    REQUEST_CONTRACT: 0x5
};

export const TABLE_ACTIONS = {
    APPROVE: "APPROVE",
    CONTACT_DETAILS: "CONTACT_DETAILS",
    PREVIEW: "PREVIEW"
};
