import React, { Component } from "react";
import isEqual from "lodash/isEqual";
import PropTypes from "prop-types";
import DeleteIcon from "@mui/icons-material/Delete";
import { MAP_CHANGE_TYPE, MOUSE_EVENTS, MVC_PATH_EVENT } from "./const";
import { calculateCoordinatesCenter, getBoundsLiteral, mvcArrayGeoValues } from "./helper";
import Button from "../Button";
import Marker from "./Marker";
import MapInfoWindow from "./MapInfoWindow";

export default class PolygonManager extends Component {
    #instance = null;
    #map = null;
    #maps = null;
    #listeners = [];

    constructor(props) {
        super(props);
        this.#instance = props.instance;
        this.#map = props.map;
        this.#maps = props.maps;

        this.state = { isDragging: false };
    }

    get Options() {
        return {
            index: this.props.index,
            instance: this.#instance,
            map: this.#map,
            maps: this.#maps,
            editable: this.props.editable,
            onInitialize: this.props.onInitialize,
            onChange: this.props.onChange,
            // controlling info window
            withCenterMarker: this.props.withCenterMarker,
            onPolySelect: this.props.onPolySelect,
            selectedPolyKey: this.props.selectedPolyKey
        };
    }

    get Center() {
        const coordinates = mvcArrayGeoValues(this.Options.instance.getPath());
        return calculateCoordinatesCenter(coordinates);
    }

    componentDidMount() {
        this.init();
    }

    componentWillUnmount() {
        this.#listeners.length && this.#listeners.forEach((listener) => listener.remove());
    }

    init() {
        const { instance, map, maps, onInitialize, onChange, onPolySelect } = this.Options;

        if (!instance || !map || !maps) {
            return;
        }

        const oldpath = mvcArrayGeoValues(instance.getPath());

        const handlePathChange = (oldpath = [], instance) => {
            const newpaths = mvcArrayGeoValues(instance.getPath());
            if (!isEqual(newpaths, oldpath)) {
                onPolySelect?.(null);
                onChange?.(this.createParams({ coordinates: newpaths }), MAP_CHANGE_TYPE.POLY_PATH_CHANGED);
            }
        };

        this.#listeners = [
            // listen for new path changes
            instance.addListener(MOUSE_EVENTS.DRAG, () => {
                onPolySelect?.(null);
            }),
            instance.addListener(MOUSE_EVENTS.DRAG_START, () => {
                onPolySelect?.(null);
                this.setState({ isDragging: true });
            }),
            instance.addListener(MOUSE_EVENTS.DRAG_END, () => {
                handlePathChange(oldpath, instance);
                this.setState({ isDragging: false });
            })
        ];

        for (const event of Object.values(MVC_PATH_EVENT)) {
            this.#listeners.concat(
                instance.getPath().addListener(event, () => {
                    handlePathChange(oldpath, instance);
                    this.setState({}); // forece rerender
                })
            );
        }
        onInitialize?.(this.createParams(), MAP_CHANGE_TYPE.POLY_INITIALIZED);
    }

    createParams(newObj = {}) {
        const { map, maps, coordinates } = this.Options;
        return {
            map: newObj.map || map,
            maps: newObj.maps || maps,
            coordinates: newObj.coordinates || coordinates,
            latlangLiteral: getBoundsLiteral(maps, newObj.coordinates || coordinates || [])
        };
    }

    render() {
        const { instance, map, maps, onChange, withCenterMarker, onPolySelect, index, selectedPolyKey, editable } = this.Options;

        const handleRemovePolygon = () => {
            instance.setPath([]);
            onChange?.([], MAP_CHANGE_TYPE.POLY_REMOVE);
            onPolySelect?.(null);
        };

        const windowContent = (
            <Button beforeExtra={<DeleteIcon />} className="danger" onClick={handleRemovePolygon.bind(this)} small mini>
                Remove Area
            </Button>
        );

        return (
            <>
                {withCenterMarker && this.Center && !this.state.isDragging && (
                    <Marker
                        map={map}
                        maps={maps}
                        lat={this.Center.lat}
                        lng={this.Center.lng}
                        title={instance.title || `Site ${index + 1}`}
                        onClick={() => onPolySelect?.(index)}
                        isCenter
                    />
                )}
                {editable && selectedPolyKey == index && this.Center && !this.state.isDragging && (
                    <MapInfoWindow
                        title={instance.title || `Site ${index + 1}`}
                        content={windowContent}
                        map={map}
                        position={this.Center}
                        onCloseClick={() => onPolySelect?.(null)}
                        minWidth={200}
                        maxWidth={200}
                        shouldFocus
                    />
                )}
            </>
        );
    }
}

PolygonManager.propTypes = {
    index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    instance: PropTypes.object,
    map: PropTypes.object,
    maps: PropTypes.object,
    editable: PropTypes.bool,
    onInitialize: PropTypes.func,
    onChange: PropTypes.func,
    selectedPolyKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onPolySelect: PropTypes.func,
    withCenterMarker: PropTypes.bool
};
