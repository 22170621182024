import React from "react";
import { Link } from "react-router-dom";
import { useCompanyStatusCheck, useGetLicenseTimeRemaining } from "./hooks";
import Navigation from "../../../common/classes/Navigation";
import { TABS } from "../../../pages/Settings";

function LicenseCommercialExpiring() {
    const check = useCompanyStatusCheck();
    const licenseTimeRemaining = useGetLicenseTimeRemaining(false, { isCommercial: true });

    const isExpired = licenseTimeRemaining.isExpired;
    const isProfile = location.pathname == Navigation.Routes.SETTINGS.path && location.hash == "#profile";
    const showGotoProfile = !check.isSubscriptionExpired && !check.isSettingsSetup && !isProfile;

    const defaultMsg = (
        <span>
            Your <strong>Commerical License</strong> is expiring in {licenseTimeRemaining.text}. Please upload a new License to ensure uninterrupted
            service.{" "}
            {showGotoProfile && (
                <Link key={3} to={Navigation.Routes.SETTINGS.path + "#" + TABS.PROFILE.id}>
                    Click to go to Profile
                </Link>
            )}
        </span>
    );

    const expMsg = (
        <span>
            Your <strong>Commerical License</strong> is expired. Update your license in the profile section if you want uninterrupted service after
            your subscription ends.{" "}
            {showGotoProfile && (
                <Link key={3} to={Navigation.Routes.SETTINGS.path + "#" + TABS.PROFILE.id}>
                    Click to go to Profile
                </Link>
            )}
        </span>
    );

    if (!licenseTimeRemaining.showWarning) {
        return <></>;
    }

    return (
        <div className="flex w100 gap-1 small-font semi-bold center" style={{ textAlign: "center" }}>
            {isExpired ? expMsg : defaultMsg}
        </div>
    );
}

export default LicenseCommercialExpiring;
