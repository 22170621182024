import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Navigation from "../../../common/classes/Navigation";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import { TABS } from "../../../pages/Settings";
import { useCompanyStatusCheck } from "./hooks";

function ContractRequested({ onlyMessage }) {
    const location = useLocation();
    const user = useAppSelector(selectUser);
    const check = useCompanyStatusCheck();

    const status = user.status;
    const isRequestingContract = status?.isRequestingContract;
    const isRenewingContract = status.isRenewingContract;
    const isProfile = location.pathname == Navigation.Routes.SETTINGS.path && location.hash == "#profile";
    const showGotoProfile = !check.isSubscriptionExpired && !check.isSettingsSetup && !onlyMessage && !isProfile;

    if (!isRequestingContract && !isRenewingContract) {
        return <></>;
    }

    return (
        <div className="flex w100 small-font semi-bold center">
            <span style={{ textAlign: "center" }}>
                Your Contract Request has been submitted. Please ensure your contact details are up-to-date. One of our staff members will reach out
                to you soon.{" "}
                {showGotoProfile && (
                    <Link key={3} to={Navigation.Routes.SETTINGS.path + "#" + TABS.PROFILE.id}>
                        Click to go to Profile
                    </Link>
                )}
            </span>
        </div>
    );
}

export default ContractRequested;

ContractRequested.propTypes = {
    onlyMessage: PropTypes.bool
};
