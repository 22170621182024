import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import ViewIcon from "@mui/icons-material/Visibility";
import RemoveIcon from "@mui/icons-material/RemoveCircle";
import Button from "../Button";
import { TOAST_TYPE, blobToBase64, bytesToMegabytes, createConfirmAlert, createToast, isFileObject } from "../../../utilities/helper";
import MyTooltip from "../Tooltip";

function InputFile({
    label,
    required,
    icon,
    internalIcon,
    style,
    accept,
    sizeLimit,
    name,
    onView,
    hidelabel,
    isBase64,
    alwaysEnableRemove,
    ...inputProps
}) {
    const inputFileRef = useRef(null);
    const [value, setValue] = useState("");

    const hasFile = !!value?.name;

    useEffect(() => {
        const isString = typeof inputProps.value == "string";
        const isBoolean = typeof inputProps.value == "boolean";
        const isFileObj = isFileObject(inputProps.value);
        if ((isBoolean || isFileObj || isString) && !isEqual(value, inputProps.value)) {
            setValue(inputProps.value);
        }
    }, [inputProps.value]);

    const handleFiles = async (e, file) => {
        if (file) {
            const accepts = (accept && accept.split(",")) || [];
            const isAllowed = accepts.includes(file.type);
            const isLimitExceeded = bytesToMegabytes(file.size) > sizeLimit;
            if (!isAllowed) {
                createToast(
                    `Invalid file type. Only accepts the following: (${accepts.map((a) => a.split("/").pop()).join(", ")})`,
                    TOAST_TYPE.ERROR
                );
                e && (e.target.value = null);
                return;
            }
            if (isLimitExceeded) {
                createToast(`Invalid file size. Must be less than ${sizeLimit}MB`, TOAST_TYPE.ERROR);
                e && (e.target.value = null);
                return;
            }
        }
        if (isBase64) {
            const b64 = await blobToBase64(file);
            setValue(b64);
            typeof inputProps.onChange === "function" && inputProps.onChange(e, b64);
        } else {
            setValue(file);
            typeof inputProps.onChange === "function" && inputProps.onChange(e, file);
        }
    };

    const handleChange = async (e) => {
        const files = e.target.files;
        const file = files[0];
        await handleFiles(e, file);
    };

    const handleFileRemove = () => {
        inputFileRef.current.value = "";
        setValue("");
        typeof inputProps.onChange === "function" &&
            inputProps.onChange(
                {
                    target: { files: [false], name }
                },
                ""
            );
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const files = e.dataTransfer.files;
        handleFiles(e, files[0]);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const createAcceptText = () => {
        if (!accept) {
            return "";
        }
        return `Only accepts:(${accept
            .split(",")
            .map((str) => str.split("/").pop())
            .join(", ")})`;
    };

    const renderRemoveIcon = (
        <Button
            className="small-font remove-icon"
            options={{
                style: { color: "red", textDecoration: "underline", padding: 0 },
                type: "button"
            }}
            onClick={() =>
                createConfirmAlert({
                    title: "Are you sure?",
                    content: "This will remove the file. Are you sure?",
                    onConfirm: (onClose) => {
                        handleFileRemove();
                        onClose();
                    }
                })
            }
            transparent
        >
            <MyTooltip className="flex center" message="Remove File">
                <RemoveIcon />
            </MyTooltip>
        </Button>
    );

    const createClass = () => {
        let temp = "tk-input__upload";
        if (isBase64) {
            temp += " b64";
        }
        if (value) {
            temp += " has-value";
        }
        return temp.trim();
    };

    return (
        <div onDrop={handleDrop} onDragOver={handleDragOver} className={createClass()} style={style || {}}>
            {!hidelabel && label && (
                <div>
                    {label}
                    {(required && <span className="danger-color bold">*</span>) || ""}
                </div>
            )}
            <div className="flex">
                <div className="tk-input__upload__file w100">
                    <div className="left" onClick={() => inputFileRef.current && inputFileRef.current.click()}>
                        <input
                            ref={inputFileRef}
                            {...inputProps}
                            name={name}
                            value=""
                            type="file"
                            onChange={handleChange}
                            accept={accept}
                            required={value === true || (isFileObject(value) ? false : required)}
                            style={{ opacity: 0, zIndex: -1 }}
                        />
                        {internalIcon || icon}
                        <span className="tk-input__upload__file-text semi-bold">
                            {(value && value.name) || (value && typeof value == "string") ? (
                                <span className="flex gap-05 align-center">
                                    <span style={{ whiteSpace: "nowrap" }}>Click or Drag files to replace: </span>
                                    {!isBase64 && (
                                        <>
                                            <strong>{value?.name || value}</strong>
                                        </>
                                    )}
                                </span>
                            ) : typeof value === "boolean" && value === true ? (
                                `Click or Drag files to replace the existing file. ${createAcceptText()}`.trim()
                            ) : (
                                `Click or Drag files here to upload. ${createAcceptText()}`.trim()
                            )}

                            {isBase64 && value && <img src={value} alt="preview" className="responsive-img tk-input__upload__preview" />}
                            {isBase64 && (
                                <div>
                                    <span></span>
                                </div>
                            )}
                        </span>
                    </div>
                    {!isBase64 && (onView || hasFile) && (
                        <div className="right flex gap-05" style={{ marginLeft: ".5rem" }}>
                            {onView && (
                                <Button
                                    className="small-font view-icon"
                                    options={{ style: { textDecoration: "underline", padding: 0 }, type: "button" }}
                                    onClick={onView}
                                    transparent
                                >
                                    <MyTooltip className="flex center" message="View File">
                                        <ViewIcon />
                                    </MyTooltip>
                                </Button>
                            )}
                            {(hasFile || (alwaysEnableRemove && inputProps.value)) && renderRemoveIcon}
                        </div>
                    )}
                </div>
                {isBase64 && value && <div className="tk-input__upload__remove">{renderRemoveIcon}</div>}
            </div>
        </div>
    );
}

InputFile.propTypes = {
    label: PropTypes.any,
    required: PropTypes.bool,
    icon: PropTypes.any,
    internalIcon: PropTypes.any,
    style: PropTypes.object,
    accept: PropTypes.any,
    sizeLimit: PropTypes.any,
    name: PropTypes.string,
    onView: PropTypes.func,
    hidelabel: PropTypes.bool,
    isBase64: PropTypes.bool,
    alwaysEnableRemove: PropTypes.bool
};

export default InputFile;
